import FormFieldProps from 'components/Form/FormField.type'
import { shipmentPairerTranslations } from './ShipmentPairer.data'

export const mapDevicesToObjects = (deviceIds: string[]): FormFieldProps[] => {
  // Translated Data //
  const { translation } = shipmentPairerTranslations()
  return deviceIds.map((device) => {
    return {
      name: device,
      label: translation.card.label.beacon_id,
      id: device,
      value: device,
    }
  })
}
