import { FunctionComponent, PropsWithChildren, ReactNode } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

// Functional
import { isProd } from 'global/helpers/environment'
import { getSelectedCompany } from 'context/authentication/User.helpers'
import { Role } from 'context/authentication/Role.types'
import { CompanyType } from 'context/authentication/Company.types'

const OperatorAdminRoute: FunctionComponent<PropsWithChildren<RouteProps>> = ({ children, ...rest }) => {
  const noProd = !isProd()
  const company = getSelectedCompany()
  const isOperatorAdmin = company?.role === Role.Admin && company?.type === CompanyType.Operator

  return (
    <Route
      {...rest}
      render={(): ReactNode => {
        return noProd && isOperatorAdmin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }}
    />
  )
}

export default OperatorAdminRoute
