import { ReactElement } from 'react'

// @mui imports
import Alert from '@mui/material/Alert'
import Fade from '@mui/material/Fade'
import Snackbar from '@mui/material/Snackbar'

// Types
import { ShipmentDetailsMenuAlertProps } from './ShipmentDetailsMenu.types'

const ShipmentDetailsMenuAlert = ({
  message,
  alertOpen,
  setAlertOpen,
}: ShipmentDetailsMenuAlertProps): ReactElement => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={alertOpen}
      onClose={(): void => setAlertOpen(false)}
      TransitionComponent={Fade}
      autoHideDuration={message?.persistent ? null : 3000}
      key="shipmentDetailsMenuAlert"
    >
      <Alert
        variant="filled"
        severity={message?.status === '200' ? 'success' : message?.status === '400' ? 'error' : 'info'}
        onClose={(): void => setAlertOpen(false)}
      >
        {message?.messageText ?? message?.name}{' '}
        {message?.status === '200' ? 'succeeded' : message?.status === '400' ? 'failed' : null}
      </Alert>
    </Snackbar>
  )
}

export default ShipmentDetailsMenuAlert
