import { ReactElement, useContext, useEffect, useState } from 'react'
import { ref, getDatabase, onValue } from 'firebase/database'
import { app, firebaseAvailable } from '../../firebase'

// @mui material imports
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Link from '@mui/material/Link'

// Types
import { BruceBannerMessageProps, BruceBannerProps, BruceBannerType } from './BruceBanner.type'

// Helpers
import { messageValidator } from './BruceBanner.helpers'

// Context
import { UserContext } from 'context/authentication/UserContext'

// Data
import { bruceBannerTranslations } from './BruceBanner.data'

const BruceBanner = (props: BruceBannerProps): ReactElement => {
  if (!firebaseAvailable) return <></>
  // Context
  const { userTimezone } = useContext(UserContext)

  const { moduleName } = props
  // Firebase config
  const firebaseDB = getDatabase(app)
  // Module specific
  const [bruceBannerMessages, setBruceBannerMessages] = useState([])
  const [bruceBannerOpen, setBruceBannerOpen] = useState(false)
  const handleBruceBannerClose = (messageId: number): void => {
    localStorage.setItem('bannerMessageRead', String(messageId))
    setBruceBannerOpen(false)
  }

  useEffect(() => {
    const firebaseQuery = ref(firebaseDB, `/update_notifications_${moduleName}`)
    return onValue(firebaseQuery, (snapshot) => {
      const data = snapshot.val()

      if (snapshot.exists()) {
        setBruceBannerMessages(Object.values(data))
      }
    })
  }, [])

  const bruceBannerActiveMessage = messageValidator(bruceBannerMessages, moduleName, userTimezone)

  // Translated Data //
  const { translation } = bruceBannerTranslations(
    bruceBannerActiveMessage?.visualDate,
    bruceBannerActiveMessage?.visualLiveDate
  )

  useEffect(() => {
    if (bruceBannerActiveMessage?.visible) {
      setBruceBannerOpen(true)
    }
  }, [bruceBannerActiveMessage])

  const getBruceMessage = (message: BruceBannerMessageProps): string => {
    switch (message.type) {
      case BruceBannerType.downtime:
        return translation.information_msg_downtime
      case BruceBannerType.maintenance:
        return message.pastToday
          ? translation.information_msg_maintenance_in_progress
          : translation.information_msg_maintenance_future
      default:
        return message.pastToday ? translation.information_msg_past : translation.information_msg_future
    }
  }

  return (
    <Collapse in={bruceBannerOpen}>
      {bruceBannerActiveMessage && (
        <Alert
          data-test="bruce-banner"
          severity="info"
          variant="filled"
          onClose={(): void => handleBruceBannerClose(bruceBannerActiveMessage.updateDate)}
        >
          {getBruceMessage(bruceBannerActiveMessage)}{' '}
          {bruceBannerActiveMessage.showLink && (
            <Link href="/help&info" variant="underlined">
              {translation.help_info}
            </Link>
          )}
        </Alert>
      )}
    </Collapse>
  )
}

export default BruceBanner
