import { ReactElement } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'

// @mui imports //
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'

// KN imports //
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'

// Functional //
import { getErrorMessage } from 'global/helpers/errorHandler'
import { sleep } from 'global/helpers/sleep'
import { deleteConditionGroup } from './AccessGroupTable.service'

// Data //
import { sharingRulesTranslations } from 'screens/SharingRules/SharingRules.data'

// Types //
import { DeleteConditionGroupDialogProps } from './AccessGroupTable.types'

const DeleteAccessGroupDialog = ({
  payload,
  open,
  onAction,
  onClose,
}: DeleteConditionGroupDialogProps): ReactElement => {
  // Translated data //
  const { translation } = sharingRulesTranslations()
  const { handleSubmit, formState, setError } = useForm()

  const onSubmit: SubmitHandler<object> = async (): Promise<void> => {
    try {
      if (payload.cid) await deleteConditionGroup(payload.cid)
      window.location.reload()
      onAction()
    } catch (error) {
      setError('root.server', {
        message: getErrorMessage(error),
      })
    }
  }

  return (
    <KNDialog
      open={open}
      onClose={onClose}
      closeLabel={translation.cancelDelete}
      preventClosing={formState.isSubmitting}
      title={`${String(payload.name)} — ${String(translation.delete)}`}
      actions={
        <>
          <KNLoadingButton
            type="submit"
            color="error"
            variant="contained"
            loading={formState.isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {translation.delete}
          </KNLoadingButton>
        </>
      }
      onSubmit={handleSubmit(onSubmit)}
      maxWidth="xs"
    >
      {formState.errors?.root?.server && (
        <DialogContentText component="div" mb={3}>
          <Alert severity="error">{formState.errors.root.server.message}</Alert>
        </DialogContentText>
      )}
      <DialogContentText>{translation.deleteMessage}</DialogContentText>
    </KNDialog>
  )
}

export default DeleteAccessGroupDialog
