import { ReactElement } from 'react'

// @mui material imports //
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'
import InfoIcon from '@mui/icons-material/Info'
import Tooltip from '@mui/material/Tooltip'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN Components //
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import theme from 'assets/theme'

// Functional //
import { truncatePropertyValue } from './GeneralInfoList.helpers'

// Types //
import { GeneralInfoListItemProps } from 'context/detailsNext/InsightDetails.types'

const GeneralListItemTemplate = (generalListItem: GeneralInfoListItemProps, key: number): ReactElement => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      sx={{ display: 'inline-flex', alignItems: 'center' }}
      data-test={`generalInfoListItem-${generalListItem.propertyName.replace(/ /g, '')}`}
      key={key}
    >
      {/* Icon box */}
      <Box
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            linearGradient(rgba(gradients.secondary.main, 1), rgba(gradients.primary.main, 1)),
          backgroundSize: 'cover',
          backgroundPosition: '50%',
          color: 'white.main',
          borderRadius: '4px',
          width: 35,
          height: 35,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon color="inherit">{generalListItem.icon}</Icon>
      </Box>
      {/* Property name */}
      <Box ml={1}>
        <KNTypography variant="displayXXS_SB" color="dark.main">
          {generalListItem.propertyName}
        </KNTypography>
      </Box>
      {/* Property value */}
      <Box ml={1} sx={{ display: 'flex', alignItems: 'center' }}>
        <KNTypography variant="displayXXS" color={generalListItem.color ?? 'dark.main'}>
          {generalListItem.propertyValue && isMobile
            ? truncatePropertyValue(generalListItem.propertyValue, 20)
            : truncatePropertyValue(generalListItem.propertyValue, 40)}
        </KNTypography>
        {generalListItem.additionalInfo && (
          <Tooltip
            title={
              <Box sx={{ textAlign: 'left' }}>
                <b>{generalListItem.additionalInfo.text}</b>
                <Box my={1}>
                  {generalListItem.additionalInfo.info.map((i, index) => (
                    <Box my={0.5} key={index}>
                      - {i}
                    </Box>
                  ))}
                </Box>
              </Box>
            }
          >
            <InfoIcon sx={{ ml: 1 }} color="primary" fontSize="small" />
          </Tooltip>
        )}
      </Box>
    </Box>
  )
}

export default GeneralListItemTemplate
