import i18n from 'i18n'

export const visibilityMapTranslation = (): Record<any, any> => {
  return {
    firstKnownLocationLabel: 'A',
    firstKnownLocationTooltipTitle: i18n.t('modules.cv.address_map.marker.firstKnownLocation'),
    lastKnownLocationLabel: 'B',
    lastKnownLocationTooltipTitle: i18n.t('modules.cv.address_map.marker.lastKnownLocation'),
    waypointTooltipTitle: i18n.t('modules.cv.address_map.marker.waypoint'),
    pickUpAddressTooltipTitle: i18n.t('modules.cv.address_map.marker.pickupAddress'),
    deliveryAddressTooltipTitle: i18n.t('modules.cv.address_map.marker.deliveryAddress'),
    filterDateFrom: i18n.t('modules.cv.address_map.filters.filterDateFrom'),
    filterDateTo: i18n.t('modules.cv.address_map.filters.filterDateTo'),
    filterErrorInvalidDate: i18n.t('modules.cv.address_map.filters.filterErrorInvalidDate'),
    filterErrorFutureDate: i18n.t('modules.cv.address_map.filters.filterErrorFutureDate'),
    filter6hours: i18n.t('modules.cv.address_map.filters.filter6hours'),
    filter12hours: i18n.t('modules.cv.address_map.filters.filter12hours'),
    departureAirport: i18n.t('modules.cv.address_map.marker.departureAirport'),
    arrivalAirport: i18n.t('modules.cv.address_map.marker.arrivalAirport'),
    liveAirplane: i18n.t('modules.cv.address_map.marker.liveAirplane'),
  }
}
