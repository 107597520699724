import { fetchGet, fetchPost, fetchPut } from 'global/helpers/fetchWrapper'

// Types
import { PairingDataProps } from './ShipmentPairer.type'

export const getPairingInfo = async (pairingCid: string): Promise<PairingDataProps> => {
  try {
    return (await fetchGet(`pairings/${pairingCid}`, {}, 'si')) as PairingDataProps
  } catch (error) {
    return Promise.reject(error)
  }
}

export const pairShipment = async (
  reference: string,
  devices: (string | undefined)[],
  unpairingRule: boolean,
  description?: string,
  temperatureThreshold?: { min: number | null; max: number | null }
): Promise<string> => {
  const regularParams = {
    reference: reference,
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    description: description || null,
    deviceIds: devices,
    unpairingRule: unpairingRule,
  }

  const optionalParams = {
    temperatureThreshold: temperatureThreshold
      ? {
          min: temperatureThreshold.min,
          max: temperatureThreshold.max,
        }
      : null,
  }

  try {
    return await fetchPost(
      'pairings',
      {
        ...regularParams,
        ...optionalParams,
      },
      {},
      'si'
    ).then((_) => 'success')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const editPairing = async (
  pairingCid: string,
  reference: string,
  devices: (string | undefined)[],
  unpairingRule: boolean,
  description?: string,
  temperatureThreshold?: { min: number | null; max: number | null }
): Promise<string> => {
  const regularParams = {
    reference: reference,
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    description: description || null,
    deviceIds: devices,
    unpairingRule: unpairingRule,
  }

  const optionalParams = {
    temperatureThreshold: temperatureThreshold
      ? {
          min: temperatureThreshold.min,
          max: temperatureThreshold.max,
        }
      : null,
  }

  try {
    return await fetchPut(
      `pairings/${pairingCid}`,
      {
        ...regularParams,
        ...optionalParams,
      },
      {},
      'si'
    ).then((_) => 'success')
  } catch (error) {
    return Promise.reject(error)
  }
}
