import { toDate, format, utcToZonedTime } from 'date-fns-tz'
import { ChartData, ChartOptions } from 'chart.js'
import i18n from 'i18n'

// @mui imports
import theme from 'assets/theme'

// Types
import { TemperatureDataSetProps, TemperatureThresholdProps } from './TemperatureChart.types'

// COLOR LIST //
const colors = theme.palette
const colorsForChart = [
  colors.primary.main,
  colors.secondary.main,
  colors.success.main,
  colors.error.main,
  colors.warning.main,
  colors.success.light,
  colors.primary.dark,
  colors.info.main,
]

export const getUniqueDevices = (temperatureDataSets: TemperatureDataSetProps[]): string[] =>
  temperatureDataSets
    .map((device: TemperatureDataSetProps) => device.deviceId)
    .filter((value, index, device) => device.indexOf(value) === index)
    .sort(function (a, b) {
      return a.localeCompare(b)
    })

export const mapToChartData = (
  deviceIds: string[],
  temperatureDataSets: TemperatureDataSetProps[],
  filteredIds?: string[]
): ChartData<'scatter'> => {
  const uniqueDevices = getUniqueDevices(temperatureDataSets)

  const getDeviceIndex = (deviceId: string): number => {
    return deviceIds.indexOf(deviceId)
  }

  const mappedTemperatureDataSets = uniqueDevices.map((dataSet) => {
    return {
      label: 'Temperature Data',
      values: temperatureDataSets.filter(
        (temperatureDataSet) =>
          temperatureDataSet.deviceId === dataSet && temperatureDataSet.type !== 'TEMPERATURE_PREDICTION'
      ),
    }
  })

  const mappedPredictionDataSets = uniqueDevices.map((dataSet) => {
    return {
      label: 'Prediction Data',
      values: temperatureDataSets.filter(
        (temperatureDataSet) =>
          temperatureDataSet.deviceId === dataSet && temperatureDataSet.type === 'TEMPERATURE_PREDICTION'
      ),
    }
  })

  const valuesForTemperatureChart = mappedTemperatureDataSets
    .filter((mappedDataSet) => mappedDataSet.values.length > 0)
    .map((mappedDataSet) =>
      mappedDataSet.values.map((props) => {
        const timestamp = toDate(props.timestamp).getTime()
        return {
          id: props.deviceId,
          graphValues: {
            x: timestamp,
            y: Number(props.value),
          },
          label: mappedDataSet.label,
        }
      })
    )

  const predictionsForTemperatureChart = mappedPredictionDataSets
    .filter((mappedDataSet) => mappedDataSet.values.length > 0)
    .map((mappedDataSet) =>
      mappedDataSet.values.map((props) => {
        const timestamp = toDate(props.timestamp).getTime()
        return {
          id: props.deviceId,
          graphValues: {
            x: timestamp,
            y: Number(props.value),
          },
          label: mappedDataSet.label,
        }
      })
    )

  const chartDataValues = {
    datasets: [
      ...valuesForTemperatureChart.flatMap((device, index) => {
        return {
          label: `${i18n.t('modules.cv.temperature_chart.label.id')}: ${device[0].id}`,
          fill: false,
          lineTension: 0.1,
          borderWidth: 1,
          data: filteredIds?.includes(device[0].id) ? [] : device.map((set) => set.graphValues),
          borderColor: colorsForChart[getDeviceIndex(device[0].id)] || colorsForChart[0],
          backgroundColor: colorsForChart[getDeviceIndex(device[0].id)] || colorsForChart[0],
          visible: filteredIds ? !filteredIds.includes(device[0].id) : true,
        }
      }),
      ...predictionsForTemperatureChart.flatMap((device, index) => {
        return {
          label: `${i18n.t('modules.cv.temperature_chart.label.prediction')}`,
          fill: false,
          lineTension: 0.1,
          borderWidth: 1,
          data: filteredIds?.includes(device[0].id) ? [] : device.map((set) => set.graphValues),
          borderColor: `${colorsForChart[7]}77`,
          backgroundColor: `${colorsForChart[7]}77`,
          visible: filteredIds ? !filteredIds.includes(device[0].id) : true,
          prediction: true,
          borderDash: [5, 5],
        }
      }),
    ],
  }

  return chartDataValues
}

export const getHighestTemperatureValue = (dataSets: TemperatureDataSetProps[]): number =>
  Math.max(...dataSets.map((dataSet) => Number(dataSet.value)))

export const getLowestTemperatureValue = (dataSets: TemperatureDataSetProps[]): number =>
  Math.min(...dataSets.map((dataSet) => Number(dataSet.value)))

export const getHighestTimestampValue = (dataSets: TemperatureDataSetProps[], filteredDateTo?: Date): number =>
  filteredDateTo
    ? toDate(filteredDateTo).getTime()
    : Math.max(...dataSets.map((dataSet) => toDate(dataSet.timestamp).getTime()))

export const getLowestTimestampValue = (dataSets: TemperatureDataSetProps[], filteredDateFrom?: Date): number =>
  filteredDateFrom
    ? toDate(filteredDateFrom).getTime()
    : Math.min(...dataSets.map((dataSet) => toDate(dataSet.timestamp).getTime()))

export const getThresholdBreachedXMinValue = (
  dataSets: TemperatureDataSetProps[],
  breachValue?: number
): number | undefined => {
  if (!breachValue) return
  const breachedPointIndex = dataSets.findIndex((el) => Number(el.value) === breachValue)
  if (breachedPointIndex === -1) return
  return toDate(dataSets[breachedPointIndex - 1].timestamp).getTime()
}

export const getThresholdBreachedXMaxValue = (
  dataSets: TemperatureDataSetProps[],
  breachValue?: number
): number | undefined => {
  if (!breachValue) return
  const breachedPointIndex = dataSets.findIndex((el) => Number(el.value) === breachValue)
  if (breachedPointIndex === -1) return
  return toDate(dataSets[breachedPointIndex + 1].timestamp).getTime()
}

export const getChartOptions = (
  lowestXValue: number,
  highestXValue: number,
  lowestYValue: number,
  highestYValue: number,
  temperatureLabelVisible: boolean,
  setTemperatureLabelVisible: (state: boolean) => void,
  userTimezone: string,
  temperatureThreshold?: TemperatureThresholdProps,
  firstPredictionValue?: number,
  lastPredictionValue?: number,
  thresholdBreachedXMinValue?: number,
  thresholdBreachedXMaxValue?: number
): ChartOptions<'scatter'> => {
  const thresholdMin = temperatureThreshold?.min
  const thresholdMax = temperatureThreshold?.max
  const yMin = Math.min(thresholdMin ?? thresholdMax ?? lowestYValue + 1, Math.ceil(lowestYValue))
  const yMax = Math.max(thresholdMax ?? thresholdMin ?? highestYValue - 1, Math.floor(highestYValue))
  const annotations: any = {}

  const tooltipOptions = {
    backgroundColor: 'rgb(255,255,255)',
    bodyColor: 'rgba(0, 0, 0, 1)',
    borderColor: 'rgb(0,0,0)',
    borderWidth: 1,
    callbacks: {
      label: (context: any): string => {
        const id = `${context.dataset.label as string}`
        const yAxis = `${i18n.t('modules.cv.temperature_chart.label.y_axis')}: ${Number(context.raw.y)}`
        const xAxisDate = toDate(context.raw.x).toUTCString()
        const xAxis = `${i18n.t('modules.cv.temperature_chart.label.x_axis')}: ${format(
          utcToZonedTime(new Date(xAxisDate), userTimezone),
          'dd/MM HH:mm'
        )}`
        return `${id} ${yAxis} ${xAxis}`
      },
    },
  }

  if (thresholdMin) {
    annotations.boxMin = {
      type: 'box',
      yMin: yMin - 2,
      yMax: thresholdMin,
      backgroundColor: 'rgba(178, 69, 93, 0.45)',
      borderWidth: 0,
      enter: () => setTemperatureLabelVisible(true),
      leave: () => setTemperatureLabelVisible(false),
    }
    annotations.labelMin = {
      type: 'label',
      yValue: thresholdMin - 1, // at the top
      content: `${i18n.t('modules.cv.temperature_chart.minimum_temperature')} ${thresholdMin}`,
      font: {
        size: 16,
      },
      color: theme.palette.common.white,
      display: temperatureLabelVisible,
      enter: () => setTemperatureLabelVisible(temperatureLabelVisible),
    }
  }

  if (thresholdMax) {
    annotations.boxMax = {
      type: 'box',
      yMin: thresholdMax,
      yMax: yMax + 2,
      backgroundColor: 'rgba(178, 69, 93, 0.45)',
      borderWidth: 0,
      enter: () => setTemperatureLabelVisible(true),
      leave: () => setTemperatureLabelVisible(false),
    }
    annotations.labelMax = {
      type: 'label',
      yValue: thresholdMax + 1, // at the bottom
      content: `${i18n.t('modules.cv.temperature_chart.maximum_temperature')} ${thresholdMax}`,
      font: {
        size: 16,
      },
      color: theme.palette.common.white,
      display: temperatureLabelVisible,
      enter: () => setTemperatureLabelVisible(temperatureLabelVisible),
    }
  }

  if (thresholdBreachedXMinValue && thresholdBreachedXMaxValue) {
    annotations.breach = {
      type: 'box',
      drawTime: 'beforeDatasetsDraw',
      xMin: thresholdBreachedXMinValue,
      xMax: thresholdBreachedXMaxValue,
      backgroundColor: 'rgb(249, 96, 96)',
      borderWidth: 0,
    }
  }

  if (firstPredictionValue && lastPredictionValue) {
    annotations.labelPrediction = {
      type: 'label',
      yValue: '50%',
      xValue: (firstPredictionValue + lastPredictionValue) / 2,
      align: 'end',
      content: `Temperature trend`,
      font: {
        size: 14,
      },
      color: theme.palette.primary.light,
      display: true,
    }
  }

  const options = {
    plugins: {
      annotation: {
        annotations: annotations,
      },
      tooltip: tooltipOptions,
      legend: {
        display: false,
      },
    },
    animation: {
      duration: 0, // general animation time
    },
    showLine: true,
    scales: {
      x: {
        min: lowestXValue - 100000,
        max: highestXValue + 100000,
        ticks: {
          callback: (value: string | number): string => {
            const dateValue = toDate(value).toUTCString()
            return format(utcToZonedTime(new Date(dateValue), userTimezone), 'dd/MM HH:mm')
          },
        },
        grid: {
          color: theme.palette.grey[100],
          borderColor: theme.palette.grey[300],
        },
      },
      y: {
        min: yMin - 2,
        max: yMax + 2,
        grid: {
          color: theme.palette.grey[100],
          borderColor: theme.palette.grey[300],
        },
      },
    },
  }
  return options
}
