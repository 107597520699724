import React, { useState, useEffect, ChangeEvent } from 'react'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

import { fetchGet } from 'global/helpers/fetchWrapper'
import Box from '@mui/material/Box'

interface RejectedMessage {
  messageId: string
  timestamp: string
  errorMessage: string[]
}

// eslint-disable-next-line no-useless-escape
const messagePattern = /(\w+\([^\)]+\))/g
const processMessage = (message: string) => {
  return message.match(messagePattern)
}

const RejectedMessages = () => {
  const [loading, setLoading] = useState(true)
  const [rejectedMessages, setRejectedMessages] = useState<RejectedMessage[]>([])
  const [searchTerm, setSearchTerm] = useState('')

  const fetchRejectedMessages = async () => {
    setLoading(true)
    try {
      const response = await fetchGet('/rejected-message')
      setRejectedMessages(response)
    } catch (error) {
      console.error('Failed to fetch rejected messages:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    void fetchRejectedMessages()
  }, [])

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const filteredMessages = rejectedMessages.filter((message) =>
    message.messageId.toLowerCase().includes(searchTerm.toLowerCase())
  )

  processMessage(
    'InvalidValue(documentType=RoadShipment, field=physicalSender, value=AAAAAA), InvalidValue(documentType=RoadShipment, field=tripType, value=L), ValueNotProvided(documentType=RoadShipment, field=tourNumber), ValueNotProvided(documentType=RoadShipment, field=tourNumber), ValueNotProvided(documentType=RoadShipment, field=customerIdentifier)'
  )

  return (
    <Container maxWidth="xl">
      <TextField
        label="Search by UUID"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <Box mt={2}> </Box>
      {loading ? (
        <Typography>Loading rejected messages...</Typography>
      ) : (
        <>
          <Typography variant="h5" gutterBottom>
            Rejected Messages
          </Typography>
          {filteredMessages.length > 0 ? (
            <Stack spacing={2}>
              {filteredMessages.map((message) => (
                <Paper key={message.messageId} elevation={1} sx={{ padding: 2 }}>
                  <Typography variant="subtitle1">
                    Message ID: <strong>{message.messageId}</strong>
                  </Typography>
                  <Typography variant="subtitle2">
                    Timestamp: <strong>{message.timestamp}</strong>
                  </Typography>
                  <ul>
                    {processMessage(message.errorMessage.join(', '))?.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </Paper>
              ))}
            </Stack>
          ) : (
            <Typography>No rejected messages found.</Typography>
          )}
        </>
      )}
    </Container>
  )
}

export default RejectedMessages
