import { ReactElement, useEffect, useState } from 'react'
import FadeIn from 'react-fade-in/lib/FadeIn'
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react'

// @mui imports
import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Modules
import ShipmentsInsightData from 'modules/ShipmentsInsightData/ShipmentsInsightData'
import LiveCountersWrapper from 'modules/LiveCounters/LiveCountersWrapper'

// Data
import { shipmentsInsightTranslations } from '../../modules/ShipmentsInsightData/ShipmentsInsightData.data'

const ShipmentsInsight = (): ReactElement => {
  const [dataLoading, setDataLoading] = useState(true)
  // Translated Data //
  const { translation } = shipmentsInsightTranslations()

  if (process.env.REACT_APP_PUBLIC_POSTHOG_RECORD_SESSIONS_HOOK == 'true') {
    const posthog = usePostHog()
    const recordingSessionFeatureFlag = useFeatureFlagEnabled('recording-sessions-in-cv')

    useEffect(() => {
      if (posthog && recordingSessionFeatureFlag) {
        posthog.startSessionRecording()
      }

      return () => {
        posthog.stopSessionRecording()
      }
    }, [posthog, recordingSessionFeatureFlag])
  }

  return (
    <Container data-test="shipmentInsight-container" id="shipment-insight-container" maxWidth={false}>
      <Stack direction={'column'} spacing={1}>
        {dataLoading && (
          <CircularProgress data-test="loader" size={100} sx={{ position: 'absolute', top: '25%', left: '50%' }} />
        )}
        <FadeIn visible={!dataLoading}>
          <LiveCountersWrapper setLoading={setDataLoading} />
          <KNTypography variant="displayMD_SB" color="primary.focus">
            {translation.screenName}
          </KNTypography>
          <ShipmentsInsightData setLoading={setDataLoading} />
        </FadeIn>
      </Stack>
    </Container>
  )
}

export default ShipmentsInsight
