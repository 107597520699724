import { useEffect, useState, useContext, useMemo, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

// KN imports
import { zonedDate } from 'global/helpers/dateFormatters'
import { TripListContext } from 'context/trips/TripListContext'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNDataTable from 'components/KN_Molecules/KNDataTable/KNDataTable'
import KNDataTableMenu from 'components/KN_Molecules/KNDataTable/KNDataTableMenu'
import KNDataTableOverflow from 'components/KN_Molecules/KNDataTable/KNDataTableOverflow'
import TripCargo from './TripCargo'
import LocationLink from './LocationLink'
import QuickStatusUpdateDialog from 'screens/StatusManager/QuickStatusUpdateDialog'
import TripHeader from './TripHeader'
import ShareDialog from './ShareDialog'
import AssignDriverDialog from './AssignDriverDialog'
import AssignVehicleDialog from './AssignVehicleDialog'
import { TripData } from './TripDashboard.types'
import { KNShareIcon } from 'components/KN_Molecules/KNIcon/KNMaterialIcon'

interface TripTableProps {
  trips: TripData[]
  onChange?: (updatedTrip: TripData) => void
}

const TripTable = ({ trips, onChange }: TripTableProps): ReactElement => {
  const { t } = useTranslation()
  const [activeTrip, setActiveTrip] = useState<TripData>()
  const [quickStatusUpdateDialogOpen, setQuickStatusUpdateDialogOpen] = useState(false)
  const [shareDialogOpen, setShareDialogOpen] = useState(false)
  const [assignDriverDialogOpen, setAssignDriverDialogOpen] = useState(false)
  const [assignVehicleDialogOpen, setAssignVehicleDialogOpen] = useState(false)
  const [tripListState, tripListDispatch] = useContext(TripListContext)

  useEffect(() => {
    if (tripListState.scroll !== null) {
      // NOTE: doesn't work without even minimal setTimeout
      setTimeout(() => window.scrollTo({ top: tripListState.scroll ?? 0 }), 10)
      tripListDispatch({ type: 'setScroll', payload: null })
    }
  }, [])

  const handleLinkClick = useCallback((row: TripData) => {
    setActiveTrip(row)
    tripListDispatch({ type: 'setScroll', payload: window.pageYOffset })
  }, [])

  const handleQuickStatusUpdateClick = useCallback((row: TripData) => {
    setActiveTrip(row)
    tripListDispatch({ type: 'setScroll', payload: window.pageYOffset })
    setQuickStatusUpdateDialogOpen(true)
  }, [])

  const columns = useMemo(
    () => [
      {
        name: 'trip',
        label: t('screens.cs.trip_dashboard.card.columns.trip'),
        getValue: (row: TripData) => (
          <Stack alignItems="flex-start">
            <TripHeader trip={row} linked onLinkClick={handleLinkClick} />
            <TripCargo trip={row} />
          </Stack>
        ),
        sx: { width: { xs: '24rem', sm: 'auto' } },
      },
      {
        name: 'shipments',
        label: t('screens.cs.trip_dashboard.card.columns.shipments'),
        getValue: (row: TripData) => (
          <KNDataTableOverflow
            row={row}
            label={t('general.view_all')}
            header={t('screens.cs.trip_dashboard.card.columns.shipments')}
            items={row.shipmentNumbers ?? []}
            rightAligned
          />
        ),
        sx: { width: { xs: '10rem', xxl: '14rem' } },
      },
      {
        name: 'driver_vehicle',
        label: t('screens.cs.trip_dashboard.card.columns.driver_vehicle'),
        getValue: (row: TripData) => (
          <Stack spacing={1} direction="row" alignItems="center" data-guide="driver-vehicle-actions">
            <Box data-test="driver-vehicle">
              {row.assignedDriver && (
                <KNTypography color="inherit" noWrap sx={{ maxWidth: { xs: '5.5rem', xxl: '9.5rem' } }}>
                  {row.assignedDriver.name}
                </KNTypography>
              )}
              {row.assignedVehicle && (
                <Stack spacing={0.5} direction="row" alignItems="center">
                  <KNTypography color="inherit" noWrap sx={{ maxWidth: { xs: '5.5rem', xxl: '9.5rem' } }}>
                    {row.assignedVehicle.displayLicensePlate}
                  </KNTypography>
                </Stack>
              )}
              {row.secondaryAssignedVehicle && (
                <Stack spacing={0.5} direction="row" alignItems="center">
                  <KNTypography color="inherit" noWrap sx={{ maxWidth: { xs: '5.5rem', xxl: '9.5rem' } }}>
                    {row.secondaryAssignedVehicle.displayLicensePlate}
                  </KNTypography>
                </Stack>
              )}
              {!row.assignedDriver && !row.assignedVehicle && !row.secondaryAssignedVehicle && (
                <Box>{t('screens.cs.trip_dashboard.card.not_assigned')}</Box>
              )}
            </Box>
            <KNDataTableMenu
              label={t('screens.cs.trip_dashboard.card.actions.assign_options')}
              row={row}
              actions={[
                {
                  name: 'assign_driver',
                  label: t('screens.cs.trip_dashboard.card.actions.assign_driver'),
                  onClick: (row: TripData) => {
                    setActiveTrip(row)
                    setAssignDriverDialogOpen(true)
                  },
                },
                {
                  name: 'assign_vehicle',
                  label: t('screens.cs.trip_dashboard.card.actions.assign_vehicle'),
                  onClick: (row: TripData) => {
                    setActiveTrip(row)
                    setAssignVehicleDialogOpen(true)
                  },
                },
              ]}
            />
          </Stack>
        ),
        sx: { width: { xs: '10rem', xxl: '14rem' } },
      },
      {
        name: 'first_pickup',
        label: t('screens.cs.trip_dashboard.card.columns.first_pickup'),
        getValue: (row: TripData) => (
          <>
            {zonedDate(row.earlyPickUpDate, 'full_no_year')}
            <LocationLink
              city={row.pickUpPlace}
              countryCode={row.countryCodePickUpPlace}
              marker={
                row.pickUpGeoPoint
                  ? {
                      coords: {
                        lat: row.pickUpGeoPoint.latitude,
                        lng: row.pickUpGeoPoint.longitude,
                      },
                      type: 'PUP',
                    }
                  : undefined
              }
              compact
            />
          </>
        ),
        sx: { width: { xs: '10rem', xxl: '14rem' } },
      },
      {
        name: 'last_delivery',
        label: t('screens.cs.trip_dashboard.card.columns.last_delivery'),
        getValue: (row: TripData) => (
          <>
            {zonedDate(row.lateDeliveryDate, 'full_no_year')}
            <LocationLink
              city={row.deliveryPlace}
              countryCode={row.countryCodeDeliveryPlace}
              marker={
                row.deliveryGeoPoint
                  ? {
                      coords: {
                        lat: row.deliveryGeoPoint.latitude,
                        lng: row.deliveryGeoPoint.longitude,
                      },
                      type: 'DEL',
                    }
                  : undefined
              }
              compact
            />
          </>
        ),
        sx: { width: { xs: '10rem', xxl: '14rem' } },
      },
    ],
    []
  )

  const actions = useMemo(
    () => [
      {
        name: 'share',
        label: t('screens.cs.trip_dashboard.card.actions.share_trip'),
        icon: <KNShareIcon data-test="share" />,
        onClick: (row: TripData) => {
          setActiveTrip(row)
          setShareDialogOpen(true)
        },
      },
    ],
    []
  )

  const handleQuickStatusUpdateDialogAction = useCallback((updatedTrip: TripData): void => {
    setQuickStatusUpdateDialogOpen(false)
    onChange?.(updatedTrip)
  }, [])

  const handleQuickStatusUpdateDialogClose = useCallback((): void => {
    setQuickStatusUpdateDialogOpen(false)
  }, [])

  const handleShareDialogAction = useCallback((): void => {
    setShareDialogOpen(false)
  }, [])

  const handleShareDialogClose = useCallback((): void => {
    setShareDialogOpen(false)
  }, [])

  const handleAssignDriverDialogAction = useCallback((updatedTrip: TripData): void => {
    setAssignDriverDialogOpen(false)
    onChange?.(updatedTrip)
  }, [])

  const handleAssignDriverDialogClose = useCallback((): void => {
    setAssignDriverDialogOpen(false)
  }, [])

  const handleAssignVehicleDialogAction = useCallback((updatedTrip: TripData): void => {
    setAssignVehicleDialogOpen(false)
    onChange?.(updatedTrip)
  }, [])

  const handleAssignVehicleDialogClose = useCallback((): void => {
    setAssignVehicleDialogOpen(false)
  }, [])

  return (
    <>
      <KNDataTable
        columns={columns}
        actions={actions}
        data={trips}
        onRowClick={handleQuickStatusUpdateClick}
        sx={{
          marginX: -2,
        }}
      />
      {activeTrip && (
        <>
          <QuickStatusUpdateDialog
            payload={{
              trip: activeTrip,
            }}
            open={quickStatusUpdateDialogOpen}
            onAction={handleQuickStatusUpdateDialogAction}
            onClose={handleQuickStatusUpdateDialogClose}
          />
          <ShareDialog
            payload={{
              trip: activeTrip,
            }}
            open={shareDialogOpen}
            onAction={handleShareDialogAction}
            onClose={handleShareDialogClose}
          />
          <AssignDriverDialog
            data-test="assign-driver-dialog"
            payload={{
              trip: activeTrip,
            }}
            open={assignDriverDialogOpen}
            onAction={handleAssignDriverDialogAction}
            onClose={handleAssignDriverDialogClose}
          />
          <AssignVehicleDialog
            data-test="assign-vehicle-dialog"
            payload={{
              trip: activeTrip,
            }}
            open={assignVehicleDialogOpen}
            onAction={handleAssignVehicleDialogAction}
            onClose={handleAssignVehicleDialogClose}
          />
        </>
      )}
    </>
  )
}

export default TripTable
