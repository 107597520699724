import { merge } from 'lodash'
import { useState, useEffect, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  ChartOptions,
  ChartDataset,
} from 'chart.js'
import 'chartjs-adapter-date-fns'
import { Line } from 'react-chartjs-2'

// @mui imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import ThermostatIcon from '@mui/icons-material/Thermostat'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import { getRouteName } from 'global/helpers/activeRoute'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'
import KNDetailsPopover from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopover'
import KNDetailsPopoverSection from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopoverSection'
import usePopoverState from 'components/KN_Molecules/KNDetailsPopover/usePopoverState'
import { temperatureDataTransformer } from './TripDetails.helpers'
import { getTripVehicleTemperatures } from './TripDetails.service'
import { VehicleTemperatureMetricValue } from './TripDetails.types'
import { sampleTemperatureData } from './TripDetails.mock'

// Functional
import { getTemperatureChartOptions, getDarkModeColors } from 'global/helpers/charts'

interface TemperatureProps {
  trip: TripData
  weblinkToken?: string
}

ChartJS.register(CategoryScale, LinearScale, TimeScale, PointElement, LineElement, Legend, Tooltip)

const Temperature = ({ trip, weblinkToken }: TemperatureProps): ReactElement | null => {
  const { t } = useTranslation()
  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [temperatureLoading, setTemperatureLoading] = useState(true)
  const [temperatureDatasets, setTemperatureDatasets] = useState<ChartDataset<'line'>[]>([])
  const {
    anchorEl: temperatureDetailsAnchorEl,
    open: temperatureDetailsOpen,
    handleClick: handleTemperatureDetailsClick,
    handleClose: handleTemperatureDetailsClose,
  } = usePopoverState()

  const handleTemperatureDetailsClickWrapper = useCallback(
    (event: React.MouseEvent<HTMLElement>): void => {
      handleTemperatureDetailsClick(event)
      analyticsEvent('polestar_cs_trip_temperature_details_popover', [getRouteName(location.pathname)])
    },
    [location]
  )

  const fetchTemperatureDetailsData = useCallback(async (): Promise<void> => {
    setTemperatureLoading(true)
    try {
      setTemperatureDatasets(temperatureDataTransformer(await getTripVehicleTemperatures(trip.entityId, weblinkToken)))
    } catch (error) {
      setTemperatureDatasets([])
    }
    setTemperatureLoading(false)
  }, [trip])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchTemperatureDetailsData()
  }, [])

  if (temperatureDatasets.length === 0) {
    return null
  }

  return (
    <>
      <IconButton
        onClick={handleTemperatureDetailsClickWrapper}
        size="small"
        color="primary"
        sx={{ margin: '-5px 0 !important' }}
      >
        <ThermostatIcon data-test="temperature-icon" />
      </IconButton>

      <KNDetailsPopover
        open={temperatureDetailsOpen}
        onClose={handleTemperatureDetailsClose}
        anchorEl={temperatureDetailsAnchorEl}
        rightAligned
      >
        <KNDetailsPopoverSection
          header={t('screens.cs.trip_details.temperature_details.temperature')}
          items={[
            <Box
              key="chart"
              sx={{
                width: isMobile ? '20rem' : '30rem',
                height: isMobile ? '20rem' : '20rem',
                marginTop: '-1px',
                paddingX: 1,
                paddingY: 0.5,
              }}
            >
              <Line
                options={merge(getTemperatureChartOptions(), getDarkModeColors())}
                data={{ datasets: temperatureDatasets }}
              />
            </Box>,
          ]}
        />
      </KNDetailsPopover>
    </>
  )
}

export default Temperature
