import { TripData } from './TripDashboard.types'
import { AssignDriverFormValues } from './AssignDriverDialog'
import { AssignVehicleFormValues } from './AssignVehicleDialog'

export const getUpdatedTripForAssignDriver = (trip: TripData, data: AssignDriverFormValues): TripData => {
  return {
    ...trip,
    assignedDriverId: data.driverCid ?? undefined,
  }
}

export const getUpdatedTripForAssignVehicle = (trip: TripData, data: AssignVehicleFormValues): TripData => {
  return {
    ...trip,
    assignedVehicleLicensePlate: (data.withTrailer ? data.trailerLicensePlate : data.licensePlate) ?? undefined,
    secondaryAssignedVehicleLicensePlate: (data.withTrailer ? data.licensePlate : undefined) ?? undefined,
  }
}
