// @mui imports
import Input from '@mui/material/Input'
import { CSSInterpolation } from '@mui/system'
import { styled } from '@mui/material/styles'

// Types
import KNInputProps from './types'

const KNInputRoot = styled(Input)(({ theme }) => (props: KNInputProps): CSSInterpolation => {
  const { typography } = theme

  return {
    fontWeight: typography.fontWeightBold,
    textDecoration: 'none',
  }
})

export default KNInputRoot
