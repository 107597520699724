import i18n from 'i18n'

export enum AggregatedStatus {
  AWAITING_PICKUP = 'AWAITING_PICKUP',
  IN_PROGRESS = 'IN_PROGRESS',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
}

export interface AggregatedStatusField {
  STATUS: AggregatedStatus
  STATUS_NAME: string
}

export interface Status {
  STATUS_CODE: string
  STATUS_NAME: string
  AGGREGATED_STATUS: AggregatedStatus
  AGGREGATED_STATUS_NAME: string
}

export const getAggregatedStatuses = (): AggregatedStatusField[] => {
  const AWAITING_PICKUP = i18n.t(`shared.aggregatedStatus.AWAITING_PICKUP`)
  const IN_PROGRESS = i18n.t(`shared.aggregatedStatus.IN_PROGRESS`)
  const DELIVERED = i18n.t(`shared.aggregatedStatus.DELIVERED`)
  const CANCELLED = i18n.t(`shared.aggregatedStatus.CANCELLED`)

  return [
    {
      STATUS: AggregatedStatus.AWAITING_PICKUP,
      STATUS_NAME: AWAITING_PICKUP,
    },
    { STATUS: AggregatedStatus.IN_PROGRESS, STATUS_NAME: IN_PROGRESS },
    { STATUS: AggregatedStatus.DELIVERED, STATUS_NAME: DELIVERED },
    { STATUS: AggregatedStatus.CANCELLED, STATUS_NAME: CANCELLED },
  ]
}

export const getStatuses = (): Status[] => {
  const AWAITING_PICKUP = i18n.t(`shared.aggregatedStatus.AWAITING_PICKUP`)
  const IN_PROGRESS = i18n.t(`shared.aggregatedStatus.IN_PROGRESS`)
  const DELIVERED = i18n.t(`shared.aggregatedStatus.DELIVERED`)
  const CANCELLED = i18n.t(`shared.aggregatedStatus.CANCELLED`)

  return [
    {
      STATUS_CODE: '0100',
      STATUS_NAME: i18n.t('status.0100'),
      AGGREGATED_STATUS: AggregatedStatus.AWAITING_PICKUP,
      AGGREGATED_STATUS_NAME: AWAITING_PICKUP,
    },
    {
      STATUS_CODE: '0142',
      STATUS_NAME: i18n.t('status.0142'),
      AGGREGATED_STATUS: AggregatedStatus.AWAITING_PICKUP,
      AGGREGATED_STATUS_NAME: AWAITING_PICKUP,
    },
    {
      STATUS_CODE: '0300',
      STATUS_NAME: i18n.t('status.0300'),
      AGGREGATED_STATUS: AggregatedStatus.AWAITING_PICKUP,
      AGGREGATED_STATUS_NAME: AWAITING_PICKUP,
    },
    {
      STATUS_CODE: '0400',
      STATUS_NAME: i18n.t('status.0400'),
      AGGREGATED_STATUS: AggregatedStatus.AWAITING_PICKUP,
      AGGREGATED_STATUS_NAME: AWAITING_PICKUP,
    },
    {
      STATUS_CODE: '0420',
      STATUS_NAME: i18n.t('status.0420'),
      AGGREGATED_STATUS: AggregatedStatus.AWAITING_PICKUP,
      AGGREGATED_STATUS_NAME: AWAITING_PICKUP,
    },
    {
      STATUS_CODE: '0490',
      STATUS_NAME: i18n.t('status.0490'),
      AGGREGATED_STATUS: AggregatedStatus.AWAITING_PICKUP,
      AGGREGATED_STATUS_NAME: AWAITING_PICKUP,
    },
    {
      STATUS_CODE: '9800',
      STATUS_NAME: i18n.t('status.9800'),
      AGGREGATED_STATUS: AggregatedStatus.CANCELLED,
      AGGREGATED_STATUS_NAME: CANCELLED,
    },
    {
      STATUS_CODE: '9900',
      STATUS_NAME: i18n.t('status.9900'),
      AGGREGATED_STATUS: AggregatedStatus.CANCELLED,
      AGGREGATED_STATUS_NAME: CANCELLED,
    },
    {
      STATUS_CODE: '3000',
      STATUS_NAME: i18n.t('status.3000'),
      AGGREGATED_STATUS: AggregatedStatus.DELIVERED,
      AGGREGATED_STATUS_NAME: DELIVERED,
    },
    {
      STATUS_CODE: '3002',
      STATUS_NAME: i18n.t('status.3002'),
      AGGREGATED_STATUS: AggregatedStatus.DELIVERED,
      AGGREGATED_STATUS_NAME: DELIVERED,
    },
    {
      STATUS_CODE: '3060',
      STATUS_NAME: i18n.t('status.3060'),
      AGGREGATED_STATUS: AggregatedStatus.DELIVERED,
      AGGREGATED_STATUS_NAME: DELIVERED,
    },
    {
      STATUS_CODE: '3061',
      STATUS_NAME: i18n.t('status.3061'),
      AGGREGATED_STATUS: AggregatedStatus.DELIVERED,
      AGGREGATED_STATUS_NAME: DELIVERED,
    },
    {
      STATUS_CODE: '0200',
      STATUS_NAME: i18n.t('status.0200'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '0491',
      STATUS_NAME: i18n.t('status.0491'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '0500',
      STATUS_NAME: i18n.t('status.0500'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '0501',
      STATUS_NAME: i18n.t('status.0501'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '0502',
      STATUS_NAME: i18n.t('status.0502'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '0510',
      STATUS_NAME: i18n.t('status.0510'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1000',
      STATUS_NAME: i18n.t('status.1000'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1001',
      STATUS_NAME: i18n.t('status.1001'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1010',
      STATUS_NAME: i18n.t('status.1010'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1100',
      STATUS_NAME: i18n.t('status.1100'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1200',
      STATUS_NAME: i18n.t('status.1200'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1290',
      STATUS_NAME: i18n.t('status.1290'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1300',
      STATUS_NAME: i18n.t('status.1300'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1335',
      STATUS_NAME: i18n.t('status.1335'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1336',
      STATUS_NAME: i18n.t('status.1336'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1375',
      STATUS_NAME: i18n.t('status.1375'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1400',
      STATUS_NAME: i18n.t('status.1400'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1401',
      STATUS_NAME: i18n.t('status.1401'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1402',
      STATUS_NAME: i18n.t('status.1402'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1500',
      STATUS_NAME: i18n.t('status.1500'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1550',
      STATUS_NAME: i18n.t('status.1550'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1551',
      STATUS_NAME: i18n.t('status.1551'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1552',
      STATUS_NAME: i18n.t('status.1552'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1553',
      STATUS_NAME: i18n.t('status.1553'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1556',
      STATUS_NAME: i18n.t('status.1556'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1558',
      STATUS_NAME: i18n.t('status.1558'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1559',
      STATUS_NAME: i18n.t('status.1559'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1567',
      STATUS_NAME: i18n.t('status.1567'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1568',
      STATUS_NAME: i18n.t('status.1568'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '1600',
      STATUS_NAME: i18n.t('status.1600'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '2210',
      STATUS_NAME: i18n.t('status.2210'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '2300',
      STATUS_NAME: i18n.t('status.2300'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '2338',
      STATUS_NAME: i18n.t('status.2338'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '2400',
      STATUS_NAME: i18n.t('status.2400'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '2490',
      STATUS_NAME: i18n.t('status.2490'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '2500',
      STATUS_NAME: i18n.t('status.2500'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '2501',
      STATUS_NAME: i18n.t('status.2501'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '2510',
      STATUS_NAME: i18n.t('status.2510'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '2511',
      STATUS_NAME: i18n.t('status.2511'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '2520',
      STATUS_NAME: i18n.t('status.2520'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '2525',
      STATUS_NAME: i18n.t('status.2525'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '2527',
      STATUS_NAME: i18n.t('status.2527'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '2530',
      STATUS_NAME: i18n.t('status.2530'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '2540',
      STATUS_NAME: i18n.t('status.2540'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '2600',
      STATUS_NAME: i18n.t('status.2600'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '2990',
      STATUS_NAME: i18n.t('status.2990'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '2991',
      STATUS_NAME: i18n.t('status.2991'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '3001',
      STATUS_NAME: i18n.t('status.3001'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '3052',
      STATUS_NAME: i18n.t('status.3052'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '3056',
      STATUS_NAME: i18n.t('status.3056'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '3400',
      STATUS_NAME: i18n.t('status.3400'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '8800',
      STATUS_NAME: i18n.t('status.8800'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '9805',
      STATUS_NAME: i18n.t('status.9805'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
    {
      STATUS_CODE: '9810',
      STATUS_NAME: i18n.t('status.9810'),
      AGGREGATED_STATUS: AggregatedStatus.IN_PROGRESS,
      AGGREGATED_STATUS_NAME: IN_PROGRESS,
    },
  ]
}
