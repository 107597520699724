import { useState, useCallback } from 'react'
import subHours from 'date-fns/subHours'

// @mui imports //
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import { Theme } from '@mui/material/styles/createTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN Components //
import theme from 'assets/theme'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNDatePicker from 'components/KN_Molecules/KNDatePicker/KNDatePicker'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Context //
import { getSelectedCompany } from 'context/authentication/User.helpers'
import { useInsightDetailsContext } from 'context/detailsNext/InsightDetails'

// Template //
import FilterItemTemplate from './AdvancedVisibilityFilterItem'

// Data //
import { advancedVisibilityFilterTranslation } from './AdvancedVisibilityFilter.data'

// Types //
import AdvancedVisibilityFilterProps from './AdvancedVisibilityFilter.types'

const AdvancedVisibilityFilter: React.FC<AdvancedVisibilityFilterProps> = ({
  filteredIds,
  changeDevicesFilters,
  changeFilteredDateFrom,
  changeFilteredDateTo,
  changeTemperaturePredictionToggle,
  position,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // Context //
  const { insightDetailsState } = useInsightDetailsContext()
  const selectedCompany = getSelectedCompany()
  const temperaturePredictionModuleActive = selectedCompany?.modules.temperature_prediction

  // Props //
  const { data } = insightDetailsState.insightDetailsContext
  const trackingInfoProps = data?.trackingInfoProps
  const devices = trackingInfoProps?.deviceIds
  const completionDate = trackingInfoProps?.completionDate ?? new Date()
  const trackingLevel = trackingInfoProps?.level

  // Translated Data //
  const { translation } = advancedVisibilityFilterTranslation()

  // Module state//
  const [filteredDateFrom, setFilteredDateFrom] = useState<Date | undefined>()
  const [filteredDateTo, setFilteredDateTo] = useState<Date | undefined>()
  const handleChangeDateFilter = useCallback(
    (value: Date, type: string) => {
      if (type === 'from') {
        setFilteredDateFrom(value)
        changeFilteredDateFrom(value)
        setButtonFilter(null)
      }
      if (type === 'to') {
        setFilteredDateTo(value)
        changeFilteredDateTo(value)
        setButtonFilter(null)
      }
    },
    [filteredDateFrom, filteredDateTo]
  )

  const [buttonFilter, setButtonFilter] = useState<6 | 12 | null>(null)
  const handleChangeButtonFilter = useCallback(
    (filterNumber: 6 | 12 | null) => {
      if (filterNumber === buttonFilter) {
        setButtonFilter(null)
        setFilteredDateFrom(undefined)
        changeFilteredDateFrom(undefined)
        setFilteredDateTo(undefined)
        changeFilteredDateTo(undefined)
      } else {
        setButtonFilter(filterNumber)
        setFilteredDateFrom(undefined)
        filterNumber ? changeFilteredDateFrom(subHours(new Date(completionDate), filterNumber)) : null
        setFilteredDateTo(undefined)
        filterNumber ? changeFilteredDateTo(new Date()) : null
      }
    },
    [buttonFilter]
  )

  const [temperaturePredictionToggle, setTemperaturePredictionToggle] = useState<boolean>(true)
  const handleChangeTemperaturePredictionToggle = useCallback(
    (value: boolean) => {
      setTemperaturePredictionToggle(value)
      changeTemperaturePredictionToggle(value)
    },
    [temperaturePredictionToggle]
  )

  const [filteredDevices, setFilteredDevices] = useState<string[] | undefined>(filteredIds)
  const handleChangeFilteredDevices = useCallback(
    (device: string) => {
      if (filteredDevices?.includes(device)) {
        setFilteredDevices(filteredDevices.filter((e) => e !== device))
        changeDevicesFilters(filteredDevices.filter((e) => e !== device))
      } else {
        filteredDevices ? setFilteredDevices([...filteredDevices, device]) : null
        filteredDevices ? changeDevicesFilters([...filteredDevices, device]) : null
      }
    },
    [filteredDevices]
  )

  return (
    <Box
      data-test="advanced-visibility-filter"
      sx={{
        bottom: ({ functions: { pxToRem } }: Theme): string =>
          position === 'absolute' ? `calc(5% + ${pxToRem(50) as string})` : '0',
        width: '100%',
        position: position,
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          background: ({ palette: { white } }: Theme): string =>
            position === 'absolute' ? `${white.main as string} !important` : 'transparent !important',
          height: 'auto',
          margin: ({ functions: { pxToRem } }: Theme): string | number =>
            position === 'absolute' ? `${pxToRem(0) as number} ${pxToRem(30) as number}` : 0,
          borderRadius: ({ borders: { borderRadius } }: Theme): string => borderRadius.lg as string,
          p: position === 'absolute' ? 2 : 0,
        }}
      >
        <Box>
          <Box>
            <KNTypography variant="displayXS_SB">{translation?.pairedDevices}</KNTypography>
            <Box my={1.5}>
              <Stack direction={'row'} spacing={4} useFlexGap flexWrap="wrap">
                {devices
                  ?.sort(function (a, b) {
                    return a.localeCompare(b)
                  })
                  .map((device, i) =>
                    FilterItemTemplate(
                      device,
                      i,
                      (value: string) => handleChangeFilteredDevices(value),
                      filteredDevices ? !filteredDevices.includes(device) : false,
                      trackingLevel
                    )
                  )}
              </Stack>
            </Box>
            <Stack direction={'row'} spacing={3}>
              <Box data-test="date-from" sx={{ width: '100%', position: 'relative' }}>
                <KNDatePicker
                  selected={filteredDateFrom}
                  startDate={filteredDateFrom}
                  endDate={filteredDateTo}
                  onChange={(value: Date): void => handleChangeDateFilter(value, 'from')}
                  label={translation.filterDateFrom}
                  inputProps={{ size: 'small' }}
                  placeholderText={translation.filterDateFrom}
                  value={filteredDateFrom}
                  selectsStart={true}
                  showTimeSelect
                />
              </Box>
              <Box data-test="date-to" sx={{ width: '100%', position: 'relative' }}>
                <KNDatePicker
                  selected={filteredDateTo}
                  startDate={filteredDateFrom}
                  endDate={filteredDateTo}
                  onChange={(value: Date): void => handleChangeDateFilter(value, 'to')}
                  label={translation.filterDateTo}
                  inputProps={{ size: 'small' }}
                  placeholderText={translation.filterDateTo}
                  value={filteredDateFrom}
                  selectsEnd={true}
                  showTimeSelect
                />
              </Box>
            </Stack>
            <Stack my={1.5} direction={'row'} sx={{ display: 'flex', alignItems: isMobile ? 'flex-start' : 'center' }}>
              {!isMobile && (
                <KNTypography variant="textLG_SB" color="primary.light" mr={2}>
                  {translation.predefinedFilters}
                </KNTypography>
              )}
              <KNButton
                data-test="last-six-hours"
                variant={buttonFilter !== 6 ? 'text' : 'contained'}
                onClick={(): void => handleChangeButtonFilter(6)}
                fullWidth={false}
                color="secondary"
              >
                {translation.filter6hours}
              </KNButton>
              <KNButton
                data-test="last-twelve-hours"
                variant={buttonFilter !== 12 ? 'text' : 'contained'}
                onClick={(): void => handleChangeButtonFilter(12)}
                fullWidth={false}
                color="secondary"
              >
                {translation.filter12hours}
              </KNButton>
            </Stack>
            {temperaturePredictionModuleActive && (
              <>
                <KNTypography variant="textLG_SB" color="primary.light" mr={2}>
                  {translation.temperaturePredictionToggle}
                </KNTypography>
                <Switch
                  checked={temperaturePredictionToggle}
                  onChange={(): void => handleChangeTemperaturePredictionToggle(!temperaturePredictionToggle)}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AdvancedVisibilityFilter
