import { useEffect, useRef, ReactElement, useState } from 'react'
import FadeIn from 'react-fade-in/lib/FadeIn'
import './CodeScanner.css'

// @mui imports
import Box from '@mui/material/Box'
import CheckIcon from '@mui/icons-material/Check'
import CircularProgress from '@mui/material/CircularProgress'
import ContactlessIcon from '@mui/icons-material/Contactless'
import CropFreeIcon from '@mui/icons-material/CropFree'
import Icon from '@mui/material/Icon'
import LeakAddIcon from '@mui/icons-material/LeakAdd'

// KN Components
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Types
import ScannerProps, { ScannedPairingProps } from './Scanners.types'

// Data
import { scannerTranslations } from './Scanners.data'

const DeviceScanner: React.FC<ScannerProps> = ({
  onClose,
  setInputResult,
  existingPairings,
  existingSessionId,
}): ReactElement | null => {
  const [message, setMessage] = useState<number | null>(1)
  const [scanResult, setScanResult] = useState('')
  const [codeType, setCodeType] = useState<'QR_CODE' | 'BARCODE'>('QR_CODE')
  const [scannedDevices, setScannedDevices] = useState<ScannedPairingProps[]>()
  const [logMessages, setLogMessage] = useState<{ type: string; id: string; icon: string }[]>([])
  // Android throws mobile keyboard on focus, we don't want that //
  const [disableKeyboard, setDisableKeyboard] = useState(false)

  const emailInput = useRef<HTMLInputElement>(null)

  // Translated Data //
  const truncateCode = (code: string): string => {
    return code?.length > 10 ? code?.slice(0, 10) + '...' : code
  }
  const { translation } = scannerTranslations(truncateCode(scanResult))

  // Reset scannedDevices on mount
  useEffect(() => {
    if (existingSessionId) {
      setScannedDevices(existingPairings)
    } else setScannedDevices([])
  }, [])

  const allCodes = scannedDevices
    ?.map((device) => {
      return [device.reference, ...(device.deviceIds ?? [])]
    })
    .flat()
    .filter(Boolean)

  // Appear and disappear messages
  useEffect(() => {
    if (disableKeyboard) {
      setTimeout(() => setDisableKeyboard(false), 1000)
    }
  }, [disableKeyboard])

  useEffect(() => {
    if (emailInput.current) {
      emailInput.current.focus()
    }
  }, [])

  useEffect(() => {
    if (message && message > 0) {
      setTimeout(() => setMessage(null), 3000)
    }
    if (message === null) {
      setTimeout(() => setMessage(0), 200)
    }
  }, [message])

  useEffect(() => {
    if (scanResult > '') {
      setTimeout(() => setScanResult(''), 3000)
    }
  }, [scanResult])

  // Refocus on click somewhere else
  useEffect(() => {
    document.body.addEventListener('click', function () {
      if (emailInput.current) {
        emailInput.current.focus()
      }
    })
    document.body.addEventListener('touchstart', function () {
      if (emailInput.current) {
        emailInput.current.focus()
      }
    })
  }, [])

  useEffect(() => {
    if (scanResult > '') {
      const existingQrCode = allCodes?.map((q) => q).some((c) => c === scanResult)
      if (existingQrCode) {
        setScannedDevices(scannedDevices)
        setMessage(7)
      }
      if (codeType === 'QR_CODE') {
        // EXISTING SESSION = MORE THAN 0 QR CODES SCANNED //
        if (scannedDevices) {
          const qrcodeswithoutbarcodes = scannedDevices.filter((qr) => !qr.deviceIds).length
          // THROW ERROR IF THERE IS NO BARCODE PAIRED WITH QRCODE //
          if (scannedDevices.length > 0 && qrcodeswithoutbarcodes > 0) {
            setScannedDevices(scannedDevices)
            setMessage(42)
          } else {
            if (!existingQrCode) {
              setScannedDevices([...scannedDevices, { reference: scanResult }])
              setMessage(2)
              setCodeType('BARCODE')
            }
            if (existingQrCode) {
              setScannedDevices([...scannedDevices])
              setMessage(8)
              setCodeType('QR_CODE')
            }
          }
        } else {
          // NEW SESSION //
          setScannedDevices([{ reference: scanResult }])
          setMessage(2)
          setCodeType('BARCODE')
        }
      }
      if (codeType === 'BARCODE') {
        // EXISTING SESSION = MORE THAN 0 QR CODES SCANNED //
        if (!scannedDevices) {
          setMessage(4)
        }
        if (scannedDevices) {
          const existingBarcode = scannedDevices.flatMap((qr) => qr.deviceIds).some((c) => c === scanResult)
          if (!existingBarcode) {
            const lastQrCode = scannedDevices.pop()
            if (!lastQrCode?.deviceIds) {
              setScannedDevices([
                ...scannedDevices.filter((qr) => qr.reference !== lastQrCode?.reference),
                { reference: lastQrCode?.reference, deviceIds: [scanResult] },
              ])
              setMessage(5)
            }
            if (lastQrCode?.deviceIds && lastQrCode.deviceIds?.length > 0) {
              setScannedDevices([
                ...scannedDevices.filter((qr) => qr.reference !== lastQrCode.reference),
                { reference: lastQrCode.reference, deviceIds: [...lastQrCode.deviceIds, scanResult] },
              ])
              setMessage(6)
            }
          } else {
            setScannedDevices(scannedDevices)
            setMessage(7)
          }
        }
      }
    }
  }, [scanResult])

  // Get message log
  useEffect(() => {
    if (scanResult > '' && codeType === 'QR_CODE') {
      if (message === 0) {
        setLogMessage([...logMessages, { type: 'qr_scanned', id: translation.codes.barcode_scanned, icon: 'leak_add' }])
      }
      if (message === 2) {
        setLogMessage([...logMessages, { type: 'qr_scanned', id: translation.codes.barcode_scanned, icon: 'leak_add' }])
      }
      if (message === 42) {
        setLogMessage([...logMessages, { type: 'qr_error', id: translation.codes.barcode_needs_qr, icon: 'error' }])
      }
      if (message === 8) {
        setLogMessage([...logMessages, { type: 'qr_error', id: translation.codes.barcode_inuse, icon: 'error' }])
      }
    }
    if (scanResult > '' && codeType === 'BARCODE') {
      if (message === 4) {
        setLogMessage([...logMessages, { type: 'barcode_error', id: translation.codes.qr_error, icon: 'error' }])
      }
      if (message === 5 || message === 6) {
        setLogMessage([...logMessages, { type: 'device_added', id: translation.codes.qr_added, icon: 'contactless' }])
      }
      if (message === 7) {
        setLogMessage([...logMessages, { type: 'barcode_error', id: translation.codes.qr_error, icon: 'error' }])
      }
    }
  }, [message, scanResult, codeType])

  const getMessage = (): ReactElement | null | undefined => {
    const messageTemplate = (
      type: 'neutral' | 'success' | 'error' | 'info',
      code: number,
      text: string
    ): ReactElement => {
      return (
        <FadeIn>
          <Box
            sx={{
              backgroundColor:
                type === 'success'
                  ? 'success.main'
                  : type === 'error'
                  ? 'error.main'
                  : type === 'info'
                  ? 'primary.contrastText'
                  : 'primary.main',
              width: 'auto',
              display: 'inline-flex',
              px: 1.5,
              py: 1,
              borderRadius: '50px',
              alignItems: 'center',
              opacity: 0.6,
            }}
          >
            {code === 0 && <CircularProgress color="info" size={20} sx={{ mr: 1.5 }} />}
            <KNTypography variant="displayXXS" color={type === 'info' ? 'dark' : 'white'}>
              {text}
            </KNTypography>
          </Box>
        </FadeIn>
      )
    }

    if (message === 0 && codeType === 'QR_CODE') {
      return messageTemplate('neutral', 0, translation.codes.active)
    }
    if (message === 0 && codeType === 'BARCODE') {
      return messageTemplate('neutral', 0, translation.codes.active_devices)
    }
    if (message === 1) {
      return messageTemplate('info', 1, translation.codes.started)
    }
    if (message === 2) {
      return messageTemplate('success', 2, translation.codes.barcode_scanned)
    }
    if (message === 3) {
      return messageTemplate('success', 3, translation.codes.first_barcode)
    }
    if (message === 4) {
      return messageTemplate('error', 4, translation.codes.qr_error)
    }
    if (message === 5) {
      return messageTemplate('success', 5, translation.codes.qr_added)
    }
    if (message === 6) {
      return messageTemplate('success', 6, translation.codes.qr_added_more)
    }
    if (message === 7) {
      return messageTemplate('error', 7, translation.codes.qr_inuse)
    }
    if (message === 8) {
      return messageTemplate('error', 7, translation.codes.barcode_inuse)
    }
    if (message === 42) {
      return messageTemplate('error', 42, translation.codes.barcode_needs_qr)
    }
  }

  const mapResult = (): void => {
    if (scannedDevices) {
      setInputResult({
        sessionTimestamp: existingSessionId ?? Date.now(),
        pairings: scannedDevices?.map((device) => {
          return {
            reference: device.reference ?? '',
            deviceIds: device.deviceIds ?? [],
            unpairingRule: device.unpairingRule ?? true,
            temperatureThreshold: device.temperatureThreshold ?? null,
            description: device.description,
          }
        }),
      })
    }
  }

  const disabledFinish = !scannedDevices || (scannedDevices && scannedDevices.filter((qr) => !qr.deviceIds).length > 0)

  return (
    <>
      <Box sx={{ width: '100%', height: '100%', backgroundColor: 'white !important' }}>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CropFreeIcon color="disabled" sx={{ display: 'block', width: 150, height: 150 }} />
          </Box>
        </Box>
        <div style={{ width: '100%', position: 'absolute', zIndex: '9999' }}>
          <Box sx={{ textAlign: 'center' }}>{getMessage()}</Box>
        </div>
        <div style={{ width: '100%', position: 'absolute', zIndex: '999', textAlign: 'center', background: 'white' }}>
          <Box>
            <br />
          </Box>
        </div>
        <Box style={{ width: '100%', textAlign: 'center' }}>
          <input
            ref={emailInput}
            className="scan_input"
            onChange={(e): void => setScanResult(e.target.value)}
            value={scanResult}
            readOnly={disableKeyboard}
          />
        </Box>
        <br />
        <Box>
          <KNTypography variant="textMD">{translation.devicesTip}</KNTypography>
        </Box>
        <Box sx={{ my: 2 }}>
          <KNTypography variant="displayXS_SB">{translation.log}</KNTypography>
          <Box sx={{ height: '125px', overflowY: 'scroll' }}>
            {logMessages
              ?.map((msg, i) => (
                <Box key={i} sx={{ display: 'flex', my: 0.5, alignItems: 'center' }}>
                  <Icon sx={{ mr: 1, fontSize: '16px' }}>{msg.icon}</Icon>
                  <KNTypography variant="textMD">{msg.id}</KNTypography>
                </Box>
              ))
              .reverse()}
          </Box>
        </Box>
        <Box sx={{ my: 2 }}>
          <KNTypography variant="displayXS_SB">{translation.result}</KNTypography>
          <Box sx={{ maxHeight: '125px', overflowY: 'scroll' }}>
            {scannedDevices
              ?.map((s, i) => (
                <Box key={i}>
                  <Box sx={{ my: 1 }}>
                    <KNTypography variant="textMD_SB">
                      {translation.pairing} {i + 1}
                    </KNTypography>
                    <Box key={i} sx={{ display: 'flex', my: 0.5, alignItems: 'center' }}>
                      <LeakAddIcon sx={{ mr: 1 }} />
                      <KNTypography variant="textMD_SB" sx={{ mr: 1 }} color="dark">
                        ID:{' '}
                      </KNTypography>
                      <KNTypography variant="textMD">{s.reference}</KNTypography>
                    </Box>
                    {s.deviceIds && (
                      <Box>
                        <KNTypography variant="textMD_SB" color="secondary">
                          {translation.connectedDevices}
                        </KNTypography>
                        <Box mt={1}>
                          {s.deviceIds?.map((d, i) => (
                            <Box key={i} sx={{ display: 'inline-flex', mr: 1 }}>
                              <ContactlessIcon sx={{ mr: 0.5 }} />
                              <KNTypography variant="textMD" color="dark">
                                {d}
                              </KNTypography>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              ))
              .reverse()}
          </Box>
        </Box>
        {scanResult ? scanResult : ''}
      </Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: '-5px',
          zIndex: 9999,
          width: '100%',
          pr: 1,
          height: '10%',
          backgroundColor: 'hsla(0,0%,100%,.9)!important',
          boxShadow: 'inset 0 0 1px 1px hsla(0,0%,100%,.9),0 20px 27px 0 rgba(0,0,0,.05)!important',
        }}
      >
        <Box sx={{ textAlign: 'end' }}>
          <KNButton
            variant="contained"
            size="medium"
            color="primary"
            sx={{ mr: 2 }}
            onClick={(): void => {
              setCodeType('QR_CODE')
              setDisableKeyboard(true)
            }}
            disabled={disabledFinish}
          >
            {translation.next}
          </KNButton>
          <KNButton
            variant="contained"
            size="medium"
            color="success"
            startIcon={<CheckIcon color="info" fontSize="large" />}
            disabled={disabledFinish}
            onClick={(): void => {
              mapResult()
              onClose()
            }}
          >
            {translation.finish}
          </KNButton>
        </Box>
      </Box>
    </>
  )
}

export default DeviceScanner
