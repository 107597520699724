import i18n from 'i18n'

export const trackingInfoTranslation = (): Record<any, any> => {
  const trackingDetails = 'modules.cv.tracking_details'
  return {
    translation: {
      moduleName: i18n.t(`${trackingDetails}.moduleName`),
      shipment: i18n.t(`${trackingDetails}.shipment`),
      id: i18n.t(`${trackingDetails}.ID`),
      pairingDate: i18n.t(`${trackingDetails}.pairingDate`),
      completionDate: i18n.t(`${trackingDetails}.completionDate`),
      health: i18n.t(`${trackingDetails}.health`),
      trackingDevices: i18n.t(`${trackingDetails}.trackingDevices`),
      totalCount: i18n.t(`${trackingDetails}.totalCount`),
      lastUpdate: i18n.t(`${trackingDetails}.lastUpdate`),
      wireless_device: i18n.t(`${trackingDetails}.wireless_device`),
      license_plate: i18n.t(`${trackingDetails}.license_plate`),
      completed: i18n.t(`${trackingDetails}.completed`),
      in_progress: i18n.t(`${trackingDetails}.in_progress`),
    },
  }
}
