import { useEffect, ReactElement, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'

// @mui imports
import Stack from '@mui/material/Stack'
import DialogContentText from '@mui/material/DialogContentText'
import ListItem from '@mui/material/ListItem'

// KN imports
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNFormFile from 'components/KN_Molecules/KNForm/KNFormFile'
import { BulletList } from 'screens/Weblink/RequestAccountPromo'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'
import { uploadBulkCompanies } from './CompanyManager.service'

interface BulkCreateCompaniesDialogProps {
  open: boolean
  onAction: () => void
  onClose: () => void
}

export interface BulkCreateCompaniesDialogFormValues {
  csv: File
}

const BulkCreateCompaniesDialog = ({ open, onAction, onClose }: BulkCreateCompaniesDialogProps): ReactElement => {
  const { t } = useTranslation()
  const [createdCompanies, setCreatedCompanies] = useState<string[] | null>(null)
  const { handleSubmit, reset, control, formState, setValue, setError, clearErrors } =
    useForm<BulkCreateCompaniesDialogFormValues>()

  useEffect(() => {
    if (open) {
      reset(
        processDefaultValues({
          csv: null,
        })
      )
      setCreatedCompanies(null)
    }
  }, [open])

  const onSubmit: SubmitHandler<BulkCreateCompaniesDialogFormValues> = async (
    data: BulkCreateCompaniesDialogFormValues
  ) => {
    try {
      setCreatedCompanies(await uploadBulkCompanies(data))
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNForm onSubmit={handleSubmit(onSubmit)}>
      <KNDialog
        open={open}
        onClose={createdCompanies ? onAction : onClose}
        closeLabel={createdCompanies ? t('general.close') : t('general.cancel')}
        preventClosing={formState.isSubmitting}
        title={t('screens.shared.company_manager.bulk_create_companies.bulk_create')}
        actions={
          !createdCompanies && (
            <>
              <KNLoadingButton
                type="submit"
                color="primary"
                variant="contained"
                loading={formState.isSubmitting}
                onClick={handleSubmit(onSubmit)}
              >
                {t('general.send')}
              </KNLoadingButton>
            </>
          )
        }
      >
        <KNDialogFormErrors errors={formState.errors?.root} />
        {createdCompanies ? (
          <DialogContentText component="div">
            {createdCompanies.length == 0 ? (
              <KNTypography color="text.main">
                {t('screens.shared.company_manager.bulk_create_companies.no_new_companies_created')}
              </KNTypography>
            ) : (
              <>
                <KNTypography color="text.main">
                  {t('screens.shared.company_manager.bulk_create_companies.created_companies_list')}
                </KNTypography>
                <BulletList sx={{ color: 'text.main' }}>
                  {createdCompanies.map((createdCompany) => (
                    <ListItem key={createdCompany}>{createdCompany}</ListItem>
                  ))}
                </BulletList>
              </>
            )}
          </DialogContentText>
        ) : (
          <Stack spacing={2}>
            <KNFormFile
              label={t('screens.shared.company_manager.bulk_create_companies.choose_file')}
              name="csv"
              control={control}
              setError={setError}
              clearErrors={clearErrors}
              setValue={setValue}
              accept="text/csv"
              size="small"
            />
          </Stack>
        )}
      </KNDialog>
    </KNForm>
  )
}

export default BulkCreateCompaniesDialog
