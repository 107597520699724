import i18n from 'i18n'
import { format, utcToZonedTime } from 'date-fns-tz'

// Types //
import { EventDateGroupProps, EventProps } from './EventTimeline.types'

export const mapEvents = (events: EventProps[], userTimezone: string): EventProps[] => {
  const mappedEvents = events.map((event) => {
    const { address, city, country } = event.location ?? {}
    const addressString = String([address, city, country].join(' '))
    return {
      cid: event.cid,
      statusCode: i18n.t(`status.${event.statusCode}`),
      eventCode: event.statusCode,
      dateTime: format(utcToZonedTime(new Date(event.dateTime), userTimezone), 'dd/MM/yyyy HH:mm'),
      location: { name: addressString, city: city },
    }
  })
  return mappedEvents
}

export const groupOnDate = (events: EventProps[]): EventDateGroupProps[] => {
  const groupsMap = events.reduce((map, event) => {
    const dateParts = event.dateTime.split(' ')[0].split('/')
    const date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`)

    const formattedDate = date.toISOString()

    let existingGroup = map.get(formattedDate)

    if (!existingGroup) {
      existingGroup = { date: formattedDate, events: [] }
      map.set(formattedDate, existingGroup)
    }

    existingGroup.events.push(event)

    return map
  }, new Map<string, EventDateGroupProps>())

  const dateGroups: EventDateGroupProps[] = Array.from(groupsMap.values())

  return dateGroups
}

export const getAggregatedStatusIcon = (aggregatedStatus: string): { icon: string; color?: string } => {
  if (aggregatedStatus === 'DELIVERED') {
    return { icon: 'download_done', color: 'success' }
  }
  return { icon: 'downloading' }
}

// Move this one to a more global place in the future //
export const getEventIcon = (event: EventProps): string => {
  // POD available
  if (event.eventCode === '3060') {
    return 'mark_email_read'
  }
  // Delivered to consignee ok
  if (event.eventCode === '3000') {
    return 'bookmark_added'
  }
  // Booked
  if (event.eventCode === '0142' || event.eventCode === '0100') {
    return 'bookmark_add'
  }
  return 'radio_button_checked'
}

export const toCapitalize = (str: string): string => {
  const rest = str.slice(1)
  if (rest === rest.toUpperCase()) {
    return str.charAt(0).toUpperCase() + rest.toLowerCase()
  } else {
    return str.charAt(0).toUpperCase() + rest
  }
}
