import typography from 'assets/theme/base/typography'
import borders from 'assets/theme/base/borders'
import colors from 'assets/theme/base/colors'
import pxToRem from 'assets/theme/functions/pxToRem'

const { size, fontWeightRegular } = typography
const { borderRadius } = borders
const { dark, grey } = colors

// types
type Types = any

const tab: Types = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      flex: '1 1 auto',
      textAlign: 'center',
      maxWidth: 'unset !important',
      minWidth: 'unset !important',
      minHeight: 'unset !important',
      fontSize: size.md,
      fontWeight: fontWeightRegular,
      textTransform: 'none',
      lineHeight: 'inherit',
      padding: pxToRem(8),
      borderRadius: borderRadius.lg,
      color: `${grey[400] as string} !important`,
      opacity: '1 !important',

      '&.Mui-selected': {
        color: `${dark.main as string} !important`,
      },

      '& .material-icons, .material-icons-round': {
        marginBottom: '0 !important',
        marginRight: pxToRem(4),
      },

      '& svg': {
        marginBottom: '0 !important',
        marginRight: pxToRem(4),
      },
    },

    labelIcon: {
      paddingTop: pxToRem(8),
    },
  },
}

export default tab
