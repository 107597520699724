import i18n from 'i18n'

export const monitoringRuleTranslations = (): Record<any, any> => {
  const monitoringRule = 'modules.cv.monitoring_rule'

  return {
    translation: {
      screenNameNew: i18n.t(`${monitoringRule}.screen_name_new`),
      screenNameEdit: i18n.t(`${monitoringRule}.screen_name_edit`),
    },
    formTranslations: {
      required: i18n.t('form.validation.required'),
      oneFieldRequired: i18n.t(`${monitoringRule}.one_field_required`),
      ruleNameLabel: i18n.t(`${monitoringRule}.rule_name_label`),
      eventTypeLabel: i18n.t(`${monitoringRule}.event_type_label`),
      settingsLabel: i18n.t(`${monitoringRule}.settings_label`),
      locationLabel: i18n.t(`${monitoringRule}.location_label`),
      locationCaption: i18n.t(`${monitoringRule}.location_caption`),
      pickup: i18n.t(`${monitoringRule}.pickup`),
      delivery: i18n.t(`${monitoringRule}.delivery`),
      pickupNameLabel: i18n.t(`${monitoringRule}.pickup_name_label`),
      pickupNamePlaceholder: i18n.t(`${monitoringRule}.pickup_name_label`),
      pickupCountryLabel: i18n.t(`${monitoringRule}.pickup_country_label`),
      pickupCountryPlaceholder: i18n.t(`${monitoringRule}.pickup_country_placeholder`),
      pickupPostalCodeLabel: i18n.t(`${monitoringRule}.pickup_postal_code_label`),
      pickupPostalCodePlaceholder: i18n.t(`${monitoringRule}.pickup_postal_code_placeholder`),
      pickupCityLabel: i18n.t(`${monitoringRule}.pickup_city_label`),
      pickupCityPlaceholder: i18n.t(`${monitoringRule}.pickup_city_placeholder`),
      pickupAddressLabel: i18n.t(`${monitoringRule}.pickup_address_label`),
      pickupAddressPlaceholder: i18n.t(`${monitoringRule}.pickup_address_placeholder`),
      deliveryNameLabel: i18n.t(`${monitoringRule}.delivery_name_label`),
      deliveryNamePlaceholder: i18n.t(`${monitoringRule}.delivery_name_label`),
      deliveryCountryLabel: i18n.t(`${monitoringRule}.delivery_country_label`),
      deliveryCountryPlaceholder: i18n.t(`${monitoringRule}.delivery_country_placeholder`),
      deliveryPostalCodeLabel: i18n.t(`${monitoringRule}.delivery_postal_code_label`),
      deliveryPostalCodePlaceholder: i18n.t(`${monitoringRule}.delivery_postal_code_placeholder`),
      deliveryCityLabel: i18n.t(`${monitoringRule}.delivery_city_label`),
      deliveryCityPlaceholder: i18n.t(`${monitoringRule}.delivery_city_placeholder`),
      deliveryAddressLabel: i18n.t(`${monitoringRule}.delivery_address_label`),
      deliveryAddressPlaceholder: i18n.t(`${monitoringRule}.delivery_address_placeholder`),
      saveButtonText: i18n.t(`${monitoringRule}.save_button_text`),
    },
    eventTypes: {
      arrivals: i18n.t(`${monitoringRule}.event_types.arrivals`),
      departures: i18n.t(`${monitoringRule}.event_types.departures`),
      milestoneUpdates: i18n.t(`${monitoringRule}.event_types.milestone_updates`),
      temperatureThresholdExceeded: i18n.t(`${monitoringRule}.event_types.temperature_threshold_exceeded`),
    },
  }
}
