import { useState, useEffect, useCallback, useMemo, ReactElement } from 'react'
import { Marker } from '@react-google-maps/api'
import { throttle } from 'lodash'

// @mui imports
import theme from 'assets/theme'
import Popper, { PopperProps } from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import Fade from '@mui/material/Fade'

// KN imports
import { zonedDate, relativeDate } from 'global/helpers/dateFormatters'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { GeoPoint } from 'screens/TripDetails/TripDetails.types'
import { getDistance, getPixelPosition, getIcon, getZIndex } from './KNMap.helpers'
import { KNMapTimestampMarkerProps, MapPixelPosition } from './types'

const KNMapTimestampMarker = <T extends object>({
  map,
  geoPoints,
}: KNMapTimestampMarkerProps<T>): ReactElement | null => {
  const [timestampGeoPoint, setTimestampGeoPoint] = useState<GeoPoint | null>(null)
  const [timestampAnchor, setTimestampAnchor] = useState<PopperProps['anchorEl'] | null>(null)

  const handleMarkerUpdate = (event: google.maps.MapMouseEvent) => {
    const cursorPosition = event.latLng
    if (!cursorPosition) {
      setTimestampGeoPoint(null)
      return
    }
    const cursorGeoPoint: GeoPoint = {
      latitude: cursorPosition.lat(),
      longitude: cursorPosition.lng(),
    }
    let shortestDistance = Infinity
    let closestGeoPoint: GeoPoint | null = null
    for (const geoPoint of geoPoints) {
      const distance = getDistance(cursorGeoPoint, geoPoint)
      if (distance < shortestDistance) {
        shortestDistance = distance
        closestGeoPoint = geoPoint
      }
    }
    // ignore if closest one is more than 100km away from cursor
    if (!closestGeoPoint || shortestDistance > 100) {
      setTimestampGeoPoint(null)
      return
    }
    setTimestampGeoPoint(closestGeoPoint)
  }

  const handleMarkerUpdateThrottled = useMemo(() => throttle(handleMarkerUpdate, 100), [handleMarkerUpdate, geoPoints])

  const handleMarkerHide = useCallback(
    (event: google.maps.MapMouseEvent) => {
      // make sure there are no pending calls to throttled update
      handleMarkerUpdateThrottled.cancel()
      setTimestampGeoPoint(null)
    },
    [handleMarkerUpdateThrottled]
  )

  useEffect(() => {
    const mouseMoveEvent = google.maps.event.addListener(map, 'mousemove', handleMarkerUpdateThrottled)
    const mouseOutEvent = google.maps.event.addListener(map, 'mouseout', handleMarkerHide)
    const dragStartEvent = google.maps.event.addListener(map, 'dragstart', handleMarkerHide)
    const zoomChangedEvent = google.maps.event.addListener(map, 'zoom_changed', handleMarkerHide)
    return () => {
      google.maps.event.removeListener(zoomChangedEvent)
      google.maps.event.removeListener(dragStartEvent)
      google.maps.event.removeListener(mouseOutEvent)
      google.maps.event.removeListener(mouseMoveEvent)
    }
  }, [map, handleMarkerUpdateThrottled, handleMarkerHide])

  useEffect(() => {
    if (!timestampGeoPoint) {
      setTimestampAnchor(null)
      return
    }
    const markerPixelPosition = getPixelPosition(map, timestampGeoPoint.latitude, timestampGeoPoint.longitude)
    const mapBoundingClientRect = map.getDiv().getBoundingClientRect()
    if (!markerPixelPosition || !mapBoundingClientRect) {
      setTimestampAnchor(null)
      return
    }
    setTimestampAnchor({
      getBoundingClientRect: () =>
        new DOMRect(
          Number(mapBoundingClientRect.left) + Number(markerPixelPosition.left),
          Number(mapBoundingClientRect.top) + Number(markerPixelPosition.top),
          0,
          0
        ),
    })
  }, [map, timestampGeoPoint])

  if (!timestampGeoPoint) {
    return null
  }

  return (
    <>
      <Marker
        position={{ lat: timestampGeoPoint.latitude, lng: timestampGeoPoint.longitude }}
        icon={getIcon('HEADING', theme.palette.secondary.main, undefined, timestampGeoPoint.heading)}
        zIndex={getZIndex('HEADING')}
      />
      <Popper
        id="timestamp-popper"
        open={Boolean(timestampGeoPoint)}
        anchorEl={timestampAnchor}
        placement="auto-start"
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [8, 8],
            },
          },
        ]}
        sx={{
          pointerEvents: 'none',
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={300}>
            <Paper elevation={4} sx={{ padding: 1 }}>
              <KNTypography>{zonedDate(timestampGeoPoint?.timestamp ?? null, 'full')} (UTC)</KNTypography>
              <KNTypography>{relativeDate(timestampGeoPoint?.timestamp ?? null)}</KNTypography>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  )
}

export default KNMapTimestampMarker
