import { ReactElement, useEffect, useState } from 'react'
import FadeIn from 'react-fade-in'
import { useForm } from 'react-hook-form'
import i18n from 'i18n'

// @mui imports
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Stack from '@mui/material/Stack'

// KN components
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNFormDropdown from 'components/KN_Molecules/KNForm/KNFormDropdown'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNFormDate from 'components/KN_Molecules/KNForm/KNFormDate'

// Functional
import { processDefaultValues } from 'global/helpers/form'
import { AggregatedStatus, getAggregatedStatuses, getStatuses } from 'global/helpers/statuses'
import { getDateFormat } from 'global/helpers/dateFormatters'

// Data
import { playgroundTranslations } from 'screens/Playground/Playground.data'

// Types
import { StatusField, StatusFieldDialogProps } from 'screens/Playground/Playground.types'
import { KNAdvancedDropdownOptionsProps } from 'components/KN_Molecules/KNAdvancedDropdown/types'

const StatusFieldDialog = ({
  statusData,
  handleClose,
  dialogOpen,
  handleSave,
}: StatusFieldDialogProps): ReactElement => {
  const { handleSubmit, reset, control, formState, setValue, getValues, setError, clearErrors } = useForm<StatusField>()
  const [aggregatedStatus, setAggregatedStatus] = useState<AggregatedStatus>()
  const [statusesOptions, setStatusesOptions] = useState<KNAdvancedDropdownOptionsProps[]>([])
  const { translation } = playgroundTranslations()

  useEffect(() => {
    if (statusData) reset(processDefaultValues(statusData))
    else resetForm()
  }, [statusData])

  const resetForm = (): void => {
    setAggregatedStatus(undefined)
    reset(
      processDefaultValues({
        code: null,
        dateTime: null,
        cityLocation: null,
      })
    )
  }

  useEffect(() => {
    if (!aggregatedStatus) return
    const statusList = getStatuses()
    const statusesByAggregated = statusList.filter((status) => status.AGGREGATED_STATUS === aggregatedStatus)
    setStatusesOptions(
      statusesByAggregated.map((status) => ({
        value: status.STATUS_CODE,
        label: `${status.STATUS_NAME} - ${status.STATUS_CODE}`,
      }))
    )
  }, [aggregatedStatus])

  const onSave = (data: StatusField): void => {
    handleClose()
    resetForm()
    handleSave(data)
  }

  const onClose = (): void => {
    clearErrors()
    handleClose()
  }

  const getDialogContent = (): ReactElement => (
    <Box my={2}>
      <Box mb={3}>
        <Box display="flex" alignItems="center">
          <FadeIn>
            <Stack spacing={1}>
              <KNTypography variant="textMD" color="primary.light">
                {translation.shipmentAddStatus}
              </KNTypography>
              <KNTypography variant="textSM">{translation.shipmentAddStatusText1}</KNTypography>
              <Box display="flex">
                <Select
                  sx={{ minWidth: 150, mr: 1 }}
                  value={aggregatedStatus}
                  size="small"
                  onChange={(event: SelectChangeEvent): void => {
                    const {
                      target: { value },
                    } = event
                    setAggregatedStatus(value as AggregatedStatus)
                  }}
                >
                  {getAggregatedStatuses().map((option, index) => (
                    <MenuItem key={index} value={option.STATUS}>
                      {option.STATUS_NAME}
                    </MenuItem>
                  ))}
                </Select>{' '}
                <KNFormDropdown
                  disabled={!aggregatedStatus}
                  control={control}
                  sx={{ minWidth: 150 }}
                  name={'code'}
                  size="small"
                  options={statusesOptions}
                ></KNFormDropdown>
              </Box>
              <KNTypography variant="textSM">{translation.shipmentAddStatusText2}</KNTypography>
              <KNFormText
                name="code"
                control={control}
                rules={{
                  required: i18n.t('form.validation.required'),
                }}
              />
              <Box>
                <KNTypography variant="textMD" color="primary.light">
                  {translation.shipmentAddStatusDateTime}
                </KNTypography>
                <KNFormDate
                  name="dateTime"
                  control={control}
                  format={getDateFormat('full_timezoned')}
                  showTimeSelect={true}
                  allowFuture={true}
                  rules={{
                    required: i18n.t('form.validation.required'),
                  }}
                />
              </Box>
              <Box>
                <KNTypography variant="textMD" color="primary.light">
                  {translation.shipmentAddStatusCityLocation}
                </KNTypography>
                <KNFormText name="cityLocation" control={control} />
              </Box>
            </Stack>
          </FadeIn>
        </Box>
      </Box>
    </Box>
  )
  return (
    <Dialog
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          maxHeight: 435,
          zIndex: 9999,
        },
      }}
      maxWidth="xs"
      open={dialogOpen}
    >
      <DialogContent>{getDialogContent()}</DialogContent>
      <DialogActions>
        <KNButton
          size="medium"
          color="primary"
          variant="contained"
          type="submit"
          startIcon={<CheckIcon fontSize="inherit" />}
          disabled={Object.keys(formState.errors).length > 0}
          onClick={handleSubmit(onSave)}
        >
          {translation.shipmentAddStatusSubmitButton}
        </KNButton>
        <KNButton
          size="medium"
          color="primary"
          type="button"
          variant="contained"
          startIcon={<CloseIcon fontSize="inherit" />}
          onClick={onClose}
        >
          {translation.shipmentCancelButton}
        </KNButton>
      </DialogActions>
    </Dialog>
  )
}

export default StatusFieldDialog
