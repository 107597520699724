import { GeoPoint } from 'screens/TripDetails/TripDetails.types'
import { MapPixelPosition } from './types'
import {
  encodeIconToDataUrl,
  KNGenericMapIcon,
  KNPickupMapIcon,
  KNDeliveryMapIcon,
  KNCustomsMapIcon,
  KNTruckMapIcon,
  KNTruckDelayedMapIcon,
  KNHeadingIcon,
} from 'components/KN_Molecules/KNIcon/KNMapIcon'

const DEG_TO_RAD = Math.PI / 180
const RAD_TO_DEG = 180 / Math.PI

export const getDistance = (firstGeoPoint: GeoPoint, secondGeoPoint: GeoPoint): number => {
  const R = 6371.071 // radius of Earth in kilometers
  const firstLatitudeRadians = firstGeoPoint.latitude * DEG_TO_RAD
  const secondLatitudeRadians = secondGeoPoint.latitude * DEG_TO_RAD
  const latitudeDifference = secondLatitudeRadians - firstLatitudeRadians
  const longitudeDifference = (secondGeoPoint.longitude - firstGeoPoint.longitude) * DEG_TO_RAD
  return (
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(latitudeDifference / 2) * Math.sin(latitudeDifference / 2) +
          Math.cos(firstLatitudeRadians) *
            Math.cos(secondLatitudeRadians) *
            Math.sin(longitudeDifference / 2) *
            Math.sin(longitudeDifference / 2)
      )
    )
  )
}

export const getHeading = (firstGeoPoint: GeoPoint, secondGeoPoint: GeoPoint): number => {
  const firstLatitudeRadians = firstGeoPoint.latitude * DEG_TO_RAD
  const secondLatitudeRadians = secondGeoPoint.latitude * DEG_TO_RAD
  const longitudeDifference = (secondGeoPoint.longitude - firstGeoPoint.longitude) * DEG_TO_RAD
  const y = Math.sin(longitudeDifference) * Math.cos(secondLatitudeRadians)
  const x =
    Math.cos(firstLatitudeRadians) * Math.sin(secondLatitudeRadians) -
    Math.sin(firstLatitudeRadians) * Math.cos(secondLatitudeRadians) * Math.cos(longitudeDifference)
  return (Math.atan2(y, x) * RAD_TO_DEG + 360) % 360
}

export const getPixelPosition = (
  map: google.maps.Map,
  latitude: number,
  longitude: number
): MapPixelPosition | null => {
  const mapBounds = map.getBounds()
  const mapZoom = map.getZoom()
  const mapProjection = map.getProjection()
  if (!mapBounds || !mapZoom || !mapProjection) {
    return null
  }
  const projectionScale = Math.pow(2, mapZoom)
  const northEast = mapBounds.getNorthEast()
  const southWest = mapBounds.getSouthWest()
  const topRight = mapProjection.fromLatLngToPoint(northEast)
  const bottomLeft = mapProjection.fromLatLngToPoint(southWest)
  if (!topRight || !bottomLeft) {
    return null
  }
  const point = mapProjection.fromLatLngToPoint({ lat: latitude, lng: longitude })
  if (!point) {
    return null
  }
  const positionX = (point.x - bottomLeft.x) * projectionScale
  const positionY = (point.y - topRight.y) * projectionScale
  return { top: positionY, left: positionX }
}

export const getIcon = (type: string, color?: string, label?: string, rotation?: number): google.maps.Icon => {
  const baseIcon = {
    anchor: new google.maps.Point(16, 28),
    size: { width: 32, height: 32 } as google.maps.Size,
    scaledSize: { width: 32, height: 32 } as google.maps.Size,
  }
  switch (type) {
    case 'PUP':
      return {
        ...baseIcon,
        url: encodeIconToDataUrl(<KNPickupMapIcon color={color} />),
      }
    case 'DEL':
      return {
        ...baseIcon,
        url: encodeIconToDataUrl(<KNDeliveryMapIcon color={color} />),
      }
    case 'CUS':
      return {
        ...baseIcon,
        url: encodeIconToDataUrl(<KNCustomsMapIcon color={color} />),
      }
    case 'TRUCK':
      return {
        ...baseIcon,
        anchor: new google.maps.Point(12, 12),
        size: { width: 24, height: 24 } as google.maps.Size,
        scaledSize: { width: 24, height: 24 } as google.maps.Size,
        url: encodeIconToDataUrl(<KNTruckMapIcon color={color} />),
      }
    case 'TRUCK_DELAYED':
      return {
        ...baseIcon,
        anchor: new google.maps.Point(12, 12),
        size: { width: 24, height: 24 } as google.maps.Size,
        scaledSize: { width: 24, height: 24 } as google.maps.Size,
        url: encodeIconToDataUrl(<KNTruckDelayedMapIcon color={color} />),
      }
    case 'HEADING':
      return {
        ...baseIcon,
        anchor: new google.maps.Point(12, 12),
        size: { width: 24, height: 24 } as google.maps.Size,
        scaledSize: { width: 24, height: 24 } as google.maps.Size,
        url: encodeIconToDataUrl(<KNHeadingIcon color={color} rotation={rotation} />),
      }
    case 'GENERIC':
    default:
      return {
        ...baseIcon,
        url: encodeIconToDataUrl(<KNGenericMapIcon color={color} label={label} />),
      }
  }
}

export const getZIndex = (type?: string): number => {
  switch (type) {
    case 'PUP':
    case 'DEL':
      return 200
    case 'TRUCK':
    case 'TRUCK_DELAYED':
      return 100
    case 'GEOPOINT':
      return 400
    case 'HEADING':
      return 500
    default:
      return 100
  }
}
