import { useEffect } from 'react'

// context
import { useNavigationContext } from 'context/navigation/NavigationContext'

const ScrollTo = (): null => {
  const { navigationState } = useNavigationContext()

  useEffect(
    () =>
      window.scrollTo(0, navigationState.navigationContext.scrollPosition ?? 0),
    []
  )

  return null
}

export default ScrollTo
