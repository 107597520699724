import { range } from 'lodash'
import DatePicker from 'react-datepicker'
import { format, setMonth, getMonth, getYear } from 'date-fns'
import 'react-datepicker/dist/react-datepicker.css'

// @mui imports
import { Theme } from '@mui/material/styles/createTheme'
import Box from '@mui/material/Box'
import NativeSelect from '@mui/material/NativeSelect'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import useMediaQuery from '@mui/material/useMediaQuery'
import EventIcon from '@mui/icons-material/Event'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { SystemStyleObject } from '@mui/system'

// KN imports
import theme from 'assets/theme'

// Functional
import pxToRem from 'assets/theme/functions/pxToRem'
import { getDateFormat, getDateLocale } from '../../../global/helpers/dateFormatters'

const customHeader = ({
  date,
  changeMonth,
  changeYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}): JSX.Element => {
  const today = new Date()
  const months = new Array(12).fill(null).map((_, i) =>
    format(setMonth(today, i), 'LLLL', {
      locale: getDateLocale(),
    })
  )

  const years = range(getYear(new Date()), 1990, -1)

  const handleYearChange = ({ target: { value } }): void => changeYear(value)
  const handleMonthChange = ({ target: { value } }): void => changeMonth(months.indexOf(value))

  const selectStyles = {
    fontWeight: 'bold',
    paddingLeft: pxToRem(14),
    paddingRight: pxToRem(6),
    '& :focus': {
      backgroundColor: 'inherit',
    },
    '& select': {
      p: 0,
    },
  }

  return (
    <Box data-test="date-calendar" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box sx={{ display: 'flex' }}>
        <NativeSelect
          sx={selectStyles}
          variant="standard"
          disableUnderline={true}
          onChange={handleMonthChange}
          value={months[getMonth(date)]}
          size="small"
        >
          {months.map((value) => (
            <option value={value} key={value}>
              {value}
            </option>
          ))}
        </NativeSelect>

        <NativeSelect
          sx={selectStyles}
          variant="standard"
          disableUnderline={true}
          onChange={handleYearChange}
          value={getYear(date)}
          size="small"
        >
          {years.map((year) => (
            <option value={year} key={year}>
              {year}
            </option>
          ))}
        </NativeSelect>
      </Box>
      <Box>
        <IconButton onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <IconButton onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

const KNDatePicker = ({ ...props }): JSX.Element => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <DatePicker
      {...props}
      autoComplete={'off'}
      locale={getDateLocale()}
      arrow={false}
      showMonthDropdown
      popperProps={{ strategy: 'fixed' }}
      showYearDropdown
      maxDate={!props.allowFuture && new Date()}
      dateFormat={props.format ?? getDateFormat('short')}
      renderCustomHeader={(props): JSX.Element => customHeader({ ...props })}
      customInput={
        props.renderInput ?? (
          <TextField
            {...props.inputProps}
            sx={({ palette }): SystemStyleObject<Theme> => ({
              width: '100%',
              'input::placeholder': {
                opacity: 1,
                textFillColor: palette.grey[300],
              },
            })}
            error={props.error}
            label={props.label}
            id={props.id}
            value={props.value}
            InputProps={{
              endAdornment: !isMobile ? (
                <InputAdornment position="end">
                  <EventIcon
                    sx={({ palette: { grey } }: Theme): SystemStyleObject<Theme> => ({
                      color: props.disabled ? grey[400] : grey[500],
                    })}
                  />
                </InputAdornment>
              ) : null,
            }}
          />
        )
      }
    />
  )
}

export default KNDatePicker
