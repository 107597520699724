import { useContext, useEffect, useState, ReactElement } from 'react'
import { useLocation } from 'react-router-dom'

// @mui imports
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import MenuIcon from '@mui/icons-material/Menu'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN Components
import theme from 'assets/theme'
import KNBreadcrumbs from 'components/Molecules/KNBreadcrumbs/KNBreadcrumbs'

// Modules
import NotificationsMenu from 'modules/NotificationsMenu/NotificationsMenu'
import SearchBar from 'components/Molecules/SearchBar/SearchBar'
import HelpMenu from 'modules/HelpMenu/HelpMenu'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'
import { useSearchContext } from 'context/search/SearchContext'
import { getBreadcrumbActive, getHelpActive, getNotificationsActive, getSearchActive } from 'global/helpers/activeRoute'
import { isEtnUrl, getLogo } from 'global/helpers/getLogo'

// Context
import { UserContext } from 'context/authentication/UserContext'
import { useMenuContext } from 'context/menu/MenuContext'
import { useNavigationContext } from 'context/navigation/NavigationContext'

const TopNavigation = (): ReactElement => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { user } = useContext(UserContext)
  const { navigationState } = useNavigationContext()
  const { breadcrumbHome, activeLink } = navigationState.navigationContext
  const { menuState, dispatch } = useMenuContext()
  const { menuOpen, menuExtended } = menuState.menuContext
  const { searchState } = useSearchContext()
  const { activeContext } = searchState.searchContext

  const location = useLocation()

  const [activeBreadcrumb, setActiveBreadcrumb] = useState(false)
  const [activeSearch, setActiveSearch] = useState(false)
  const [activeNotifications, setActiveNotifications] = useState(false)
  const [activeHelp, setActiveHelp] = useState(false)

  useEffect(() => {
    setActiveBreadcrumb(getBreadcrumbActive(location.pathname))
    setActiveSearch(getSearchActive(location.pathname))
    setActiveNotifications(getNotificationsActive(location.pathname))
    setActiveHelp(getHelpActive(location.pathname))
  }, [location])

  // Scrolling
  const [scrolled, setScrolled] = useState(false)
  const detectScroll = (): void => {
    window.onscroll = (): void => {
      const currentScrollPos = window.pageYOffset
      if (currentScrollPos === 0) {
        setScrolled(false)
      } else {
        setScrolled(true)
      }
    }
  }

  const handleDrawerMenuOpen = (): void => {
    dispatch({
      type: 'setMenuProps',
      payload: {
        menuOpen: !menuOpen,
        menuExtended: menuExtended,
      },
    })
    analyticsEvent('polestar_top_navigation_menu_toggled')
  }

  useEffect(() => {
    detectScroll()
  }, [])

  return (
    <AppBar
      sx={{
        transition: 'box-shadow .25s ease-in,background-color .25s ease-in',
        backgroundColor: !scrolled ? 'inherit' : 'hsla(0,0%,100%,.9)!important',
        boxShadow: !scrolled ? 'none' : ' inset 0 0 1px 1px hsla(0,0%,100%,.9),0 20px 27px 0 rgba(0,0,0,.05)!important',
        borderRadius: '0.75rem',
        position: 'sticky',
        zIndex: 8,
      }}
    >
      <Toolbar disableGutters sx={{ px: 2 }}>
        {/* BREADCRUMBS */}
        {user && (
          <Box sx={{ marginRight: 'auto' }}>
            {isMobile && getLogo('symbol', isEtnUrl ? '100px' : '32px', '32px')}
            {!isMobile && activeBreadcrumb && (
              <Box sx={{ ml: 2 }}>
                <KNBreadcrumbs
                  homeRoute={
                    user && {
                      name: breadcrumbHome.name,
                      link: breadcrumbHome.link,
                    }
                  }
                  activeRoute={activeLink}
                />
              </Box>
            )}
          </Box>
        )}
        {/* SEARCHBAR */}
        {activeContext && user && activeSearch && <SearchBar />}
        {/* HELP BUTTON */}
        {activeHelp && user && <HelpMenu />}
        {/* MOBILE MENU */}
        {isMobile && (
          <IconButton onClick={(): void => handleDrawerMenuOpen()} data-test={'drawer-menu-openButton'}>
            <MenuIcon />
          </IconButton>
        )}
        {/* NOTIFICATIONS */}
        {activeNotifications && user && <NotificationsMenu />}
      </Toolbar>
    </AppBar>
  )
}

export default TopNavigation
