import React from 'react'
import { Marker, OverlayView } from '@react-google-maps/api'

// Types //
import DevicesMapMarkerProps from './VisibilityMapMarker.types'

// Functional //
import { defaultIcon, defaultWayPointIcon, waypointIcons } from './VisibilityMapMarker.helpers'

const VisibilityMapMarker: React.FC<DevicesMapMarkerProps> = ({
  position,
  index,
  waypointIcon = false,
  customIcon,
  labelText,
  labelColor = 'white',
  onClick,
  onForeground,
  customSize,
  rotation,
}) => {
  const markerIcon = customIcon ?? (waypointIcon ? waypointIcons[index] || defaultWayPointIcon : defaultIcon(index))

  const renderLabel = (): JSX.Element | null => {
    if (!labelText) {
      return null
    }

    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: labelColor,
        }}
      >
        {labelText}
      </div>
    )
  }

  const renderOverlayView = (): JSX.Element => (
    <OverlayView position={position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
      <div
        style={{
          position: 'absolute',
          width: customSize || '10px',
          height: customSize || '10px',
          // eslint-disable-next-line @typescript-eslint/no-base-to-string
          backgroundImage: `url(${markerIcon.toString()})`,
          backgroundSize: 'cover',
          cursor: 'pointer',
          transform: `rotate(${Number(rotation)}deg)`,
        }}
        onClick={onClick}
      >
        {renderLabel()}
      </div>
    </OverlayView>
  )

  const renderMarker = (): JSX.Element => (
    <Marker
      zIndex={onForeground ? 2 : 1}
      onClick={onClick}
      position={new google.maps.LatLng(position.lat, position.lng)}
      icon={markerIcon}
      label={labelText && { text: labelText, color: labelColor }}
    />
  )

  return customSize ? renderOverlayView() : renderMarker()
}

export default VisibilityMapMarker
