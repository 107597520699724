import { ChartDataset } from 'chart.js'
import { parseISO, differenceInMinutes } from 'date-fns'

// @mui imports
import theme from 'assets/theme'

// KN imports
import { isUat } from 'global/helpers/environment'
import { getDatasetColors } from 'global/helpers/charts'
import { getDistance, getHeading } from 'components/KN_Molecules/KNMap/KNMap.helpers'
import { MapMarker } from 'components/KN_Molecules/KNMap/types'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'
import {
  LegData,
  StopData,
  StopsGroup,
  GeoPoint,
  GeoPointsGroup,
  VehicleTemperatureMetricValue,
  VehiclePositionMetricValue,
} from './TripDetails.types'

export const isViewSwitcherAvailable = (trip: TripData): boolean => {
  if (trip.sendingApplication === 'MACADAM') {
    return false
  }
  const countryCode = trip.logicalSender.substring(0, 2)
  // temporarily activate for some countries on UAT
  if (isUat() && ['DE', 'CH'].includes(countryCode)) {
    return true
  }
  const supportedRegions = [
    'AT',
    'BG',
    'CZ',
    'DK',
    'EE',
    'ES',
    'FI',
    'GR',
    'HR',
    'HU',
    'IT',
    'LT',
    'LU',
    'LV',
    'MK',
    'NL',
    'NO',
    'PL',
    'PT',
    'RO',
    'RS',
    'SE',
    'SI',
    'SK',
    'UA',
    'US',
  ]
  if (supportedRegions.includes(countryCode)) {
    return true
  }
  return false
}

export const getGroupedStops = (legs: LegData[]) =>
  legs
    .reduce((groups: StopsGroup[], leg) => {
      // create groups with stops and its related legs based on stop's sequence
      leg.wayPoints.map((stop) => {
        if (!groups[stop.sequence]) {
          groups[stop.sequence] = {
            sequence: stop.sequence,
            stopLegPairs: [],
            state: 'pending',
          }
        }
        groups[stop.sequence].stopLegPairs.push({
          stop,
          leg,
        })
      })
      return groups
    }, [])
    // ensure that groups are sorted by sequence
    .sort((a, b) => a.sequence - b.sequence)
    .map((group) => {
      // sort stops within groups by shipment number
      group.stopLegPairs = group.stopLegPairs.sort((a, b) =>
        a.leg.shipmentNumber === b.leg.shipmentNumber ? 0 : a.leg.shipmentNumber < b.leg.shipmentNumber ? -1 : 1
      )
      // calculate state of all groups
      const stops = group.stopLegPairs.map((stopLegPair) => stopLegPair.stop)
      const inProgressStops = stops.filter(
        (stop) => stop.statuses.length > 0 || (stop.documents && stop.documents.length > 0)
      )
      const completedStops = stops.filter((stop) => stop.availableStatuses.length === 0)
      if (stops.length === completedStops.length) {
        group.state = 'completed'
      } else if (inProgressStops.length > 0) {
        group.state = 'in_progress'
      }
      return group
    })

export const getStopsGroupColor = (state: string) => {
  switch (state) {
    case 'completed':
      return theme.palette.success.main
    case 'in_progress':
      return theme.palette.primary.main
    case 'pending':
    default:
      return theme.palette.primary.light
  }
}

export const temperatureDataTransformer = (data: VehicleTemperatureMetricValue[]): ChartDataset<'line'>[] => {
  const colors = getDatasetColors()
  return Object.values(
    data.reduce((datasets: Record<string, ChartDataset<'line'>>, point) => {
      const datasetName = point.sensorId ?? 'dataset'
      if (!datasets[datasetName]) {
        const nextColor = colors.pop() ?? theme.palette.white.main
        datasets[datasetName] = {
          label: point.sensorName ?? datasetName,
          data: [],
          borderColor: nextColor,
          backgroundColor: nextColor,
        }
      }
      datasets[datasetName].data.push({ x: new Date(point.timestamp).getTime(), y: parseFloat(point.value) })
      return datasets
    }, {})
  )
}

export const positionDataTransformer = (data: VehiclePositionMetricValue[]): GeoPoint[] => {
  let previousGeoPoint: GeoPoint
  // basic transform and speed estimation
  const geoPoints = data.map((value: VehiclePositionMetricValue) => {
    const geoPoint: GeoPoint = {
      latitude: parseFloat(value.latitude),
      longitude: parseFloat(value.longitude),
      timestamp: value.timestamp,
      estimatedSpeed: 0,
    }
    if (previousGeoPoint && previousGeoPoint.timestamp && geoPoint.timestamp) {
      geoPoint.estimatedSpeed = Math.ceil(
        getDistance(geoPoint, previousGeoPoint) *
          (60 / differenceInMinutes(parseISO(geoPoint.timestamp), parseISO(previousGeoPoint.timestamp)))
      )
    }
    previousGeoPoint = geoPoint
    return geoPoint
  })
  // heading calculation done on reversed list to more easily grab "next" geopoint
  let nextGeoPoint: GeoPoint
  geoPoints.reverse().map((geoPoint: GeoPoint) => {
    if (nextGeoPoint) {
      geoPoint.heading = getHeading(geoPoint, nextGeoPoint)
    }
    nextGeoPoint = geoPoint
    return geoPoint
  })
  // reverse again for proper order
  return geoPoints.reverse()
}

export const groupGeoPointsBySpeed = (geoPoints: GeoPoint[]): GeoPointsGroup[] => {
  if (geoPoints.some((geoPoint) => geoPoint.estimatedSpeed == undefined)) {
    return [
      {
        label: 'unknown',
        geoPoints: geoPoints,
      },
    ]
  }
  let previousGroupLabel: string
  return geoPoints.reduce((groups: GeoPointsGroup[], geoPoint) => {
    /* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
    const label = geoPoint.estimatedSpeed! < 30 ? 'slow' : geoPoint.estimatedSpeed! < 60 ? 'medium' : 'fast'
    const previousGroup = groups[groups.length - 1]
    if (!previousGroup || previousGroup.label != label) {
      const newGroup: GeoPointsGroup = {
        label: label,
        geoPoints: [],
      }
      if (previousGroup) {
        // NOTE: include last geopoint of previous group as a starting point of new one
        newGroup.geoPoints.push(previousGroup.geoPoints[previousGroup.geoPoints.length - 1])
      }
      newGroup.geoPoints.push(geoPoint)
      groups.push(newGroup)
    } else {
      previousGroup.geoPoints.push(geoPoint)
    }
    return groups
  }, [])
}
