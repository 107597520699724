// @mui imports
import Slider from '@mui/material/Slider'
import { CSSInterpolation } from '@mui/system'
import { styled } from '@mui/material/styles'

// Types
import KNSliderProps from './types'

const KNSliderRoot = styled(Slider)(({ theme }) => (props: KNSliderProps): CSSInterpolation => {
  const { typography } = theme

  return {
    fontWeight: typography.fontWeightBold,
    textDecoration: 'none',
  }
})

export default KNSliderRoot
