import _ from 'lodash'

// Type
import NotificationProps from './NotificationsMenu.type'

export const updateNotificationsData = (
  nextNotifications: NotificationProps[],
  prevState: NotificationProps[]
): NotificationProps[] => {
  const mergedNotifications = _.merge(
    _.keyBy(nextNotifications, 'creationDate'),
    _.keyBy(prevState, 'creationDate')
  )
  const notificationsValues = _.values(mergedNotifications)
  const notificationsValuesWithoutRead = notificationsValues.filter(
    (notification) => !notification.readAt
  )
  return notificationsValuesWithoutRead
}
