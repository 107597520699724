import i18n from 'i18n'

export const shipmentsTableTranslations = (): Record<any, any> => {
  const general = 'modules.cv.shipments_table.general'
  const ribbon = 'modules.cv.shipments_table.ribbon'
  const labels = 'modules.cv.shipments_table.labels'
  const table = 'modules.cv.shipments_table.table'
  return {
    translation: {
      general: {
        moduleName: i18n.t(`${general}.moduleName`),
        moduleToggle: i18n.t(`${general}.moduleToggle`),
        notificationRules: i18n.t(`${general}.notificationRules`),
        noDataMessage: i18n.t(`${general}.noDataMessage`),
        shareShipment: i18n.t(`${general}.shareShipment`),
        shareShipmentMessage: i18n.t(`${general}.shareShipmentMessage`),
        geofence: i18n.t(`${general}.geofence`),
        eta: i18n.t(`${general}.eta`),
      },
      ribbon: i18n.t(`${ribbon}`),
      labels: {
        labelWithNotifications: i18n.t(`${labels}.labelWithNotifications`),
        labelTrackingActive: i18n.t(`${labels}.labelTrackingActive`),
        labelTrackingCompleted: i18n.t(`${labels}.labelTrackingCompleted`),
        labelPODAvailable: i18n.t(`${labels}.labelPODAvailable`),
        labelHealth: i18n.t(`${labels}.labelHealth`),
        labelTMSDataAvailable: i18n.t(`${labels}.labelTMSDataAvailable`),
        labelRoadShipment: i18n.t(`${labels}.labelRoadShipment`),
        labelAirShipment: i18n.t(`${labels}.labelAirShipment`),
      },
      table: {
        tableSectionOther: i18n.t(`${table}.tableSectionOther`),
        tableColumnOrderId: i18n.t(`${table}.tableColumnOrderId`),
        tableColumnType: i18n.t(`${table}.tableColumnType`),
        tableColumnShipmentId: i18n.t(`${table}.tableColumnShipmentId`),
        tableColumnStatus: i18n.t(`${table}.tableColumnStatus`),
        tableColumnDate: i18n.t(`${table}.tableColumnDate`),
        tableColumnCRN: i18n.t(`${table}.tableColumnCRN`),
        tableColumnPickupLocation: i18n.t(`${table}.tableColumnPickupLocation`),
        tableColumnDeliveryLocation: i18n.t(`${table}.tableColumnDeliveryLocation`),
        tableColumnPlannedPickup: i18n.t(`${table}.tableColumnPlannedPickup`),
        tableColumnPlannedDelivery: i18n.t(`${table}.tableColumnPlannedDelivery`),
      },
      descriptions: {
        descriptionFollowed: i18n.t(`${table}.descriptionFollowed`),
      },
    },
  }
}

export const moonshotTableTranslations = (): Record<any, any> => {
  const general = 'modules.cv.moonshot_table.general'
  const labels = 'modules.cv.shipments_table.labels'
  const table = 'modules.cv.moonshot_table.table'
  return {
    moonshotTranslation: {
      general: {
        moduleName: i18n.t(`${general}.moduleName`),
        moduleToggle: i18n.t(`${general}.moduleToggle`),
        noDataMessage: i18n.t(`${general}.noDataMessage`),
        editPairing: i18n.t(`${general}.editPairing`),
        unpairShipment: i18n.t(`${general}.unpairShipment`),
        removeEntry: i18n.t(`${general}.removeEntry`),
        confirm: {
          title: i18n.t(`${general}.confirm.title`),
          content: i18n.t(`${general}.confirm.content`),
          confirmButton: i18n.t(`${general}.confirm.confirmButton`),
          cancelButton: i18n.t(`${general}.confirm.cancelButton`),
        },
      },
      labels: {
        labelTrackingActive: i18n.t(`${labels}.labelTrackingActive`),
        labelTrackingCompleted: i18n.t(`${labels}.labelTrackingCompleted`),
        labelHealth: i18n.t(`${labels}.labelHealth`),
      },
      table: {
        tableColumnReference: i18n.t(`${table}.tableColumnReference`),
        tableColumnType: i18n.t(`${table}.tableColumnType`),
        tableColumnDescription: i18n.t(`${table}.tableColumnDescription`),
        tableColumnPairingDate: i18n.t(`${table}.tableColumnPairingDate`),
        tableColumnCompletionDate: i18n.t(`${table}.tableColumnCompletionDate`),
        tableColumnStatus: i18n.t(`${table}.tableColumnStatus`),
        tableColumnHealth: i18n.t(`${table}.tableColumnHealth`),
        tableColumnDevices: i18n.t(`${table}.tableColumnDevices`),
      },
    },
  }
}

export const shipmentsInsightTranslations = (): Record<any, any> => {
  const shipmentInsight = 'modules.cv.shipment_insight'
  return {
    translation: {
      general: {
        moduleName: i18n.t(`${shipmentInsight}.moduleName`),
        total_items: i18n.t(`${shipmentInsight}.total_items`),
      },
      filters: {
        names: {
          reference: i18n.t(`${shipmentInsight}.filters.reference`),
          status: i18n.t(`${shipmentInsight}.filters.status`),
          dateType: i18n.t(`${shipmentInsight}.filters.dateType`),
          dateFrom: i18n.t(`${shipmentInsight}.filters.dateFrom`),
          dateTo: i18n.t(`${shipmentInsight}.filters.dateTo`),
        },
        dateFilters: {
          pickup: i18n.t(`${shipmentInsight}.dateFilters.pickup`),
          delivery: i18n.t(`${shipmentInsight}.dateFilters.delivery`),
          pairing: i18n.t(`${shipmentInsight}.dateFilters.pairing`),
          unpairing: i18n.t(`${shipmentInsight}.dateFilters.unpairing`),
        },
        filterPlaceholders: {
          status: i18n.t(`${shipmentInsight}.filterPlaceholders.status`),
          dateType: i18n.t(`${shipmentInsight}.filterPlaceholders.dateType`),
          datePicker: i18n.t(`${shipmentInsight}.filterPlaceholders.datePicker`),
        },
        status: {
          AWAITING_PICKUP: i18n.t(`shared.aggregatedStatus.AWAITING_PICKUP`),
          IN_PROGRESS: i18n.t(`shared.aggregatedStatus.IN_PROGRESS`),
          DELIVERED: i18n.t(`shared.aggregatedStatus.DELIVERED`),
          CANCELLED: i18n.t(`shared.aggregatedStatus.CANCELLED`),
          UNKNOWN: i18n.t(`shared.aggregatedStatus.UNKNOWN`),
        },
      },
      table: {
        tableColumnOrderId: i18n.t(`${shipmentInsight}.table.tableColumnOrderId`),
        tableColumnType: i18n.t(`${shipmentInsight}.table.tableColumnType`),
        tableColumnShipmentId: i18n.t(`${shipmentInsight}.table.tableColumnShipmentId`),
        tableColumnPickupLocation: i18n.t(`${shipmentInsight}.table.tableColumnPickupLocation`),
        tableColumnDeliveryLocation: i18n.t(`${shipmentInsight}.table.tableColumnDeliveryLocation`),
        tableRowFollowed: i18n.t(`${shipmentInsight}.table.tableRowFollowed`),
        tableColumnEta: i18n.t(`${shipmentInsight}.table.tableColumnEta`),
      },
    },
  }
}
