import { ReactElement, useContext } from 'react'

// @mui imports //
import useMediaQuery from '@mui/material/useMediaQuery'
import Stack from '@mui/material/Stack'

// KN Components //
import theme from 'assets/theme'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Context //
import { useInsightDetailsContext } from 'context/detailsNext/InsightDetails'

// Data //
import { addressInfoTranslations } from './AddressInfo.data'

// Context //
import { UserContext } from 'context/authentication/UserContext'

// Template //
import AddressInfoItemTemplate from './AddressInfoItem'

const AddressInfo = (): ReactElement | null => {
  const isTabletHor = useMediaQuery(theme.breakpoints.down('lg'))
  const { insightDetailsState } = useInsightDetailsContext()
  const { translation } = addressInfoTranslations()
  const { userTimezone } = useContext(UserContext)

  // Props //
  const { data } = insightDetailsState.insightDetailsContext
  const pickup = data?.addressInfoProps?.pickup
  const delivery = data?.addressInfoProps?.delivery

  if (!pickup && !delivery) return null
  return (
    <>
      {isTabletHor && <KNTypography variant="displayXS_SB">{translation.moduleName}</KNTypography>}
      <Stack data-test="address-info" direction={isTabletHor ? 'column' : 'row'} spacing={isTabletHor ? 2 : 30}>
        {pickup && AddressInfoItemTemplate(pickup, 'origin', userTimezone)}
        {delivery && AddressInfoItemTemplate(delivery, 'destination', userTimezone)}
      </Stack>
    </>
  )
}

export default AddressInfo
