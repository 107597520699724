/* eslint-disable @typescript-eslint/no-floating-promises */
import { useEffect, ReactElement, useState } from 'react'
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage'
import FadeIn from 'react-fade-in/lib/FadeIn'
import { app, firebaseAvailable } from '../../firebase'

// @mui imports
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'

// KN imports
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Data
import { releaseNotesTranslations } from './ReleaseNotes.data'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'

const ReleaseNotes = (): ReactElement => {
  if (!firebaseAvailable) return <></>

  // Translated Data //
  const { translation } = releaseNotesTranslations()

  // Folders //
  const [error, setError] = useState()
  const [loadingFolders, setLoadingFolders] = useState(true)
  const [folders, setFolders] = useState<string[]>([])
  const [activeFolder, setActiveFolder] = useState<string>()

  // Files //
  const [loadingGuides, setLoadingGuides] = useState(true)
  const [guides, setGuides] = useState<{ path: string; name: string }[]>()
  const [files, setFiles] = useState<{ path: string; name: string }[]>()

  // Create a reference with an initial file path and name
  const storage = getStorage(app)

  useEffect(() => {
    // GET FOLDERS
    const listRefFolders = ref(storage, 'releasenotes/')
    listAll(listRefFolders)
      .then((res) => {
        const folders = res.prefixes.reverse().map((prefix) => prefix.name)
        setFolders(folders)
        setActiveFolder(folders[0])
        setLoadingFolders(false)
      })
      .catch((error) => {
        setError(error)
        setLoadingFolders(false)
      })

    // GET GUIDES
    const listRefGuides = ref(storage, `guides/`)
    listAll(listRefGuides)
      .then((res) => {
        const files = res.items.map((item) => {
          return { path: item.fullPath, name: item.name }
        })
        setGuides(files)
        setLoadingGuides(false)
      })
      .catch((error) => {
        setError(error)
        setLoadingGuides(false)
      })
  }, [])

  useEffect(() => {
    // GET FILES FOR THE CURRENT FOLDER
    if (activeFolder) {
      const listRef = ref(storage, `releasenotes/${activeFolder}`)
      listAll(listRef)
        .then((res) => {
          const files = res.items.map((item) => {
            return { path: item.fullPath, name: item.name }
          })
          setFiles(files)
          setLoadingFolders(false)
        })
        .catch((error) => {
          setError(error)
          setLoadingFolders(false)
        })
    }
  }, [activeFolder])

  const getFileDownload = (file: { path: string; name: string }): void => {
    analyticsEvent('polestar_release_notes_file_downloaded')
    getDownloadURL(ref(storage, `${file.path}`)).then((url) => {
      // `url` is the download URL for 'file'
      fetch(url).then((response) => {
        response
          .blob()
          .then((blob) => {
            // Creating new object of file
            const fileURL = window.URL.createObjectURL(blob)
            // Setting various property values
            const alink = document.createElement('a')
            alink.href = fileURL
            alink.download = file.name
            alink.click()
          })
          .catch((error) => {
            setError(error)
          })
      })
    })
  }

  const handleChange = (folder: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    folder !== activeFolder ? setLoadingFolders(true) : null
    setActiveFolder(folder)
  }

  return (
    <>
      {error && (
        <Alert variant="filled" severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <Box pl={2}>
        <KNTypography variant="displayMD_SB" color="primary.focus">
          {translation.screenName}
        </KNTypography>
      </Box>
      <Grid data-test="release-notes-page-container" container>
        <Grid item xs={12} md={8} xl={6}>
          {!loadingGuides && guides && guides?.length > 0 && (
            <Box pl={2} mt={2} mb={1} data-test="user-guides-container">
              <KNTypography variant="displayXS_SB" color="secondary.focus">
                {translation.user_guides}
              </KNTypography>
              {guides?.map((guide, i) => (
                <KNTypography
                  color="text.light"
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    transition: 'all 0.5s ease-out',
                    '&:hover': {
                      color: 'secondary.main',
                      textDecoration: 'underline',
                    },
                  }}
                  key={i}
                  my={1}
                  onClick={(): void => getFileDownload(guide)}
                >
                  <Icon sx={{ mr: 1 }}>file_open</Icon>
                  {guide.name}
                </KNTypography>
              ))}
            </Box>
          )}
          {loadingFolders && (
            <FadeIn visible={loadingFolders}>
              <KNLoader />
            </FadeIn>
          )}
          {!loadingFolders &&
            folders.map((folder, i) => (
              <FadeIn key={i} visible={!loadingFolders}>
                <Accordion square expanded={activeFolder === folder} onChange={handleChange(folder)} sx={{ my: 1 }}>
                  <AccordionSummary data-test="release-notes-container">
                    <KNTypography variant="displayXS_SB" color="secondary.focus">
                      {String([translation.release_notes, folder].join(' '))}
                    </KNTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {files?.map((file, i) => (
                      <KNTypography
                        color="text.light"
                        sx={{
                          cursor: 'pointer',
                          display: 'flex',
                          transition: 'all 0.5s ease-out',
                          '&:hover': {
                            color: 'secondary.main',
                            textDecoration: 'underline',
                          },
                        }}
                        key={i}
                        mb={1}
                        onClick={(): void => getFileDownload(file)}
                      >
                        <Icon sx={{ mr: 1 }}>file_open</Icon>
                        {file.name}
                      </KNTypography>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </FadeIn>
            ))}
        </Grid>
      </Grid>
    </>
  )
}

export default ReleaseNotes
