import { forwardRef } from 'react'

// KN imports
import Icon from 'components/KN_Components/Base/Icons/Icon'

// Types
import KNIconButtonProps from './types'

// Custom KN styles
import KNIconButtonRoot from './KNIconButtonRoot'

const KNIconButton = forwardRef<HTMLButtonElement, KNIconButtonProps>(
  ({ iconName, iconSize, withknstyling, dataAttribute, ...rest }, ref) => (
    <KNIconButtonRoot
      withknstyling={withknstyling}
      iconName={iconName}
      {...rest}
      ref={ref}
      data-test={`${String(dataAttribute)}-KNIconButton`}
    >
      <Icon name={iconName} size={iconSize} />
    </KNIconButtonRoot>
  )
)

export default KNIconButton
