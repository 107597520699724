import { ReactElement } from 'react'
import { format, utcToZonedTime } from 'date-fns-tz'

// @mui material imports //
import Box from '@mui/material/Box'

// KN Components //
import KNCountryFlag from 'components/KN_Molecules/KNCountryFlag/KNCountryFlag'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Functional //
import { valueToZonedDatetimeWithoutOffset } from 'global/helpers/dataTransformation'

// Types //
import { ShipmentAddressProps } from 'context/detailsNext/InsightDetails.types'

// Data //
import { addressInfoTranslations } from './AddressInfo.data'

const AddressInfoItemTemplate = (
  addressInfoItem: ShipmentAddressProps,
  addressType: 'origin' | 'destination',
  userTimezone: string
): ReactElement => {
  // Translated Data //
  const { translation } = addressInfoTranslations()

  return (
    <Box data-test={`address-info-item-${addressType}`}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <KNTypography variant="displayXXS_SB" color="dark.main">
          {addressType === 'origin' && translation.origin}
          {addressType === 'destination' && translation.destination}
        </KNTypography>
        <KNTypography variant="textLG">{addressInfoItem.address.name}</KNTypography>
        <KNTypography variant="textMD" color="grey.500">
          {addressType === 'origin' && addressInfoItem.early && (
            <>
              <b>{translation.expected}</b>{' '}
              {format(utcToZonedTime(new Date(addressInfoItem.early), userTimezone), 'dd/MM/yyyy HH:mm')}
            </>
          )}
          {addressType === 'destination' && addressInfoItem.late && (
            <>
              <b>{translation.expected}</b>{' '}
              {format(utcToZonedTime(new Date(addressInfoItem.late), userTimezone), 'dd/MM/yyyy HH:mm')}
            </>
          )}
        </KNTypography>
      </Box>
      {(addressInfoItem.address.address ||
        addressInfoItem.address.postalCode ||
        addressInfoItem.address.city ||
        addressInfoItem.address.country) && (
        <Box mt={0.5} sx={{ display: 'flex', flexDirection: 'column' }}>
          <KNTypography variant="textLG" color="primary.light">
            {translation.address}
          </KNTypography>
          {addressInfoItem.address.address && (
            <KNTypography variant="textLG" color="dark.main">
              {addressInfoItem.address.address}
            </KNTypography>
          )}
          {addressInfoItem.address.postalCode && addressInfoItem.address.city && (
            <KNTypography variant="textLG" color="dark.main">
              {`${String(addressInfoItem.address.postalCode)} ${String(addressInfoItem.address.city)}`}
            </KNTypography>
          )}
          {addressInfoItem.address.country && (
            <KNTypography variant="textLG" color="dark.main">
              <KNCountryFlag countryCode={addressInfoItem.address.country} sx={{ mr: 1 }} />
              {addressInfoItem.address.country}
            </KNTypography>
          )}
        </Box>
      )}
    </Box>
  )
}

export default AddressInfoItemTemplate
