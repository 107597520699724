import { Link } from 'react-router-dom'

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs, Theme } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { SystemStyleObject } from '@mui/system'

// KN Components
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Types
import KNBreadcrumbsProps from './KNBreadcrumbs.type'
import { KNHomeIcon } from 'components/KN_Molecules/KNIcon/KNIcon'

const KNBreadcrumbs: React.FC<KNBreadcrumbsProps> = ({ homeRoute, activeRoute }) => (
  <MuiBreadcrumbs
    separator={
      <NavigateNextIcon
        fontSize="small"
        sx={({ palette: { primary } }: Theme): SystemStyleObject<Theme> => ({
          color: primary.dark,
        })}
      />
    }
  >
    {homeRoute && (
      <Link to={homeRoute.link} style={{ textDecoration: 'none' }}>
        <KNTypography
          sx={({ palette: { dark, primary } }: Theme): SystemStyleObject<Theme> => ({
            color: dark.disabled,
            transition: 'color .5s',
            '&:hover': {
              color: primary.main,
            },
          })}
          variant="displayXXS"
        >
          <KNHomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          {homeRoute.name}
        </KNTypography>
      </Link>
    )}
    {activeRoute && (
      <KNTypography color="primary.dark" variant="displayXXS">
        {activeRoute}
      </KNTypography>
    )}
  </MuiBreadcrumbs>
)

export default KNBreadcrumbs
