import { useContext, useEffect, useState, ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom'
import FadeIn from 'react-fade-in/lib/FadeIn'

// @mui imports
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'
import ScheduleIcon from '@mui/icons-material/Schedule'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Link from '@mui/material/Link'
import Slide from '@mui/material/Slide'
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { SystemStyleObject } from '@mui/system'

// KN Components
import theme from 'assets/theme'
import KNChip from 'components/KN_Components/Base/KNChip/KNChip'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { KNHideMenuIcon, KNRevealIcon, KNUserIcon } from 'components/KN_Molecules/KNIcon/KNIcon'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'
import { getActiveMenu } from 'global/helpers/activeRoute'
import { isEtnUrl, getLogo } from 'global/helpers/getLogo'

// Data
import { getMenuOptions } from './DrawerMenu.helpers'

// Context
import { useMenuContext } from 'context/menu/MenuContext'
import { UserContext } from 'context/authentication/UserContext'
import { getSelectedCompany, getStartPage, setSelectedCompany } from 'context/authentication/User.helpers'

// Types
import { Company } from 'context/authentication/Company.types'

const DrawerMenu = (): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { user, userTimezone, logoutUser } = useContext(UserContext)
  const { menuState, dispatch } = useMenuContext()
  const { menuOpen, menuExtended } = menuState.menuContext
  const userIsCustomer = getSelectedCompany()?.type === 'Customer'
  const menuOptions = getMenuOptions()

  const showCompanySelector = user?.companies && user.companies.length > 1

  const location = useLocation()
  const [activeMenu, setActiveMenu] = useState<string | null>(null)
  useEffect(() => {
    setActiveMenu(getActiveMenu(location.pathname))
  }, [location])

  // Dropdown Menu
  const [anchorElCompanies, setAnchorElCompanies] = useState<null | HTMLElement>(null)
  const [anchorElUserMenu, setAnchorElUserMenu] = useState<null | HTMLElement>(null)
  const companyMenuOpen = Boolean(anchorElCompanies)
  const userMenuOpen = Boolean(anchorElUserMenu)
  const handleClickCompanies = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElCompanies(event.currentTarget)
  }
  const handleClickUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUserMenu(event.currentTarget)
  }
  const handleClose = useCallback((): void => {
    if (anchorElCompanies) setAnchorElCompanies(null)
    else if (anchorElUserMenu) setAnchorElUserMenu(null)
  }, [anchorElCompanies, anchorElUserMenu])

  const handleCloseDrawer = (): void => {
    dispatch({
      type: 'setMenuProps',
      payload: {
        menuExtended: menuExtended,
        menuOpen: false,
      },
    })
  }

  const truncateUserDisplayName = (string: string): string => {
    return string.length > 18 ? string.slice(0, 18 - 1) + '...' : string
  }

  const truncateTimeZone = (input: string): string => {
    const string = input.split('/')
    let firstPart = string[0]
    let secondPart = string.slice(1).join('/')

    if (string.length === 1) {
      return input
    }

    if (input.length > 22) {
      firstPart = string[0].slice(0, 4)
    }

    if (input.length > 22) {
      secondPart = `${secondPart.slice(0, 7)}...${secondPart.slice(-7)}`
    }

    return `${firstPart}/${secondPart}`
  }

  const handleSelectCompany = useCallback((company: Company): void => {
    const previousSelectedCompany = getSelectedCompany()
    setSelectedCompany(company)
    analyticsEvent('polestar_selected_company_changed')
    if (
      previousSelectedCompany &&
      previousSelectedCompany.type === company.type &&
      previousSelectedCompany.role === company.role
    ) {
      // Reload only if we have the same set of permissions
      window.location.reload()
    } else {
      window.location.assign(getStartPage())
    }
  }, [])

  // Drawer Menu
  const handleDrawerMenuExtend = (): void => {
    dispatch({
      type: 'setMenuProps',
      payload: {
        menuOpen: menuOpen,
        menuExtended: !menuExtended,
      },
    })
    if (menuExtended) analyticsEvent('polestar_collapse_side_menu')
    else analyticsEvent('polestar_expand_side_menu')
  }

  const mobileWrapper = (): JSX.Element => {
    const drawerMenu = (
      <Box
        data-test="drawer-menu"
        sx={({ boxShadows, palette: { white, dark }, borders: { borderRadius } }: Theme): SystemStyleObject<Theme> => ({
          height: '98%',
          backgroundColor: white.main,
          boxShadow: boxShadows.standardBoxShadow,
          color: dark.main,
          fontWeight: 300,
          borderRadius: borderRadius.lg,
          mt: 1,
          ml: 1,
          p: 2,
          zIndex: 9,
          position: 'fixed',
          width: menuExtended ? '250px' : '100px',
          transition: 'width 0.2s',
          visibility: (isMobile && menuOpen) || !isMobile ? 'visible' : 'hidden',
        })}
      >
        <Box sx={{ position: 'relative', height: '100%' }}>
          <Box
            sx={{
              height: isEtnUrl ? '55px' : '32px',
            }}
          >
            {menuExtended ? getLogo('logo', '100%', '100%') : getLogo('symbol', '100%', '100%')}
          </Box>
          <Box my={4.5} sx={{ textAlign: menuExtended ? 'left' : 'center' }}>
            {menuOptions.map((section, index) => (
              <Box key={index} mb={2.5}>
                <KNTypography variant="textMD_SB" color="primary.light">
                  {menuExtended && (
                    <FadeIn visible={menuExtended} delay={isMobile ? 0 : 200}>
                      {t(section.sectionName)}
                    </FadeIn>
                  )}
                  {!menuExtended && (section.sectionShortName ? t(section.sectionShortName) : t(section.sectionName))}
                </KNTypography>
                {section.sectionOptions.map((sectionOption) => (
                  <Link
                    component={RouterNavLink}
                    key={sectionOption.label}
                    to={sectionOption.link}
                    onClick={handleCloseDrawer}
                    data-test={`drawer-menu-navigate-${sectionOption.label}`}
                  >
                    <Box
                      my={0.5}
                      mx={menuExtended ? -1 : 'auto'}
                      py={1}
                      px={1}
                      sx={{
                        width: menuExtended ? 'auto' : '40px',
                        borderRadius: ({ borders }) => borders.borderRadius.lg,
                        backgroundColor: activeMenu === sectionOption.name ? 'primary.contrastText' : null,
                        color: activeMenu === sectionOption.name ? 'primary.main' : 'dark.focus',
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        transition: isMobile ? 'all 200ms ease-in-out' : 'none',
                        '&:hover': {
                          color: 'primary.main',
                          backgroundColor: 'primary.contrastText',
                        },
                      }}
                    >
                      <Icon color="inherit" sx={{ mr: 1.5 }} fontSize="medium">
                        {sectionOption.icon}
                      </Icon>
                      {menuExtended && (
                        <FadeIn visible={menuExtended} delay={isMobile ? 0 : 200}>
                          <KNTypography variant="textLG" color="inherit" sx={{ textTransform: 'capitalize' }}>
                            {t(sectionOption.label)}
                          </KNTypography>
                        </FadeIn>
                      )}
                    </Box>
                  </Link>
                ))}
              </Box>
            ))}
          </Box>
          <Box
            sx={({ functions: { pxToRem } }: Theme): SystemStyleObject<Theme> => ({
              position: 'absolute',
              bottom: isMobile ? 0 : pxToRem(30),
              width: '100%',
            })}
          >
            <Box
              data-guide="menu-profile"
              p={2}
              sx={{
                width: menuExtended ? '100%' : '72px',
                display: 'inline-flex',
                cursor: 'pointer',
                alignItems: 'center',
                borderRadius: 2,
                backgroundColor: 'primary.contrastText',
                backgroundPosition: 'center',
                transition: isMobile ? 'all 0.2s ease' : 'none',
                '&:hover': {
                  opacity: 0.8,
                },
              }}
              onClick={handleClickUserMenu}
            >
              <Box data-test="username">
                <Box
                  sx={{
                    width: '40px',
                    height: '40px',
                    backgroundColor: ({ palette: { white } }: Theme): string => white.main,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <KNUserIcon
                    sx={({ palette: { primary } }: Theme): SystemStyleObject<Theme> => ({
                      color: primary.main,
                    })}
                  />
                </Box>
              </Box>
              <Box pl={1} sx={{ display: 'flex', flexDirection: 'column' }}>
                {menuExtended && user && (
                  <FadeIn visible={menuExtended} delay={isMobile ? 0 : 200}>
                    <KNTypography
                      variant="textMD"
                      sx={({ palette: { dark } }: Theme): SystemStyleObject<Theme> => ({
                        color: dark.main,
                      })}
                    >
                      {truncateUserDisplayName(user.displayName ?? user.email)}
                    </KNTypography>
                    {userTimezone && userIsCustomer && (
                      <KNChip
                        label={
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <ScheduleIcon sx={{ fontSize: 14, mr: 0.5 }} color="primary" />
                            <KNTypography variant="textSM">{truncateTimeZone(userTimezone)}</KNTypography>
                          </Box>
                        }
                        size="small"
                        color={'white'}
                        variant="contained"
                      />
                    )}
                  </FadeIn>
                )}
              </Box>
            </Box>
            <Menu
              anchorEl={anchorElUserMenu}
              id="user-menu"
              open={userMenuOpen}
              onClose={handleClose}
              anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
              transformOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
              }}
            >
              <MenuItem
                sx={{
                  width: '100%',
                }}
              >
                <Link component={RouterNavLink} to={'/profile'} data-test="drawer-menu-navigate-profile">
                  <KNTypography
                    sx={({ palette: { dark } }: Theme): SystemStyleObject<Theme> => ({
                      color: dark.main,
                    })}
                  >
                    {t('drawer_menu.show_my_profile')}
                  </KNTypography>
                </Link>
              </MenuItem>
              <MenuItem
                data-test={'logout-button'}
                onClick={(): void => {
                  logoutUser()
                  analyticsEvent('polestar_logout')
                }}
              >
                <Icon
                  sx={({ typography: { size } }: Theme): SystemStyleObject<Theme> => ({
                    mr: 0.5,
                    fontSize: size.md,
                  })}
                >
                  logout
                </Icon>
                {t('drawer_menu.logout')}
              </MenuItem>
            </Menu>
            {showCompanySelector && (
              <>
                <KNChip
                  label={getSelectedCompany()?.displayName}
                  id="company-button"
                  data-test={'drawer-menu-companyMenuButton'}
                  size="small"
                  onClick={handleClickCompanies}
                  sx={{
                    mt: 1,
                    width: '100%',
                  }}
                  aria-controls={companyMenuOpen ? 'company-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={companyMenuOpen ? 'true' : undefined}
                />
                <Menu
                  anchorEl={anchorElCompanies}
                  id="company-menu"
                  open={companyMenuOpen}
                  onClose={handleClose}
                  anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                  transformOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                  }}
                >
                  {user?.companies.map((company, index) => (
                    <MenuItem
                      key={index}
                      onClick={(): void => handleSelectCompany(company)}
                      data-test={`drawer-menu-companySelector-${company.displayName}`}
                    >
                      <ListItemText disableTypography>{company.displayName}</ListItemText>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Box>
          {!isMobile && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                display: 'flex',
                justifyContent: menuExtended ? 'flex-end' : 'center',
                left: !menuExtended ? -5 : 0,
              }}
            >
              <IconButton
                onClick={(): void => handleDrawerMenuExtend()}
                data-test={'drawer-menu-extendButton'}
                color="primary"
                size={menuExtended ? 'small' : 'small'}
              >
                {menuExtended ? <KNHideMenuIcon /> : <KNRevealIcon sx={{ height: '20px' }} />}
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    )
    if (isMobile) {
      return (
        <Slide direction="right" in={isMobile && menuOpen} unmountOnExit>
          {drawerMenu}
        </Slide>
      )
    }
    return drawerMenu
  }

  return mobileWrapper()
}

export default DrawerMenu
