import i18n from 'i18n'

export const generalInfoListTranslations = (): Record<any, any> => {
  const generalInfoList = 'modules.cv.general_info_list'
  return {
    translation: {
      pairingID: i18n.t(`${generalInfoList}.pairingID`),
      shipmentID: i18n.t(`${generalInfoList}.shipmentID`),
      description: i18n.t(`${generalInfoList}.description`),
      aggregatedStatus: i18n.t(`${generalInfoList}.aggregated_status`),
      health: i18n.t(`${generalInfoList}.health`),
      additionalRefInfo: i18n.t(`${generalInfoList}.additionalRefInfo`),
      healthStatus: {
        OK: i18n.t(`${generalInfoList}.healthStatus.okay`),
        warning: i18n.t(`${generalInfoList}.healthStatus.warning`),
        error: i18n.t(`${generalInfoList}.healthStatus.error`),
        unset: i18n.t(`${generalInfoList}.healthStatus.unset`),
      },
      transportationType: i18n.t(`${generalInfoList}.transportationType`),
      transportationTypes: {
        road: i18n.t(`${generalInfoList}.transportationTypes.road`),
        air: i18n.t(`${generalInfoList}.transportationTypes.air`),
      },
    },
  }
}
