import { useState, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'

// @mui imports
import DialogContentText from '@mui/material/DialogContentText'

// KN imports
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'

// Functional
import { sleep } from 'global/helpers/sleep'
import { deleteCompany } from './CompanyManager.service'
import { analyticsEvent } from 'global/helpers/analytics'
import { processServerErrorMessages } from 'global/helpers/form'

// Types
import { Company } from 'context/authentication/Company.types'
import DialogContent from '@mui/material/DialogContent'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'

interface DeleteCompanyDialogPayload {
  company: Company
}

interface DeleteCompanyDialogProps {
  payload: DeleteCompanyDialogPayload
  open: boolean
  onAction: () => void
  onClose: () => void
}

const DeleteCompanyDialog = ({ payload, open, onAction, onClose }: DeleteCompanyDialogProps): ReactElement => {
  const { t } = useTranslation()
  const { handleSubmit, formState, setError } = useForm()
  const [deletionConfirmed, setDeletionConfirmed] = useState(false)

  const onSubmit: SubmitHandler<object> = async (): Promise<void> => {
    try {
      await deleteCompany(payload.company.cid)
      // NOTE: avoid stale data
      await sleep(500)
      onAction()
      analyticsEvent('polestar_cv_company_deleted')
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNDialog
      open={open}
      onClose={onClose}
      closeLabel={t('general.cancel')}
      preventClosing={formState.isSubmitting}
      title={`${payload.company.displayName} — ${t('screens.shared.company_manager.delete_company.delete_company')}`}
      actions={
        <>
          <KNLoadingButton
            data-test="delete-button"
            type="submit"
            color="error"
            variant="contained"
            disabled={!deletionConfirmed}
            loading={formState.isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {t('screens.shared.company_manager.card.actions.delete')}
          </KNLoadingButton>
        </>
      }
      onSubmit={handleSubmit(onSubmit)}
      maxWidth="xs"
    >
      <KNDialogFormErrors errors={formState.errors?.root} />
      <DialogContent>
        <KNTypography color="grey.500">{t('screens.shared.company_manager.delete_company.message')}</KNTypography>
        <Box data-test="confirm-checkbox" display="flex" alignItems="center">
          <Checkbox
            sx={{ ml: -1 }}
            value={deletionConfirmed}
            checked={deletionConfirmed}
            onChange={(): void => setDeletionConfirmed(!deletionConfirmed)}
          />
          <KNTypography color="grey.500">{t('shared.manager.deletion_confirm_checkbox')}</KNTypography>
        </Box>
      </DialogContent>
    </KNDialog>
  )
}

export default DeleteCompanyDialog
