import { ReactElement } from 'react'

// @mui imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles/createTheme'

// KN imports
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import Modal from 'components/Molecules/Modal/Modal'

// Data
import { shareShipmentTranslations } from './ShareShipmentDetails.data'
import ShipmentBackground from './ShipmentBackground'

const ShareShipmentDetailsErrorPage = (): ReactElement => {
  // Translated Data //
  const { translation } = shareShipmentTranslations()

  const pinCodeModalUI = (): JSX.Element => {
    return (
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        overflow="hidden"
      >
        <Box>
          <Stack direction="column">
            <KNTypography variant="displayXS_SB" color="dark.main">
              {translation.errorTitle}
            </KNTypography>
            <Box mt={1}>
              <KNTypography variant="textLG" color="dark.focus">
                {translation.errorMessage}
              </KNTypography>
            </Box>
            <KNButton
              variant="gradient"
              color="primary"
              fullWidth
              sx={{
                fontSize: ({ typography: { size } }: Theme): string => size.md,
                height: '44px',
                mt: 1,
              }}
              onClick={(): void => window.location.replace('/login')}
            >
              {translation.goBack}
            </KNButton>
          </Stack>
        </Box>
      </Modal>
    )
  }
  return (
    <>
      <ShipmentBackground />
      <>{pinCodeModalUI()}</>
    </>
  )
}

export default ShareShipmentDetailsErrorPage
