import { useFeatureFlagEnabled } from 'posthog-js/react'

// https://posthog.com/docs/feature-flags/adding-feature-flag-code?tab=React documentation

// Keep the feature flags like this in order to keep a nice overview on what's available atm

export const scannerFeatureFlag = (): boolean | undefined => {
  return useFeatureFlagEnabled('scanner')
}

export const sharingRulesFeatureFlag = (): boolean | undefined => {
  return useFeatureFlagEnabled('sharing_rules')
}
