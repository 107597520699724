import { useEffect, useState, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { set as dateFnsSet } from 'date-fns'

// @mui imports
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import CloseIcon from '@mui/icons-material/Close'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

// KN imports
import i18n from 'i18n'
import { zonedDate } from 'global/helpers/dateFormatters'
import { sleep } from 'global/helpers/sleep'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import KNCaption from 'components/KN_Molecules/KNCaption/KNCaption'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'
import { getTripLegs } from 'screens/TripDetails/TripDetails.service'
import { LegData, StopData } from 'screens/TripDetails/TripDetails.types'
import QuickStatusUpdateStop from './QuickStatusUpdateStop'
import TripCargo from 'screens/TripDashboard/TripCargo'
import LocationLink from 'screens/TripDashboard/LocationLink'
import TripStatusChip from 'screens/TripDashboard/TripStatusChip'
import TripHeader from 'screens/TripDashboard/TripHeader'
import LegHeader from 'screens/TripDetails/LegHeader'

interface QuickStatusUpdateDialogPayload {
  trip: TripData
}

interface QuickStatusUpdateDialogProps {
  payload: QuickStatusUpdateDialogPayload
  open: boolean
  onAction: (updatedTrip: TripData) => void
  onClose: () => void
}

const QuickStatusUpdateDialog = ({ payload, open, onAction, onClose }: QuickStatusUpdateDialogProps): ReactElement => {
  const { t } = useTranslation()
  const [legsData, setLegsData] = useState<LegData[]>([])
  const [loading, setLoading] = useState(true)
  const [refreshTripList, setRefreshTripList] = useState(false)
  const [expanded, setExpanded] = useState<string | false>(false)

  const fetchTripDetailsData = async (backgroundLoading = false): Promise<void> => {
    if (backgroundLoading) {
      // tripListDispatch({ type: 'setBackgroundLoading', payload: true })
      await sleep(1000)
    } else {
      setLoading(true)
    }
    setLegsData(await getTripLegs(payload.trip.entityId))
    if (backgroundLoading) {
      // tripListDispatch({ type: 'setBackgroundLoading', payload: false })
    } else {
      setLoading(false)
    }
  }

  const handlePanelChange = (panel: string): void => {
    setExpanded(panel === expanded ? false : panel)
  }

  const handlePanelAction = useCallback(
    async (updatedStops: StopData[]): Promise<void> => {
      const updatedLegsData = legsData.map((leg) => {
        // NOTE: return one of the updated stops if wayPointCid matches, or original stop if not
        leg.wayPoints = leg.wayPoints.map(
          (stop) => updatedStops.filter((updatedStop) => updatedStop.wayPointCid === stop.wayPointCid)?.[0] ?? stop
        )
        return leg
      })
      setLegsData(updatedLegsData)
      setRefreshTripList(true)
      await fetchTripDetailsData(true)
    },
    [legsData]
  )

  useEffect(() => {
    if (open) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchTripDetailsData()
    }
  }, [open])

  useEffect(() => {
    if (legsData) {
      let defaultPanel
      // find default panel to expand
      legsData.map((leg) => {
        leg.wayPoints.map((stop) => {
          // ignore if there's something chosen already
          if (defaultPanel) {
            return
          }
          if (stop.availableStatuses.length > 0 && stop.type !== 'CUS') {
            defaultPanel = stop.wayPointCid
          }
        })
      })
      setExpanded(defaultPanel)
    }
  }, [legsData])

  return (
    <KNDialog
      open={open}
      closeAction={false}
      onClose={(): void => {
        if (refreshTripList) {
          onAction(payload.trip) // NOTE: no change to trip
        } else {
          onClose()
        }
      }}
      allowBackdropClose
      maxWidth="xs"
      slideDirection="left"
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'flex-end',
        },
        '& .MuiPaper-root': {
          margin: 1,
          height: 'calc(100% - 16px)',
          maxHeight: 'calc(100% - 16px)',
        },
      }}
    >
      <Stack
        data-test="quick-update-trip-status"
        direction="row"
        mb={0.5}
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <TripHeader trip={payload.trip} stacked size="large" />
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            m: -0.75,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
      <TripCargo trip={payload.trip} sx={{ mb: 3 }} />
      {loading ? (
        <KNLoader>
          <KNTypography>{t('screens.cs.trip_details.loading')}</KNTypography>
        </KNLoader>
      ) : (
        <>
          <DialogContentText data-test="dialog-content-text" component="div" mb={3}>
            <KNCaption color="warning" icon={<WarningIcon />}>
              {t('screens.cs.trip_details.update_status.warning')}
            </KNCaption>
          </DialogContentText>
          <Stack spacing={4} mb={4}>
            {legsData.map((leg) => (
              <Box key={leg.shipmentNumber}>
                <LegHeader trip={payload.trip} leg={leg} size="large" />
                <Divider orientation="horizontal" flexItem sx={{ borderColor: 'dark.main', mt: 1.5, mb: 2 }} />
                <Stack
                  data-test="trip-info-container"
                  spacing={2}
                  divider={<Divider orientation="horizontal" flexItem />}
                >
                  {leg.wayPoints.map((stop) => (
                    <QuickStatusUpdateStop
                      key={stop.wayPointCid}
                      trip={payload.trip}
                      leg={leg}
                      stop={stop}
                      expanded={expanded === stop.wayPointCid}
                      onChange={handlePanelChange}
                      onAction={handlePanelAction}
                    />
                  ))}
                </Stack>
              </Box>
            ))}
          </Stack>
          <KNCaption icon={<InfoIcon />}>{t('screens.cs.trip_details.local_time')}</KNCaption>
        </>
      )}
    </KNDialog>
  )
}

export default QuickStatusUpdateDialog
