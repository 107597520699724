import theme from 'assets/theme'

export const getColorByIndex = (index: number): string => {
  const colors = theme.palette
  const allColors = [
    colors.primary.main,
    colors.secondary.main,
    colors.success.main,
    colors.error.main,
    colors.warning.main,
    colors.success.light,
    colors.primary.dark,
    colors.info.main,
  ]
  return allColors[index]
}
