import { ReactElement, useContext, useEffect, useState } from 'react'
import format from 'date-fns/format'
import { utcToZonedTime } from 'date-fns-tz'

// @mui imports //
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN Components //
import theme from 'assets/theme'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Context //
import { useInsightDetailsContext } from 'context/detailsNext/InsightDetails'
import { UserContext } from 'context/authentication/UserContext'

// Functional //
import { getTrackingDevicesData } from './TrackingInfo.service'
import { getErrorMessage } from 'global/helpers/errorHandler'

// Types //
import TrackingInfoProps, { TrackingDeviceInfoProps } from './TrackingInfo.types'

// Data //
import { trackingInfoTranslation } from './TrackingInfo.data'

// Template //
import TrackingInfoItemTemplate from './TrackingInfoItem'

const TrackingInfo = ({ token, pinCode }: TrackingInfoProps): ReactElement | null => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { insightDetailsState } = useInsightDetailsContext()

  // Context //
  const { userTimezone } = useContext(UserContext)

  // Translated Data
  const { translation } = trackingInfoTranslation()

  // Module state//
  const [dataLoading, setDataLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [trackingDevicesData, setTrackingDevicesData] = useState<TrackingDeviceInfoProps[]>()

  // Props //
  const { data } = insightDetailsState.insightDetailsContext
  const pairingId = data?.pairingId
  const completed = data?.trackingInfoProps?.completionDate
  const trackingInfoProps = data?.trackingInfoProps
  const trackingLevel = trackingInfoProps?.level
  const pairingDate = trackingInfoProps?.creationDate
  const completionDate = trackingInfoProps?.completionDate

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setDataLoading(true)
      try {
        setTrackingDevicesData(await getTrackingDevicesData(pairingId ?? '', token, pinCode))
      } catch (error) {
        setError(getErrorMessage(error))
      }
      setDataLoading(false)
    }
    if (pairingId) {
      void fetchData()
    }
  }, [pairingId])

  if (!pairingId) return null
  return (
    <>
      {isMobile && <KNTypography variant="displayXS_SB">{translation.moduleName}</KNTypography>}
      <Box>
        <Box data-test="tracking-info" mb={1.5}>
          <KNTypography variant="displayXS_SB" color={completed ? 'success' : 'primary'}>
            {translation.shipment} {completed ? translation.completed : translation.in_progress}
          </KNTypography>
        </Box>
        <Box>
          <Stack direction={{ sm: 'column', md: 'row' }} spacing={25}>
            <Box>
              <KNTypography variant="displayXXS" color="primary.light" sx={{ display: 'block' }}>
                {translation.pairingDate}
              </KNTypography>
              <KNTypography data-test="pairing-date" variant="textLG" color="dark.main">
                {pairingDate && format(utcToZonedTime(new Date(pairingDate), userTimezone), 'dd/MM/yyyy HH:mm')}
              </KNTypography>
            </Box>
            {completionDate && (
              <Box>
                <KNTypography variant="displayXXS" color="primary.light" sx={{ display: 'block' }}>
                  {translation.completionDate}
                </KNTypography>
                <KNTypography data-test="completion-date" variant="textLG" color="dark.main">
                  {completionDate && format(utcToZonedTime(new Date(completionDate), userTimezone), 'dd/MM/yyyy HH:mm')}
                </KNTypography>
              </Box>
            )}
          </Stack>
          <Box mt={1}>
            <KNTypography variant="displayXXS" color="primary.light" sx={{ display: 'block' }}>
              {translation.trackingDevices}
            </KNTypography>
            <Stack data-test="tracking-devices" direction={'row'} spacing={4} useFlexGap flexWrap="wrap">
              {trackingDevicesData?.map((trackingDevice, i) =>
                TrackingInfoItemTemplate(trackingDevice, i, userTimezone, trackingLevel)
              )}
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default TrackingInfo
