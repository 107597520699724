import { useEffect, useState, ReactElement } from 'react'
import { useHistory } from 'react-router-dom'

// @mui imports
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'

// KN imports
import { IconName } from 'components/KN_Components/Base/Icons/Icon.type'
import Form from 'components/Form/Form/Form'
import Scanner from 'components/Molecules/Scanner/Scanner'
import KNFeatureWrapper from 'components/KN_Molecules/KNFeatureWrapper/KNFeatureWrapper'
import KNIconMessage from 'components/KN_Molecules/KNIconMessage/KNIconMessage'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Functional
import { editPairing, getPairingInfo, pairShipment } from './ShipmentPairer.service'
import { mapDevicesToObjects } from './ShipmentPairer.helpers'
import { analyticsEvent } from 'global/helpers/analytics'
import { getErrorMessage } from 'global/helpers/errorHandler'

// Types
import FormFieldProps from 'components/Form/FormField.type'
import { ShipmentPairerProps } from './ShipmentPairer.type'

// Data
import { shipmentPairerTranslations } from './ShipmentPairer.data'

const ShipmentPairer = (props: ShipmentPairerProps): ReactElement => {
  const { id = '', editMode, modalMode } = props
  const history = useHistory()
  const [description, changeDescription] = useState('')
  const [reference, changeReference] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [beaconFields, setBeaconFields] = useState<FormFieldProps[]>([])
  const [temperatureToggleActive, setTemperatureToggleActive] = useState(true)
  const [automaticUnpairingToggleActive, setAutomaticUnpairingToggleActive] = useState(true)
  const [minTemperature, setMinTemperature] = useState<{
    active: boolean
    value: number | null
  }>({
    active: false,
    value: null,
  })
  const [maxTemperature, setMaxTemperature] = useState<{
    active: boolean
    value: number | null
  }>({
    active: false,
    value: null,
  })
  const [temperaturePreset, setTemperaturePreset] = useState('')
  const [pairingResult, setPairingResult] = useState<{
    status?: string
    message?: string
  }>()

  // Check description for special characters
  const [specialCharsError, setSpecialCharsError] = useState('')
  useEffect(() => {
    // eslint-disable-next-line no-useless-escape
    const specialChars = /[`!@#$%^&*()+\[\]{};':"\\|,.<>\/?~]/
    setSpecialCharsError(specialChars.test(description) ? translation.card.error.special_chars : '')
  }, [description])

  // Translated Data
  const { translation } = shipmentPairerTranslations()

  // Get Pairing Info
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true)
      const pairingInfo = await getPairingInfo(id)
      changeReference(pairingInfo.reference)
      changeDescription(pairingInfo.description)
      setBeaconFields(mapDevicesToObjects(pairingInfo.deviceIds))
      if (pairingInfo.temperatureThreshold) {
        pairingInfo.temperatureThreshold.min !== null || pairingInfo.temperatureThreshold.max !== null
          ? setTemperatureToggleActive(true)
          : setTemperatureToggleActive(false)
        setMinTemperature({
          active: pairingInfo.temperatureThreshold.min !== null,
          value: pairingInfo.temperatureThreshold.min ?? null,
        })
        setMaxTemperature({
          active: pairingInfo.temperatureThreshold.max !== null,
          value: pairingInfo.temperatureThreshold.max ?? null,
        })
      }
      setAutomaticUnpairingToggleActive(pairingInfo.unpairingRule !== null)
      setLoading(false)
    }
    if (id !== '') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchData()
    }
  }, [id, editMode])

  // Reset thresholds
  useEffect(() => {
    if (temperatureToggleActive) {
      setMinTemperature({
        active: false,
        value: null,
      })
      setMaxTemperature({
        active: false,
        value: null,
      })
    }
    analyticsEvent('polestar_cv_temperature_treshold_toggle')
  }, [temperatureToggleActive])

  useEffect(() => {
    analyticsEvent('polestar_cv_set_temperature_preset', [temperaturePreset])
  }, [temperaturePreset])

  const shipmentFields: FormFieldProps[] = [
    {
      label: translation.card.label.shipment_id,
      id: translation.card.label.shipment_id.replace(/\s/g, ''),
      name: 'ShipmentID',
      onChange: (e): void => changeReference(e.target.value),
      icon: IconName.shipmentid,
      fieldEnhancer: !editMode ? (
        <Scanner
          onChange={(value): void => {
            changeReference(value)
            analyticsEvent('polestar_cv_scan_shipment_id', [value])
          }}
          dataAttribute={'scanner'}
        />
      ) : undefined,
      withFieldValidation: true,
      errorMessage: translation.card.label.empty_field,
      required: true,
      value: reference,
      disabled: editMode,
    },
    {
      label: translation.card.label.shipment_description,
      id: translation.card.label.shipment_description.replace(/\s/g, ''),
      name: 'Shipment Description',
      onChange: (e): void => {
        changeDescription(e.target.value)
        analyticsEvent('polestar_cv_add_shipment_description', [e.target.value])
      },
      icon: IconName.text,
      withMaxChars: true,
      errorMessage: specialCharsError,
      autoFocus: false,
      required: false,
      value: description,
      helperText: specialCharsError || translation.card.label.optional_field,
    },
  ]

  const temperatureThresholds = {
    active: id === '' ? false : temperatureToggleActive,
    setActiveButtonTitle: translation.card.button.temperature_threshold_toggle,
    getSliderStatus: (): void => {
      setTemperatureToggleActive(!temperatureToggleActive)
    },
    radioName: translation.card.button.temperature_threshold_type,
    radioOptions: [
      {
        label: translation.card.button.temperature_threshold_min,
        checked: minTemperature.active,
        getValue: (val: number): void => {
          setMinTemperature({ ...minTemperature, value: val })
          analyticsEvent('polestar_cv_set_min_temperature', [val.toString()])
        },
        handleCheck: (val: boolean): void =>
          setMinTemperature({
            active: val,
            value: minTemperature.value ? minTemperature.value : 0,
          }),
        icon: IconName.min_temp,
        value: minTemperature.value,
      },
      {
        label: translation.card.button.temperature_threshold_max,
        checked: maxTemperature.active,
        getValue: (val: number): void => {
          setMaxTemperature({ ...maxTemperature, value: val })
          analyticsEvent('polestar_cv_set_max_temperature', [val.toString()])
        },
        handleCheck: (val: boolean): void =>
          setMaxTemperature({
            active: val,
            value: maxTemperature.value ? maxTemperature.value : 0,
          }),
        icon: IconName.max_temp,
        value: maxTemperature.value,
      },
    ],
    standardValues: {
      label: translation.dropdown.name,
      activeValue: temperaturePreset,
      dropdownOptions: [
        {
          label: '< -20°C',
          onClick: (): void => {
            setTemperaturePreset('< -20°C')
            setMinTemperature({ active: false, value: null })
            setMaxTemperature({ active: true, value: -20 })
            analyticsEvent('polestar_cv_temperature_preset_<-20')
          },
        },
        {
          label: '+2°C <-> +8°C',
          onClick: (): void => {
            setTemperaturePreset('+2°C <-> +8°C')
            setMinTemperature({ active: true, value: 2 })
            setMaxTemperature({ active: true, value: 8 })
            analyticsEvent('polestar_cv_temperature_preset_2-8')
          },
        },
        {
          label: '+15°C <-> +25°C',
          onClick: (): void => {
            setTemperaturePreset('+15°C <-> +25°C')
            setMinTemperature({ active: true, value: 15 })
            setMaxTemperature({ active: true, value: 25 })
            analyticsEvent('polestar_cv_temperature_preset_15-25')
          },
        },
      ],
    },
  }

  const toggleFields = [
    {
      title: translation.card.label.automatic_unpairing,
      onChange: () => {
        setAutomaticUnpairingToggleActive(!automaticUnpairingToggleActive)
        analyticsEvent('polestar_cv_toggle_automatic_unpairing')
      },
      checked: automaticUnpairingToggleActive,
      dataAttribute: 'automatic-unpairing',
    },
  ]

  const fields = beaconFields.concat(shipmentFields)

  const submitShipment = async (): Promise<void> => {
    setLoading(true)
    await pairShipment(
      reference,
      beaconFields.map((field) => field.value),
      automaticUnpairingToggleActive,
      description,
      {
        min: minTemperature.active ? minTemperature.value : null,
        max: maxTemperature.active ? maxTemperature.value : null,
      }
    )
      .then(() => setPairingResult({ status: 'success', message: '' }))
      .catch((response) => {
        setPairingResult({ status: 'error', message: getErrorMessage(response) })
      })
    setLoading(false)
    setTimeout(() => history.push('/visibility-dashboard'), 5000)
  }

  const submitEdit = async (): Promise<void> => {
    setLoading(true)
    await editPairing(
      id,
      reference,
      beaconFields.map((field) => field.value),
      automaticUnpairingToggleActive,
      description,
      {
        min: minTemperature.active ? minTemperature.value : null,
        max: maxTemperature.active ? maxTemperature.value : null,
      }
    )
      .then(() => setPairingResult({ status: 'success', message: '' }))
      .catch((response) => setPairingResult({ status: 'error', message: response }))
    setLoading(false)
    setTimeout(() => history.go(0), 3000)
    analyticsEvent('polestar_cv_edit_pairing', [id])
  }

  const emptyFieldsValidator =
    beaconFields.length === 0 ||
    reference === '' ||
    fields.filter((field) => field.required && field.value === '').length > 0

  const sameValuesValidator =
    beaconFields
      .map((field) => field.value)
      .filter((item, index) => beaconFields.map((field) => field.value).indexOf(item) !== index).length > 0

  const getLoaderText = (): string => {
    if (pairingResult?.status === 'success') {
      return translation.button.success
    }
    return pairingResult?.message ?? translation.button.error
  }

  const getInterface = (): ReactElement => {
    return (
      <Box sx={{ minHeight: '300px' }}>
        <Fade timeout={300} in={loading} unmountOnExit>
          <div>
            <KNLoader>
              <KNTypography>{translation.button.loading}</KNTypography>
            </KNLoader>
          </div>
        </Fade>
        <Fade timeout={2500} in={pairingResult !== undefined && !loading} unmountOnExit mountOnEnter>
          <div>
            <KNIconMessage
              data-test="pairing-result-message"
              messageType={pairingResult?.status === 'success' ? 'success' : 'error'}
            >
              <KNTypography>{getLoaderText()}</KNTypography>
            </KNIconMessage>
          </div>
        </Fade>
        <Fade timeout={0} in={!loading && !pairingResult} unmountOnExit>
          <div>
            <Form
              fields={shipmentFields}
              withFlexibleArrayOfFields
              flexibleArrayOfFields={beaconFields}
              setFlexibleArrayOfFields={(value): void => setBeaconFields(value)}
              onSubmit={editMode ? submitEdit : submitShipment}
              textSubmitButton={editMode ? translation.button.edit_shipment : translation.button.add_shipment}
              flexibleArrayOfFieldsAddButton={translation.card.button.add_beacon}
              flexibleArrayOfFieldsEnhancer={true}
              flexibleArrayOfFieldsLabel={translation.card.label.beacon_id}
              flexibleArrayOfFieldsError={translation.card.label.empty_field}
              sliderField={temperatureThresholds}
              switchFields={toggleFields}
              submitDisabled={emptyFieldsValidator || sameValuesValidator || specialCharsError !== ''}
              submitExplanation={translation.card.button.tooltip}
              dataAttribute={'shipmentPairer'}
            />
          </div>
        </Fade>
      </Box>
    )
  }

  return (
    <>
      {!modalMode ? (
        <KNFeatureWrapper featureTitle={translation.moduleName}>{getInterface()}</KNFeatureWrapper>
      ) : (
        getInterface()
      )}
    </>
  )
}

export default ShipmentPairer
