import { ReactElement } from 'react'
import { etaTranslations } from './ETA.data'

// @mui imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Theme } from '@mui/material'
import { SystemStyleObject } from '@mui/system'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Functional
import { valueToZonedDatetimeWithOffset, valueToZonedDatetimeWithoutOffset } from 'global/helpers/dataTransformation'

// Context
import { useInsightDetailsContext } from 'context/detailsNext/InsightDetails'
import ETABlinkBlink from './ETABlinkBlink'

const ETA = (): ReactElement | null => {
  const { insightDetailsState } = useInsightDetailsContext()

  // Props
  const { data } = insightDetailsState.insightDetailsContext
  const latestEta = data?.etaProps?.latestEta
  const delivered = data?.etaProps?.delivered

  // Translations
  const { translation } = etaTranslations()

  if (!latestEta) return null
  return (
    <Box>
      <KNTypography
        data-test="eta"
        variant="displayXXS"
        sx={({ palette: { black } }: Theme): SystemStyleObject<Theme> => ({
          color: black.main,
        })}
      >
        {translation.label}
      </KNTypography>
      <Grid container alignItems="center" sx={{ mt: 1 }}>
        <Grid item xs={8}>
          <Box sx={{ display: 'flex' }}>
            <KNTypography
              sx={({ palette: { secondary } }: Theme): SystemStyleObject<Theme> => ({
                color: secondary.main,
                alignItems: 'center',
              })}
              variant="displayXXS_SB"
            >
              {valueToZonedDatetimeWithoutOffset(latestEta)}
            </KNTypography>
            <ETABlinkBlink />
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ position: 'relative' }}>
          <KNTypography
            sx={({ palette: { secondary } }: Theme): SystemStyleObject<Theme> => ({
              color: secondary.main,
              display: 'flex',
            })}
            variant="displayXXS"
          >
            {valueToZonedDatetimeWithOffset(latestEta)?.offset ?? ''}
          </KNTypography>
          <KNTypography
            variant="textSM"
            sx={({ palette: { primary }, functions: { pxToRem } }: Theme): SystemStyleObject<Theme> => ({
              color: primary.light,
              position: 'absolute',
              mt: pxToRem(-5),
            })}
          >
            {translation.timezone}
          </KNTypography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ETA
