import { useState } from 'react'
import FadeIn from 'react-fade-in'
import { TransitionGroup } from 'react-transition-group'

// @mui imports
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import Fade from '@mui/material/Fade'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Tooltip from '@mui/material/Tooltip'

// KN imports
import Icon from 'components/KN_Components/Base/Icons/Icon'
import { IconName } from 'components/KN_Components/Base/Icons/Icon.type'
import Scanner from 'components/Molecules/Scanner/Scanner'
import TextInput from '../TextInput/TextInput'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNIconButton from 'components/KN_Components/Base/KNIconButton/KNIconButton'
import KNSwitch from 'components/KN_Components/Base/KNSwitch/KNSwitch'
import KNTextSlider from 'components/KN_Molecules/KNTextSlider/KNTextSlider'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import FormFieldProps from '../FormField.type'

// Types
import FormProps from './Form.type'

const Form: React.FC<FormProps> = ({
  fields,
  withFlexibleArrayOfFields,
  flexibleArrayOfFields,
  textSubmitButton,
  onSubmit,
  setFlexibleArrayOfFields,
  flexibleArrayOfFieldsAddButton,
  flexibleArrayOfFieldsEnhancer,
  flexibleArrayOfFieldsLabel = '',
  flexibleArrayOfFieldsError = '',
  sliderField,
  switchFields = [],
  submitDisabled,
  submitExplanation,
  submitFullWidth = false,
  dataAttribute,
}) => {
  const extraField = (id: string, value?: string): FormFieldProps => {
    const emptyField = {
      label: flexibleArrayOfFieldsLabel,
      id: id,
      name: flexibleArrayOfFieldsLabel,
      withFieldValidation: true,
      errorMessage: flexibleArrayOfFieldsError,
      required: true,
      value: '',
    }
    if (typeof value === 'string') {
      return { ...emptyField, value: value }
    }
    return emptyField
  }

  const setFieldValue = (value?: any): void => {
    if (setFlexibleArrayOfFields) {
      setFlexibleArrayOfFields(flexibleArrayOfFields?.concat(extraField(String(Math.random()), value)))
    }
  }

  const changeFieldValue = (id: string, value: string): void => {
    if (setFlexibleArrayOfFields) {
      setFlexibleArrayOfFields(flexibleArrayOfFields?.map((f) => (f.id === id ? { ...f, value: value } : f)))
    }
  }

  const removeField = (id: string): void => {
    if (setFlexibleArrayOfFields) {
      setFlexibleArrayOfFields(flexibleArrayOfFields?.filter((f) => f.id !== id))
    }
  }

  const [sliderActive, setSliderActive] = useState(sliderField?.active)

  const handleSliderChange = (): void => {
    setSliderActive(!sliderActive)
    if (sliderField?.getSliderStatus) {
      sliderField.getSliderStatus(!sliderActive)
    }
  }

  return (
    <form noValidate>
      <Box my={2}>
        {fields.map((field, i) => (
          <Box my={4} key={i}>
            {field.fieldTitle}
            <Grid container spacing={2} alignItems="center">
              {field.fieldEnhancer ? (
                <>
                  <Grid item xs={10} sx={{ minHeight: '105px' }}>
                    <TextInput {...field} key={i} />
                  </Grid>
                  <Grid item xs={2}>
                    {field.fieldEnhancer}
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <TextInput {...field} key={i} />
                </Grid>
              )}
            </Grid>
          </Box>
        ))}
      </Box>
      {withFlexibleArrayOfFields && (
        <Box my={2}>
          <TransitionGroup>
            {flexibleArrayOfFields?.map((field, i) => (
              <Collapse key={i}>
                <Grid container spacing={2} my={2} alignItems="center">
                  <Grid item xs={8} sx={{ minHeight: '105px' }}>
                    <TextInput
                      {...field}
                      key={i}
                      onChange={(e): void =>
                        field.onChange ? field.onChange(e) : changeFieldValue(field.id, e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <KNIconButton
                        onClick={(): void => removeField(field.id)}
                        iconName={IconName.delete}
                        withknstyling
                        sx={{
                          border: '1px solid',
                          borderColor: 'primary.main',
                        }}
                        size="large"
                        dataAttribute={dataAttribute}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Collapse>
            ))}
          </TransitionGroup>
          <Grid container spacing={2} my={2}>
            <Grid item xs={10}>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '100%',
                }}
              >
                <Tooltip title={flexibleArrayOfFieldsAddButton ?? ''} placement="right" arrow>
                  <KNIconButton
                    onClick={setFieldValue}
                    iconName={IconName.add}
                    withknstyling
                    sx={{
                      border: '1px solid',
                      borderColor: 'primary.main',
                    }}
                    size="large"
                  />
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={2}>
              {flexibleArrayOfFieldsEnhancer && (
                <Scanner onChange={(value): void => setFieldValue(value)} type="qrcode" dataAttribute={dataAttribute} />
              )}
            </Grid>
          </Grid>
          {sliderField && (
            <Grid container spacing={2} my={2}>
              <Grid item xs={10} data-test="temperature-container">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <KNTypography>{sliderField.setActiveButtonTitle}</KNTypography>
                  <KNSwitch onChange={handleSliderChange} checked={sliderActive} dataAttribute={dataAttribute} />
                  {sliderField.standardValues && (
                    <Box ml="auto">
                      <FadeIn visible={sliderActive}>
                        <FormControl sx={{ minWidth: 150 }} size="small">
                          <InputLabel>{sliderField.standardValues.label}</InputLabel>
                          <Select label={sliderField.standardValues.label} size="small">
                            {sliderField.standardValues.dropdownOptions.map((dropdownOption) => (
                              <MenuItem
                                key={dropdownOption.label}
                                value={dropdownOption.label}
                                onClick={dropdownOption.onClick}
                              >
                                {dropdownOption.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </FadeIn>
                    </Box>
                  )}
                </Box>
              </Grid>
              {sliderActive &&
                sliderField.radioOptions.map((option) => (
                  <>
                    <Grid
                      item
                      xs={10}
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      <Checkbox
                        sx={{ ml: -1 }}
                        checked={option.checked}
                        onChange={(): void => option.handleCheck(!option.checked)}
                        data-test={`${String(dataAttribute)}-checkbox`}
                      />
                      <KNTypography>{option.label}</KNTypography>
                    </Grid>
                    <Grid item xs={10} data-test={`${String(dataAttribute)}-slider`}>
                      <KNTextSlider
                        getValue={option.getValue}
                        icon={option.icon}
                        value={option.value ? option.value : 0}
                        disabled={!option.checked}
                      />
                    </Grid>
                  </>
                ))}
            </Grid>
          )}
          {switchFields.length > 0 && (
            <Grid container spacing={2} my={2}>
              <Grid data-test="automatic-unpairing-toogle" item xs={10}>
                {switchFields.map(({ title, ...rest }, i) => (
                  <Box
                    key={i}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <KNTypography>{title}</KNTypography>
                    <KNSwitch {...rest} />
                  </Box>
                ))}
              </Grid>
            </Grid>
          )}
        </Box>
      )}
      <Box mt={2}>
        <Grid item xs={submitFullWidth ? 12 : 10}>
          <KNButton
            onClick={onSubmit}
            disabled={submitDisabled}
            fullWidth
            variant="contained"
            dataAttribute={dataAttribute}
          >
            {textSubmitButton}
          </KNButton>
          {submitExplanation && (
            <Fade in={submitDisabled}>
              <Box
                mt={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Icon color="secondary" name={IconName.info_circle} />
                <KNTypography color="secondary" variant="caption" pl={1}>
                  {submitExplanation}
                </KNTypography>
              </Box>
            </Fade>
          )}
        </Grid>
      </Box>
    </form>
  )
}

export default Form
