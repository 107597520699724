import { ReactElement, useEffect } from 'react'

// @mui imports
import Grid from '@mui/material/Grid'

// Functional
import ShipmentPairer from 'modules/ShipmentPairer/ShipmentPairer'
import { analyticsEvent, analyticsPageView } from 'global/helpers/analytics'

const PairShipment = (): ReactElement => {
  useEffect(() => {
    analyticsPageView('polestar/cv/pairing')
  }, [])

  return (
    <Grid data-test="paring-page-container" container>
      <Grid item xs={12} md={8} xl={6}>
        <ShipmentPairer />
      </Grid>
    </Grid>
  )
}

export default PairShipment
