// Functional //
import { fetchDelete, fetchGet, fetchPost, fetchPut } from 'global/helpers/fetchWrapper'

// Types //
import { ConditionGroup } from './AccessGroupTable.types'

export const getConditionGroups = async (): Promise<ConditionGroup[]> => {
  return await fetchGet(`conditiongroups`, {}, 'si', 'v1')
}

export const getConditionGroup = async (cid: string): Promise<ConditionGroup> => {
  return await fetchGet(`conditiongroups/${cid}`, {}, 'si', 'v1')
}

export const deleteConditionGroup = async (cid: string): Promise<void> => {
  return await fetchDelete(`conditiongroups/${cid}`, null, {}, 'si', 'v1')
}

export const postConditionGroup = async (data: ConditionGroup): Promise<void> => {
  return await fetchPost(`conditiongroups`, data, {}, 'si', 'v1')
}

export const updateConditionGroup = async (cid: string, data: ConditionGroup): Promise<void> => {
  return await fetchPut(`conditiongroups/${cid}`, data, {}, 'si', 'v1')
}
