import { useState, useContext, useMemo, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNDataTable from 'components/KN_Molecules/KNDataTable/KNDataTable'
import KNDataTableOverflow from 'components/KN_Molecules/KNDataTable/KNDataTableOverflow'
import CompanyTypeLabel from './CompanyTypeLabel'
import CreateOrEditCompanyDialog from './CreateOrEditCompanyDialog'
import DeleteCompanyDialog from './DeleteCompanyDialog'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'
import { hasRole } from 'global/helpers/authorization'

// Types
import { Role } from 'context/authentication/Role.types'
import { Company } from 'context/authentication/Company.types'

interface CompanyTableProps {
  companies: Company[]
  onChange?: () => void
}

const CompanyTable = ({ companies, onChange }: CompanyTableProps): ReactElement => {
  const { t } = useTranslation()
  const [activeCompany, setActiveCompany] = useState<Company>()
  const [createOrEditCompanyDialogOpen, setCreateOrEditCompanyDialogOpen] = useState(false)
  const [deleteCompanyDialogOpen, setDeleteCompanyDialogOpen] = useState(false)

  const handleEditClick = useCallback((row: Company) => {
    setActiveCompany(row)
    setCreateOrEditCompanyDialogOpen(true)
    analyticsEvent('polestar_cv_edit_company_button_clicked')
  }, [])

  const handleDeleteClick = useCallback((row: Company) => {
    setActiveCompany(row)
    setDeleteCompanyDialogOpen(true)
    analyticsEvent('polestar_cv_delete_company_button_clicked')
  }, [])

  const columns = useMemo(
    () => [
      {
        name: 'displayName',
        label: t('screens.shared.company_manager.card.columns.display_name'),
        getValue: (row: Company) => (
          <Stack data-test="company-name" spacing={1} direction="row" alignItems="center">
            <Box>{row.displayName}</Box>
            <CompanyTypeLabel type={row.type} />
            {row.properties?.visibilityLevel && (
              <KNTypography variant="label" color="primary.light">
                {t(`screens.shared.company_manager.visibility_types.${row.properties.visibilityLevel}`)}
              </KNTypography>
            )}
          </Stack>
        ),
        sx: { width: { xs: '24rem', sm: 'auto' } },
      },
      {
        name: 'customerIdentifier',
        label: t('screens.shared.company_manager.card.columns.customer_ids'),
        getValue: (row: Company) => (
          <KNDataTableOverflow
            row={row}
            label={t('general.view_all')}
            header={t('screens.shared.company_manager.card.columns.customer_ids')}
            items={row.customerIdentifiers ?? []}
            rightAligned
          />
        ),
        sx: { width: '16rem' },
      },
    ],
    []
  )

  const actions = useMemo(
    () => [
      {
        name: 'edit',
        label: t('screens.shared.company_manager.card.actions.edit'),
        icon: <EditIcon />,
        onClick: handleEditClick,
      },
      {
        name: 'delete',
        label: t('screens.shared.company_manager.card.actions.delete'),
        icon: <DeleteIcon />,
        onClick: handleDeleteClick,
      },
    ],
    []
  )

  const handleCreateOrEditDialogAction = useCallback((): void => {
    setCreateOrEditCompanyDialogOpen(false)
    onChange?.()
  }, [])

  const handleCreateOrEditDialogClose = useCallback((): void => {
    setCreateOrEditCompanyDialogOpen(false)
  }, [])

  const handleDeleteDialogAction = useCallback((): void => {
    setDeleteCompanyDialogOpen(false)
    onChange?.()
  }, [])

  const handleDeleteDialogClose = useCallback((): void => {
    setDeleteCompanyDialogOpen(false)
  }, [])

  return (
    <>
      <KNDataTable
        columns={columns}
        actions={hasRole(Role.Admin) ? actions : []}
        data={companies}
        sx={{
          marginX: -2,
        }}
      />
      {activeCompany && hasRole(Role.Admin) && (
        <>
          <CreateOrEditCompanyDialog
            payload={{
              company: activeCompany,
            }}
            open={createOrEditCompanyDialogOpen}
            onAction={handleCreateOrEditDialogAction}
            onClose={handleCreateOrEditDialogClose}
          />
          <DeleteCompanyDialog
            payload={{
              company: activeCompany,
            }}
            open={deleteCompanyDialogOpen}
            onAction={handleDeleteDialogAction}
            onClose={handleDeleteDialogClose}
          />
        </>
      )}
    </>
  )
}

export default CompanyTable
