import { ReactElement, useCallback, useState } from 'react'

// @mui imports
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { Theme } from '@mui/material/styles/createTheme'

// KN imports
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import Modal from 'components/Molecules/Modal/Modal'

// Context
import { InsightDetailsContextProvider } from 'context/detailsNext/InsightDetails'

// Modules
import ShipmentDetailsNext from 'screens/ShipmentDetailsNext/ShipmentDetails'
import ShipmentBackground from './ShipmentBackground'

// Data
import { shareShipmentTranslations } from './ShareShipmentDetails.data'
const ShareShipmentDetails = (): ReactElement => {
  const [pinCode, setPinCode] = useState('')
  const handleSetPin = useCallback((value: string): void => {
    setPinCode(value)
  }, [])
  const [pincodeActivated, setPincodeActivated] = useState(false)
  const handleSetPincodeActivated = useCallback(() => {
    setPincodeActivated(true)
  }, [])

  // Translated Data //
  const { translation } = shareShipmentTranslations()

  const pinCodeModalUI = (): JSX.Element => {
    return (
      <Modal
        open={!pincodeActivated}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        overflow="hidden"
      >
        <Box data-test="pin-dialog">
          <Stack direction="column">
            <KNTypography variant="displayXS_SB" color="dark.main">
              {translation.title}
            </KNTypography>
            <Box mt={1}>
              <KNTypography variant="textLG" color="dark.focus">
                {translation.message}
              </KNTypography>
            </Box>
            <FormControl variant="standard" sx={{ mt: 3, mb: 1 }}>
              <TextField
                data-test="pin-input"
                placeholder="Pincode..."
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                  handleSetPin(event.target.value)
                }}
                onKeyUp={(e): void => {
                  console.log(e.key)
                  if (e.key === 'Enter') handleSetPincodeActivated()
                }}
              />
            </FormControl>
            <KNButton
              data-test="submit-button"
              variant="gradient"
              color="primary"
              fullWidth
              disabled={pinCode === ''}
              sx={{
                fontSize: ({ typography: { size } }: Theme): string => size.md,
                height: '44px',
              }}
              onClick={handleSetPincodeActivated}
            >
              {translation.submit}
            </KNButton>
          </Stack>
        </Box>
      </Modal>
    )
  }
  return (
    <>
      {!pincodeActivated && <ShipmentBackground />}
      <>
        {!pincodeActivated ? (
          pinCodeModalUI()
        ) : (
          <InsightDetailsContextProvider>
            <ShipmentDetailsNext pinCode={pinCode} />
          </InsightDetailsContextProvider>
        )}
      </>
    </>
  )
}

export default ShareShipmentDetails
