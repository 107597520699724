import React, { ReactElement, useState } from 'react'

// Mui imports
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Modal from '@mui/material/Modal'
import { Theme } from '@mui/system/createTheme'
import { SystemStyleObject } from '@mui/system'
import useMediaQuery from '@mui/material/useMediaQuery'
import ReadMoreIcon from '@mui/icons-material/ReadMore'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import VideoChatIcon from '@mui/icons-material/VideoChat'

// KN imports
import theme from 'assets/theme'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Modules
import CodeScanner from 'components/Molecules/CodeScanner/CodeScanner'
import DeviceScanner from 'components/Molecules/CodeScanner/DeviceScanner'

// Data
import { pairDevicesTranslations } from '../../screens/PairDevices/PairDevices.data'

// Types
import { PairingSession } from 'screens/PairDevices/PairDevices.types'

interface OptionButtonProps {
  icon: ReactElement
  label: string
  onClick: () => void
}

const OptionButton: React.FC<OptionButtonProps> = ({ icon, label, onClick }) => (
  <Grid
    item
    xs={6}
    md={4}
    display="flex"
    flexDirection="column"
    alignItems="center"
    onClick={onClick}
    sx={({ palette: { primary } }): SystemStyleObject<Theme> => ({
      cursor: 'pointer',
      color: primary.light,
      transition: 'all 100ms ease-in-out',
      '&:hover': {
        color: primary.main,
      },
    })}
  >
    {React.cloneElement(icon, {
      sx: {
        color: 'inherit',
        width: 50,
        height: 50,
        mb: 1,
      },
    })}
    <KNTypography textAlign="center" color="inherit" variant={'textMD'}>
      {label}
    </KNTypography>
  </Grid>
)

interface ScanningDevicesProps {
  setEventTrigger: () => void
  setInputResult: (input: PairingSession) => void
}

const ScanningDevices: React.FC<ScanningDevicesProps> = ({ setEventTrigger, setInputResult }): ReactElement => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  // Translated data //
  const { translation } = pairDevicesTranslations()

  // Module state //
  const [mobileScannerOpen, setMobileScannerOpen] = useState(false)
  const [deviceScannerOpen, setDeviceScannerOpen] = useState(false)

  const getOptions = (): ReactElement => (
    <Grid container spacing={2} display="flex" alignItems="flex-start">
      {isMobile && (
        <OptionButton
          icon={<DocumentScannerIcon />}
          label={translation.mobileScan}
          onClick={(): void => {
            setMobileScannerOpen(true)
          }}
        />
      )}
      {isMobile && (
        <OptionButton
          icon={<VideoChatIcon />}
          label={translation.scanningDevices}
          onClick={(): void => {
            setDeviceScannerOpen(true)
          }}
        />
      )}
      <OptionButton
        icon={<ReadMoreIcon />}
        label={translation.manualInput}
        onClick={(): void => {
          setInputResult({
            sessionTimestamp: Date.now(),
            pairings: [
              { reference: '', description: null, deviceIds: [], temperatureThreshold: null, unpairingRule: true },
            ],
          })
          setEventTrigger()
        }}
      />
    </Grid>
  )

  if (mobileScannerOpen) {
    return (
      <Modal open={mobileScannerOpen} sx={{ backgroundColor: 'white !important' }}>
        <>
          <IconButton
            edge="end"
            color="inherit"
            onClick={(): void => setMobileScannerOpen(false)}
            sx={{ position: 'absolute', top: 0, right: 5, zIndex: 99999 }}
          >
            <CloseIcon color="info" />
          </IconButton>
          <CodeScanner
            setInputResult={(input: PairingSession): void => setInputResult(input)}
            onClose={(): void => {
              setMobileScannerOpen(false), setEventTrigger()
            }}
          />
        </>
      </Modal>
    )
  }

  if (deviceScannerOpen) {
    return (
      <Modal open={deviceScannerOpen} sx={{ backgroundColor: 'white !important' }}>
        <>
          <IconButton
            edge="end"
            color="inherit"
            onClick={(): void => setDeviceScannerOpen(false)}
            sx={{ position: 'absolute', top: 0, right: 5, zIndex: 99999 }}
          >
            <CloseIcon color="info" />
          </IconButton>
          <DeviceScanner
            setInputResult={(input: PairingSession): void => setInputResult(input)}
            onClose={(): void => {
              setDeviceScannerOpen(false), setEventTrigger()
            }}
          />
        </>
      </Modal>
    )
  }

  return getOptions()
}

export default ScanningDevices
