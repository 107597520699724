import { ReactElement } from 'react'
import { Controller, FieldValues } from 'react-hook-form'

// @mui imports
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'
import { Theme } from '@mui/system/createTheme'
import { SystemStyleObject } from '@mui/system'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { getFieldErrorMessages } from 'global/helpers/form'

// Types
import { KNFormIconButtonGroupProps } from './types'

const KNFormIconButtonGroup = <T extends FieldValues>({
  name,
  control,
  rules,
  label,
  options,
  direction,
  sx,
}: KNFormIconButtonGroupProps<T>): ReactElement => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { onChange, value }, fieldState: { invalid, error }, formState }): ReactElement => (
      <FormControl fullWidth sx={sx} error={invalid} disabled={formState.isSubmitting}>
        {label && <FormLabel>{label}</FormLabel>}
        <Stack direction={direction} spacing={3} useFlexGap flexWrap="wrap">
          {options.map((option, key) => (
            <Box key={key} display="flex" justifyContent="center" width="74px">
              <Box display="flex" flexDirection="column" alignItems="center">
                <IconButton
                  onClick={(): void => {
                    onChange(option.value)
                  }}
                  sx={({ palette: { primary, grey, white } }): SystemStyleObject<Theme> => ({
                    backgroundColor: value === option.value ? `${primary.main} !important` : grey[200],
                    color: white.main,
                  })}
                >
                  {option.icon}
                </IconButton>
                <KNTypography
                  mt={1}
                  sx={({ palette: { primary, grey } }): SystemStyleObject<Theme> => ({
                    color: value === option.value ? primary.main : grey[500],
                    textAlign: 'center',
                  })}
                  variant={option.value ? 'textMD_SB' : 'textMD'}
                >
                  {option.label}
                </KNTypography>
              </Box>
            </Box>
          ))}
        </Stack>
        {error?.message && <FormHelperText>{getFieldErrorMessages(error)}</FormHelperText>}
      </FormControl>
    )}
  />
)

export default KNFormIconButtonGroup
