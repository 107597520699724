import { Company } from 'context/authentication/Company.types'
import { Role } from 'context/authentication/Role.types'

export interface UserData {
  cid: string
  email: string
  displayName: string
  roles: Record<string, Role>
  uid: string
  companies?: UserCompanyData[]
}

export interface UserCompanyData extends Company {
  role: Role
}

export interface StoreLanguageCommand {
  language: string
}

export enum WelcomeGuideStatus {
  PENDING = 'PENDING',
  SKIPPED = 'SKIPPED',
  DONE = 'DONE',
}

export interface UserPreferencesData {
  locale?: string
  timeZoneId?: string
  welcomeGuide?: WelcomeGuideStatus
  welcomeGuideTripList?: WelcomeGuideStatus
  welcomeGuideTripDetails?: WelcomeGuideStatus
  wantsNewTripEmail?: boolean
  wantsUpdateTripEmail?: boolean
  wantsTripCancellationEmail?: boolean
}

export interface TimeZone {
  id: string
  displayName: string
}
