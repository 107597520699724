import { useState, ReactElement } from 'react'
import { debounce } from 'lodash'
import { Link as RouterLink } from 'react-router-dom'
import FadeIn from 'react-fade-in/lib/FadeIn'

// @mui imports
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import Fade from '@mui/material/Fade'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import { Theme } from '@mui/material/styles'
import { SystemStyleObject } from '@mui/system'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN Components
import theme from 'assets/theme'
import KNChip from 'components/KN_Components/Base/KNChip/KNChip'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Functional
import { useSearchContext } from 'context/search/SearchContext'
import { analyticsEvent } from 'global/helpers/analytics'

// Data
import { searchBarTranslations } from './SearchBar.data'
import InputAdornment from '@mui/material/InputAdornment'

// Easter Egg
import { getGluten } from './classified/SearchBar.ee'

const SearchBar: React.FC = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  // Module State //
  const [searchTerm, setSearchTerm] = useState('')
  const [showQueryTooShortMessage, setShowQueryTooShortMessage] = useState(false)
  const { searchState, dispatchSearchState } = useSearchContext()
  const { searchContext } = searchState
  const { searchResults, searchQuery, searchLoading } = searchContext

  // Translated Data //
  const { translation } = searchBarTranslations()

  const handleSearchChange = (e: string): void => {
    setSearchTerm(e)
    dispatchSearchState({
      type: 'setSearchProps',
      payload: {
        ...searchContext,
        searchQuery: e,
      },
    })
    analyticsEvent('polestar_cv_searchbar_used')
  }

  const setInputChange = debounce((value: string) => {
    if (searchLoading) return
    if (value.length < 3) {
      setShowQueryTooShortMessage(true)
      return
    }
    setShowQueryTooShortMessage(false)
    handleSearchChange(value)
  }, 350)

  const getNoResultsMessageUI = (): ReactElement => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <KNTypography variant="button">
          {translation.noResults} : {!searchLoading ? searchQuery : null}
        </KNTypography>
        <KNTypography variant="caption" mt={1} color="secondary.focus">
          {translation.noResultsDescription}
        </KNTypography>
      </Box>
    )
  }

  const getValueTooShortMessageUI = (): ReactElement => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <KNTypography variant="button">{translation.searchQueryTooShort}</KNTypography>
        <KNTypography variant="caption" mt={1} color="secondary.focus">
          {translation.searchQueryTooShortDescription}
        </KNTypography>
      </Box>
    )
  }

  const getResultsUI = (): ReactElement => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {searchResults?.map((searchResult) => (
        <Box key={searchResult.link} mb={1}>
          <KNTypography variant="body2" mb={1}>
            <Link component={RouterLink} to={searchResult.link} fontWeight={500} variant="underlined">
              {searchResult.name}
            </Link>
          </KNTypography>
          <Box>
            <KNTypography variant="caption" mt={1} mr={2} color="secondary.focus">
              {searchResult.descriptionProperty}:
            </KNTypography>
            <KNChip label={searchResult.descriptionValue} size="small" color={searchResult.descriptionColor} />
          </Box>
          {searchResults.length > 1 && <Divider sx={{ mt: 2 }} />}
        </Box>
      ))}
    </Box>
  )

  return (
    <>
      <form>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <TextField
            id="search-bar"
            className="text"
            onChange={(e): void => setInputChange(e.target.value)}
            placeholder={translation.placeholder}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={({ palette: { dark } }: Theme): SystemStyleObject<Theme> => ({
                    color: dark.disabled,
                  })}
                >
                  <Icon>search</Icon>
                </InputAdornment>
              ),
              endAdornment: (
                <Box sx={{ display: 'flex' }}>
                  <Fade in={searchLoading}>
                    <CircularProgress size={20} />
                  </Fade>
                </Box>
              ),
              sx: { borderRadius: '16px' },
              size: 'small',
            }}
            onClick={(): void => {
              if (searchTerm) {
                handleSearchChange(searchTerm)
              }
            }}
          />
        </Box>
        <FadeIn visible={!!(searchQuery && searchQuery > '' && !searchLoading) || showQueryTooShortMessage}>
          <Paper
            data-test="search-bar-results"
            elevation={4}
            sx={{
              position: 'absolute',
              mt: 1,
              p: 2,
              width: isMobile ? '170px' : '270px',
            }}
          >
            {/* Easter egg available */}
            {searchTerm && getGluten(searchTerm)}
            {showQueryTooShortMessage ? (
              getValueTooShortMessageUI()
            ) : (
              <>
                {searchResults && searchResults.length === 0 && getNoResultsMessageUI()}
                {searchResults && searchResults.length > 0 && getResultsUI()}
              </>
            )}
          </Paper>
        </FadeIn>
      </form>
    </>
  )
}

export default SearchBar
