import { useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

// @mui imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Link from '@mui/material/Link'
import IconButton from '@mui/material/IconButton'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import { zonedDate } from 'global/helpers/dateFormatters'
import { getRouteName } from 'global/helpers/activeRoute'
import { getDarkModeColors } from 'global/helpers/charts'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNDetailsPopover from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopover'
import KNDetailsPopoverSection from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopoverSection'
import KNDetailsPopoverList from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopoverList'
import usePopoverState from 'components/KN_Molecules/KNDetailsPopover/usePopoverState'
import { KNADRIcon } from 'components/KN_Molecules/KNIcon/KNMaterialIcon'
import TripStatusChip from './TripStatusChip'
import { TripData } from './TripDashboard.types'
import Tracking from 'screens/TripDetails/Tracking'
import Temperature from 'screens/TripDetails/Temperature'
import { LegData } from 'screens/TripDetails/TripDetails.types'

export interface TripHeaderProps {
  trip: TripData
  legs?: LegData[]
  linked?: boolean
  onLinkClick?: (trip: TripData) => void
  stacked?: boolean
  size?: 'large'
  withTemperature?: boolean
  weblinkToken?: string
}

const TripHeader = ({
  trip,
  legs,
  linked = false,
  onLinkClick,
  stacked = false,
  size,
  withTemperature,
  weblinkToken,
}: TripHeaderProps): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()

  const {
    anchorEl: tripDetailsAnchorEl,
    open: tripDetailsOpen,
    handleClick: handleTripDetailsClick,
    handleClose: handleTripDetailsClose,
  } = usePopoverState()
  const {
    anchorEl: dangerousDetailsAnchorEl,
    open: dangerousDetailsOpen,
    handleClick: handleDangerousDetailsClick,
    handleClose: handleDangerousDetailsClose,
  } = usePopoverState()

  const handlePropagation = useCallback((event) => event.stopPropagation(), [])

  const handleLinkClick = useCallback((): void => onLinkClick?.(trip), [])

  const handleTripDetailsClickWrapper = useCallback((event: React.MouseEvent<HTMLElement>): void => {
    handleTripDetailsClick(event)
    analyticsEvent('polestar_cs_trip_details_popover', [getRouteName(location.pathname)])
  }, [])

  const handleDangerousDetailsClickWrapper = useCallback((event: React.MouseEvent<HTMLElement>): void => {
    handleDangerousDetailsClick(event)
    analyticsEvent('polestar_cs_trip_dangerous_goods_popover', [getRouteName(location.pathname)])
  }, [])

  return (
    <Box onClick={handlePropagation}>
      <Stack data-test="trip-id-status-container" data-guide="trip-summary" direction="column" alignItems="start">
        <Stack spacing={1} direction="row" alignItems="center" sx={{ maxWidth: '100%' }}>
          {linked ? (
            <Link
              data-test="trips-id-link"
              component={RouterLink}
              to={`/trips/${trip.entityId}`}
              color="text.main"
              variant={size === 'large' ? 'displaySM_SB' : 'displayXXS_SB'}
              onClick={handleLinkClick}
              className="hover-link"
              noWrap
            >
              {trip.voyageNumber}
            </Link>
          ) : (
            <KNTypography color="text.main" variant={size === 'large' ? 'displaySM_SB' : 'displayXXS_SB'} noWrap>
              {trip.voyageNumber}
            </KNTypography>
          )}
          {!stacked && <TripStatusChip status={trip.status} />}
          <Stack direction="row">
            <IconButton
              onClick={handleTripDetailsClickWrapper}
              size="small"
              color="primary"
              sx={{ margin: '-5px 0 !important' }}
            >
              <InfoOutlinedIcon />
            </IconButton>
            {trip.dangerousGoodsDetails.length > 0 && (
              <IconButton
                onClick={handleDangerousDetailsClickWrapper}
                size="small"
                color="error"
                sx={{ margin: '-5px 0 !important' }}
              >
                <KNADRIcon />
              </IconButton>
            )}
            {trip.status !== 'NEW' && <Tracking trip={trip} preloadedLegs={legs} weblinkToken={weblinkToken} />}
            {withTemperature && <Temperature trip={trip} weblinkToken={weblinkToken} />}
          </Stack>
        </Stack>
        {stacked && <TripStatusChip status={trip.status} />}
      </Stack>

      <KNDetailsPopover
        open={tripDetailsOpen}
        onClose={handleTripDetailsClose}
        anchorEl={tripDetailsAnchorEl}
        rightAligned
      >
        <KNDetailsPopoverList
          items={[
            {
              label: t('screens.cs.trip_dashboard.card.created_on'),
              value: zonedDate(trip.creationDate) ?? undefined,
            },
            ...(trip.carrierInstructions
              ? [
                  {
                    label: t('screens.cs.trip_dashboard.trip_details.instructions'),
                    value: trip.carrierInstructions,
                    stacked: true,
                  },
                ]
              : []),
            ...(trip.customerReferences.length > 0
              ? [
                  {
                    label: t('screens.cs.trip_dashboard.trip_details.references'),
                    value: trip.customerReferences,
                    stacked: true,
                  },
                ]
              : []),
            {
              label: t('screens.cs.trip_dashboard.trip_details.kn_branch'),
              value: trip.mappedSender,
            },
          ]}
        />
      </KNDetailsPopover>

      {trip.dangerousGoodsDetails.length > 0 && (
        <KNDetailsPopover
          open={dangerousDetailsOpen}
          onClose={handleDangerousDetailsClose}
          anchorEl={dangerousDetailsAnchorEl}
          rightAligned
        >
          {trip.dangerousGoodsDetails.map((details, index) => (
            <KNDetailsPopoverList
              key={index}
              description={details.description}
              items={[
                {
                  label: t('screens.cs.trip_details.dangerous_goods.packages'),
                  value: details.packagesType
                    ? `${details.numberOfPackages} × ${details.packagesType}`
                    : details.numberOfPackages,
                },
                {
                  label: t('screens.cs.trip_details.dangerous_goods.un_number'),
                  value: details.unNumber,
                },
                {
                  label: t('screens.cs.trip_details.dangerous_goods.class'),
                  value: details.classType,
                },
                {
                  label: t('screens.cs.trip_details.dangerous_goods.packing_group'),
                  value: details.packingGroup,
                },
                {
                  label: t('screens.cs.trip_details.dangerous_goods.quantity'),
                  value: details.quantityType ? `${details.quantity} × ${details.quantityType}` : details.quantity,
                },
              ]}
            />
          ))}
        </KNDetailsPopover>
      )}
    </Box>
  )
}

export default TripHeader
