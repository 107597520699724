import { useState, useCallback, ReactElement, useEffect } from 'react'
import FadeIn from 'react-fade-in'

// @mui imports
import Box from '@mui/material/Box'

// KN import
import DeleteMonitoringRuleDialog from './DeleteMonitoringRuleDialog'
import KNDataTable from 'components/KN_Molecules/KNDataTable/KNDataTable'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'

// Functional
import { getActions, getColumns } from './MonitoringRulesTable.helpers'
import { getMonitoringRules } from './MonitoringRulesTable.service'
import { getMonitoringRulesData } from 'screens/NotificationCenter/NotificationCenter.helpers'

// Types
import { MonitoringRuleRow, MonitoringRulesTableProps } from './MonitoringRulesTable.types'
import { getSingleShipmentMonitoringRule } from 'screens/MonitoringRuleCreateOrEdit/MonitoringRuleCreateOrEdit.service'

const MonitoringRulesTable = ({ userTimezone, singleShipmentMode }: MonitoringRulesTableProps): ReactElement => {
  const [monitoringRulesData, setMonitoringRulesData] = useState<MonitoringRuleRow[]>([])
  const [loading, setLoading] = useState(true)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [activeRow, setActiveRow] = useState<MonitoringRuleRow>()

  const fetchData = async (): Promise<void> => {
    setLoading(true)
    if (singleShipmentMode) {
      const monitoringRules = await getSingleShipmentMonitoringRule(singleShipmentMode.cid)
      setMonitoringRulesData(getMonitoringRulesData(monitoringRules, userTimezone))
    } else {
      const monitoringRules = await getMonitoringRules()
      setMonitoringRulesData(getMonitoringRulesData(monitoringRules, userTimezone))
    }
    setLoading(false)
  }

  useEffect(() => {
    void fetchData()
  }, [])

  const handleDeleteClick = useCallback((row: MonitoringRuleRow) => {
    setActiveRow(row)
    setDeleteDialogOpen(true)
  }, [])

  const handleDeleteDialogAction = useCallback((): void => {
    setDeleteDialogOpen(false)
  }, [])

  const handleDeleteDialogClose = useCallback((): void => {
    setDeleteDialogOpen(false)
  }, [])

  return (
    <Box data-test="monitoring-rules-table">
      {loading && (
        <FadeIn visible={loading}>
          <KNLoader />
        </FadeIn>
      )}
      {!loading && monitoringRulesData.length > 0 && (
        <>
          <FadeIn>
            <KNDataTable
              columns={getColumns(singleShipmentMode ? true : false)}
              actions={getActions(handleDeleteClick)}
              data={monitoringRulesData}
              sx={{
                mx: -2,
              }}
            />
          </FadeIn>
          {activeRow && (
            <DeleteMonitoringRuleDialog
              payload={{
                monitoringRule: activeRow,
              }}
              open={deleteDialogOpen}
              onAction={handleDeleteDialogAction}
              onClose={handleDeleteDialogClose}
            />
          )}
        </>
      )}
    </Box>
  )
}

export default MonitoringRulesTable
