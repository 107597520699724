import { ReactElement } from 'react'

// @mui imports
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'
import { Theme } from '@mui/material/styles'
import { Tooltip } from '@mui/material'
import { SystemStyleObject } from '@mui/system'

// KN Components
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Types
import { CounterProps, CounterTheme } from './LiveCounters.types'

// Helpers
import pxToRem from 'assets/theme/functions/pxToRem'

const LiveCounterTemplate = ({ liveCounter }: { liveCounter: CounterProps }): ReactElement => {
  const { name, amount, icon, active, description } = liveCounter

  return (
    <Tooltip title={description}>
      <Box
        data-test="live-counter-container"
        px={2}
        py={1.5}
        sx={({ boxShadows, borders: { borderRadius }, functions: { pxToRem } }: Theme): SystemStyleObject<Theme> => ({
          backgroundColor: active ? CounterTheme.light : CounterTheme.white,
          borderRadius: borderRadius.lg,
          boxShadow: boxShadows.standardBoxShadow,
          display: 'flex',
          height: pxToRem(75),
          transition: 'all 0.75s ease',
          '&:hover': {
            backgroundColor: active ? CounterTheme.light : CounterTheme.contrast,
          },
        })}
      >
        <Box data-test={`liveCounter-container-${name.replace(/\s+/g, '-')}`}>
          <Box
            sx={({ borders: { borderRadius }, functions: { pxToRem } }: Theme): SystemStyleObject<Theme> => ({
              borderRadius: borderRadius.round,
              backgroundColor: active ? CounterTheme.white : CounterTheme.light,
              color: active ? CounterTheme.primary : CounterTheme.white,
              width: pxToRem(50),
              height: pxToRem(50),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            })}
          >
            <Icon color={'inherit'}>{icon}</Icon>
          </Box>
        </Box>
        <Box
          sx={{
            marginLeft: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <KNTypography
            data-test={`liveCounter-name-${name.replace(/\s+/g, '-')}`}
            sx={({ typography }: Theme): SystemStyleObject<Theme> => ({
              pr: 1,
              color: active ? CounterTheme.white : CounterTheme.primary,
              fontSize: typography.size.lg,
              transition: 'all 0.75s ease',
            })}
          >
            {name}
          </KNTypography>
          <KNTypography
            data-test={`liveCounter-number-${name.replace(/\s+/g, '-')}`}
            sx={({ typography }: Theme): SystemStyleObject<Theme> => ({
              color: active ? CounterTheme.white : CounterTheme.black,
              fontSize: pxToRem(36),
              lineHeight: typography.lineHeight.sm,
              fontWeight: typography.fontWeightBold,
              transition: 'all 0.75s ease',
            })}
          >
            {amount}
          </KNTypography>
        </Box>
      </Box>
    </Tooltip>
  )
}

export default LiveCounterTemplate
