import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  ReactElement,
} from 'react'

// Functional
import { findById } from './SearchContext.service'

// Types
import SearchContextProps from './SearchContext.types'

interface Action {
  type: 'setSearchProps'
  payload: SearchContextProps
}
type Dispatch = (action: Action) => void
interface State {
  searchContext: SearchContextProps
}
interface SearchContextProviderProps {
  children: React.ReactNode
}
interface SearchStateContextType {
  searchState: State
  dispatchSearchState: Dispatch
}

const SearchStateContext = createContext<SearchStateContextType | undefined>(
  undefined
)

function searchReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setSearchProps': {
      return { searchContext: action.payload }
    }
    default: {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function SearchContextProvider({
  children,
}: SearchContextProviderProps): ReactElement {
  const [searchState, dispatchSearchState] = useReducer(searchReducer, {
    searchContext: {},
  })

  const handleGetShipment = async (searchQuery): Promise<void> => {
    dispatchSearchState({
      type: 'setSearchProps',
      payload: {
        ...searchState.searchContext,
        searchLoading: true,
      },
    })
    await findById(searchQuery).then((response) => {
      dispatchSearchState({
        type: 'setSearchProps',
        payload: {
          ...searchState.searchContext,
          searchResults: response,
          searchLoading: false,
        },
      })
    })
  }

  const { searchQuery, activeContext } = searchState.searchContext
  useEffect(() => {
    if (activeContext === 'ShipmentSearch' && searchQuery) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleGetShipment(searchQuery)
    }
  }, [searchQuery])

  const value = { searchState, dispatchSearchState }
  return (
    <SearchStateContext.Provider value={value}>
      {children}
    </SearchStateContext.Provider>
  )
}

function useSearchContext(): SearchStateContextType {
  const context = useContext(SearchStateContext)
  if (context === undefined) {
    throw new Error('error')
  }
  return context
}

export { SearchContextProvider, useSearchContext }
