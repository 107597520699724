// Modules
import ShipmentPairer from 'modules/ShipmentPairer/ShipmentPairer'
import Geofence from 'screens/Geofence/Geofence'
import MonitoringRuleForm from 'modules/MonitoringRuleForm/MonitoringRuleForm'

// Data
import { shipmentDetailsMenuTranslations } from './ShipmentDetailsMenu.data'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'
import { sharePairing, shareShipment, unpairShipment } from './ShipmentDetailsMenu.service'

// Types
import { ShareShipmentResponse, ShipmentDetailsMenuOptionProps } from './ShipmentDetailsMenu.types'

export const getShipmentDetailsMenuOptions = (
  shipmentId: string,
  pairingCid?: string,
  unpairingDate?: string,
  comRefId?: string,
  level?: string,
  entitytype?: string,
  sendingApplication?: string,
  trackingNumber?: string
): ShipmentDetailsMenuOptionProps[] => {
  const href = window.location.href
  // Translated Data //
  const { translation } = shipmentDetailsMenuTranslations()

  const shipmentLevelIIIOptions =
    pairingCid && !unpairingDate && level === 'Max'
      ? [
          {
            icon: 'link',
            name: translation.editPairing,
            withModal: <ShipmentPairer id={pairingCid} editMode={true} modalMode />,
          },
          {
            icon: 'link_off',
            name: translation.unpairShipment,
            onClick: async (): Promise<void> => {
              await unpairShipment(pairingCid || '')
              analyticsEvent('polestar_cv_shipment_details_unpair_shipment', [pairingCid])
            },
          },
        ]
      : []

  const isPairingOnly = entitytype === 'P' && pairingCid
  const shareShipmentOption = [
    {
      icon: 'reply',
      name: translation.shareShipment,
      optionMessage: `${translation.shareShipmentMessage as string}`,
      noRefresh: true,
      onClick: isPairingOnly
        ? async (): Promise<any> =>
            sharePairing(pairingCid).then((response) => {
              const link = getShareLink(href, response)
              void navigator.clipboard.writeText(link)
              return response.password
            })
        : async (): Promise<any> =>
            shareShipment(shipmentId).then((response) => {
              getShareLink(href, response)
              const link = getShareLink(href, response)
              void navigator.clipboard.writeText(link)
              return response.password
            }),
    },
  ]

  const followOption = [
    {
      icon: 'edit_notifications',
      name: translation.notificationRules,
      withModal: (
        <MonitoringRuleForm
          singleShipmentMode={{
            sendingApplication: sendingApplication ?? '',
            trackingNumber: trackingNumber ?? '',
            cid: shipmentId,
          }}
        />
      ),
    },
  ]

  const geofenceOption = [
    {
      icon: 'map',
      name: translation.geofence,
      withModal: <Geofence />,
    },
  ]

  const shipmentDetailsMenuOptions =
    shipmentId && comRefId
      ? [...shareShipmentOption, ...followOption, ...geofenceOption, ...shipmentLevelIIIOptions]
      : [...shareShipmentOption, ...shipmentLevelIIIOptions]
  return shipmentDetailsMenuOptions
}

export const getShareLink = (href: string, response: ShareShipmentResponse): string => {
  const externalHref = href.replace('shipment-details', 'share_shipment')
  return String(`${externalHref}/${response.tokenId}`)
}
