import { ReactElement } from 'react'
import FadeIn from 'react-fade-in'

// @mui imports //
import Box from '@mui/material/Box'
import Stack from '@mui/system/Stack'
import Grid from '@mui/material/Grid'
import { TextField } from '@mui/material'

// Types //
import { AddressStepProps } from './MegaForm.types'

const AddresStep = ({ currentAddress, setCurrentAddress }: AddressStepProps): ReactElement => {
  return (
    <FadeIn>
      <Box mb={3}>
        <Box display="flex" alignItems="center">
          <FadeIn>
            <Stack spacing={1}>
              <Box mt={1}>
                <TextField
                  label={'name'}
                  value={currentAddress?.name ?? ''}
                  fullWidth
                  onChange={(e): void => setCurrentAddress({ ...currentAddress, name: e.target.value })}
                />
              </Box>
              <Box mt={1}>
                <TextField
                  label={'address'}
                  value={currentAddress?.address ?? ''}
                  fullWidth
                  onChange={(e): void => setCurrentAddress({ ...currentAddress, address: e.target.value })}
                />
              </Box>
              <Box mt={1}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <TextField
                      label={'postal code'}
                      value={currentAddress?.postalCode ?? ''}
                      fullWidth
                      onChange={(e): void => setCurrentAddress({ ...currentAddress, postalCode: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      label={'city'}
                      value={currentAddress?.city ?? ''}
                      fullWidth
                      onChange={(e): void => setCurrentAddress({ ...currentAddress, city: e.target.value })}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={1}>
                <TextField
                  label={'country'}
                  value={currentAddress?.country ?? ''}
                  fullWidth
                  onChange={(e): void => setCurrentAddress({ ...currentAddress, country: e.target.value })}
                />
              </Box>
            </Stack>
          </FadeIn>
        </Box>
      </Box>
    </FadeIn>
  )
}

export default AddresStep
