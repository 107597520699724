import { GeoPoint } from 'screens/Geofence/Geofence.types'

interface UserContext {
  groupCid: string
  userId: string
}

export enum TemplateDataContextType {
  geofence = 'geofence',
  temperature = 'temperature',
  shipment_status = 'shipment_status',
  delivery = 'delivery',
  pick_up = 'pick_up',
}

interface TemplateDataContext {
  type: TemplateDataContextType
  temperature?: number
  statusCode?: string
  location?: GeoPoint
  direction?: string
}

interface NotificationContext {
  type: string
  cid: string
  templateDataContext: TemplateDataContext
  trackingNumber: string
  sendingApplication: string
}

interface ChannelStatuses {
  channel: string
  sentAt: string
}

export default interface NotificationProps {
  cid: string
  createdAt: string
  readAt: boolean
  userContext: UserContext
  notificationContext: NotificationContext
  channelStatuses: ChannelStatuses[]
  metaData: object
}
