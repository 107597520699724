import i18n from 'i18n'

// Functional
import { fetchGet } from 'global/helpers/fetchWrapper'

// Types
import { ChipProps } from '@mui/material/Chip'
import { SearchDataPairing, SearchDataProps, SearchDataShipment, SearchResult } from './SearchContext.types'
import { searchContextTranslations } from './SearchContext.data'
import { moonshotTableTranslations } from 'modules/ShipmentsInsightData/ShipmentsInsightData.data'

export const findById = async (searchQuery: string): Promise<SearchResult[]> => {
  const getDescriptionColor = (status): ChipProps['color'] => {
    switch (status) {
      case 'UNKNOWN':
        return 'default'
      case 'DELIVERED':
        return 'primary'
      default:
        return 'primary'
    }
  }

  const toSearchResult = (result: SearchDataProps): SearchResult => {
    // Translated Data //
    const { translation } = searchContextTranslations()
    const { moonshotTranslation } = moonshotTableTranslations()

    if (result.entityType === 'Pairing') {
      const data = result.data as SearchDataPairing
      return {
        name: data.reference,
        descriptionProperty: translation.status,
        descriptionValue: data.completionDate
          ? moonshotTranslation.labels.labelTrackingCompleted
          : moonshotTranslation.labels.labelTrackingActive,
        descriptionColor: data.completionDate ? 'secondary' : 'primary',
        link: `/shipment-details/P/${result.entityId}/${data.reference}`,
      }
    }
    const data = result.data as SearchDataShipment
    return {
      name: data.comRefId,
      descriptionProperty: translation.status,
      descriptionValue: i18n.t(`shared.aggregatedStatus.${data.status}`),
      descriptionColor: getDescriptionColor(data.status),
      link: `/shipment-details/S/${result.entityId}/${data.comRefId}`,
    }
  }

  try {
    const data: SearchDataProps[] = await fetchGet(`search?q=${searchQuery}`, {}, 'si')
    return data.map((result) => toSearchResult(result))
  } catch (error) {
    return Promise.reject(error)
  }
}
