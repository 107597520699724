export const getGluten = (glutenType: string) => {
  if (glutenType === 'baguette') {
    return (
      <>
        <img src={require('./baguette.png')} height={'50px'} />
      </>
    )
  }
  if (glutenType === 'croissant') {
    return (
      <>
        <img src={require('./croissant.png')} height={'50px'} />
      </>
    )
  }
}
