import { ReactElement } from 'react'

// @mui imports
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import FormHelperText from '@mui/material/FormHelperText'

// KN imports
import { KNAdvancedDropdownProps } from './types'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

const KNAdvancedDropdown = ({
  label,
  value,
  options,
  predefinedValue,
  onChange,
  multiple,
  size,
  disabled,
  sx,
  error,
  helperText,
  description,
}: KNAdvancedDropdownProps): ReactElement => (
  <FormControl size={size} fullWidth sx={sx} error={error} disabled={disabled}>
    {label && <InputLabel>{label}</InputLabel>}
    <Select
      data-test="dropdown"
      label={label}
      multiple={multiple}
      renderValue={(selected: string | string[]): string =>
        options
          .filter((option) => {
            if (typeof selected === 'string') {
              return selected === option.value
            }
            return selected.includes(option.value)
          })
          .map((option) => option.label)
          .join(', ')
      }
      value={value ?? ''}
      onChange={onChange}
    >
      {predefinedValue && (
        <MenuItem divider value={predefinedValue.value}>
          {multiple && <Checkbox sx={{ p: 0, mr: 1 }} disableRipple checked={value.includes(predefinedValue.value)} />}
          {predefinedValue.icon}
          <ListItemText disableTypography primary={predefinedValue.label} />
        </MenuItem>
      )}
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {multiple && <Checkbox sx={{ p: 0, mr: 1 }} disableRipple checked={value.includes(option.value)} />}
          {option.icon}
          <ListItemText disableTypography primary={option.label} />
        </MenuItem>
      ))}
    </Select>
    {helperText && <FormHelperText>{helperText}</FormHelperText>}
    {description && (
      <KNTypography variant="textSM" sx={{ whiteSpace: 'pre-wrap' }} mt={1}>
        {options.find((option) => option.value === value)?.description}
      </KNTypography>
    )}
  </FormControl>
)

export default KNAdvancedDropdown
