import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

// Data
import { notificationCenterTranslations } from './NotificationCenter.data'
import { monitoringRuleTranslations } from 'screens/MonitoringRuleCreateOrEdit/MonitoringRuleCreateOrEdit.data'

// Types
import { MonitoringRule, MonitoringRuleRow, SettingType } from 'modules/MonitoringRulesTable/MonitoringRulesTable.types'

export const getMonitoringRulesData = (
  monitoringRules: MonitoringRule[],
  userTimezone: string
): MonitoringRuleRow[] => {
  const { translation, status, type } = notificationCenterTranslations()
  const { formTranslations } = monitoringRuleTranslations()

  return monitoringRules.map((rule) => {
    const ruleTypeSegments = rule.monitoringRuleTrigger.type.split('.')
    const ruleType = ruleTypeSegments.pop() ?? rule.monitoringRuleTrigger.type
    const settings: string[] = []

    if (status[rule.settings[SettingType.STATUS]])
      settings.push(`${String(translation.status)}: ${String(status[rule.settings[SettingType.STATUS]])}`)

    if (rule.settings[SettingType.PICK_UP])
      settings.push(`${String(formTranslations.pickup)}: ${String(rule.settings[SettingType.PICK_UP])}`)

    if (rule.settings[SettingType.DELIVERY])
      settings.push(`${String(formTranslations.delivery)}: ${String(rule.settings[SettingType.DELIVERY])}`)

    return {
      cid: rule.cid,
      name: rule.name,
      type: type[ruleType],
      settings: settings,
      created: format(utcToZonedTime(new Date(rule.createdAt), userTimezone), 'dd/MM/yyyy HH:mm'),
    }
  })
}
