import { ReactElement } from 'react'

// @mui imports
import Box from '@mui/material/Box'
import { Theme } from '@mui/material'
import { SystemStyleObject } from '@mui/system'

// KN components
import { KNAcuteIcon } from 'components/KN_Molecules/KNIcon/KNMaterialIcon'

// Functional
import { pulseAnimation } from 'global/helpers/animations'

const ETABlinkBlink = (): ReactElement => {
  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', marginLeft: 1 }}>
        <Box
          sx={({ borders: { borderRadius } }: Theme): SystemStyleObject<Theme> => ({
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
            width: '120%',
            height: '120%',
            '&:after': {
              content: '""',
              position: 'absolute',
              display: 'block',
              width: '100%',
              height: '100%',
              backgroundColor: '#6666ff',
              opacity: 0.05,
              borderRadius: borderRadius.round,
              animation: pulseAnimation(),
              zIndex: -1,
            },
          })}
        />
        <KNAcuteIcon
          sx={({ palette: { primary } }: Theme): SystemStyleObject<Theme> => ({
            color: primary.main,
            zIndex: 1,
          })}
        />
      </Box>
    </Box>
  )
}

export default ETABlinkBlink
