import { Fragment, useState, useEffect, useCallback, ReactElement } from 'react'
import { GoogleMap, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api'
import { UseLoadScriptOptions } from '@react-google-maps/api/dist/useJsApiLoader'

// @mui imports
import { useTheme } from '@mui/material/styles'

// KN imports
import { visibilityMapStyles } from 'modules/VisibilityMap/VisibilityMap.styles'
import { GeoPoint } from 'screens/TripDetails/TripDetails.types'
import KNMapTimestampMarker from './KNMapTimestampMarker'
import { getIcon, getZIndex } from './KNMap.helpers'
import { KNMapProps, MapMarker } from './types'

const loaderOptions: UseLoadScriptOptions = {
  id: 'google-map-script',
  googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY || '',
}

const KNMap = <T extends object>({
  markers,
  geoPoints,
  groupedGeoPoints,
  zoom = 10,
  withTimestamps = false,
  onMarkerClick,
}: KNMapProps<T>): ReactElement | null => {
  const theme = useTheme()
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const { isLoaded } = useJsApiLoader(loaderOptions)

  const handleMapLoad = useCallback((mapInstance: google.maps.Map) => {
    setMap(mapInstance)
  }, [])

  // NOTE: tie together internal marker definition with google maps marker instance
  const handleMarkerLoad = useCallback((marker: MapMarker<T>, markerInstance: google.maps.Marker) => {
    marker.instance = markerInstance
  }, [])

  const handleMapUnmount = useCallback((mapInstance: google.maps.Map) => {
    setMap(null)
  }, [])

  useEffect(() => {
    if (!map) {
      return
    }
    const focusedMarkers = markers.filter((marker) => marker.focused)
    const bounds = new google.maps.LatLngBounds()
    for (const marker of focusedMarkers.length > 0 ? focusedMarkers : markers) {
      bounds.extend(marker.coords)
    }
    map.fitBounds(bounds, 16)
    // make sure we set maximum zoom after fitBounds() call,
    // since it can zoom too much with single marker
    // NOTE: fitBounds() is async, hence the use of event listener
    google.maps.event.addListenerOnce(map, 'bounds_changed', () => {
      if ((map.getZoom() ?? zoom) > 10) {
        map.setZoom(10)
      }
    })
  }, [map, geoPoints])

  if (!isLoaded) {
    return null
  }

  return (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '100%',
      }}
      zoom={zoom}
      onLoad={handleMapLoad}
      onUnmount={handleMapUnmount}
      options={{
        mapTypeControl: true,
        scaleControl: false,
        streetViewControl: false,
        controlSize: 32,
        styles: visibilityMapStyles,
        maxZoom: 16,
      }}
    >
      {map && geoPoints && withTimestamps && <KNMapTimestampMarker map={map} geoPoints={geoPoints} />}
      {markers.map((marker, i) => (
        <Fragment key={i}>
          <Marker
            position={marker.coords}
            animation={4}
            icon={getIcon(marker.type, marker.color, marker.label)}
            zIndex={getZIndex(marker.type)}
            onClick={(event: google.maps.MapMouseEvent) => onMarkerClick?.(marker, map!)}
            onLoad={(markerInstance: google.maps.Marker) => handleMarkerLoad(marker, markerInstance)}
          />
        </Fragment>
      ))}
      {groupedGeoPoints
        ?.filter((geoPointsGroup) => geoPointsGroup.label == 'slow')
        .map((geoPointsGroup, index) => (
          <Polyline
            key={index}
            path={geoPointsGroup.geoPoints.map((point) => {
              return {
                lat: point.latitude,
                lng: point.longitude,
              }
            })}
            options={{
              clickable: false,
              draggable: false,
              editable: false,
              geodesic: true,
              strokeColor: 'red',
              strokeWeight: 16,
              strokeOpacity: 0.2,
            }}
          />
        ))}
      {groupedGeoPoints?.map((geoPointsGroup, index) => (
        <Polyline
          key={index}
          path={geoPointsGroup.geoPoints.map((point) => {
            return {
              lat: point.latitude,
              lng: point.longitude,
            }
          })}
          options={{
            clickable: false,
            draggable: false,
            editable: false,
            geodesic: true,
            strokeColor: geoPointsGroup.label == 'slow' ? 'red' : geoPointsGroup.label == 'medium' ? 'orange' : 'green',
            strokeWeight: 3,
          }}
        />
      ))}
      {!groupedGeoPoints && geoPoints && (
        <Polyline
          path={geoPoints.map((point) => {
            return {
              lat: point.latitude,
              lng: point.longitude,
            }
          })}
          options={{
            clickable: false,
            draggable: false,
            editable: false,
            geodesic: true,
            strokeColor: '#12316e',
            strokeWeight: 3,
          }}
        />
      )}
    </GoogleMap>
  )
}

export default KNMap
