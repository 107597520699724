import { ReactElement } from 'react'

// @mui imports
import Box from '@mui/material/Box'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Data
import { Pairing } from 'screens/PairDevices/PairDevices.types'
import { KNDataTableColumn } from 'components/KN_Molecules/KNDataTable/types'

export const getHeader = (headerName: string, boldText?: string): ReactElement => (
  <Box mb={1}>
    <KNTypography variant="textMD" color="dark.light">
      {headerName}
    </KNTypography>
    {boldText && (
      <KNTypography variant="textMD_SB" color="primary.light" ml={0.5}>
        `({boldText})`
      </KNTypography>
    )}
  </Box>
)

export const columns = (): KNDataTableColumn<Pairing>[] => {
  return [
    {
      name: 'paired',
      label: 'paired',
      getValue: (row: Pairing) => (row.shipmentCid ? <CheckCircleIcon /> : <HourglassBottomIcon />),
    },
    {
      name: 'reference',
      label: 'shipmentId',
    },
    {
      name: 'description',
      label: 'description',
    },
    {
      name: 'temperatureThresholdValue',
      label: 'temperatureThreshold',
    },
    {
      name: 'unpairingRule',
      label: 'autoUnpair',
    },
  ]
}
