import i18n from 'i18n'

export const notificationMenuTranslations = (): Record<any, any> => {
  const general = 'shared.notifications_menu.general'
  const types = 'shared.notifications_menu.types'
  return {
    translation: {
      moduleName: i18n.t(`${general}.moduleName`),
      moreButton: i18n.t(`${general}.moreButton`),
      lessButton: i18n.t(`${general}.lessButton`),
      markAllAsRead: i18n.t(`${general}.markAllAsRead`),
      noNotifications: i18n.t(`${general}.noNotifications`),
      closeMessage: i18n.t(`${general}.closeMessage`),
      types: i18n.t(`${types}`),
    },
  }
}
