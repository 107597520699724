// @mui material components
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { CSSInterpolation } from '@mui/system'

// Types
import KNIconButtonProps from './types'

const KNIconButtonRoot = styled(IconButton)(
  ({ theme }) =>
    (props: KNIconButtonProps): CSSInterpolation => {
      const { palette } = theme
      const { withknstyling } = props

      // styles for KN
      const stylesKN = {
        backgroundColor: palette.common.white,
        border: '8px solid #fff',
        transition: 'all 0.3s linear',
        '&:hover': {
          backgroundColor: palette.primary.main,
          color: palette.common.white,
          svg: {
            stroke: palette.common.white,
            fill: palette.common.white,
          },
        },
      }

      return {
        ...(withknstyling && stylesKN),
      }
    }
)

export default KNIconButtonRoot
