import i18n from 'i18n'

export const searchBarTranslations = (): Record<any, any> => {
  const searchBar = 'shared.search_bar'
  return {
    translation: {
      placeholder: i18n.t(`${searchBar}.placeholder`),
      noResults: i18n.t(`${searchBar}.noResults`),
      noResultsDescription: i18n.t(`${searchBar}.noResultsDescription`),
      searchQueryTooShort: i18n.t(`${searchBar}.searchQueryTooShort`),
      searchQueryTooShortDescription: i18n.t(
        `${searchBar}.searchQueryTooShortDescription`
      ),
    },
  }
}
