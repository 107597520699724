import i18n from 'i18n'

export const shipmentFilesTranslations = (): Record<any, any> => {
  const shipmentFiles = 'modules.cv.attachments_info'
  return {
    translation: {
      moduleName: i18n.t(`${shipmentFiles}.moduleName`),
      otherAttachments: i18n.t(`${shipmentFiles}.otherAttachments`),
      downloadLoadingState: i18n.t(`${shipmentFiles}.downloadLoadingState`),
      downloadSuccess: i18n.t(`${shipmentFiles}.downloadSuccess`),
      downloadError: i18n.t(`${shipmentFiles}.downloadError`),
      ProofOfDelivery: i18n.t(`${shipmentFiles}.pod`),
      ProofOfPickup: i18n.t(`${shipmentFiles}.pop`),
      podNotAvailable: i18n.t(`${shipmentFiles}.podNotAvailable`),
      podDateTime: i18n.t(`${shipmentFiles}.podDateTime`),
    },
  }
}
