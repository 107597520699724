import i18n from '../../i18n'
import { toDate, format, formatInTimeZone } from 'date-fns-tz'
import { enGB } from 'date-fns/locale'
import { parseISO } from 'date-fns'

// Temperature //
export const valueToTemperature = (value: string): number => {
  const temperatureValue = Number(value)
  return temperatureValue
}

// Location //
export const valueToLocation = (value: string): number[] => {
  const locationValue = value.split(',').map((val) => Number(val))
  return locationValue
}

// Date
export const valueToDate = (value: string | number | undefined): string => {
  if (!value) return ''
  const dateTimeValue = toDate(value)
  return format(dateTimeValue, 'dd/MM/yyyy')
}

// Datetime //
export const valueToDatetime = (value: string | number | undefined): string => {
  if (!value) return ''
  const dateTimeValue = toDate(value)
  return format(dateTimeValue, 'dd/MM/yyyy')
}

// ZonedDatetime //
export const valueToZonedDatetimeWithOffset = (
  value: string
): { dateTime: string, offset: string } | null => {
  if (!value) {
    return null
  }
  const dateValue = toDate(value)
  const offsetOnly = format(dateValue, 'XXX')
  const parsedTime = parseISO(value)
  const formattedDateTime = formatInTimeZone(
    parsedTime,
    `${offsetOnly}`,
    'dd/MM/yyyy kk:mm xxx',
    {
      locale: enGB,
    }
  )
  const splitFormattedTime = formattedDateTime.includes(' -')
    ? formattedDateTime.split(' -')
    : formattedDateTime.split(' +')
  return {
    dateTime: splitFormattedTime[0],
    offset: `UTC ${splitFormattedTime[1]}`,
  }
}

export const valueToZonedDatetimeWithoutOffset = (value: string): string => {
  const formattedDateTime = valueToZonedDatetimeWithOffset(value)?.dateTime
  return formattedDateTime ?? ''
}

// TimeWindow //
export const valueToTimeWindow = (value: any): string => {
  let timeWindowValue = ''
  if (value.early) {
    timeWindowValue += valueToZonedDatetimeWithoutOffset(value.early)
  }
  if (value.late) {
    if (timeWindowValue !== '') timeWindowValue += ' - '
    timeWindowValue += valueToZonedDatetimeWithoutOffset(value.late)
  }
  return timeWindowValue
}

// Indicator //
export const valueToIndicator = (value: string): string => {
  switch (value) {
    case 'OK':
      return 'success ' + i18n.t('modules.cv.level3_info.healthStatus.okay')
    case 'WARNING':
      return 'warning ' + i18n.t('modules.cv.level3_info.healthStatus.warning')
    case 'ERROR':
      return 'error ' + i18n.t('modules.cv.level3_info.healthStatus.error')
    default:
      return 'default'
  }
}

export const valueIndicatorToColor = (value: string): string => {
  switch (value) {
    case 'OK':
      return 'success'
    case 'WARNING':
      return 'warning'
    case 'ERROR':
      return 'error'
    default:
      return 'secondary.focus'
  }
}
