/* eslint-disable @typescript-eslint/non-nullable-type-assertion-style */
import React, { useEffect, useRef, ReactElement, useState } from 'react'
import FadeIn from 'react-fade-in/lib/FadeIn'
import { Html5Qrcode, Html5QrcodeResult } from 'html5-qrcode'
import './CodeScanner.css'

// @mui imports
import { keyframes } from '@emotion/react'
import Box from '@mui/material/Box'
import CheckIcon from '@mui/icons-material/Check'
import CircularProgress from '@mui/material/CircularProgress'
import ContactlessIcon from '@mui/icons-material/Contactless'
import Divider from '@mui/material/Divider'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import LeakAddIcon from '@mui/icons-material/LeakAdd'
import Popper from '@mui/material/Popper'
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles/createTheme'
import Tooltip from '@mui/material/Tooltip'

// KN Components
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Types
import ScannerProps, { ScannedPairingProps } from './Scanners.types'

// Data
import { scannerTranslations } from './Scanners.data'

const CodeScanner: React.FC<ScannerProps> = ({
  onClose,
  setInputResult,
  existingPairings,
  existingSessionId,
}): ReactElement | null => {
  const scannerRef = useRef<HTMLDivElement | null>(null)
  const [message, setMessage] = useState<number | null>(1)
  const [scanResult, setScanResult] = useState<Html5QrcodeResult>()
  const [scanMode, setScanMode] = useState<'REFERENCE' | 'DEVICE'>('REFERENCE')
  const [scannedDevices, setScannedDevices] = useState<ScannedPairingProps[]>()
  const [logMessages, setLogMessage] = useState<{ type: string; id: string; icon: string }[]>([])
  const [screenOrientation, setScreenOrientation] = useState<'portrait' | 'landscape' | null>()

  // Translated Data //
  const truncateCode = (code: string): string => {
    return code?.length > 10 ? code?.slice(0, 10) + '...' : code
  }
  const { translation } = scannerTranslations(truncateCode(scanResult?.decodedText as string))

  // POPUP //
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'scanning_log' : undefined

  // END POPUP //

  // Detect screen orientation //
  useEffect(() => {
    const getOrientation = (): 'portrait' | 'landscape' => {
      if (window.innerHeight > window.innerWidth) {
        return 'portrait'
      } else {
        return 'landscape'
      }
    }
    const handleOrientationChange = (): void => {
      setScreenOrientation(getOrientation())
    }

    handleOrientationChange()

    window.addEventListener('resize', handleOrientationChange)

    return () => {
      window.removeEventListener('resize', handleOrientationChange)
    }
  }, [])

  // Reset scannedDevices on mount
  useEffect(() => {
    if (existingSessionId) {
      setScannedDevices(existingPairings)
    } else setScannedDevices([])
  }, [])

  const allCodes = scannedDevices
    ?.map((device) => {
      return [device.reference, ...(device.deviceIds ?? [])]
    })
    .flat()
    .filter(Boolean)

  useEffect(() => {
    if (scanResult) {
      const existingQrCode = allCodes?.map((q) => q).some((c) => c === scanResult.decodedText)
      if (existingQrCode) {
        setScannedDevices(scannedDevices)
        setMessage(7)
      }
      if (scanMode === 'REFERENCE') {
        // EXISTING SESSION = MORE THAN 0 Bar CODES SCANNED //
        if (scannedDevices) {
          const qrcodeswithoutbarcodes = scannedDevices.filter((qr) => !qr.deviceIds).length
          // THROW ERROR IF THERE IS NO BARCODE PAIRED WITH QRCODE //
          if (scannedDevices.length > 0 && qrcodeswithoutbarcodes > 0) {
            setScannedDevices(scannedDevices)
            setMessage(42)
          } else {
            if (!existingQrCode) {
              setScannedDevices([...scannedDevices, { reference: scanResult.decodedText }])
              setMessage(2)
              setScanMode('DEVICE')
            }
          }
        } else {
          // NEW SESSION //
          setScannedDevices([{ reference: scanResult.decodedText, unpairingRule: true, temperatureThreshold: null }])
          setMessage(3)
          setScanMode('DEVICE')
        }
      }
      if (scanMode === 'DEVICE') {
        if (existingQrCode) {
          setScannedDevices(scannedDevices)
          setMessage(7)
        }
        // EXISTING SESSION = MORE THAN 0 QR CODES SCANNED //
        if (!scannedDevices) {
          setMessage(4)
        }
        if (scannedDevices) {
          const existingBarcode = scannedDevices.flatMap((qr) => qr.deviceIds).some((c) => c === scanResult.decodedText)
          if (!existingBarcode) {
            const lastQrCode = scannedDevices.pop()
            if (!lastQrCode?.deviceIds) {
              setScannedDevices([
                ...scannedDevices.filter((qr) => qr.reference !== lastQrCode?.reference),
                { reference: lastQrCode?.reference, deviceIds: [scanResult.decodedText] },
              ])
              setMessage(5)
            }
            if (lastQrCode?.deviceIds && lastQrCode.deviceIds?.length > 0) {
              setScannedDevices([
                ...scannedDevices.filter((qr) => qr.reference !== lastQrCode.reference),
                { reference: lastQrCode.reference, deviceIds: [...lastQrCode.deviceIds, scanResult.decodedText] },
              ])
              setMessage(6)
            }
          }
        }
      }
    }
  }, [scanResult])

  // Appear and disappear messages
  useEffect(() => {
    if (message && message > 0) {
      setTimeout(() => setMessage(null), 3000)
    }
    if (message === null) {
      setTimeout(() => setMessage(0), 200)
    }
  }, [message])

  useEffect(() => {
    const aspectRatio = window.innerWidth / window.innerHeight

    if (screenOrientation === 'landscape') {
      let lastResult
      let countResults = 0
      const html5QrCode = new Html5Qrcode(/* element id */ 'reader')
      Html5Qrcode.getCameras()
        .then((devices) => {
          /**
           * devices would be an array of objects of type:
           * { id: "id", label: "label" }
           */
          if (devices?.length) {
            // const cameraId = devices[0].id
            // .. use this to start scanning.

            html5QrCode
              .start(
                // cameraId,
                { facingMode: { exact: 'environment' } },
                {
                  fps: 100, // Optional, frame per seconds for qr code scanning
                  qrbox: { width: 700, height: 500 },
                  aspectRatio: aspectRatio,
                },
                (decodedText, decodedResult) => {
                  if (decodedText !== lastResult) {
                    ++countResults
                    lastResult = decodedText
                    setScanResult(decodedResult)
                  }
                },
                (errorMessage) => {
                  // parse error, ignore it.
                }
              )
              .catch((err) => {
                // Start failed, handle it.
              })
          }
        })
        .catch((err) => {
          // handle err
        })
      return () => {
        if (html5QrCode) {
          html5QrCode
            .stop()
            .then(() => {
              //cleanup
            })
            .catch((err) => {
              // Handle cleanup failure
            })
        }
      }
    }
  }, [screenOrientation])

  // Get message topbar
  const getMessage = (): ReactElement | null | undefined => {
    const messageTemplate = (
      type: 'neutral' | 'success' | 'error' | 'info',
      code: number,
      text: string
    ): ReactElement => {
      return (
        <FadeIn>
          <Box
            sx={{
              backgroundColor:
                type === 'success'
                  ? 'success.main'
                  : type === 'error'
                  ? 'error.main'
                  : type === 'info'
                  ? 'primary.contrastText'
                  : 'primary.main',
              width: 'auto',
              display: 'inline-flex',
              px: 1.5,
              py: 1,
              borderRadius: '50px',
              alignItems: 'center',
              opacity: 0.6,
            }}
          >
            {code === 0 && <CircularProgress color="info" size={20} sx={{ mr: 1.5 }} />}
            <KNTypography variant="displayXXS" color={type === 'info' ? 'dark' : 'white'}>
              {text}
            </KNTypography>
          </Box>
        </FadeIn>
      )
    }
    if (message === 0 && scanMode === 'REFERENCE') {
      return messageTemplate('neutral', 0, translation.codes.active)
    }
    if (message === 0 && scanMode === 'DEVICE') {
      return messageTemplate('neutral', 0, translation.codes.active_devices)
    }
    if (message === 1) {
      return messageTemplate('info', 1, translation.codes.started)
    }
    if (message === 2) {
      return messageTemplate('success', 2, translation.codes.barcode_scanned)
    }
    if (message === 3) {
      return messageTemplate('success', 3, translation.codes.first_barcode)
    }
    if (message === 4) {
      return messageTemplate('error', 4, translation.codes.qr_error)
    }
    if (message === 5) {
      return messageTemplate('success', 5, translation.codes.qr_added)
    }
    if (message === 6) {
      return messageTemplate('success', 6, translation.codes.qr_added_more)
    }
    if (message === 7) {
      return messageTemplate('error', 7, translation.codes.qr_inuse)
    }
    if (message === 8) {
      return messageTemplate('error', 8, translation.codes.barcode_inuse)
    }
    if (message === 42) {
      return messageTemplate('error', 42, translation.codes.barcode_needs_qr)
    }
  }

  // Get message log
  useEffect(() => {
    if (message === 2) {
      setLogMessage([...logMessages, { type: 'qr_scanned', id: translation.codes.barcode_scanned, icon: 'leak_add' }])
    }
    if (message === 3) {
      setLogMessage([...logMessages, { type: 'pairing_started', id: translation.codes.started, icon: 'flag' }])
    }
    if (message === 4) {
      setLogMessage([...logMessages, { type: 'barcode_error', id: translation.codes.qr_error, icon: 'error' }])
    }
    if (message === 5 || message === 6) {
      setLogMessage([...logMessages, { type: 'device_added', id: translation.codes.qr_added, icon: 'contactless' }])
    }
    if (message === 7) {
      setLogMessage([...logMessages, { type: 'barcode_error', id: translation.codes.qr_inuse, icon: 'error' }])
    }
    if (message === 8) {
      setLogMessage([...logMessages, { type: 'barcode_error', id: translation.codes.qr_inuse, icon: 'error' }])
    }
    if (message === 42) {
      setLogMessage([...logMessages, { type: 'qr_error', id: translation.codes.barcode_needs_qr, icon: 'error' }])
    }
  }, [message, scanResult])

  const getMessageForTooltip = (): ReactElement => {
    const logMessage = logMessages[logMessages.length - 1]
    return (
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <Icon sx={{ mr: 1, fontSize: '16px' }}>{logMessage?.icon}</Icon>
        <KNTypography variant="textMD" color="dark">
          {logMessage?.id}
        </KNTypography>
      </Box>
    )
  }

  const mapResult = (): void => {
    if (scannedDevices) {
      setInputResult({
        sessionTimestamp: Date.now(),
        pairings: scannedDevices?.map((device) => {
          return {
            reference: device.reference ?? '',
            deviceIds: device.deviceIds ?? [],
            unpairingRule: device.unpairingRule ?? true,
            temperatureThreshold: device.temperatureThreshold ?? null,
            description: device.description,
          }
        }),
      })
    }
  }

  const disabledFinish =
    (scannedDevices && scannedDevices.length === 0) ||
    (scannedDevices &&
      scannedDevices?.filter((scannedDevice) => !scannedDevice.deviceIds || scannedDevice.deviceIds.length === 0)
        .length > 0)

  const OrientationIcon = (): JSX.Element => {
    const rotateAnimation = keyframes`
            0% {
              transform: rotate(45deg);
            }
            50% {
              transform: rotate(-45deg);
            }
            100% {
              transform: rotate(45deg);
            }
          `

    const iconStyle: React.CSSProperties = {
      animation: `${rotateAnimation} 3s ease-in-out infinite`,
    }

    return (
      <ScreenRotationIcon
        fontSize="large"
        sx={{ color: ({ palette: { white } }: Theme): string => white.main, ...iconStyle }}
      />
    )
  }

  if (screenOrientation === 'portrait') {
    return (
      <div style={{ position: 'absolute', zIndex: 999, width: '100%', height: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', ml: 2 }}>
          <Stack direction={'column'} spacing={2}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <OrientationIcon />
            </Box>
            <KNTypography variant="displaySM" color="white">
              {translation.landscape}
            </KNTypography>
          </Stack>
        </Box>
      </div>
    )
  }
  return (
    <>
      <div style={{ position: 'absolute', top: '2%', zIndex: 999, width: '100%' }}>
        <Box sx={{ textAlign: 'center' }}>{getMessage()}</Box>
      </div>
      <div id="reader" ref={scannerRef} style={{ height: '100%' }}></div>
      <div style={{ position: 'absolute', bottom: '10%', zIndex: 999, width: '100%' }}>
        <Box sx={{ textAlign: 'end' }}>
          <Tooltip
            title={getMessageForTooltip()}
            arrow
            open={Boolean(message && message > 1)}
            placement="left"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'grey.200',
                  color: 'dark.main',
                  opacity: '0.8 !important',
                  maxWidth: '320px',
                  '& .MuiTooltip-arrow': {
                    color: 'grey.200',
                  },
                },
              },
            }}
          >
            <IconButton aria-label="log" size="large" aria-describedby={id} type="button" onClick={handleClick}>
              <FormatListBulletedIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            placement="left-end"
            sx={{
              backgroundColor: 'white !important',
              height: !scannedDevices && logMessages.length === 0 ? 'auto' : '80%',
              p: 2,
              width: '80%',
              mr: '10px !important',
              opacity: 0.8,
              borderRadius: '25px',
              boxShadow: 'inset 0 0 1px 1px hsla(0,0%,100%,.9),0 20px 27px 0 rgba(0,0,0,.05)!important',
              overflow: 'scroll',
              zIndex: 9999,
            }}
          >
            <>
              {logMessages && logMessages.length > 0 && (
                <Box>
                  <KNTypography variant="textLG_SB" color="dark.main">
                    {translation.log}
                  </KNTypography>
                  <Box mt={1}>
                    {logMessages
                      ?.map((msg, i) => (
                        <Box key={i} sx={{ display: 'flex', my: 0.5, alignItems: 'center' }}>
                          <Icon sx={{ mr: 1, fontSize: '16px' }}>{msg.icon}</Icon>
                          <KNTypography variant="textMD">{msg.id}</KNTypography>
                        </Box>
                      ))
                      .reverse()}
                  </Box>
                  <Divider sx={{ my: 1 }} />
                </Box>
              )}
              {scannedDevices && scannedDevices.length > 0 && (
                <>
                  <KNTypography variant="textLG_SB" color="dark.main">
                    {translation.result}
                  </KNTypography>
                  {scannedDevices?.map((s, i) => (
                    <>
                      <Box key={i} sx={{ my: 1 }}>
                        <KNTypography variant="textMD_SB">
                          {translation.pairing} {i + 1}
                        </KNTypography>
                        <Box key={i} sx={{ display: 'flex', my: 0.5, alignItems: 'center' }}>
                          <LeakAddIcon sx={{ mr: 1 }} />
                          <KNTypography variant="textMD_SB" sx={{ mr: 1 }} color="dark">
                            ID:{' '}
                          </KNTypography>
                          <KNTypography variant="textMD">{s.reference}</KNTypography>
                        </Box>
                        {s.deviceIds && (
                          <Box>
                            <KNTypography variant="textMD_SB" color="secondary">
                              {translation.connectedDevices}
                            </KNTypography>
                            <Box mt={1}>
                              {s.deviceIds?.map((d, i) => (
                                <Box key={i} sx={{ display: 'inline-flex', mr: 1 }}>
                                  <ContactlessIcon sx={{ mr: 0.5 }} />
                                  <KNTypography variant="textMD" color="dark">
                                    {d}
                                  </KNTypography>
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </>
                  ))}
                </>
              )}
              {!scannedDevices && logMessages.length === 0 && (
                <KNTypography variant="textMD_SB" color="primary">
                  {translation.noResults}
                </KNTypography>
              )}
            </>
          </Popper>
        </Box>
      </div>
      <div style={{ position: 'absolute', bottom: '1%', zIndex: 9999, width: '100%' }}>
        <Box sx={{ textAlign: 'end' }}>
          <KNButton
            variant="contained"
            size="medium"
            color="primary"
            sx={{ mr: 2 }}
            onClick={(): void => {
              setScanMode('REFERENCE')
            }}
            disabled={disabledFinish}
          >
            {translation.next}
          </KNButton>
          <KNButton
            variant="contained"
            size="medium"
            color="success"
            startIcon={<CheckIcon color="info" fontSize="large" />}
            disabled={disabledFinish}
            onClick={(): void => {
              mapResult()
              onClose()
            }}
          >
            {translation.finish}
          </KNButton>
        </Box>
      </div>
    </>
  )
}

export default CodeScanner
