// @mui imports
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'
import { SystemStyleObject } from '@mui/system'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNFeatureWrapperProps from './KNFeatureWrapper.type'
import theme from 'assets/theme'
import { Theme } from '@mui/material/styles/createTheme'

const KNFeatureWrapper: React.FC<KNFeatureWrapperProps> = ({ featureTitle, withIconBox, children, style }) => {
  return (
    <Box
      p={3}
      my={withIconBox && 2}
      sx={({ boxShadows, palette: { white, gradients } }: Theme): SystemStyleObject<Theme> => ({
        backgroundColor: white.main,
        backgroundImage:
          style === 'gradient'
            ? ({ functions: { rgba, linearGradient } }): string =>
                linearGradient(rgba(gradients.secondary.main, 1), rgba(gradients.primary.main, 1))
            : 'none',
        backgroundSize: 'cover',
        backgroundPosition: '50%',
        borderRadius: 2,
        position: 'relative',
        boxShadow: boxShadows.standardBoxShadow,
      })}
    >
      {withIconBox && (
        <Box
          height="50px"
          width="50px"
          sx={({ palette: { gradients, common } }: Theme): SystemStyleObject<Theme> => ({
            backgroundColor: theme.palette.primary.main,
            backgroundImage:
              withIconBox.style === 'gradient'
                ? ({ functions: { rgba, linearGradient } }): string =>
                    linearGradient(rgba(gradients.secondary.main, 1), rgba(gradients.primary.main, 1))
                : 'none',
            borderRadius: '8px',
            color: common.white,
            display: 'flex',
            position: 'absolute',
            top: '-25px',
            alignItems: 'center',
            justifyContent: 'center',
          })}
        >
          <Icon color="inherit">{withIconBox.icon}</Icon>
        </Box>
      )}
      {featureTitle && !withIconBox && (
        <KNTypography color={style === 'gradient' ? 'white' : 'default'}>{featureTitle}</KNTypography>
      )}
      {featureTitle && withIconBox && (
        <KNTypography sx={{ position: 'absolute', paddingLeft: 8, top: 0 }}>{featureTitle}</KNTypography>
      )}
      {children}
    </Box>
  )
}

export default KNFeatureWrapper
