import { fetchDelete, fetchGet, fetchPost, fetchPut } from 'global/helpers/fetchWrapper'
import { Pairing, PairingSession } from './PairDevices.types'

// Types

export const createPairingSession = async (data: any[]): Promise<void> =>
  await fetchPost('pairings/bulk', data, {}, 'si')

export const getPairingSessions = async (): Promise<PairingSession[]> => await fetchGet('pairings/sessions', {}, 'si')

export const pairShipment = async (data: Pairing): Promise<void> => await fetchPost('pairings', data, {}, 'si')

export const editPairing = async (cid: string, data: Pairing): Promise<void> =>
  await fetchPut(`pairings/${cid}`, data, {}, 'si')

export const unpairShipment = async (cid: string): Promise<void> => await fetchDelete(`pairings/${cid}`, null, {}, 'si')

export const unpairBulk = async (data: any[]): Promise<void> => await fetchDelete('pairings/bulk', data, {}, 'si')
