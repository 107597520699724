// Functional
import { fetchGet, fetchPost, fetchPut, fetchDelete } from 'global/helpers/fetchWrapper'

// Types
import { GeofenceData, GeofenceRequestBody } from './Geofence.types'

export const getShipmentGeofences = async (shipmentId: string): Promise<GeofenceData[]> =>
  await fetchGet(`shipments/${shipmentId}/geofences`, {}, 'si', 'v2')

export const createShipmentGeofence = async (shipmentId: string, data: GeofenceRequestBody): Promise<[]> =>
  await fetchPost(`shipments/${shipmentId}/geofences`, data, {}, 'si', 'v2')

export const editShipmentGeofence = async (
  shipmentId: string,
  geofenceId: string,
  data: GeofenceRequestBody
): Promise<[]> => await fetchPut(`shipments/${shipmentId}/geofences/${geofenceId}`, data, {}, 'si', 'v2')

export const deleteShipmentGeofence = async (shipmentId: string, geofenceId: string): Promise<void> =>
  await fetchDelete(`shipments/${shipmentId}/geofences/${geofenceId}`, null, {}, 'si', 'v2')
