import { ReactElement, useEffect, useState } from 'react'
import FadeIn from 'react-fade-in/lib/FadeIn'
import { useParams } from 'react-router'

// @mui material imports //
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'

// KN Components //
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import MegaForm from 'components/KN_Molecules/MegaForm/MegaForm'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'

// Functional //
import { getFormSteps, getMappedConditions } from './SharingRuleCreateOrEdit.helpers'
import { sharingRuleCreateTranslations } from './SharingRuleCreateOrEdit.data'
import { AggregatedStatus } from 'global/helpers/statuses'
import {
  getConditionGroup,
  postConditionGroup,
  updateConditionGroup,
} from 'modules/AccessGroupTable/AccessGroupTable.service'
import { sleep } from 'global/helpers/sleep'
import { getErrorMessage } from 'global/helpers/errorHandler'
import { getSelectedCompany } from 'context/authentication/User.helpers'
import { getUsers } from 'screens/UserManager/UserManager.service'

// Types //
import { AccessGroupConditionTypes, ConditionGroup } from 'modules/AccessGroupTable/AccessGroupTable.types'
import { AddressField } from 'components/KN_Molecules/MegaForm/MegaForm.types'
import { UserData } from 'screens/UserManager/UserManager.types'
import { Role } from 'context/authentication/Role.types'

const SharingRuleCreateOrEdit = (): ReactElement => {
  const [conditionGroup, setConditionGroup] = useState<ConditionGroup>({
    name: '',
    userEmails: [],
    conditions: [],
  })
  const [errors, setErrors] = useState<string[]>([])
  const [disableSubmit, setDisableSubmit] = useState<boolean>(true)
  const [guestUsers, setGuestUsers] = useState<UserData[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState<boolean>(false)

  const { translation } = sharingRuleCreateTranslations()

  const { action, conditionGroupCid } = useParams<{
    action: string
    conditionGroupCid?: string
  }>()

  const fetchData = async (): Promise<void> => {
    if (!conditionGroupCid) return
    setLoading(true)
    try {
      setConditionGroup(await getConditionGroup(conditionGroupCid))
    } catch (error) {
      setErrors([...errors, getErrorMessage(error)])
    }
    setLoading(false)
  }

  useEffect(() => {
    if (action === 'edit') {
      void fetchData()
    }
  }, [])

  useEffect(() => {
    if (errors.length > 0) setSubmitted(false)
  }, [errors])

  useEffect(() => {
    if (!conditionGroup.name || !conditionGroup.conditions || conditionGroup.conditions.length === 0)
      setDisableSubmit(true)
    else setDisableSubmit(false)
  }, [conditionGroup])

  const resetErrors = (): void => {
    if (errors.length > 0) setErrors([])
  }

  useEffect(() => {
    const fetchUsers = async (): Promise<void> => {
      try {
        let users = await getUsers()
        const selectedCompany = getSelectedCompany()
        if (selectedCompany) {
          users = users.filter((user) => user.roles[selectedCompany.cid] === Role.Guest)
        }
        setGuestUsers(users)
      } catch (error) {
        setErrors([...errors, getErrorMessage(error)])
      }
    }
    void fetchUsers()
  }, [])

  const setUserEmails = (emails: string[]): void => {
    setConditionGroup({ ...conditionGroup, userEmails: emails })
    resetErrors()
  }

  const setGroupName = (name: string): void => {
    setConditionGroup({ ...conditionGroup, name: name })
    resetErrors()
  }

  const setConditionType = (conditionType: AccessGroupConditionTypes): void => {
    const newCondition = { type: conditionType }
    setConditionGroup({ ...conditionGroup, conditions: [...conditionGroup.conditions, newCondition] })
    resetErrors()
  }

  const setAddressGroupConditions = (conditionType: AccessGroupConditionTypes, address: AddressField): void => {
    const cGroupIndex = conditionGroup.conditions.findIndex((condition) => condition.type === conditionType)
    if (cGroupIndex > -1) {
      const newConditions = conditionGroup.conditions
      newConditions[cGroupIndex].address = address
      setConditionGroup({ ...conditionGroup, conditions: newConditions })
    } else {
      const newCondition = { type: conditionType, address: address }
      setConditionGroup({ ...conditionGroup, conditions: [...conditionGroup.conditions, newCondition] })
    }
    resetErrors()
  }

  const setAggregatedStatusGroupConditions = (
    conditionType: AccessGroupConditionTypes,
    aggregatedStatus: AggregatedStatus
  ): void => {
    const cGroupIndex = conditionGroup.conditions.findIndex((condition) => condition.type === conditionType)
    if (cGroupIndex > -1) {
      const newConditions = conditionGroup.conditions
      newConditions[cGroupIndex].status = aggregatedStatus
      setConditionGroup({ ...conditionGroup, conditions: newConditions })
    } else {
      const newCondition = { type: conditionType, status: aggregatedStatus }
      setConditionGroup({ ...conditionGroup, conditions: [...conditionGroup.conditions, newCondition] })
    }
    resetErrors()
  }

  const removeCondition = (index: number): void => {
    const conditions = conditionGroup.conditions
    conditions.splice(index, 1)
    setConditionGroup({ ...conditionGroup, conditions: conditions })
  }

  const resetConditionGroup = (): void => {
    setSubmitted(false)
    setConditionGroup({
      name: '',
      userEmails: [],
      conditions: [],
    })
  }

  const submit = async (): Promise<void> => {
    setSubmitted(true)
    if (!conditionGroup.name) {
      setErrors([...errors, translation.errorNameField])
      return
    } else if (conditionGroup.conditions.length === 0) {
      setErrors([...errors, translation.errorConditionsField])
      return
    } else {
      try {
        if (action === 'edit' && conditionGroupCid) {
          await updateConditionGroup(conditionGroupCid, conditionGroup)
        } else await postConditionGroup(conditionGroup)
        resetConditionGroup()
        history.back()
      } catch (error) {
        setErrors([...errors, getErrorMessage(error)])
      }
    }
  }

  return (
    <FadeIn>
      {loading && (
        <FadeIn visible={loading}>
          <KNLoader />
        </FadeIn>
      )}
      <Paper elevation={8} sx={{ padding: 2 }}>
        <Stack
          spacing={1}
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems={{ xs: 'start', md: 'center' }}
          mb={2}
        >
          <KNTypography variant="displayMD_SB" color="primary.focus">
            {action === 'edit' ? translation.screenNameEdit : translation.screenNameCreate}
          </KNTypography>
        </Stack>
        <MegaForm
          steps={getFormSteps(
            conditionGroup,
            setConditionType,
            setAddressGroupConditions,
            setAggregatedStatusGroupConditions,
            guestUsers,
            setUserEmails,
            setGroupName
          )}
          picked={getMappedConditions(conditionGroup, removeCondition)}
          errors={errors}
          editMode={action === 'edit'}
          submit={submit}
          resetForm={resetConditionGroup}
          submitted={submitted}
          disableSubmit={disableSubmit}
        />
      </Paper>
    </FadeIn>
  )
}

export default SharingRuleCreateOrEdit
