import { ReactElement, useEffect, useState } from 'react'
import FadeIn from 'react-fade-in'

// @mui imports
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN imports
import theme from 'assets/theme'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import PairingSessions from 'modules/PairDevices/PairingSessions'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'

// Functional
import { getPairingSessions } from './PairDevices.service'

// Data
import { pairDevicesTranslations } from './PairDevices.data'

// Types
import { PairingSession } from './PairDevices.types'
import ScanningDevices from 'modules/PairDevices/ScanningDevices'
import Alert from '@mui/material/Alert'

const PairDevices = (): ReactElement => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [loading, setLoading] = useState(true)
  const [pairingSessions, setPairingSessions] = useState<PairingSession[]>([])
  const [eventTrigger, setEventTrigger] = useState<Date>()
  const [inputResult, setInputResult] = useState<PairingSession>()

  // Translated data //
  const { translation } = pairDevicesTranslations()

  const fetchData = async (): Promise<void> => {
    setLoading(true)
    const pairingSessionsData = await getPairingSessions()
    setPairingSessions(pairingSessionsData)
    setLoading(false)
  }

  useEffect(() => {
    void fetchData()
  }, [])

  useEffect(() => {
    setInputResult(inputResult)
  }, [eventTrigger, inputResult])

  return (
    <Container maxWidth={false} data-test="pair-devices-container">
      {loading ? (
        <FadeIn visible={loading}>
          <KNLoader />
        </FadeIn>
      ) : (
        <>
          <KNTypography variant="displayMD_SB" color="dark.main">
            {translation.screenName}
          </KNTypography>

          <Box mt={3}>
            {/* Welcome Screen */}
            <Box
              mt={3}
              display="flex"
              flexDirection="column"
              sx={{
                width: isMobile ? '100%' : '50%',
                mr: isMobile ? 0 : 2,
              }}
            >
              <KNTypography variant="textLG_SB" color="primary.focus">
                {translation.welcomeTitle}
              </KNTypography>
              <Box mt={1}>
                <KNTypography variant="textMD" color="grey.500">
                  {translation.welcomeText}
                </KNTypography>
              </Box>
              <Box mt={2}>
                <ScanningDevices
                  setEventTrigger={(): void => setEventTrigger(new Date())}
                  setInputResult={(input: PairingSession): void => setInputResult(input)}
                />
              </Box>
            </Box>
            <PairingSessions
              pairingSessionsData={pairingSessions}
              enforcedEdit={eventTrigger ? true : false}
              inputResult={inputResult}
            />
          </Box>
        </>
      )}
    </Container>
  )
}

export default PairDevices
