import { Control, UseFormGetValues, UseFormSetValue } from 'react-hook-form'

export interface Device {
  deviceId: string
}

export interface Pairing {
  reference?: any
  cid?: string
  shipmentCid?: string
  description?: string | null
  deviceIds: string[]
  temperatureThreshold: {
    min: number | null
    max: number | null
  } | null
  unpairingRule: boolean
  completionDate?: string
}

export enum PairingSessionType {
  saved = 'saved',
  pending = 'pending',
  reopened = 'reopened',
}

export interface PairingSession {
  sessionTimestamp: string | number
  pairings: Pairing[]
  type?: PairingSessionType
}

export interface PairingSessionFormProps {
  editMode?: boolean
  control: Control<PairingSession>
  getValues: UseFormGetValues<PairingSession>
  setValue: UseFormSetValue<PairingSession>
  pairingIndex: number
}

export interface TemperatureThresholdDialogProps {
  currentMin: number | null
  currentMax: number | null
  dialogOpen: boolean
  handleClose: () => void
  handleSave: (min: number | null, max: number | null) => void
}

export interface PairingSessionsTableProps {
  pairingSessionsData: PairingSession[]
}

export interface PairingSessionsProps {
  pairingSessionsData?: PairingSession[]
  enforcedEdit?: boolean
  inputResult?: PairingSession
}

export interface PairingSessionRowProps {
  pairing: Pairing
  type?: PairingSessionType
}
