import { ReactElement } from 'react'

// KN components
import GuardedRoute from './GuardedRoute'

// Screens Shared
import Support from 'modules/Support/Support'

// Screens CV
import VisibilityDashboard from '../VisibilityDashboard/VisibilityDashboard'
import ShipmentDetailsNext from 'screens/ShipmentDetailsNext/ShipmentDetails'

// Context
import { DashboardContextProvider } from 'context/dashboard/DashboardContext'
import { InsightDetailsContextProvider } from 'context/detailsNext/InsightDetails'

const CVRoot = (): ReactElement => {
  return (
    <DashboardContextProvider>
      <GuardedRoute path="/visibility-dashboard" companyTypeGuard="Customer" excludedRoles={['Pairer']}>
        <Support moduleName="cv" />
        <VisibilityDashboard />
      </GuardedRoute>
      <InsightDetailsContextProvider>
        <GuardedRoute
          path="/shipment-details/:entitytype/:shipmentid/:shipmentname"
          companyTypeGuard="Customer"
          excludedRoles={['Pairer']}
        >
          <Support moduleName="cv" />
          <ShipmentDetailsNext />
        </GuardedRoute>
      </InsightDetailsContextProvider>
    </DashboardContextProvider>
  )
}

export default CVRoot
