import { Locale } from 'date-fns'
import { parseISO, formatISO, formatDistanceToNow } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import {
  enGB,
  af,
  ar,
  bg,
  cs,
  de,
  el,
  et,
  fr,
  hr,
  hu,
  mk,
  nn,
  pl,
  pt,
  ro,
  ru,
  sk,
  sl,
  sr,
  tr,
  uk,
} from 'date-fns/locale'

// KN imports
import i18n from 'i18n'
import { regexIsoDate } from './validators'

// NOTE: keep all methods up-to-date with supported translations

export const getDateLocale = (): Locale => {
  const locale = i18n.language ? i18n.language.slice(0, 2) : 'en'
  switch (locale) {
    case 'af':
      return af
    case 'ar':
      return ar
    case 'bg':
      return bg
    case 'cs':
      return cs
    case 'de':
      return de
    case 'el':
      return el
    case 'et':
      return et
    case 'fr':
      return fr
    case 'hr':
      return hr
    case 'hu':
      return hu
    case 'mk':
      return mk
    case 'nn':
      return nn
    case 'pl':
      return pl
    case 'pt':
      return pt
    case 'ro':
      return ro
    case 'ru':
      return ru
    case 'sk':
      return sk
    case 'sl':
      return sl
    case 'sr':
      return sr
    case 'tr':
      return tr
    case 'uk':
      return uk
    case 'en':
    default:
      return enGB
  }
}

export const getDateFormat = (format: string): string => {
  // NOTE: missing from date-fns, based on existing localizations
  let shortNoYear: string
  let mediumNoYear: string
  let full: string
  const locale = i18n.language ? i18n.language.slice(0, 2) : 'en'
  switch (locale) {
    case 'af':
      shortNoYear = 'MM/dd'
      mediumNoYear = 'MMM yyyy'
      full = 'MM/dd/y'
      break
    case 'ar':
      shortNoYear = 'dd/MM'
      mediumNoYear = 'd MMM'
      full = 'dd/MM/y'
      break
    case 'bg':
      shortNoYear = 'dd/MM'
      mediumNoYear = 'dd MMM'
      full = 'dd/MM/y'
      break
    case 'cs':
      shortNoYear = 'dd.MM'
      mediumNoYear = 'd. M.'
      full = 'dd.MM.y'
      break
    case 'de':
      shortNoYear = 'dd.MM'
      mediumNoYear = 'do MMM'
      full = 'dd.MM.y'
      break
    case 'el':
      shortNoYear = 'd/M'
      mediumNoYear = 'd MMM'
      full = 'd/M/y'
      break
    case 'et':
      shortNoYear = 'dd.MM'
      mediumNoYear = 'd. MMM'
      full = 'dd.MM.y'
      break
    case 'fr':
      shortNoYear = 'dd/MM'
      mediumNoYear = 'd MMM'
      full = 'dd/MM/y'
      break
    case 'hr':
      shortNoYear = 'dd. MM.'
      mediumNoYear = 'd. MMM'
      full = 'dd. MM. y'
      break
    case 'hu':
      shortNoYear = 'MM. dd.'
      mediumNoYear = 'MMM d.'
      full = 'MM. dd. y'
      break
    case 'mk':
      shortNoYear = 'dd/MM'
      mediumNoYear = 'dd MMM'
      full = 'dd/MM/y'
      break
    case 'nn':
      shortNoYear = 'dd.MM'
      mediumNoYear = 'd. MMM'
      full = 'dd.MM.y'
      break
    case 'pl':
      shortNoYear = 'dd.MM'
      mediumNoYear = 'do MMM'
      full = 'dd.MM.y'
      break
    case 'pt':
      shortNoYear = 'dd/MM'
      mediumNoYear = "d 'de' MMM"
      full = 'dd/MM/y'
      break
    case 'ro':
      shortNoYear = 'dd.MM'
      mediumNoYear = 'd MMMM'
      full = 'dd.MM.y'
      break
    case 'ru':
      shortNoYear = 'dd.MM'
      mediumNoYear = 'd MMM'
      full = 'dd.MM.y'
      break
    case 'sk':
      shortNoYear = 'd. M.'
      mediumNoYear = 'd. M.'
      full = 'd. M. y'
      break
    case 'sl':
      shortNoYear = 'd. MM.'
      mediumNoYear = 'd. MMM'
      full = 'd. MM. y'
      break
    case 'sr':
      shortNoYear = 'd. MM.'
      mediumNoYear = 'd. MMM'
      full = 'd. MM. y'
      break
    case 'tr':
      shortNoYear = 'dd.MM.'
      mediumNoYear = 'd MMM'
      full = 'dd.MM.y'
      break
    case 'uk':
      shortNoYear = 'dd.MM.'
      mediumNoYear = 'd MMM'
      full = 'dd.MM.y'
      break
    case 'en':
    default:
      shortNoYear = 'dd/MM'
      mediumNoYear = 'd MMM'
      full = 'dd/MM/y'
      break
  }

  // NOTE: returns localized variants
  switch (format) {
    case 'iso_no_tz':
      return "yyyy-MM-dd'T'HH:mm:ss" // 2022-04-29T12:00:00
    case 'full':
      return 'PP p' // Apr 29, 2022 12:00 AM
    case 'full_timezoned':
      return `${full} p` // 29/04/2022 12:00 AM
    case 'short_timezoned':
      return `${full}` // 29/04/2022
    case 'full_no_year':
      return `${mediumNoYear} p` // Apr 29 12:00 AM
    case 'medium':
      return 'PP' // Apr 29, 2022
    case 'medium_no_year':
      return mediumNoYear
    case 'short':
      return 'P' // 04/29/2022
    case 'short_with_time':
      return 'Pp' // 04/29/2022, 12:00 AM
    case 'short_no_year':
      return shortNoYear
    case 'short_no_year_with_time':
      return `${shortNoYear} p`
    case 'time':
      return 'p' // 12:00 AM
    case 'gmt':
      return 'O' // GMT-8, GMT+5:30, GMT+0
    case 'offset':
      return 'XXX' // -08:00, +05:30, Z
    case 'iso':
    default:
      return "yyyy-MM-dd'T'HH:mm:ssXXX" // 2022-04-29T12:00:00+02:00
  }
}

export const getDateMask = (withTime = false): string => {
  const locale = i18n.language?.slice(0, 2) ?? 'en'
  switch (locale) {
    case 'cs':
    case 'de':
    case 'et':
    case 'hr':
    case 'hu':
    case 'nn':
    case 'pl':
    case 'ro':
    case 'ru':
    case 'sk':
    case 'sl':
    case 'sr':
    case 'uk':
      return withTime ? '__.__.____ __:__' : '__.__.____'
    case 'af':
    case 'ar':
    case 'bg':
    case 'el':
    case 'fr':
    case 'mk':
    case 'pt':
    case 'en':
    default:
      return withTime ? '__/__/____ __:__' : '__/__/____'
  }
}

export const getTimeMask = (): string => {
  const locale = i18n.language?.slice(0, 2) ?? 'en'
  switch (locale) {
    case 'af':
    case 'ar':
    case 'bg':
    case 'cs':
    case 'de':
    case 'el':
    case 'et':
    case 'fr':
    case 'hr':
    case 'hu':
    case 'mk':
    case 'nn':
    case 'pl':
    case 'pt':
    case 'ro':
    case 'ru':
    case 'sk':
    case 'sl':
    case 'sr':
    case 'tr':
    case 'uk':
    case 'en':
    default:
      return '__:__'
  }
}

export const zonedDate = (value: Date | string | null, format = 'medium', tz: string | null = null): string | null => {
  if (!value) {
    return null
  }
  if (value instanceof Date) {
    value = formatISO(value)
  }
  const parts = value.match(regexIsoDate)
  if (!parts) {
    return null
  }
  if (!tz) {
    tz = parts[7] || 'UTC'
  }
  const date = parseISO(value)
  return formatInTimeZone(date, tz, getDateFormat(format), {
    locale: getDateLocale(),
  })
}

export const relativeDate = (date: Date | string | null): string | null => {
  if (!date) {
    return null
  }
  if (typeof date === 'string') {
    date = parseISO(date)
  }
  return formatDistanceToNow(date, { locale: getDateLocale(), addSuffix: true })
}
