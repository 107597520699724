import { useEffect, useState, ReactElement, useContext, Fragment } from 'react'
import i18n from 'i18n'
import format from 'date-fns-tz/format'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'

// @mui imports //
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Icon from '@mui/material/Icon'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles/createTheme'
import { SystemStyleObject } from '@mui/system'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN Components //
import theme from 'assets/theme'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNCountryFlag from 'components/KN_Molecules/KNCountryFlag/KNCountryFlag'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Context //
import { useInsightDetailsContext } from 'context/detailsNext/InsightDetails'
import { UserContext } from 'context/authentication/UserContext'

// Functional //
import { getEventTimelineData } from './EventTimeline.service'
import { getErrorMessage } from 'global/helpers/errorHandler'
import { getAggregatedStatusIcon, getEventIcon, groupOnDate, mapEvents, toCapitalize } from './EventTimeline.helpers'

// Data //
import { eventTimelineTranslations } from './EventTimeline.data'

// Types //
import EventTimelineProps, { EventProps } from './EventTimeline.types'

const EventTimeline = ({ token, pinCode }: EventTimelineProps): ReactElement | null => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { insightDetailsState, dataLoading } = useInsightDetailsContext()
  const { userTimezone } = useContext(UserContext)

  // Props //
  const { shipmentId, data } = insightDetailsState.insightDetailsContext
  const status = data?.status
  const creationDate = data?.creationDate

  // Module state//
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [eventsCollapsed, setEventsCollapsed] = useState(false)
  const [eventsData, setEventsData] = useState<EventProps[]>()
  const [locationDetailsLoading, setLocationDetailsLoading] = useState(false)
  const [eventsWithLocationDetails, setEventsWithLocationDetails] = useState<EventProps[]>()
  const [view, setView] = useState<'timeline' | 'date'>('timeline')
  const mapKey = process.env.REACT_APP_MAPS_API_KEY || ''

  // Translated Data //
  const { translation } = eventTimelineTranslations()

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true)
      try {
        const events = await getEventTimelineData(shipmentId ?? '', token, pinCode)
        setEventsData(mapEvents(events, userTimezone))
        setLoading(false)
      } catch (error) {
        setError(getErrorMessage(error))
      }
      setLoading(false)
    }
    if (shipmentId && !dataLoading) {
      void fetchData()
    }
  }, [])

  const extractDetailsData = (data) => {
    // Here we can also add a radius for in the future //
    const results = data.results
    if (results && results.length > 0) {
      const addressComponents = results[0].address_components
      for (const component of addressComponents) {
        if (component.types.includes('country')) {
          return { longName: component.long_name, shortName: component.short_name }
        }
      }
    }
    return ''
  }

  const getLocationDetails = (address: string): Promise<any> => {
    return fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${mapKey}`
    )
      .then((response) => response.json())
      .then((data) => {
        const country = extractDetailsData(data)
        return country
      })
      .catch((error) => {
        console.error('Error fetching geocode data:', error)
        throw error
      })
  }

  useEffect(() => {
    const fetchLocationDetails = async (): Promise<void> => {
      setLocationDetailsLoading(true)
      try {
        if (eventsData) {
          const promises = eventsData.map(async (event) =>
            event.location?.city
              ? { ...event, locationDetails: await getLocationDetails(event.location?.city) }
              : { ...event }
          )
          const locationDetails = await Promise.all(promises)
          setEventsWithLocationDetails(locationDetails)
        }
      } catch (error) {
        setError(getErrorMessage(error))
      }
      setLocationDetailsLoading(false)
    }
    if (eventsData && !loading) {
      void fetchLocationDetails()
    }
  }, [eventsData, loading])

  const eventsGroupedOnDate = eventsWithLocationDetails && groupOnDate(eventsWithLocationDetails)

  const eventTemplate = (
    event: EventProps,
    index: number,
    noDivider?: boolean,
    relativeView?: boolean
  ): ReactElement => {
    const dividerWithMoreEvents =
      eventsWithLocationDetails && eventsWithLocationDetails.length > 3 && !eventsCollapsed && index === 1
    const getDivider = (index: number): ReactElement | undefined => {
      const divider = (
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              position: 'relative',
              marginLeft: '9px',
              height: '62px',
              mt: 0.5,
              '::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                width: '2px',
                height: '130%',
                backgroundColor: ({ palette: { primary, white } }: Theme): string =>
                  dividerWithMoreEvents ? white.main : primary.light,
                borderRadius: '8px',
                borderColor: ({ palette: { secondary } }: Theme): string => secondary.main,
                borderStyle: dividerWithMoreEvents ? 'dashed' : null,
                borderWidth: dividerWithMoreEvents ? '1px' : null,
              },
            }}
          />
          {dividerWithMoreEvents && (
            <Box
              sx={{ position: 'absolute', bottom: '1%', left: '15px', cursor: 'pointer' }}
              onClick={(): void => setEventsCollapsed(!eventsCollapsed)}
            >
              <KNTypography color="secondary">+{eventsWithLocationDetails.length - 3}</KNTypography>
            </Box>
          )}
        </Box>
      )
      if (noDivider) {
        return
      } else if (eventsData && eventsData.length <= 3) {
        if (index + 1 !== eventsData?.length) {
          return divider
        }
      } else if (eventsData && eventsData.length > 3) {
        if (!eventsCollapsed && index + 1 !== 3) {
          return divider
        }
        if (eventsCollapsed && index + 1 !== eventsData?.length) {
          return divider
        }
      }
    }

    return (
      <Fragment key={index}>
        <Box sx={{ position: 'relative', display: 'flex', mt: 1 }}>
          <Icon fontSize="small" sx={{ color: 'primary.light', mt: 2.5 }}>
            {getEventIcon(event)}
          </Icon>
          <Box
            sx={{
              position: relativeView ? 'relative' : 'absolute',
              right: 0,
              width: '100%',
              pl: view === 'timeline' ? 4 : 1,
            }}
          >
            <Box
              sx={({ boxShadows, borders: { borderRadius } }: Theme): SystemStyleObject<Theme> => ({
                p: 2,
                background: 'white',
                borderRadius: borderRadius.lg,
                boxShadow: boxShadows.sm,
                height: 'auto',
                position: 'relative',
              })}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <KNTypography variant="textLG" color="dark" sx={{ width: '50%' }}>
                  {i18n.t(`${event.statusCode}`)}
                </KNTypography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Icon color="secondary" sx={{ fontSize: '16px', mr: 0.5 }}>
                    pin_drop
                  </Icon>
                  <KNTypography variant="textMD" color="secondary">
                    {!event.locationDetails ? (
                      event.location?.name
                    ) : (
                      <>
                        {event.location?.city && toCapitalize(event.location?.city)},{' '}
                        {isMobile ? event.locationDetails.shortName : event.locationDetails.longName}
                        <KNCountryFlag countryCode={event.locationDetails.shortName} sx={{ ml: 1, mt: '6px' }} />
                      </>
                    )}
                  </KNTypography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: ({ palette: { info } }: Theme): string => info.dark,
                }}
              >
                <Icon sx={{ fontSize: '14px', mr: 0.5 }}>schedule</Icon>
                <KNTypography variant="textMD" color="inherit">
                  {event.dateTime}
                </KNTypography>
              </Box>
            </Box>
          </Box>
        </Box>
        {getDivider(index)}
      </Fragment>
    )
  }

  if (!status || !eventsData) return null
  return (
    <>
      <Box>
        {status > '' && (
          <Box data-test="shipment-status">
            <KNTypography variant="displayXS_SB" sx={{ display: 'block' }}>
              {translation.shipment_status}
            </KNTypography>
          </Box>
        )}
        {/* Timeline */}
        <Box my={1}>
          <Stack>
            <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
              <Icon color={getAggregatedStatusIcon(status).color === 'success' ? 'success' : 'primary'}>
                {getAggregatedStatusIcon(status).icon}
              </Icon>
              <KNTypography variant="displayXXS_SB" ml={1} color="dark.focus">
                {i18n.t(`shared.aggregatedStatus.${status}`)}
              </KNTypography>
            </Box>
            {eventsWithLocationDetails && eventsWithLocationDetails.length === 0 && (
              <Stack sx={{ display: 'flex', flexDirection: 'column', mt: 1, mb: -6, ml: 4.5 }}>
                <KNTypography variant="textLG" color="primary.light" sx={{ width: '50%' }}>
                  {translation.no_events}
                </KNTypography>
              </Stack>
            )}
            {eventsWithLocationDetails && view === 'timeline' && (
              <Box sx={{ mb: '16px !important', ml: '8px !important' }}>
                {eventsWithLocationDetails && eventsWithLocationDetails.length > 3 && (
                  <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
                    {eventsWithLocationDetails?.slice(0, 2).map((event, index) => {
                      return eventTemplate(event, index)
                    })}
                    <Collapse in={eventsCollapsed} mountOnEnter>
                      <>
                        {eventsWithLocationDetails
                          ?.slice(2, eventsWithLocationDetails.length - 1)
                          .map((event, i) => eventTemplate(event, 2 + i))}
                      </>
                    </Collapse>
                    {eventsWithLocationDetails
                      ?.slice(eventsWithLocationDetails.length - 1, eventsWithLocationDetails.length)
                      .map((event, index) => {
                        return eventTemplate(event, index, true)
                      })}
                  </Stack>
                )}
                {eventsWithLocationDetails && eventsWithLocationDetails.length <= 3 && (
                  <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
                    {eventsWithLocationDetails?.map((event, index) => {
                      return eventTemplate(event, index)
                    })}
                  </Stack>
                )}
              </Box>
            )}
            {eventsWithLocationDetails && view === 'date' && (
              <Box sx={{ ml: '8px !important' }}>
                <Stack sx={{ display: 'flex', flexDirection: 'column' }}>
                  {eventsGroupedOnDate?.map((eventDate, index) => {
                    return (
                      <Box key={index} my={1}>
                        <KNTypography variant="textLG_SB" color="primary">
                          {format(utcToZonedTime(new Date(eventDate.date), userTimezone), 'dd/MM/yyyy')}
                        </KNTypography>
                        <Box>
                          {eventDate?.events.map((event, index) => {
                            return eventTemplate(event, index, true, true)
                          })}
                        </Box>
                      </Box>
                    )
                  })}
                </Stack>
              </Box>
            )}
            <Box mt={view === 'timeline' ? 5 : 1} sx={{ display: 'inline-flex', alignItems: 'center' }}>
              <Icon color="primary">add</Icon>
              <KNTypography variant="displayXXS_SB" ml={1} color="dark.focus">
                {translation.shipment_created}
              </KNTypography>
              <Box ml={'auto'} color="info.dark" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <Icon sx={{ fontSize: '14px', mr: 0.5 }}>schedule</Icon>
                <KNTypography color="inherit">
                  {creationDate && format(utcToZonedTime(new Date(creationDate), userTimezone), 'dd/MM/yyyy HH:mm')}
                </KNTypography>
              </Box>
            </Box>
          </Stack>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {view === 'timeline' &&
            eventsCollapsed &&
            eventsWithLocationDetails &&
            eventsWithLocationDetails.length > 3 && (
              <KNButton
                variant="text"
                onClick={(): void => setEventsCollapsed(!eventsCollapsed)}
                dataAttribute="collapse-all-events"
                color="secondary"
              >
                {translation.collapseEventsButton} <ExpandLessIcon sx={{ ml: 1 }} />
              </KNButton>
            )}
          {view === 'timeline' &&
            !eventsCollapsed &&
            eventsWithLocationDetails &&
            eventsWithLocationDetails.length > 3 && (
              <KNButton
                variant="text"
                onClick={(): void => setEventsCollapsed(!eventsCollapsed)}
                dataAttribute="view-all-events"
                color="secondary"
              >
                {translation.viewAllEventsButton} <ExpandMoreIcon sx={{ ml: 1 }} />
              </KNButton>
            )}
          {eventsWithLocationDetails && eventsWithLocationDetails.length > 0 && (
            <Box ml="auto">
              <KNButton
                variant={view === 'date' ? 'contained' : 'text'}
                color="secondary"
                size="small"
                onClick={(): void => setView('date')}
              >
                {translation.date} {!isMobile && translation.view}
              </KNButton>
              <KNButton
                variant={view === 'timeline' ? 'contained' : 'text'}
                color="secondary"
                size="small"
                onClick={(): void => setView('timeline')}
              >
                {translation.timeline} {!isMobile && translation.view}
              </KNButton>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default EventTimeline
