import { useState, useEffect, useRef, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'

// @mui imports
import Stack from '@mui/material/Stack'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'
import InputLabel from '@mui/material/InputLabel'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import { getRouteName } from 'global/helpers/activeRoute'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import { processServerErrorMessages, processDefaultValues } from 'global/helpers/form'
import KNTooltipButton from 'components/KN_Molecules/KNTooltipButton/KNTooltipButton'
import { KNTooltipButtonHandle } from 'components/KN_Molecules/KNTooltipButton/types'
import KNClipboardField from 'components/KN_Molecules/KNClipboardField/KNClipboardField'
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNFormPhone from 'components/KN_Molecules/KNForm/KNFormPhone'
import { regexEmail } from 'global/helpers/validators'
import { TripData } from './TripDashboard.types'
import { getShareToken, shareTokenViaEmail, shareTokenViaSms } from './TripDashboard.service'

interface ShareDialogPayload {
  trip: TripData
  weblinkToken?: string
}

interface ShareDialogProps {
  payload: ShareDialogPayload
  open: boolean
  onAction: () => void
  onClose: () => void
}

export interface ShareViaEmailFormValues {
  weblink: string
  email: string
}

export interface ShareViaSmsFormValues {
  weblink: string
  phoneNumber: string
}

const ShareDialog = ({ payload, open, onAction, onClose }: ShareDialogProps): ReactElement => {
  const { t } = useTranslation()
  const location = useLocation()
  const {
    handleSubmit: handleSubmitEmail,
    reset: resetEmail,
    control: controlEmail,
    formState: formStateEmail,
    setError: setErrorEmail,
  } = useForm<ShareViaEmailFormValues>()
  const {
    handleSubmit: handleSubmitSms,
    reset: resetSms,
    control: controlSms,
    formState: formStateSms,
    getValues: getValuesSms,
    setError: setErrorSms,
  } = useForm<ShareViaSmsFormValues>()
  const emailButtonRef = useRef<KNTooltipButtonHandle>(null)
  const smsButtonRef = useRef<KNTooltipButtonHandle>(null)
  const [shareToken, setShareToken] = useState('')
  const [loading, setLoading] = useState(true)

  const fetchShareToken = async (): Promise<void> => {
    setLoading(true)
    const { weblinkToken } = await getShareToken(payload.trip.entityId, payload.weblinkToken)
    setShareToken(`${window.location.origin}/wl/${weblinkToken}`)
    setLoading(false)
  }

  useEffect(() => {
    if (open) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchShareToken()
      resetEmail(
        processDefaultValues({
          email: payload.trip.assignedDriver?.email,
        })
      )
      resetSms(
        processDefaultValues({
          phoneNumber: payload.trip.assignedDriver?.contactNumber,
        })
      )
    } else {
      setShareToken('')
    }
  }, [open])

  const onSubmitEmail: SubmitHandler<ShareViaEmailFormValues> = async (data: ShareViaEmailFormValues) => {
    try {
      await shareTokenViaEmail(payload.trip.entityId, { ...data, weblink: shareToken }, payload.weblinkToken)
      emailButtonRef.current?.showTooltip()
      analyticsEvent('polestar_cs_trip_shared', [getRouteName(location.pathname), 'email'])
    } catch (error) {
      setErrorEmail('root', processServerErrorMessages(error))
    }
  }

  const onSubmitSms: SubmitHandler<ShareViaSmsFormValues> = async (data: ShareViaSmsFormValues) => {
    try {
      await shareTokenViaSms(payload.trip.entityId, { ...data, weblink: shareToken }, payload.weblinkToken)
      smsButtonRef.current?.showTooltip()
      analyticsEvent('polestar_cs_trip_shared', [getRouteName(location.pathname), 'sms'])
    } catch (error) {
      setErrorSms('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNDialog
      open={open}
      onClose={onClose}
      preventClosing={formStateEmail.isSubmitting || formStateSms.isSubmitting}
      title={`${payload.trip.voyageNumber} — ${t('screens.cs.trip_dashboard.card.actions.share_trip')}`}
    >
      <KNDialogFormErrors errors={formStateEmail.errors?.root} />
      <KNDialogFormErrors errors={formStateSms.errors?.root} />
      <DialogContentText mb={3}>{t('screens.cs.trip_dashboard.share.message')}</DialogContentText>
      <Stack spacing={2}>
        <KNClipboardField
          value={shareToken}
          loading={loading}
          onAction={(): void => {
            analyticsEvent('polestar_cs_trip_shared', [getRouteName(location.pathname), 'link'])
          }}
        />
        <KNForm onSubmit={handleSubmitEmail(onSubmitEmail)}>
          <InputLabel>{t('screens.cs.trip_dashboard.share.via_email')}</InputLabel>
          <Stack
            data-test="email"
            spacing={{ xs: 0.5, sm: 1 }}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'start', sm: 'center' }}
          >
            <KNFormText
              name="email"
              placeholder={t('screens.cs.trip_dashboard.share.email_placeholder')}
              control={controlEmail}
              disabled={loading}
              rules={{
                pattern: {
                  value: regexEmail,
                  message: t('form.validation.invalid_email'),
                },
                required: t('form.validation.required'),
              }}
            />
            <KNTooltipButton
              ref={emailButtonRef}
              tooltip={t('form.sent')}
              variant="contained"
              size="small"
              color="secondary"
              disabled={loading}
              loading={formStateEmail.isSubmitting}
              onClick={handleSubmitEmail(onSubmitEmail)}
            >
              {t('form.send')}
            </KNTooltipButton>
          </Stack>
        </KNForm>
        <KNForm onSubmit={handleSubmitSms(onSubmitSms)}>
          <InputLabel>{t('screens.cs.trip_dashboard.share.via_sms')}</InputLabel>
          <Stack
            data-test="sms"
            spacing={{ xs: 0.5, sm: 1 }}
            direction={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'start', sm: 'center' }}
          >
            <KNFormPhone
              name="phoneNumber"
              placeholder={t('screens.cs.trip_dashboard.share.phone_placeholder')}
              control={controlSms}
              disabled={loading}
              rules={{
                required: t('form.validation.required'),
              }}
              getValues={getValuesSms}
            />
            <KNTooltipButton
              ref={smsButtonRef}
              tooltip={t('form.sent')}
              variant="contained"
              size="small"
              color="secondary"
              disabled={loading}
              loading={formStateSms.isSubmitting}
              onClick={handleSubmitSms(onSubmitSms)}
            >
              {t('form.send')}
            </KNTooltipButton>
          </Stack>
        </KNForm>
      </Stack>
    </KNDialog>
  )
}

export default ShareDialog
