// @mui imports //
import DeleteIcon from '@mui/icons-material/Delete'
import ModeIcon from '@mui/icons-material/Mode'
import Box from '@mui/material/Box'

// KN imports //
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Data //
import { sharingRulesTranslations } from 'screens/SharingRules/SharingRules.data'

// Functional //
import { getMappedConditions } from 'screens/SharingRuleCreateOrEdit/SharingRuleCreateOrEdit.helpers'

// Types //
import { ConditionGroup, ConditionGroupsTableAction, ConditionGroupsTableColumn } from './AccessGroupTable.types'

export const getColumns = (): ConditionGroupsTableColumn[] => {
  const { translation } = sharingRulesTranslations()

  return [
    {
      name: 'name',
      label: translation.name,
    },
    {
      name: 'userEmails',
      label: translation.users,
      getValue: (row: ConditionGroup): JSX.Element => <>{row.userEmails.join(', ')}</>,
    },
    {
      name: 'conditions',
      label: translation.conditions,
      getValue: (row: ConditionGroup): JSX.Element => {
        const mappedConditions = getMappedConditions(row)
        return (
          <Box>
            {mappedConditions.map((condition, i) => (
              <Box key={i} mt={1} display="flex" alignItems={'center'}>
                <Box sx={{ color: 'primary.main', display: 'flex', alignItems: 'center', fontSize: '20px' }}>
                  {condition.icon}
                </Box>
                <KNTypography color="black.main">
                  {condition.description} {condition.details} {condition.detailIcon}
                </KNTypography>
              </Box>
            ))}
          </Box>
        )
      },
    },
  ]
}

export const getActions = (onDelete, onEdit): ConditionGroupsTableAction[] => {
  const { translation } = sharingRulesTranslations()
  return [
    {
      name: 'delete',
      label: translation.delete,
      icon: <DeleteIcon />,
      onClick: onDelete,
    },
    {
      name: 'edit',
      label: translation.edit,
      icon: <ModeIcon />,
      onClick: onEdit,
    },
  ]
}
