import { useEffect, ReactElement, useState, useCallback, useMemo, useContext } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import i18n from 'i18n'
import ReactSwipe from 'react-swipe'
import FadeIn from 'react-fade-in/lib/FadeIn'
import { zonedTimeToUtc } from 'date-fns-tz'
import './ShipmentDetails.css'

// @mui material imports
import Box from '@mui/material/Box'
import CircleIcon from '@mui/icons-material/Circle'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import Fade from '@mui/material/Fade'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { Theme } from '@mui/material/styles/createTheme'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN Components
import theme from 'assets/theme'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// KN Modules
import AddressInfo from 'modules/AddressInfo/AddressInfo'
import AdvancedVisibilityFilter from 'modules/AdvancedVisibilityFilter/AdvancedVisibilityFilter'
import CargoInfo from 'modules/CargoInfo/CargoInfo'
import ETA from 'modules/ETA/ETA'
import EventTimeline from 'modules/EventTimeline/EventTimeline'
import GeneralInfoList from 'modules/GeneralInfoList/GeneralInfoList'
import ShipmentFiles from 'modules/ShipmentFiles/ShipmentFiles'
import TemperatureChart from 'modules/TemperatureChart/TemperatureChart'
import TrackingInfo from 'modules/TrackingInfo/TrackingInfo'
import VisibilityMap from 'modules/VisibilityMap/VisibilityMap'
import ShareShipmentDetailsErrorPage from 'screens/ShareShipmentDetails/ShareShipmentDetailsErrorPage'

// Context //
import { useInsightDetailsContext } from 'context/detailsNext/InsightDetails'
import { useNavigationContext } from 'context/navigation/NavigationContext'
import { useSearchContext } from 'context/search/SearchContext'
import { UserContext } from 'context/authentication/UserContext'

// Functional //
import { getMobileViewPillContent } from './ShipmentDetails.helpers'
import { analyticsEvent, analyticsPageView } from 'global/helpers/analytics'

// Data //
import { shipmentDetailsTranslations } from './ShipmentDetails.data'

// Types //
import ShipmentDetailsNextProps from './ShipmentDetails.types'
import { EntityType } from 'context/detailsNext/InsightDetails.types'

const ShipmentDetailsNext = ({ pinCode }: ShipmentDetailsNextProps): ReactElement => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'))
  const location = useLocation()

  // Context //
  const { userTimezone } = useContext(UserContext)

  const { shipmentid, entitytype, shipmentname, token } = useParams<{
    shipmentid: string
    shipmentname: string
    entitytype: string
    token: string
  }>()
  const entityType = entitytype === 'P' ? EntityType.pairing : EntityType.shipment
  const shipment = entityType === EntityType.shipment
  const pairing = entityType === EntityType.pairing
  const [swiperTimeout, setSwiperTimeout] = useState(true)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSwiperTimeout(false)
    }, 4000)

    return () => clearTimeout(timeoutId)
  }, [])

  // Additional context for logged in users //
  const dispatch = !token ? useNavigationContext().dispatch : null
  const navigationState = !token ? useNavigationContext().navigationState : null
  const dispatchSearchState = !token ? useSearchContext().dispatchSearchState : null

  const { dispatchInsightDetailsState, insightDetailsState, dataLoading } = useInsightDetailsContext()
  const { shareError } = insightDetailsState.insightDetailsContext

  const data = insightDetailsState.insightDetailsContext.data
  const trackingInfoProps = data?.trackingInfoProps
  const pairingId = data?.pairingId
  const pairedDevicesAvailable = data?.trackingInfoProps?.deviceIds && data?.trackingInfoProps?.deviceIds.length > 0

  // Translated Data //
  const { translation } = shipmentDetailsTranslations()

  // Module state //
  const filesAvailable = data?.filesProps && data?.filesProps.length > 0
  const devicesAvailable = trackingInfoProps?.deviceIds && trackingInfoProps.deviceIds?.length > 0
  const mapAvailable = data?.visibilityMapInfoProps && data?.visibilityMapInfoProps.length > 0
  const temperatureChartAvailable = data?.temperatureChartProps && data?.temperatureChartProps.length > 0
  const etaCompleted = data?.etaProps?.delivered

  const [activeExtra, setActiveExtra] = useState('cargo_info')
  useEffect(() => {
    if (pairing) {
      setActiveExtra('tracking_details')
    } else {
      filesAvailable ? setActiveExtra('attachments') : setActiveExtra('cargo_info')
    }
  }, [filesAvailable, pairing])

  const [activeAdvancedModule, setActiveAdvancedModule] = useState('')
  useEffect(() => {
    if (mapAvailable && temperatureChartAvailable) {
      setActiveAdvancedModule('combined_view')
    }
    if (mapAvailable && !temperatureChartAvailable) {
      setActiveAdvancedModule('map_view')
    }
    if (!mapAvailable && temperatureChartAvailable) {
      setActiveAdvancedModule('temperature_view')
    }
    if (!mapAvailable && !temperatureChartAvailable) {
      setActiveAdvancedModule('')
    }
  }, [mapAvailable, temperatureChartAvailable])
  const handleSetAdvancedModule = useCallback(
    (activeAdvancedModule: string) => {
      setActiveAdvancedModule(activeAdvancedModule)
    },
    [activeAdvancedModule]
  )
  const [mobileView, setMobileView] = useState('general_info')
  const handleSetMobileView = useCallback(
    (mobileView: string) => {
      setMobileView(mobileView)
    },
    [mobileView]
  )

  // Navigation / reset //
  useEffect(() => {
    analyticsPageView('polestar/cv/shipment_details')
    return () => {
      dispatchInsightDetailsState({
        type: 'setInsightDetailsProps',
        payload: {
          shipmentId: '',
          entityType: undefined,
        },
      })
    }
  }, [])

  useEffect(() => {
    dispatch &&
      navigationState &&
      dispatch({
        type: 'setNavigationProps',
        payload: {
          ...navigationState.navigationContext,
          breadcrumbHome: { name: 'Home', link: '/visibility-dashboard' },
          breadcrumbActive: translation.screenName,
          activeLink: `${shipmentname}`,
        },
      })
    dispatchSearchState?.({
      type: 'setSearchProps',
      payload: {
        activeContext: 'ShipmentSearch',
      },
    })
    dispatchInsightDetailsState({
      type: 'setInsightDetailsProps',
      payload: {
        ...insightDetailsState.insightDetailsContext,
        shipmentId: shipmentid,
        entityType: entityType,
        token: token,
        pinCode: pinCode,
      },
    })
  }, [location.pathname])

  const [filteredDateFrom, setFilteredDateFrom] = useState<Date | undefined>()
  const [filteredDateTo, setFilteredDateTo] = useState<Date | undefined>()
  const [temperaturePredictionToggle, setTemperaturePredictionToggle] = useState<boolean>(true)
  const [filteredDevices, setFilteredDevices] = useState<string[]>([])

  const handleSetTabValue = useCallback(
    (event: any, tabValue: string) => {
      setActiveExtra(tabValue)
    },
    [activeExtra]
  )

  const handleSetFilteredDevices = useCallback(
    (idValues: string[]) => {
      setFilteredDevices(idValues)
    },
    [filteredDevices]
  )

  const handleSetFilteredDateFrom = useCallback(
    (value: Date | undefined) => {
      setFilteredDateFrom(value)
    },
    [filteredDateFrom]
  )

  const handleSetFilteredDateTo = useCallback(
    (value: Date | undefined) => {
      setFilteredDateTo(value)
    },
    [filteredDateTo]
  )

  const handleSetTemperaturePredictionToggle = useCallback(
    (value: boolean) => {
      setTemperaturePredictionToggle(value)
    },
    [temperaturePredictionToggle]
  )

  const getAdditionalModulesTabs = useMemo(() => {
    const additionalModulesTabs = (): ReactElement => {
      return (
        <Tabs data-test="tab-modules" orientation={'horizontal'} value={activeExtra} onChange={handleSetTabValue}>
          {filesAvailable && (
            <Tab icon={<Icon>content_paste_go</Icon>} label={translation.attachments} value="attachments" />
          )}
          <Tab icon={<Icon>view_in_ar</Icon>} label={translation.cargo_info} value="cargo_info" />
          {devicesAvailable && (
            <Tab icon={<Icon>share_location</Icon>} label={translation.tracking_details} value="tracking_details" />
          )}
        </Tabs>
      )
    }
    return additionalModulesTabs
  }, [filesAvailable, devicesAvailable, activeExtra])

  const getadvancedModulesSwitch = useMemo(() => {
    const advancedModulesSwitch = (): ReactElement => {
      const switchItem = (module: string, label: string): ReactElement => {
        return (
          <KNButton
            onClick={(): void => {
              handleSetAdvancedModule(module)
              analyticsEvent('polestar_cv_shipment_details_next_module_set', [module])
            }}
            variant={activeAdvancedModule === module ? 'contained' : 'outlined'}
            color="primary"
          >
            {label}
          </KNButton>
        )
      }
      return (
        <Box sx={{ width: '100%' }}>
          <Stack data-test="views" direction="row" spacing={2}>
            {mapAvailable &&
              temperatureChartAvailable &&
              switchItem(
                'combined_view',
                isTablet ? translation.combined_view.split(' ')[0] : translation.combined_view
              )}
            {mapAvailable &&
              switchItem('map_view', isTablet ? translation.map_view.split(' ')[0] : translation.map_view)}
            {temperatureChartAvailable &&
              switchItem(
                'temperature_view',
                isTablet ? translation.temperature_view.split(' ')[0] : translation.temperature_view
              )}
          </Stack>
        </Box>
      )
    }
    return advancedModulesSwitch
  }, [mapAvailable, temperatureChartAvailable, activeAdvancedModule])

  const getDesktopInterface = (): ReactElement => {
    return (
      <FadeIn visible={!dataLoading} delay={200}>
        <Stack
          data-test="shipment-details-container"
          direction={'row'}
          justifyContent="space-between"
          alignItems="stretch"
          spacing={3}
          mt={!token ? 0 : 2}
          divider={
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                backgroundColor: ({ palette: { grey } }: Theme): string => `${grey[100]} !important`,
                my: ({ functions: { pxToRem } }: Theme): string => `${pxToRem(100) as number} !important`,
              }}
            />
          }
        >
          <Stack direction={'column'} sx={{ width: '50%', height: '100%' }} spacing={2.5} pl={4.5}>
            <GeneralInfoList />
            {!etaCompleted && <ETA />}
            <EventTimeline token={token} pinCode={pinCode} />
            <AddressInfo />
            <Box>
              {shipment && getAdditionalModulesTabs()}
              <Box
                sx={{
                  width: '100%',
                  overflow: 'hidden',
                  backgroundColor: ({ palette: { light } }: Theme): string => light.light,
                  borderRadius: ({ borders: { borderRadius } }: Theme): string | number => borderRadius.lg,
                  padding: 2,
                  minHeight: ({ functions: { pxToRem } }: Theme): string => pxToRem(200),
                }}
              >
                {shipment && (
                  <>
                    <Fade in={activeExtra === 'attachments'}>
                      <Box sx={{ display: activeExtra !== 'attachments' ? 'none' : 'block' }}>
                        <ShipmentFiles />
                      </Box>
                    </Fade>
                    <Fade in={activeExtra === 'cargo_info'}>
                      <Box sx={{ display: activeExtra !== 'cargo_info' ? 'none' : 'block' }}>
                        <CargoInfo />
                      </Box>
                    </Fade>
                    <Fade in={activeExtra === 'tracking_details'}>
                      <Box sx={{ display: activeExtra !== 'tracking_details' ? 'none' : 'block' }}>
                        <TrackingInfo token={token} pinCode={pinCode} />
                      </Box>
                    </Fade>
                  </>
                )}
                {pairing && (
                  <Fade in={activeExtra === 'tracking_details'}>
                    <Box sx={{ display: activeExtra !== 'tracking_details' ? 'none' : 'block' }}>
                      <TrackingInfo token={token} pinCode={pinCode} />
                    </Box>
                  </Fade>
                )}
              </Box>
            </Box>
          </Stack>
          <Stack direction={'column'} sx={{ width: '50%', height: '100vh', position: 'relative' }} spacing={3}>
            {mapAvailable && temperatureChartAvailable && getadvancedModulesSwitch()}
            <>
              <VisibilityMap
                filteredIds={filteredDevices}
                filteredDateFrom={filteredDateFrom}
                filteredDateTo={filteredDateTo}
                size={activeAdvancedModule === 'combined_view' ? 'half' : 'full'}
                visible={activeAdvancedModule === 'temperature_view' ? false : true}
              />
              <TemperatureChart
                filteredIds={filteredDevices}
                filteredDateFrom={filteredDateFrom}
                filteredDateTo={filteredDateTo}
                temperaturePredictionToggle={temperaturePredictionToggle}
                visible={activeAdvancedModule === 'map_view' ? false : true}
              />
            </>
            {(mapAvailable || temperatureChartAvailable) && pairedDevicesAvailable && (
              <AdvancedVisibilityFilter
                filteredIds={filteredDevices}
                changeDevicesFilters={(idValues: string[]): void => handleSetFilteredDevices(idValues)}
                changeFilteredDateFrom={(value?: Date | undefined): void => {
                  const timeZonedDateFrom = value ? zonedTimeToUtc(value, userTimezone) : undefined
                  handleSetFilteredDateFrom(timeZonedDateFrom)
                }}
                changeFilteredDateTo={(value?: Date | undefined): void => {
                  const timeZonedDateTo = value ? zonedTimeToUtc(value, userTimezone) : undefined
                  handleSetFilteredDateTo(timeZonedDateTo)
                }}
                changeTemperaturePredictionToggle={(value?: boolean): void =>
                  handleSetTemperaturePredictionToggle(value ?? false)
                }
                position={activeAdvancedModule === 'map_view' ? 'absolute' : 'relative'}
              />
            )}
          </Stack>
        </Stack>
      </FadeIn>
    )
  }

  const getMobileViewPill = useMemo(() => {
    const mapVisible =
      pairingId || data?.addressInfoProps?.delivery.address.location || data?.addressInfoProps?.pickup.address.location
    let screens: any
    if (shipment) {
      screens = ['general_info', 'event_timeline', 'additional_info']
    }
    if (pairing) {
      screens = ['additional_info']
    }
    if (mapVisible || temperatureChartAvailable) {
      screens = [...screens, 'advanced_features']
    }
    if (pairing && !mapAvailable && !temperatureChartAvailable) {
      screens = ['additional_info']
    }

    const Swiper = (): JSX.Element => {
      const swiperStyle: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        bottom: '15%',
        position: 'relative',
        WebkitAnimation: 'linear infinite',
        WebkitAnimationName: 'run',
        WebkitAnimationDuration: '1s',
        paddingRight: '15px',
      }

      const runKeyframes = `
        @-webkit-keyframes run {
          0% {
            left: 0;
          }
          50% {
            left: 60%;
          }
          100% {
            left: 0;    
          }
        }
      `

      return (
        <div>
          <style>{runKeyframes}</style>
          <div id="swiper" style={swiperStyle}>
            <KeyboardDoubleArrowRightIcon
              fontSize="small"
              sx={{ color: ({ palette: { white } }: Theme): string => white.main }}
            />
          </div>
        </div>
      )
    }

    const mobileViewPill = (): ReactElement => {
      return (
        <Box sx={{ position: 'fixed', bottom: 10, display: 'flex', justifyContent: 'center', width: '90%' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: ({ palette: { primary } }: Theme): string => primary.light,
              px: 2.5,
              py: 1,
              borderRadius: ({ borders: { borderRadius } }: Theme): string | number => borderRadius.round,
              height: '40px',
              opacity: 0.7,
            }}
          >
            <KNTypography variant="textLG_SB" color="white">
              {i18n.t(
                `screens.cv.shipment_details.mobile_views.${getMobileViewPillContent(mobileView, screens).activeScreen}`
              )}
            </KNTypography>
            <Box ml={1} sx={{ display: 'flex', alignItems: 'center' }}>
              {swiperTimeout ? (
                <FadeIn visible={swiperTimeout}>
                  <Swiper />
                </FadeIn>
              ) : (
                getMobileViewPillContent(mobileView, screens).allScreens.map((screen, i) => (
                  <FadeIn key={i} visible={!swiperTimeout}>
                    <CircleIcon
                      key={i}
                      sx={{
                        height: 10,
                        width: 10,
                        mx: 0.25,
                        color: ({ palette: { grey, white } }: Theme): string =>
                          screen === mobileView ? white.main : grey[300],
                      }}
                    />
                  </FadeIn>
                ))
              )}
            </Box>
          </Box>
        </Box>
      )
    }
    return mobileViewPill
  }, [mapAvailable, temperatureChartAvailable, mobileView, swiperTimeout])

  let reactSwipeEl: ReactSwipe | null
  const swipeOptions = useMemo(
    () => ({
      continuous: false,
      transitionEnd(index: number, e: any): void {
        handleSetMobileView(e.id)
      },
    }),
    []
  )

  const getMobileInterface = (): ReactElement => {
    const getGeneralInfo = (): ReactElement => {
      return (
        <div id="general_info">
          <Stack direction={'column'} spacing={2.5}>
            {!etaCompleted && <ETA />}
            <AddressInfo />
          </Stack>
        </div>
      )
    }
    const getEventTimeline = (): ReactElement => {
      return (
        <div id="event_timeline">
          <Stack direction={'column'} spacing={2.5}>
            <EventTimeline token={token} pinCode={pinCode} />
          </Stack>
        </div>
      )
    }
    const getAdditionalInfo = (): ReactElement => {
      return (
        <div id="additional_info">
          <Stack direction={'column'} spacing={2.5}>
            {filesAvailable && <ShipmentFiles />}
            <CargoInfo />
            {pairedDevicesAvailable && <TrackingInfo token={token} pinCode={pinCode} />}
          </Stack>
        </div>
      )
    }
    const getAdvancedModules = (): ReactElement => {
      return (
        <div id="advanced_features">
          <Stack direction={'column'} spacing={2.5}>
            <VisibilityMap
              filteredIds={filteredDevices}
              filteredDateFrom={filteredDateFrom}
              filteredDateTo={filteredDateTo}
              size={temperatureChartAvailable && mapAvailable ? 'mobile' : 'full'}
              visible={true}
            />
            <TemperatureChart
              filteredIds={filteredDevices}
              filteredDateFrom={filteredDateFrom}
              filteredDateTo={filteredDateTo}
              temperaturePredictionToggle={temperaturePredictionToggle}
              visible={true}
            />
            {pairedDevicesAvailable && (mapAvailable || temperatureChartAvailable) && (
              <AdvancedVisibilityFilter
                filteredIds={filteredDevices}
                changeDevicesFilters={(idValues: string[]): void => handleSetFilteredDevices(idValues)}
                changeFilteredDateFrom={(value?: Date | undefined): void =>
                  handleSetFilteredDateFrom(value ?? undefined)
                }
                changeFilteredDateTo={(value?: Date | undefined): void => handleSetFilteredDateTo(value ?? undefined)}
                changeTemperaturePredictionToggle={(value?: boolean): void =>
                  handleSetTemperaturePredictionToggle(value ?? false)
                }
                position={'relative'}
              />
            )}
          </Stack>
        </div>
      )
    }

    return (
      <FadeIn visible={!dataLoading} delay={200}>
        <Stack direction={'column'} spacing={2.5} px={2} sx={{ height: '95vh' }}>
          <GeneralInfoList />
          <ReactSwipe
            className="shipment_details_swiper"
            swipeOptions={swipeOptions}
            ref={(el: ReactSwipe | null): ReactSwipe | null => (reactSwipeEl = el)}
          >
            {shipment && getGeneralInfo()}
            {shipment && getEventTimeline()}
            {(shipment || pairingId) && getAdditionalInfo()}
            {getAdvancedModules()}
          </ReactSwipe>
        </Stack>
        {getMobileViewPill()}
      </FadeIn>
    )
  }

  if (shareError) return <ShareShipmentDetailsErrorPage />
  if (dataLoading)
    return (
      <CircularProgress
        data-test="loader"
        size={100}
        sx={{ position: 'absolute', top: '25%', left: isMobile ? '35%' : '50%' }}
      />
    )
  else {
    return <>{isMobile ? getMobileInterface() : getDesktopInterface()}</>
  }
}

export default ShipmentDetailsNext
