import { ReactElement, cloneElement } from 'react'

// @mui imports
import { Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Tooltip from '@mui/material/Tooltip'

// KN Components
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

export interface PredefinedFiltersItemProps {
  label: string
  icon: ReactElement
  counter: number
  tooltip: string
  active?: boolean
  onClick: () => void
}

const PredefinedFiltersItem = ({
  label,
  icon,
  counter,
  tooltip,
  active = false,
  onClick,
}: PredefinedFiltersItemProps): ReactElement => (
  <Tooltip title={tooltip} arrow enterDelay={1000}>
    <Box
      data-test="predefined-filter-item"
      paddingX={2}
      paddingY={1}
      sx={{
        cursor: 'pointer',
        borderRadius: ({ borders: { borderRadius } }: Theme) => borderRadius.lg,
        ...(active
          ? {
              backgroundColor: 'primary.light',
              '& .MuiAvatar-root': {
                backgroundColor: 'white.main',
              },
              '& .MuiTypography-textMD_SB, & .MuiTypography-displayLG_SB': {
                color: 'white.main',
              },
            }
          : {
              '&:hover': {
                backgroundColor: 'light.main',
              },
            }),
      }}
      onClick={onClick}
    >
      <Avatar
        sx={{
          backgroundColor: 'primary.light',
        }}
      >
        {cloneElement(icon, {
          color: 'primary',
          fontSize: 'medium',
        })}
      </Avatar>
      <KNTypography component="div" variant="textMD_SB" color="primary.light" marginTop={1} marginBottom={0.5}>
        {label}
      </KNTypography>
      <KNTypography data-test="counter-number" component="div" variant="displayLG_SB" color="text.main">
        {counter}
      </KNTypography>
    </Box>
  </Tooltip>
)

export default PredefinedFiltersItem
