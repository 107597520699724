import { useState, useEffect, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, ValidateResult } from 'react-hook-form'

// @mui imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import InfoIcon from '@mui/icons-material/Info'

// KN imports
import i18n from 'i18n'
import { humanReadableBytes } from 'global/helpers/numberFormatters'
import KNCaption from 'components/KN_Molecules/KNCaption/KNCaption'
import KNFormFile from 'components/KN_Molecules/KNForm/KNFormFile'
import { StopData } from 'screens/TripDetails/TripDetails.types'
import { UploadFileResponse, uploadFile, minFileSize, maxFileSize } from './StatusManager.service'

interface DocumentsUploadFieldsPayload {
  stop: StopData
  weblinkToken?: string
  noPhotos?: boolean
}

interface DocumentsUploadFieldsProps {
  payload: DocumentsUploadFieldsPayload
}

const DocumentsUploadFields = ({ payload }: DocumentsUploadFieldsProps): ReactElement => {
  const { t } = useTranslation()
  const { control, setValue, getValues, setError, clearErrors } = useFormContext()

  const processFile = async (file): Promise<UploadFileResponse> => {
    const uploadedFile = await uploadFile(file, payload.weblinkToken)
    // NOTE: if the flag is missing, assume it's a proper document so it won't display a warning message
    if (uploadedFile.isDocument === undefined) {
      uploadedFile.isDocument = true
    }
    return uploadedFile
  }

  return (
    <Stack data-test="file-container" spacing={2}>
      {['PUP', 'DEL'].includes(payload.stop.type) && (
        <Stack data-test="attach-proof-delivery" spacing={0.5}>
          <KNFormFile
            label={
              payload.stop.type === 'PUP'
                ? t('screens.cs.trip_details.upload_document.attach_pop')
                : t('screens.cs.trip_details.upload_document.attach_pod')
            }
            name={payload.stop.type === 'PUP' ? 'popFiles' : 'podFiles'}
            control={control}
            setError={setError}
            clearErrors={clearErrors}
            setValue={setValue}
            disabled={payload.stop.isProofAttached || getValues('paperless')}
            accept="image/jpeg, image/png, application/pdf"
            size="small"
            multiple
            processFile={processFile}
            minFileSize={minFileSize}
            maxFileSize={maxFileSize}
            validateDocuments
          />
          {payload.stop.isProofAttached && (
            <KNCaption icon={<InfoIcon />}>
              {t('screens.cs.trip_details.upload_document.proof_already_attached')}
            </KNCaption>
          )}
          {getValues('paperless') && (
            <KNCaption icon={<InfoIcon />}>
              {t('screens.cs.trip_details.upload_document.proof_disabled_for_paperless')}
            </KNCaption>
          )}
        </Stack>
      )}
      {!payload.noPhotos && (
        <KNFormFile
          label={t('screens.cs.trip_details.upload_document.attach_photos')}
          name="photoFiles"
          control={control}
          setError={setError}
          clearErrors={clearErrors}
          setValue={setValue}
          accept="image/jpeg, image/png, application/pdf"
          size="small"
          multiple
          processFile={processFile}
          minFileSize={minFileSize}
          maxFileSize={maxFileSize}
        />
      )}
      {payload.stop.palletsDocumentRequired && (
        <Stack data-test="attach-pallet-slip" spacing={0.5}>
          <KNFormFile
            label={t('screens.cs.trip_details.upload_document.attach_pallet_slip')}
            name="palletFile"
            control={control}
            setError={setError}
            clearErrors={clearErrors}
            setValue={setValue}
            disabled={payload.stop.palletsDocumentAttached}
            accept="image/jpeg, image/png, application/pdf"
            size="small"
            processFile={processFile}
            minFileSize={minFileSize}
            maxFileSize={maxFileSize}
            validateDocuments
          />
          {payload.stop.palletsDocumentAttached && (
            <KNCaption icon={<InfoIcon />}>
              {t('screens.cs.trip_details.upload_document.pallet_slip_already_attached')}
            </KNCaption>
          )}
        </Stack>
      )}
    </Stack>
  )
}

export default DocumentsUploadFields
