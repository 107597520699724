export enum IconName {
  user = 'user',
  user_circle = 'user_circle',
  password = 'password',
  search = 'search',
  shipmentid = 'shipmentid',
  knid = 'knid',
  pairing = 'pairing',
  unpairing = 'unpairing',
  awaiting_pickup = 'awaiting_pickup',
  in_progress = 'in_progress',
  delivered = 'delivered',
  delivery_address = 'delivery_address',
  pickup_address = 'pickup_address',
  cancel = 'cancel',
  menu = 'menu',
  logout = 'logout',
  home = 'home',
  pair = 'pair',
  inventory = 'inventory',
  add = 'add',
  check_circle = 'check_circle',
  delete = 'delete',
  delete_circle = 'delete_circle',
  barcode = 'barcode',
  qrcode = 'qrcode',
  pin = 'pin',
  open_shipment = 'open_shipment',
  info_circle = 'info_circle',
  at = 'at',
  warning = 'warning',
  health = 'health',
  favorite = 'favorite',
  follow = 'follow',
  table_pin = 'table_pin',
  ellipsis = 'ellipsis',
  min_temp = 'min_temp',
  max_temp = 'max_temp',
  text = 'text',
  weight = 'weight',
  volume = 'volume',
  pkg_number = 'pkg_number',
  link = 'link',
  link_off = 'link_off',
}

export enum IconSize {
  small = '20px',
  medium = '35px',
  large = '50px',
  extraLarge = '70px',
}

export default interface IconProps {
  name: IconName
  color?: any
  colorType?: 'main' | 'dark' | null
  size?: IconSize
}
