import { useEffect, useState } from 'react'

// @mui imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

// @KN Components
import Icon from 'components/KN_Components/Base/Icons/Icon'
import { IconSize } from 'components/KN_Components/Base/Icons/Icon.type'
import KNInput from 'components/KN_Components/Base/KNInput/KNInput'
import KNSlider from 'components/KN_Components/Base/KNSlider/KNSlider'

// Types
import KNTextSliderProps from './types'

const KNTextSlider: React.FC<KNTextSliderProps> = ({
  icon,
  getValue,
  value,
  disabled,
}) => {
  const [sliderValue, setSliderValue] = useState<
    number | string | (number | string)[]
  >(value ?? 0)

  const handleSliderChange = (
    event: Event,
    newValue: number | number[]
  ): void => {
    setSliderValue(newValue)
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setSliderValue(event.target.value === '' ? '' : Number(event.target.value))
  }

  const handleBlur = (): void => {
    if (sliderValue < 0) {
      setSliderValue(0)
    } else if (sliderValue > 100) {
      setSliderValue(100)
    }
  }

  useEffect(() => {
    getValue(sliderValue)
  }, [sliderValue])

  return (
    <Box>
      <Grid container spacing={2} alignItems="center">
        {icon && (
          <Grid item>
            <Icon name={icon} size={IconSize.medium} />
          </Grid>
        )}
        <Grid item xs>
          <KNSlider
            value={typeof value === 'number' ? value : 0}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            min={-50}
            max={50}
            disabled={disabled}
          />
        </Grid>
        <Grid item>
          <KNInput
            value={value}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 1,
              min: 0,
              max: 100,
              type: 'number',
              disabled: { disabled },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default KNTextSlider
