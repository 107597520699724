import { ReactElement, useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import FadeIn from 'react-fade-in'

// @mui imports
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'

// KN imports
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import MonitoringRulesTable from 'modules/MonitoringRulesTable/MonitoringRulesTable'

// Context
import { UserContext } from 'context/authentication/UserContext'

// Functional
import { notificationCenterTranslations } from './NotificationCenter.data'
import { analyticsEvent } from 'global/helpers/analytics'

const NotificationCenter = (): ReactElement => {
  const history = useHistory()

  // Context //
  const { userTimezone } = useContext(UserContext)

  // Translated data //
  const { translation } = notificationCenterTranslations()

  return (
    <Container maxWidth={false} data-test="notification-center-container">
      <FadeIn>
        <Paper elevation={0} sx={{ padding: 2 }}>
          <Stack
            spacing={1}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems={{ xs: 'start', md: 'center' }}
            mb={2}
          >
            <KNTypography variant="displayMD_SB" color="primary.focus">
              {translation.screenName}
            </KNTypography>
            <KNButton
              onClick={(): void => {
                history.push('/notification-center/new')
                analyticsEvent('polestar_cv_create_monitoring_rule_button_clicked')
              }}
              data-test="add-new-monitoring-rule-button"
              variant="contained"
              color="secondary"
              size="small"
            >
              {translation.addNew}
            </KNButton>
          </Stack>
          <MonitoringRulesTable userTimezone={userTimezone} />
        </Paper>
      </FadeIn>
    </Container>
  )
}

export default NotificationCenter
