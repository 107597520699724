import { ReactElement } from 'react'

// @mui material imports //
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Theme } from '@mui/material/styles/createTheme'

// KN Components //
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Types //
import { CargoDetailsProps } from 'context/detailsNext/InsightDetails.types'

// Data //
import { cargoInfoTranslations } from './CargoInfo.data'

const CargoDetailsItemTemplate = (cargoDetailsItem: CargoDetailsProps, index: number): ReactElement => {
  // Translated Data //
  const { translation } = cargoInfoTranslations()

  const cargoValueTemplate = (value: string | number, property: string): ReactElement => {
    return (
      <Box>
        <KNTypography variant="textLG" color="dark.main" sx={{ display: 'block' }}>
          {value}
        </KNTypography>
        <KNTypography variant="textMD" color="primary.light" sx={{ display: 'block', mt: -1 }}>
          {property}
        </KNTypography>
      </Box>
    )
  }

  return (
    <Grid container data-test={`cargo-info-details-item-${index}`} spacing={2}>
      <Grid item xs={5} md={5} lg={3}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              height: 20,
              width: 20,
              backgroundColor: ({ palette: { primary } }: Theme): string => primary.light,
              color: ({ palette: { white } }: Theme): string => white.main,
              mr: 1,
            }}
          >
            <KNTypography variant="textMD" color="white.main">
              {index + 1}
            </KNTypography>
          </Box>
          {cargoDetailsItem.numberOfPackages !== null &&
            cargoValueTemplate(
              `${String(cargoDetailsItem.numberOfPackages)} ${String(translation.items)}`,
              translation.number_of_items
            )}
        </Box>
      </Grid>
      <Grid item xs={3} md={3} lg={2}>
        {cargoDetailsItem.grossWeightInKg !== null &&
          cargoValueTemplate(`${String(cargoDetailsItem.grossWeightInKg)} kg`, translation.weight)}
      </Grid>
      <Grid item xs={3} md={3} lg={2}>
        {cargoDetailsItem.volumeInMtq !== null &&
          cargoValueTemplate(`${String(cargoDetailsItem.volumeInMtq)} m3`, translation.volume)}
      </Grid>
    </Grid>
  )
}

export default CargoDetailsItemTemplate
