import { Role } from './Role.types'

export enum CompanyType {
  Carrier = 'Carrier',
  Customer = 'Customer',
  Operator = 'Operator',
}

export enum VisibilityLevel {
  Basic = 'Basic',
  Advanced = 'Advanced',
  Max = 'Max',
}

export interface CompanyModules {
  location: boolean
  temperature: boolean
  temperature_prediction: boolean
  eta: boolean
}

export interface Company {
  cid: string
  type: CompanyType
  displayName: string
  customerIdentifiers: string[]
  role?: Role
  properties?: {
    visibilityLevel?: VisibilityLevel
    address?: string
  }
  modules: CompanyModules
}
