import { ReactElement, useEffect, useState } from 'react'
import FadeIn from 'react-fade-in'

// @mui imports //
import Box from '@mui/material/Box'
import { Theme } from '@mui/material/styles'
import ModeIcon from '@mui/icons-material/Mode'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import useMediaQuery from '@mui/material/useMediaQuery'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Autocomplete from '@mui/material/Autocomplete'
import Chip from '@mui/material/Chip'
import Checkbox from '@mui/material/Checkbox'

// KN imports //
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import theme from 'assets/theme'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'

// Modules //
import OptionStep from './OptionStep'
import AddressStep from './AddressStep'
import AggregatedStatusStep from './AggregatedStatusStep'

// Data //
import { MegaFormTranslations } from './MegaForm.data'

// Types //
import { AddressField, MegaFormProps, OptionStepProps } from './MegaForm.types'

const MegaForm = ({
  steps,
  picked,
  editMode,
  errors,
  submit,
  resetForm,
  submitted,
  disableSubmit,
}: MegaFormProps): ReactElement => {
  const [stepNumber, setStepNumber] = useState<number>(0)
  const [continueButtonDisabled, setContinueButtonDisabled] = useState<boolean>(false)
  const [optionsChosen, setOptionsChosen] = useState<number[]>([])
  const [currentOption, setCurrentOption] = useState<OptionStepProps>()
  const [currentAddress, setCurrentAddress] = useState<AddressField>({})
  const [currentStatus, setCurrentStatus] = useState<string>()

  const { translation } = MegaFormTranslations()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const lastStepIndex = steps.findIndex((step) => step.type === 'last_step')

  useEffect(() => {
    if (editMode) setStepNumber(lastStepIndex)
  }, [])

  useEffect(() => {
    setOptionsChosen(picked.map((option) => option.optionId))
  }, [picked])

  useEffect(() => {
    if (
      (steps[stepNumber].type === 'option' && optionsChosen.length === 0) ||
      (steps[stepNumber].type === 'address' &&
        !currentAddress?.name &&
        !currentAddress?.address &&
        !currentAddress?.additionalAddress &&
        !currentAddress?.city &&
        !currentAddress?.postalCode &&
        !currentAddress?.country) ||
      (steps[stepNumber].type === 'aggregated_status' && currentStatus === undefined)
    )
      setContinueButtonDisabled(true)
    else setContinueButtonDisabled(false)
  }, [stepNumber, optionsChosen, currentAddress, currentStatus])

  useEffect(() => {
    steps[stepNumber].type === 'address' ? setCurrentAddress(steps[stepNumber].value) : setCurrentAddress({})
    steps[stepNumber].type === 'aggregated_status'
      ? setCurrentStatus(steps[stepNumber].value)
      : setCurrentStatus(undefined)
  }, [stepNumber])

  // COLOR LIST //
  const colors = theme.palette
  const colorsForPickedBoxes = [
    colors.primary.main,
    colors.secondary.main,
    colors.primary.light,
    colors.primary.dark,
    colors.info.main,
  ]

  return (
    <Box>
      {errors.length > 0 && (
        <DialogContentText component="div" mb={3}>
          <Alert severity="error">
            {errors.map((error, i) => (
              <Box key={i}>{error}</Box>
            ))}
          </Alert>
        </DialogContentText>
      )}
      <FadeIn>
        {steps[stepNumber] && (
          <Box sx={{ width: isMobile ? '100%' : '50%' }}>
            {stepNumber === lastStepIndex ? (
              <Box>
                {picked.map((pickedElement, i) => (
                  <Box
                    key={i}
                    sx={{
                      padding: 2,
                      color: ({ palette: { white } }: Theme): string => white.main,
                      borderRadius: ({ borders: { borderRadius } }: Theme): string | number => borderRadius.lg,
                      backgroundColor: colorsForPickedBoxes[i],
                      boxShadow: ({ boxShadows }: Theme): string => boxShadows.md,
                      cursor: pickedElement.goToStep !== lastStepIndex ? 'pointer' : 'auto',
                    }}
                    display="flex"
                    justifyContent="space-between"
                    my={2}
                  >
                    <Box display="flex" onClick={(): void => setStepNumber(pickedElement.goToStep)}>
                      <Box mr={1} sx={{ fontSize: '40px', display: 'flex', alignItems: 'center' }}>
                        {pickedElement.icon}
                      </Box>
                      <Box display="flex" flexDirection="column" justifyContent="center">
                        <KNTypography variant="text_LG" color="white.main">
                          {pickedElement.description}
                        </KNTypography>
                        <KNTypography variant="text_LG" color="white.main" sx={{ mt: 1 }}>
                          {pickedElement.details} {pickedElement.detailIcon}
                        </KNTypography>
                      </Box>
                    </Box>
                    <IconButton onClick={pickedElement.remove}>
                      <CloseIcon sx={{ color: 'white.main' }} />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            ) : (
              currentOption && (
                <Box>
                  <Box
                    sx={{
                      padding: 2,
                      color: ({ palette: { white } }: Theme): string => white.main,
                      borderRadius: ({ borders: { borderRadius } }: Theme): string | number => borderRadius.lg,
                      backgroundColor: colorsForPickedBoxes[0],
                      boxShadow: ({ boxShadows }: Theme): string => boxShadows.md,
                      cursor: 'pointer',
                    }}
                    display="flex"
                    justifyContent="space-between"
                    my={2}
                  >
                    <Box
                      display="flex"
                      onClick={(): void => {
                        setCurrentOption(undefined)
                        setStepNumber(steps[stepNumber].goBackToStep)
                      }}
                    >
                      <Box mr={1} sx={{ fontSize: '40px', display: 'flex', alignItems: 'center' }}>
                        {currentOption.icon}
                      </Box>
                      <Box display="flex" flexDirection="column" justifyContent="center">
                        <KNTypography variant="text_LG" color="white.main">
                          {currentOption.description}
                        </KNTypography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )
            )}
            {stepNumber === lastStepIndex && steps[stepNumber].addMoreText && (
              <Box
                sx={{ cursor: 'pointer' }}
                my={2}
                onClick={(): void => {
                  setCurrentOption(undefined)
                  setStepNumber(0)
                }}
              >
                <Divider>
                  <Box display="flex" alignItems="center" mx={1}>
                    <AddIcon sx={{ color: 'secondary.main', mr: 1 }} />
                    <KNTypography variant="text_LG" color="secondary.main">
                      {steps[stepNumber].addMoreText}
                    </KNTypography>
                  </Box>
                </Divider>
              </Box>
            )}
            <Box display="flex" alignItems="center">
              {stepNumber !== lastStepIndex && (
                <Box
                  sx={{
                    mr: 1,
                    width: '50px',
                    height: '50px',
                    borderRadius: ({ borders: { borderRadius } }: Theme): string | number => borderRadius.lg,
                    backgroundColor: ({ palette: { primary } }: Theme): string => primary.main,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <KNTypography variant="displayMD_SB" color="white.main">
                    {steps[stepNumber].stepLevel}
                  </KNTypography>
                </Box>
              )}
              {steps[stepNumber].titleIcon && steps[stepNumber].titleIcon}
              <KNTypography variant="displaySM_SB" color="black.main">
                {steps[stepNumber].title}
              </KNTypography>
            </Box>
            <Box my={2} ml={6}>
              {steps[stepNumber].type === 'option' &&
                steps[stepNumber].options &&
                steps[stepNumber].options?.map((option, i) => (
                  <OptionStep
                    key={i}
                    option={option}
                    setStepNumber={setStepNumber}
                    optionsChosen={optionsChosen}
                    setCurrentOption={setCurrentOption}
                  />
                ))}
              {steps[stepNumber].type === 'address' && (
                <AddressStep currentAddress={currentAddress} setCurrentAddress={setCurrentAddress} />
              )}
              {steps[stepNumber].type === 'aggregated_status' && (
                <AggregatedStatusStep currentStatus={currentStatus} setCurrentStatus={setCurrentStatus} />
              )}
            </Box>
            <Box my={2}>
              {steps[stepNumber].customFields?.map((field, i) =>
                field.type === 'text' ? (
                  <Box mt={1} key={i}>
                    <KNTypography variant="text_LG" color="black.main">
                      {field.label}
                    </KNTypography>
                    <TextField fullWidth value={field.value} onChange={field.onSet} placeholder={field.placeholder} />
                  </Box>
                ) : field.type === 'multiselect' && field.options ? (
                  <Box mt={1} key={i}>
                    <KNTypography variant="text_LG" color="black.main">
                      {field.label}
                    </KNTypography>
                    <Autocomplete
                      multiple
                      options={field.options}
                      disableCloseOnSelect
                      getOptionLabel={(option): string => option.label}
                      value={field.value ?? []}
                      onChange={(event, newValue): void => {
                        field.onSet(newValue)
                      }}
                      renderTags={(tagValue, getTagProps): ReactElement[] =>
                        tagValue.map((option, index) => (
                          // eslint-disable-next-line react/jsx-key
                          <Chip {...getTagProps({ index })} size="small" label={option.label} />
                        ))
                      }
                      renderOption={(props, option): ReactElement => {
                        const selected = field.value.findIndex((element) => element.value === option.value) > -1
                        return (
                          <Box component="li" {...props} key={option.value}>
                            <Checkbox checked={selected} value={selected} />
                            {option.label}
                          </Box>
                        )
                      }}
                      renderInput={(params): ReactElement => <TextField {...params} placeholder={field.placeholder} />}
                    />
                  </Box>
                ) : null
              )}
            </Box>
            {steps[stepNumber].stepLevel < 3 && (
              <Box display="flex" alignItems="center" my={2}>
                <Box
                  sx={{
                    mr: 1,
                    width: '50px',
                    height: '50px',
                    borderRadius: ({ borders: { borderRadius } }: Theme): string | number => borderRadius.lg,
                    backgroundColor: ({ palette: { grey } }: Theme): string => grey[200],
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <KNTypography variant="displayMD_SB" color="grey.400">
                    {Number(steps[stepNumber].stepLevel) + 1}
                  </KNTypography>
                </Box>
                {steps[stepNumber].titleIcon && steps[stepNumber].titleIcon}
                <KNTypography variant="displaySM_SB" color="grey.400">
                  {steps[stepNumber].stepLevel === 1 ? translation.details : translation.finalize}
                </KNTypography>
              </Box>
            )}
            {stepNumber < lastStepIndex && (
              <Grid container>
                <Grid item xs={6}>
                  {stepNumber > 0 && stepNumber < lastStepIndex && (
                    <Box display="flex" justifyContent="flex-start">
                      <KNButton
                        variant="text"
                        size="small"
                        startIcon={<ArrowBackIcon />}
                        onClick={(): void => {
                          setCurrentOption(undefined)
                          setStepNumber(steps[stepNumber].goBackToStep)
                        }}
                      >
                        {translation.buttonBack}
                      </KNButton>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Box display="flex" justifyContent="flex-end">
                    <KNButton
                      variant="outlined"
                      size="small"
                      disabled={continueButtonDisabled}
                      endIcon={<ArrowForwardIcon />}
                      onClick={(): void => {
                        if (steps[stepNumber].type === 'address') steps[stepNumber].onSet?.(currentAddress)
                        else if (steps[stepNumber].type === 'aggregated_status')
                          steps[stepNumber].onSet?.(currentStatus)
                        setStepNumber(steps[stepNumber].goToStep)
                      }}
                    >
                      {translation.buttonContinue}
                    </KNButton>
                  </Box>
                </Grid>
              </Grid>
            )}
            {stepNumber === lastStepIndex && (
              <Box display="flex" justifyContent="flex-end">
                <KNButton
                  sx={{ mr: 2, color: 'primary.light' }}
                  variant="text"
                  size="small"
                  onClick={(): void => {
                    setCurrentOption(undefined)
                    resetForm()
                    history.back()
                  }}
                >
                  {translation.buttonCancel}
                </KNButton>
                <KNLoadingButton
                  variant="contained"
                  color="success"
                  startIcon={<ModeIcon />}
                  size="small"
                  loading={submitted}
                  disabled={disableSubmit}
                  onClick={async (): Promise<void> => await submit()}
                >
                  {translation.buttonSave}
                </KNLoadingButton>
              </Box>
            )}
          </Box>
        )}
      </FadeIn>
    </Box>
  )
}

export default MegaForm
