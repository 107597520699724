import React, { useEffect, useState } from 'react'
import { GoogleMap, CircleF, PolygonF, DrawingManagerF, useJsApiLoader, InfoBox, Circle } from '@react-google-maps/api'
import { visibilityMapContainerStyleFull, visibilityMapStyles } from 'modules/VisibilityMap/VisibilityMap.styles'

// @mui imports
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import AdjustIcon from '@mui/icons-material/Adjust'
import PolylineIcon from '@mui/icons-material/Polyline'
import { Theme } from '@mui/material/styles/createTheme'

// KN imports
import borders from 'assets/theme/base/borders'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Context
import { useInsightDetailsContext } from 'context/detailsNext/InsightDetails'

// Data
import { geofenceTranslations } from 'screens/Geofence/Geofence.data'

// Types
import { GeofenceCircle, GeofenceMapProps, GeofencePolygon, GeofenceType } from 'screens/Geofence/Geofence.types'

// Components
import { detailedLocationMarker } from 'modules/VisibilityMap/VisibilityMapItems'
import { getCenterLocationBetween, getDefaultCenterLocation } from 'modules/VisibilityMap/VisibilityMap.helpers'

function GeofenceMap({ geofenceData, saveGeofence, deleteGeofence }: GeofenceMapProps): React.ReactElement {
  // Context for markers //
  const { insightDetailsState } = useInsightDetailsContext()
  const { data } = insightDetailsState.insightDetailsContext
  const pickup = data?.addressInfoProps?.pickup?.address
  const delivery = data?.addressInfoProps?.delivery?.address

  // Module specific //
  const [map, setMap] = useState<google.maps.Map>()
  const [drawingType, setDrawingType] = useState<'circle' | 'polygon'>('polygon')
  const [localPolyArray, setLocalPolyArray] = useState<GeofencePolygon[]>([])
  const [polygonRef, setPolygonRef] = useState<GeofencePolygon | null>(null)
  const [localCircleArray, setLocalCircleArray] = useState<GeofenceCircle[]>([])
  const [circleRef, setCircleRef] = useState<GeofenceCircle | null>(null)
  const [mode, setMode] = useState<string>('edit')
  const [center, setCenter] = useState<any>({ lat: 0, lng: 0 })
  const [zoom, setZoom] = useState(map?.getZoom() ?? 2)

  const { translation } = geofenceTranslations()
  const { borderRadius } = borders

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY ? process.env.REACT_APP_MAPS_API_KEY : '',
    libraries: ['drawing'],
  })

  useEffect(() => {
    if (!map) return
    const bounds = new window.google.maps.LatLngBounds()
    map.addListener('zoom_changed', () => setZoom(map.getZoom() ?? 0))
    if (geofenceData && geofenceData.length > 0) {
      // Polygon
      geofenceData
        ?.filter((geofence) => geofence.shape === 'polygon')
        ?.map((g) =>
          setLocalPolyArray(
            geofenceData.map((data) => ({
              cid: data.cid,
              polygon: createPolygon(data.points?.map((point) => ({ lat: point.latitude, lng: point.longitude }))),
              type: data.type,
            }))
          )
        )

      geofenceData
        ?.filter((geofence) => geofence.shape === 'radius')
        ?.map((g) =>
          setLocalCircleArray(
            geofenceData.map((data) => {
              const circle = createCircle({ lat: data?.center?.latitude, lng: data?.center?.longitude }, data?.radius)
              if (circle.getBounds()) bounds.union(circle.getBounds() as google.maps.LatLngBounds)
              return {
                cid: data.cid,
                circle: circle,
                type: data.type,
              }
            })
          )
        )
    }

    geofenceData?.map((data) => {
      data.points?.map((point) =>
        bounds.extend({
          lat: point.latitude,
          lng: point.longitude,
        })
      )
    })

    if (pickup?.location) {
      bounds.extend({
        lat: pickup.location.latitude,
        lng: pickup.location.longitude,
      })
    }

    if (delivery?.location) {
      bounds.extend({
        lat: delivery.location.latitude,
        lng: delivery.location.longitude,
      })
    }

    map.fitBounds(bounds)
    if (geofenceData && geofenceData.length === 0) {
      if (pickup && !delivery) {
        const center = getDefaultCenterLocation({
          latitude: pickup.location.latitude,
          longitude: pickup.location.longitude,
        })
        setCenter(center)
        setZoom(4)
      } else if (pickup && delivery) {
        const center = getCenterLocationBetween(
          { latitude: pickup.location.latitude, longitude: pickup.location.longitude },
          { latitude: delivery.location.latitude, longitude: delivery.location.longitude }
        )
        setCenter(center)
        setZoom(4)
      }
    }
  }, [geofenceData, map])

  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(undefined)
  }, [])

  const handleCenterChanged = (): void => {
    if (map) setCenter(map.getCenter())
  }

  function createPolygon(path): google.maps.Polygon {
    return new window.google.maps.Polygon({
      paths: path,
      editable: true,
      clickable: true,
      draggable: true,
    })
  }

  const onPolygonComplete = (polygon): void => {
    const newPoly = { cid: null, polygon: createPolygon(polygon.getPath()), type: mode } as GeofencePolygon
    setLocalPolyArray([...localPolyArray, newPoly])
    polygon.setMap(null)
    setPolygonRef(newPoly)
  }

  const onPolyMouseUp = (polygon): void => {
    polygon.setMap(null)
  }

  function createCircle(center, radius): google.maps.Circle {
    return new window.google.maps.Circle({
      center,
      radius,
      editable: true,
      draggable: true,
      clickable: true,
    })
  }

  const onCircleComplete = (circle): void => {
    const newCircle = {
      cid: null,
      circle: createCircle(circle.getCenter(), circle.getRadius()),
      type: mode,
    } as GeofenceCircle
    setLocalCircleArray([...localCircleArray, newCircle])
    circle.setMap(null)
    setCircleRef(newCircle)
  }

  const onCircleMouseUp = (circle): void => {
    circle.setMap(null)
  }

  const saveData = (): void => {
    saveGeofence(polygonRef)
    saveGeofence(circleRef)
    setPolygonRef(null)
    setCircleRef(null)
  }

  const deleteZone = (): void => {
    let index
    let type

    // Find the index and type of the zone to delete
    if (polygonRef) {
      index = localPolyArray.findIndex(
        (x) => x.polygon.getPath().getArray() === polygonRef?.polygon.getPath().getArray()
      )
      type = 'polygon'
    } else if (circleRef) {
      index = localCircleArray.findIndex((x) => x.circle === circleRef?.circle)
      type = 'radius'
    }

    if (index !== undefined && type !== undefined) {
      if (type === 'polygon' && localPolyArray[index].cid) {
        deleteGeofence(localPolyArray[index].cid)
      } else if (type === 'radius' && localCircleArray[index].cid) {
        deleteGeofence(localCircleArray[index].cid)
      }

      // Remove the zone from the appropriate array
      if (type === 'polygon') {
        const arr = [...localPolyArray]
        arr.splice(index, 1)
        setLocalPolyArray(arr)
      } else if (type === 'radius') {
        const arr = [...localCircleArray]
        arr.splice(index, 1)
        setLocalCircleArray(arr)
      }

      setPolygonRef(null)
      setCircleRef(null)
    }
  }

  function handleCircleChanged(this: google.maps.Circle): void {
    if (!circleRef) return
    const updatedCenter = {
      lat: this.getCenter()?.lat(),
      lng: this.getCenter()?.lng(),
    } as google.maps.LatLngLiteral
    const curr: GeofenceCircle = { ...circleRef }
    curr.circle?.setCenter(updatedCenter)
    curr.circle?.setRadius(this.getRadius())
    setCircleRef(curr)
  }

  return (
    <Box>
      {isLoaded && (
        <>
          <ToggleButtonGroup color="primary" value={mode} onChange={(e, newMode): void => setMode(newMode)} exclusive>
            <ToggleButton value={'edit'}>{translation.edit}</ToggleButton>
            <ToggleButton value={GeofenceType.ENTER_ZONE}>{translation.drawEnterZone}</ToggleButton>
            <ToggleButton value={GeofenceType.EXIT_ZONE}>{translation.drawExitZone}</ToggleButton>
            <ToggleButton value={GeofenceType.ENTER_OR_EXIT_ZONE}>{translation.drawEnterAndExitZone}</ToggleButton>
          </ToggleButtonGroup>
          <Button onClick={(): void => deleteZone()} disabled={!polygonRef && !circleRef}>
            {translation.deleteZone}
          </Button>
          <Button onClick={saveData} disabled={!polygonRef && !circleRef}>
            {translation.saveZone}
          </Button>
          {mode !== 'edit' && (
            <Box my={2} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <ToggleButtonGroup
                color="primary"
                value={drawingType || 'polygon'}
                onChange={(e, newType): void => setDrawingType(newType)}
                exclusive
              >
                <ToggleButton value={'polygon'}>
                  <PolylineIcon sx={{ fontSize: ({ typography: { size } }: Theme): string => size.xl }} />
                </ToggleButton>
                <ToggleButton value={'circle'}>
                  <AdjustIcon sx={{ fontSize: ({ typography: { size } }: Theme): string => size.xl }} />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          )}
          <GoogleMap
            mapContainerStyle={{
              ...visibilityMapContainerStyleFull,
              height: '450px',
              borderRadius: borderRadius.lg,
            }}
            options={{
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              styles: visibilityMapStyles,
            }}
            onDragEnd={handleCenterChanged}
            center={center}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onClick={(): void => {
              setPolygonRef(null)
              setCircleRef(null)
            }}
          >
            {pickup?.location && (
              <>
                {detailedLocationMarker(pickup, 'pickup')}
                {zoom > 5 && (
                  <InfoBox
                    options={{
                      closeBoxURL: '',
                      alignBottom: true,
                      pixelOffset: new google.maps.Size(0, 50),
                    }}
                    position={new google.maps.LatLng(pickup.location.latitude, pickup.location.longitude)}
                  >
                    <Box
                      p={2}
                      sx={{
                        bgcolor: 'white.main',
                        borderRadius: 2,
                        width: '150px',
                      }}
                    >
                      <KNTypography>{translation.pickup}</KNTypography>
                    </Box>
                  </InfoBox>
                )}
              </>
            )}
            {delivery?.location && (
              <>
                {detailedLocationMarker(delivery, 'delivery')}
                {zoom > 5 && (
                  <InfoBox
                    options={{
                      closeBoxURL: '',
                      alignBottom: true,
                      pixelOffset: new google.maps.Size(0, 50),
                    }}
                    position={new google.maps.LatLng(delivery.location.latitude, delivery.location.longitude)}
                  >
                    <Box
                      p={2}
                      sx={{
                        bgcolor: 'white.main',
                        borderRadius: 2,
                        width: '150px',
                      }}
                    >
                      <KNTypography>{translation.delivery}</KNTypography>
                    </Box>
                  </InfoBox>
                )}
              </>
            )}
            {localPolyArray.map((poly, index) => (
              <PolygonF
                key={index}
                editable={poly.polygon.getPath().getArray() === polygonRef?.polygon.getPath().getArray()}
                draggable
                path={poly.polygon.getPath()}
                options={{
                  fillColor:
                    poly.polygon.getPath().getArray() === polygonRef?.polygon.getPath().getArray() ? 'green' : 'black',
                  strokeColor:
                    poly.polygon.getPath().getArray() === polygonRef?.polygon.getPath().getArray() ? 'green' : 'black',
                }}
                onMouseDown={(): void => {
                  setPolygonRef(poly)
                }}
                onMouseUp={(): void => {
                  onPolyMouseUp(polygonRef?.polygon)
                }}
              />
            ))}

            {localCircleArray.map((c, index) => (
              <CircleF
                key={index}
                editable={c.circle === circleRef?.circle}
                draggable
                center={c.circle.getCenter() ?? { lat: 0, lng: 0 }}
                radius={c.circle.getRadius()}
                options={{
                  fillColor: c.circle === circleRef?.circle ? 'green' : 'black',
                  strokeColor: c.circle === circleRef?.circle ? 'green' : 'black',
                }}
                onMouseDown={(): void => {
                  setCircleRef(c)
                }}
                onMouseUp={(): void => {
                  onCircleMouseUp(circleRef?.circle)
                }}
                onRadiusChanged={handleCircleChanged}
                onDragEnd={handleCircleChanged}
              />
            ))}
            {zoom > 5 && circleRef && (
              <InfoBox
                position={new google.maps.LatLng(circleRef.circle.getCenter() ?? { lat: 0, lng: 0 })}
                options={{
                  closeBoxURL: '',
                }}
              >
                <Box
                  sx={{
                    bgcolor: 'white.main',
                    p: 2,
                    borderRadius: ({ borders: { borderRadius } }: Theme): string | number => borderRadius.lg,
                  }}
                >
                  <KNTypography variant="textLG">radius: </KNTypography>
                  <KNTypography variant="textLG_SB">
                    {circleRef.circle.getRadius()?.toFixed(5) ?? circleRef.circle.getRadius()}
                  </KNTypography>
                </Box>
              </InfoBox>
            )}
            {(drawingType === 'circle' || drawingType === 'polygon') && (
              <DrawingManagerF
                onPolygonComplete={(polygon): void => onPolygonComplete(polygon)}
                onCircleComplete={(circle): void => onCircleComplete(circle)}
                options={{
                  drawingControl: false,
                  drawingMode: mode === 'edit' ? null : google.maps.drawing.OverlayType[drawingType.toUpperCase()],
                  drawingControlOptions: {
                    position: google.maps.ControlPosition.TOP_CENTER,
                    drawingModes: [google.maps.drawing.OverlayType[drawingType.toUpperCase()]],
                  },
                  polygonOptions: {
                    editable: true,
                    draggable: true,
                    clickable: true,
                  },
                  circleOptions: {
                    editable: true,
                    draggable: true,
                    clickable: true,
                  },
                }}
              />
            )}
          </GoogleMap>
        </>
      )}
    </Box>
  )
}

export default GeofenceMap
