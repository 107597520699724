import { createContext, ReactElement, ReactNode, useContext, useReducer } from 'react'
import DashboardContextProps from './DashboardContext.types'

interface Action {
  type: 'setDashboardProps'
  payload: DashboardContextProps
}
type Dispatch = (action: Action) => void
interface State {
  dashboardContext: DashboardContextProps
}
interface DashboardContextProviderProps {
  children: ReactNode
}
interface DashboardStateContextType {
  dashboardState: State
  dispatchDashboardState: Dispatch
}

const DashboardStateContext = createContext<DashboardStateContextType | undefined>(undefined)

function dashboardReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setDashboardProps': {
      return { dashboardContext: action.payload }
    }
    default: {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function DashboardContextProvider({ children }: DashboardContextProviderProps): ReactElement {
  const [dashboardState, dispatchDashboardState] = useReducer(dashboardReducer, {
    dashboardContext: {
      pageSize: 10,
      pageNumber: 1,
      sort: [],
      idFilterValue: '',
      statusFilterValue: '',
      dateFilterValue: '',
      dateRangeValue: { startDate: undefined, endDate: undefined },
      counterFilterValue: undefined,
      sorter: '',
      sortingDirection: '',
    },
  })
  const value = { dashboardState, dispatchDashboardState }
  return <DashboardStateContext.Provider value={value}>{children}</DashboardStateContext.Provider>
}

function useDashboardContext(): DashboardStateContextType {
  const context = useContext(DashboardStateContext)
  if (context === undefined) {
    throw new Error('error')
  }
  return context
}

export { DashboardContextProvider, useDashboardContext }
