import i18n from 'i18n'

export const sharingRuleCreateTranslations = (): Record<any, any> => {
  const sharingRuleCreate = 'modules.cv.sharing_rule_create'

  return {
    translation: {
      screenNameCreate: i18n.t(`${sharingRuleCreate}.screen_name_create`),
      screenNameEdit: i18n.t(`${sharingRuleCreate}.screen_name_edit`),
      errorNameField: i18n.t(`${sharingRuleCreate}.errors.name`),
      errorConditionsField: i18n.t(`${sharingRuleCreate}.errors.conditions`),
      errorUsersField: i18n.t(`${sharingRuleCreate}.errors.users`),
      stepOneTitle: i18n.t(`${sharingRuleCreate}.steps.step_one_title`),
      stepTwoTitle: i18n.t(`${sharingRuleCreate}.steps.step_two_title`),
      stepThreeTitle: i18n.t(`${sharingRuleCreate}.steps.step_three_title`),
      stepFourTitle: i18n.t(`${sharingRuleCreate}.steps.step_four_title`),
      stepFiveTitle: i18n.t(`${sharingRuleCreate}.steps.step_five_title`),
      addMoreText: i18n.t(`${sharingRuleCreate}.steps.add_more_text`),
      optionOneTitle: i18n.t(`${sharingRuleCreate}.steps.options.option_one_title`),
      optionOneDescription: i18n.t(`${sharingRuleCreate}.steps.options.option_one_description`),
      optionTwoTitle: i18n.t(`${sharingRuleCreate}.steps.options.option_two_title`),
      optionTwoDescription: i18n.t(`${sharingRuleCreate}.steps.options.option_two_description`),
      optionThreeTitle: i18n.t(`${sharingRuleCreate}.steps.options.option_three_title`),
      optionThreeDescription: i18n.t(`${sharingRuleCreate}.steps.options.option_three_description`),
      optionFourTitle: i18n.t(`${sharingRuleCreate}.steps.options.option_four_title`),
      optionFourDescription: i18n.t(`${sharingRuleCreate}.steps.options.option_four_description`),
      optionFiveTitle: i18n.t(`${sharingRuleCreate}.steps.options.option_five_title`),
      optionFiveDescription: i18n.t(`${sharingRuleCreate}.steps.options.option_five_description`),
      optionSixTitle: i18n.t(`${sharingRuleCreate}.steps.options.option_six_title`),
      optionSixDescription: i18n.t(`${sharingRuleCreate}.steps.options.option_six_description`),
      customFieldGroupName: i18n.t(`${sharingRuleCreate}.steps.custom_fields.group_name`),
      customFieldUsers: i18n.t(`${sharingRuleCreate}.steps.custom_fields.users`),
      customFieldUsersPlaceholder: i18n.t(`${sharingRuleCreate}.steps.custom_fields.users_placeholder`),
      customFieldGroupNamePlaceholder: i18n.t(`${sharingRuleCreate}.steps.custom_fields.group_name_placeholder`),
    },
    statusTranslations: {
      AWAITING_PICKUP: i18n.t(`shared.aggregatedStatus.AWAITING_PICKUP`),
      IN_PROGRESS: i18n.t(`shared.aggregatedStatus.IN_PROGRESS`),
      DELIVERED: i18n.t(`shared.aggregatedStatus.DELIVERED`),
      CANCELLED: i18n.t(`shared.aggregatedStatus.CANCELLED`),
      UNKNOWN: i18n.t(`shared.aggregatedStatus.UNKNOWN`),
    },
  }
}
