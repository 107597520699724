import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import FadeIn from 'react-fade-in'

// @mui imports //
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'

// KN imports //
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import AccessGroupTable from 'modules/AccessGroupTable/AccessGroupTable'

// Data //
import { sharingRulesTranslations } from './SharingRules.data'

const SharingRules = (): ReactElement => {
  const history = useHistory()

  // Translated data //
  const { translation } = sharingRulesTranslations()

  return (
    <Container maxWidth={false} data-test="sharing-rules-container">
      <FadeIn>
        <Paper elevation={0} sx={{ padding: 2 }}>
          <Stack
            spacing={1}
            direction={{ xs: 'column', md: 'row' }}
            justifyContent="space-between"
            alignItems={{ xs: 'start', md: 'center' }}
            mb={2}
          >
            <KNTypography variant="displayMD_SB" color="primary.focus">
              {translation.screenName}
            </KNTypography>
            <KNButton
              onClick={(): void => {
                history.push('/sharing-rules/new')
              }}
              variant="contained"
              color="secondary"
              size="small"
            >
              {translation.addNew}
            </KNButton>
          </Stack>
          <AccessGroupTable />
        </Paper>
      </FadeIn>
    </Container>
  )
}

export default SharingRules
