import { useState, useCallback, ReactElement, useEffect } from 'react'
import FadeIn from 'react-fade-in'
import { useHistory } from 'react-router-dom'

// @mui imports //
import Box from '@mui/material/Box'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'

// KN import //
import KNDataTable from 'components/KN_Molecules/KNDataTable/KNDataTable'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import DeleteAccessGroupDialog from './DeleteAccessGroupDialog'

// Functional //
import { getActions, getColumns } from './AccessGroupTable.helpers'
import { getErrorMessage } from 'global/helpers/errorHandler'
import { getConditionGroups } from './AccessGroupTable.service'

// Data //
import { sharingRulesTranslations } from 'screens/SharingRules/SharingRules.data'

// Types //
import { ConditionGroup } from './AccessGroupTable.types'

const AccessGroupTable = (): ReactElement => {
  const [accessGroupData, setAccessGroupData] = useState<ConditionGroup[]>([])
  const [loading, setLoading] = useState(true)
  const [fetchError, setFetchError] = useState<string>()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [activeRow, setActiveRow] = useState<ConditionGroup>()

  // Translated data //
  const { translation } = sharingRulesTranslations()

  const history = useHistory()

  const fetchData = async (): Promise<void> => {
    setLoading(true)
    try {
      const conditionGroup = await getConditionGroups()
      setAccessGroupData(conditionGroup)
    } catch (error) {
      setFetchError(getErrorMessage(error))
    }
    setLoading(false)
  }

  useEffect(() => {
    void fetchData()
  }, [])

  const handleEditClick = useCallback((row: ConditionGroup) => {
    if (row.cid) history.push(`/sharing-rules/edit/${row.cid}`)
  }, [])

  const handleDeleteClick = useCallback((row: ConditionGroup) => {
    setActiveRow(row)
    setDeleteDialogOpen(true)
  }, [])

  const handleDeleteDialogAction = useCallback((): void => {
    setDeleteDialogOpen(false)
  }, [])

  const handleDeleteDialogClose = useCallback((): void => {
    setDeleteDialogOpen(false)
  }, [])

  return (
    <Box data-test="monitoring-rules-table">
      {loading && (
        <FadeIn visible={loading}>
          <KNLoader />
        </FadeIn>
      )}
      {fetchError ? (
        <DialogContentText component="div" mb={3}>
          <Alert severity="error">
            <Box>{fetchError}</Box>
          </Alert>
        </DialogContentText>
      ) : accessGroupData.length === 0 ? (
        <Box>{translation.noGroupsMessage}</Box>
      ) : (
        <>
          <FadeIn>
            <KNDataTable
              columns={getColumns()}
              actions={getActions(handleDeleteClick, handleEditClick)}
              data={accessGroupData}
              sx={{
                mx: -2,
              }}
            />
          </FadeIn>
          {activeRow && (
            <DeleteAccessGroupDialog
              payload={activeRow}
              open={deleteDialogOpen}
              onAction={handleDeleteDialogAction}
              onClose={handleDeleteDialogClose}
            />
          )}
        </>
      )}
    </Box>
  )
}

export default AccessGroupTable
