import { Fragment, ReactElement } from 'react'
import { utcToZonedTime, format } from 'date-fns-tz'
import { InfoBox, Polyline } from '@react-google-maps/api'

// @mui imports //
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'

// KN components //
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import VisibilityMapMarker from './VisibilityMapMarker/VisibilityMapMarker'

// Functional //
import { getFirstLocation, getLastLocation, getOtherLocations } from './VisibilityMap.helpers'
import { getColorByIndex } from 'global/helpers/styling'
import { analyticsEvent } from 'global/helpers/analytics'

// Types //
import { CoordsProps, LocationDataProps, LocationProps, TooltipContent } from './VisibilityMap.types'

// Data //
import { visibilityMapTranslation } from './VisibilityMap.data'

const createTooltipContent = (position: CoordsProps, title: string, body: string, caption: string): TooltipContent => ({
  tooltipActive: true,
  tooltipPosition: position,
  tooltipTitle: title,
  tooltipBody: body,
  tooltipCaption: caption,
})

export const detailedLocationMarker = (
  address: LocationDataProps,
  type: 'pickup' | 'delivery',
  setTooltip?: any
): ReactElement => {
  const customIconUrl = type === 'pickup' ? '/img/map/pickup-pin.png' : '/img/map/delivery-pin.png'
  return (
    <VisibilityMapMarker
      position={{
        lat: address.location?.latitude ?? 0,
        lng: address.location?.longitude ?? 0,
      }}
      index={2}
      customIcon={process.env.PUBLIC_URL + customIconUrl}
      onClick={(): void =>
        setTooltip?.(
          createTooltipContent(
            {
              lat: address.location?.latitude ?? 0,
              lng: address.location?.longitude ?? 0,
            },
            type === 'pickup'
              ? visibilityMapTranslation().pickUpAddressTooltipTitle
              : visibilityMapTranslation().deliveryAddressTooltipTitle,
            [address.address, address.postalCode, address.country].join(' '),
            ''
          )
        )
      }
    />
  )
}

const createTimestampTooltip = (
  device: { details: LocationProps[] },
  index: number,
  setTooltip: any,
  userTimezone: string,
  locationType: 'first' | 'last'
): ReactElement => {
  const timeZonedTimestamp = utcToZonedTime(
    locationType === 'first' ? getFirstLocation(device.details).timestamp : getLastLocation(device.details).timestamp,
    userTimezone
  )

  return (
    <VisibilityMapMarker
      position={locationType === 'first' ? getFirstLocation(device.details) : getLastLocation(device.details)}
      index={index}
      labelText={
        locationType === 'first'
          ? visibilityMapTranslation().firstKnownLocationLabel
          : visibilityMapTranslation().lastKnownLocationLabel
      }
      onForeground
      onClick={(): void =>
        setTooltip(
          createTooltipContent(
            locationType === 'first' ? getFirstLocation(device.details) : getLastLocation(device.details),
            locationType === 'first'
              ? visibilityMapTranslation().firstKnownLocationTooltipTitle
              : visibilityMapTranslation().lastKnownLocationTooltipTitle,
            format(new Date(timeZonedTimestamp), 'dd/MM/yyyy HH:mm'),
            ''
          )
        )
      }
    />
  )
}

export const firstLocationMarker = (
  device: { details: LocationProps[] },
  index: number,
  setTooltip: any,
  userTimezone: string
): ReactElement => createTimestampTooltip(device, index, setTooltip, userTimezone, 'first')

export const lastLocationMarker = (
  device: { details: LocationProps[] },
  index: number,
  setTooltip: any,
  userTimezone: string
): ReactElement => createTimestampTooltip(device, index, setTooltip, userTimezone, 'last')

export const visibilityMapTooltip = (
  tooltipPosition: CoordsProps,
  setTooltip: any,
  tooltipTitle: string,
  tooltipBody: string,
  tooltipCaption: string
): ReactElement => (
  <InfoBox
    options={{
      closeBoxURL: '',
      alignBottom: true,
      pixelOffset: new google.maps.Size(0, 60),
    }}
    position={new google.maps.LatLng(tooltipPosition.lat, tooltipPosition.lng)}
  >
    <Box
      p={2}
      sx={{
        bgcolor: 'white.main',
        borderRadius: 2,
        minWidth: '250px',
      }}
    >
      <>
        <Icon
          sx={{
            cursor: 'pointer',
            position: 'absolute',
            top: 5,
            right: 5,
          }}
          fontSize="small"
          onClick={(): void => setTooltip()}
        >
          close
        </Icon>
        <KNTypography variant="textSM" color="primary.light" sx={{ display: 'block' }}>
          {tooltipTitle}
        </KNTypography>
        <KNTypography variant="textMD" color="primary.main" sx={{ display: 'block' }}>
          {tooltipBody}
        </KNTypography>
        <KNTypography variant="textSM" color="primary.light" sx={{ display: 'block' }}>
          {tooltipCaption}
        </KNTypography>
      </>
    </Box>
  </InfoBox>
)

export const deviceLocationOptions = (index: number): google.maps.PolylineOptions => ({
  strokeColor: getColorByIndex(index) || getColorByIndex[0],
  strokeOpacity: 0.7,
  strokeWeight: 3,
  clickable: true,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
  icons: [
    {
      icon: {
        fillOpacity: 1,
        strokeWeight: 0,
        scale: 1,
        anchor: new google.maps.Point(12, 12),
        path: 'M12 2 4.5 20.29l.71.71L12 18l6.79 3 .71-.71z',
        fillColor: getColorByIndex(index) || getColorByIndex[0],
      },
      offset: '64px',
      repeat: '96px',
    },
  ],
})

export const deviceLocationMarker = (
  device: { details: LocationProps[] },
  index: number,
  setTooltip: any,
  userTimezone: string
): ReactElement => {
  return (
    <Fragment key={index}>
      <Polyline
        key={index}
        path={device.details.filter((detail) => detail.visible)}
        options={deviceLocationOptions(index)}
      />
      {getOtherLocations(device.details)
        .filter((detail) => detail.visible)
        .map((detail, i) => {
          const timeZonedTimestamp = utcToZonedTime(detail.timestamp, userTimezone)

          return (
            <VisibilityMapMarker
              position={{
                lat: detail.lat,
                lng: detail.lng,
              }}
              index={index}
              key={i}
              waypointIcon
              onClick={(): void => {
                setTooltip({
                  tooltipActive: true,
                  tooltipPosition: {
                    lat: detail.lat - 0.000002,
                    lng: detail.lng,
                  },
                  tooltipTitle: detail.deviceId,
                  tooltipBody: format(new Date(timeZonedTimestamp), 'dd/MM/yyyy HH:mm'),
                })

                analyticsEvent('polestar_cv_visibility_map_marker_clicked')
              }}
            />
          )
        })}
    </Fragment>
  )
}

export const airportMarker = (
  address: LocationDataProps,
  type: 'departure' | 'arrival',
  setTooltip?: any
): ReactElement => {
  const customIconUrl = type === 'departure' ? '/img/map/airport_departure.png' : '/img/map/airport_arrival.png'
  return (
    <VisibilityMapMarker
      position={{
        lat: address.location?.latitude ?? 0,
        lng: address.location?.longitude ?? 0,
      }}
      index={2}
      customIcon={process.env.PUBLIC_URL + customIconUrl}
      onClick={(): void =>
        setTooltip?.({
          tooltipActive: true,
          tooltipPosition: {
            lat: address.location?.latitude ?? 0,
            lng: address.location?.longitude ?? 0,
          },
          tooltipTitle:
            type === 'departure'
              ? visibilityMapTranslation().departureAirport
              : visibilityMapTranslation().arrivalAirport,
          tooltipBody: [address.name, address.city, address.country].join(', '),
        })
      }
      customSize="40px"
    />
  )
}

export const planeMarker = (address: LocationDataProps, rotation?: number, setTooltip?: any): ReactElement => {
  const customIconUrl = '/img/map/airplane_live.gif'
  return (
    <VisibilityMapMarker
      position={{
        lat: address.location?.latitude ?? 0,
        lng: address.location?.longitude ?? 0,
      }}
      index={2}
      customIcon={process.env.PUBLIC_URL + customIconUrl}
      onClick={(): void =>
        setTooltip?.({
          tooltipActive: true,
          tooltipPosition: {
            lat: address.location?.latitude ?? 0,
            lng: address.location?.longitude ?? 0,
          },
          tooltipTitle: visibilityMapTranslation().liveAirplane,
        })
      }
      rotation={rotation}
      customSize="100px"
    />
  )
}
