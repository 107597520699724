import i18n from 'i18n'

export const helpMenuTranslations = (): Record<any, any> => {
  const help = 'shared.help_menu'

  return {
    translation: {
      moduleName: i18n.t(`${help}.moduleName`),
      paulWalkerHelpMenuItem: i18n.t(`${help}.options.paul_walker`),
    },
  }
}
