// Functional
import { hasRole } from 'global/helpers/authorization'

// Types
import { Company, CompanyType } from './Company.types'
import { getToken } from './Auth.service'
import { Role } from './Role.types'

export const getUserToken = async (): Promise<string> => await getToken(getSelectedCompany()?.cid)

export const getSelectedCompany = (): Company | undefined => {
  const selectedCompany = localStorage.getItem('selectedCompany')
  if (selectedCompany) {
    return JSON.parse(selectedCompany)
  } else {
    return undefined
  }
}

export const setSelectedCompany = (company: Company): void => {
  localStorage.setItem('selectedCompany', JSON.stringify(company))
}

export const clearSelectedCompany = (): void => {
  localStorage.removeItem('selectedCompany')
}

export const getStartPage = (): string => {
  const selectedCompany = getSelectedCompany()
  if (!selectedCompany) {
    return '/login'
  }
  if (hasRole(Role.Pairer)) {
    return '/pairing'
  }
  switch (selectedCompany.type) {
    case CompanyType.Operator:
      return '/users'
    case CompanyType.Carrier:
      return '/trips'
    case CompanyType.Customer:
      return '/visibility-dashboard'
    default:
      return '/profile'
  }
}
