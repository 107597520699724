import { ReactElement } from 'react'

// @mui imports //
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import ViewInArIcon from '@mui/icons-material/ViewInAr'

// KN Components //
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { KNWeightIcon } from 'components/KN_Molecules/KNIcon/KNMaterialIcon'

// Context //
import { useInsightDetailsContext } from 'context/detailsNext/InsightDetails'

// Template //
import CargoDetailsItemTemplate from './CargoInfoItem'

// Data //
import { cargoInfoTranslations } from './CargoInfo.data'

const CargoInfo = (): ReactElement | null => {
  const { insightDetailsState } = useInsightDetailsContext()

  // Props //
  const { data } = insightDetailsState.insightDetailsContext
  const cargoSummary = data?.cargoProps?.cargoSummary
  const cargoDetails = data?.cargoProps?.cargoDetails

  // Translated Data //
  const { translation } = cargoInfoTranslations()

  if (!cargoSummary) return null
  return (
    <>
      <KNTypography data-test="cargo-info-label" variant="displayXS_SB">
        {translation.moduleName}
      </KNTypography>
      <Box mt={1.5}>
        <Box mb={0.5}>
          <KNTypography variant="displayXXS" color="primary.light">
            {translation.summary}
          </KNTypography>
        </Box>
        <Stack data-test="cargo-info-summary" direction="row" spacing={2.5}>
          <KNTypography variant="textLG" color="dark.main" sx={{ display: 'flex', alignItems: 'center' }}>
            <Inventory2OutlinedIcon color="primary" fontSize="small" sx={{ mr: 0.5 }} />
            {cargoSummary?.numberOfPackages
              ? `${String(cargoSummary?.numberOfPackages)} ${String(translation.items)}`
              : translation.notAvailable}
          </KNTypography>
          <KNTypography variant="textLG" color="dark.main" sx={{ display: 'flex', alignItems: 'center' }}>
            <KNWeightIcon color="primary" fontSize="small" sx={{ mr: 0.5 }} />
            {cargoSummary?.grossWeightInKg ? `${String(cargoSummary?.grossWeightInKg)}kg` : translation.notAvailable}
          </KNTypography>
          <KNTypography variant="textLG" color="dark.main" sx={{ display: 'flex', alignItems: 'center' }}>
            <ViewInArIcon color="primary" fontSize="small" sx={{ mr: 0.5 }} />
            {cargoSummary?.volumeInMtq ? `${String(cargoSummary?.volumeInMtq)}m3` : translation.notAvailable}
          </KNTypography>
        </Stack>
        <Box mt={1}>
          <KNTypography variant="displayXXS" color="primary.light">
            {translation.packages}
          </KNTypography>
        </Box>
        {cargoDetails?.map((cargoDetail, i) => (
          <Box data-test="cargo-info-packages" key={i} my={0.5}>
            {CargoDetailsItemTemplate(cargoDetail, i)}
          </Box>
        ))}
      </Box>
    </>
  )
}

export default CargoInfo
