import i18n from 'i18n'

export const VisibilityDashboardTranslations = (): Record<any, any> => {
  const visibilityDashboardGuide = 'screens.cv.visibility_dashboard.guide'
  return {
    guideTranslations: {
      welcomeTitle: i18n.t(`${visibilityDashboardGuide}.welcome_title`),
      welcomeMessage: i18n.t(`${visibilityDashboardGuide}.welcome_message`),
      listTitle: i18n.t(`${visibilityDashboardGuide}.list_title`),
      listMessage: i18n.t(`${visibilityDashboardGuide}.list_message`),
      countersTitle: i18n.t(`${visibilityDashboardGuide}.counters_title`),
      countersMessage: i18n.t(`${visibilityDashboardGuide}.counters_message`),
      filtersTitle: i18n.t(`${visibilityDashboardGuide}.filters_title`),
      filtersMessage: i18n.t(`${visibilityDashboardGuide}.filters_message`),
      buttonNext: i18n.t('general.next'),
      buttonSkip: i18n.t('general.skip'),
      buttonDone: i18n.t('general.close'),
    },
  }
}
