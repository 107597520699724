import i18n from 'i18n'

export const MegaTableTranslations = (): Record<any, any> => {
  const megaTable = 'shared.mega_table'
  return {
    translation: {
      total_items: i18n.t(`${megaTable}.total_items`),
    },
  }
}
