import { ReactElement } from 'react'
import { Controller, FieldValues } from 'react-hook-form'

// @mui imports
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Chip from '@mui/material/Chip'
import { Theme } from '@mui/system/createTheme'
import { SystemStyleObject } from '@mui/system'
import Stack from '@mui/material/Stack'

// KN imports
import { getFieldErrorMessages } from 'global/helpers/form'

// Types
import { KNFormChipGroupProps } from './types'

const KNFormChipGroup = <T extends FieldValues>({
  name,
  control,
  rules,
  label,
  options,
  direction,
  sx,
}: KNFormChipGroupProps<T>): ReactElement => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { onChange, value }, fieldState: { invalid, error }, formState }): ReactElement => (
      <FormControl fullWidth sx={sx} error={invalid} disabled={formState.isSubmitting}>
        {label && <FormLabel>{label}</FormLabel>}
        <Stack direction={direction} spacing={1} useFlexGap flexWrap="wrap">
          {options.map((option, key) => (
            <Chip
              key={key}
              label={option.label}
              onClick={(): void => {
                onChange(option.value)
              }}
              sx={({ palette: { primary, grey, light, white } }: Theme): SystemStyleObject<Theme> => ({
                backgroundColor: value === option.value ? primary.light : light.light,
                color: value === option.value ? white.main : grey[500],
              })}
              variant={'filled'}
              size="small"
            />
          ))}
        </Stack>
        {error?.message && <FormHelperText>{getFieldErrorMessages(error)}</FormHelperText>}
      </FormControl>
    )}
  />
)

export default KNFormChipGroup
