import { ReactElement } from 'react'
import i18n from 'i18next'

// @mui imports
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import TaskIcon from '@mui/icons-material/Task'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { getFile } from 'screens/TripDetails/TripDetails.service'
import { StopDocument } from 'screens/TripDetails/TripDetails.types'

export interface DocumentLinkProps {
  file: StopDocument
  index?: number
  weblinkToken?: string
}

const getName = (file): string => {
  switch (file.fileType) {
    case 'POP':
      return i18n.t('attachments.types.POP')
    case 'POD':
      return i18n.t('attachments.types.POD')
    default:
      return file.name
  }
}

const DocumentLink = ({ file, index, weblinkToken }: DocumentLinkProps): ReactElement => {
  const isProof = ['POP', 'POD'].includes(file.fileType)
  const handleClick = async (): Promise<void> => {
    const blob = await getFile(file.id, weblinkToken)
    const url = URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    anchor.href = url
    anchor.setAttribute('download', file.name)
    anchor.click()
    URL.revokeObjectURL(url)
    anchor.remove()
  }

  return (
    <Link
      component="button"
      type="button"
      onClick={handleClick}
      underline="always"
      sx={{
        fontSize: 'inherit',
        marginRight: 1, // TODO: move outside of component
      }}
    >
      <Stack spacing={0.5} direction="row" alignItems="center">
        {isProof ? <TaskIcon fontSize="small" /> : <InsertDriveFileIcon fontSize="small" />}
        <KNTypography
          noWrap
          sx={{
            maxWidth: isProof ? 'auto' : '8rem',
            fontSize: 'inherit',
          }}
        >
          {getName(file)}
          {index !== undefined && ` (${index + 1})`}
        </KNTypography>
      </Stack>
    </Link>
  )
}

export default DocumentLink
