// Types //
import { Company, VisibilityLevel } from 'context/authentication/Company.types'
import { Role } from 'context/authentication/Role.types'
import { UserData } from 'screens/UserManager/UserManager.types'

export interface CompanyProps {
  name: string
  type?: string
  visibilityLevel?: VisibilityLevel
  createCompany: boolean
}

export interface UserProps {
  email?: string
  displayName?: string
  role?: Role
  createUser: boolean
}

export enum Scenario {
  VehicleLevelVisibilityAdvanced = 'VehicleLevelVisibilityAdvanced',
  CarrierSolutionRegressionSuite = 'CarrierSolutionRegressionSuite',
}

export enum DocumentType {
  ProofOfDelivery = 'ProofOfDelivery',
}

export interface CustomerAdress {
  name?: string
  country?: string
  postalCode?: string
  city?: string
  address?: string
}

export interface GeoPoint {
  latitude: number
  longitude: number
}

export interface PickUpDelivery {
  early: null
  late: null
  address: {
    name?: string
    country?: string
    postalCode?: string
    city?: string
    address?: string
    location?: GeoPoint
  }
}

export interface CreatePredefinedScenarioFormValues {
  company: CompanyProps
  user: UserProps | null
  scenarioId: Scenario
}

export interface StatusField {
  code: string
  dateTime: Date | null
  cityLocation?: string
}

export interface StatusFieldDialogProps {
  statusData?: StatusField
  dialogOpen: boolean
  handleClose: () => void
  handleSave: (data: StatusField) => void
}

export interface GeocodeAddressDialogProps {
  addressData?: PickUpDelivery
  dialogOpen: boolean
  handleClose: () => void
  handleSave: (data: PickUpDelivery) => void
}

export interface CreateCompanyDialogProps {
  companyData?: CompanyProps
  dialogOpen: boolean
  scenario: boolean
  handleClose: () => void
  handleSave: (data: CompanyProps) => void
}

export interface CreateUserDialogProps {
  userData: UserProps | null
  dialogOpen: boolean
  handleClose: () => void
  handleSave: (data: UserProps) => void
}

export interface GrantRoleDialogProps {
  usersOptions: DropdownProps[]
  dialogOpen: boolean
  handleClose: () => void
  handleSave: (data: UserProps) => void
}

export interface ShipmentTelematics {
  licensePlate?: string
}

export interface Document {
  type?: DocumentType
  countryCode?: string
}

export interface TemperatureThreshold {
  min: number
  max: number
}

export interface Pairing {
  createPairing: {
    description?: string
    temperatureThreshold?: TemperatureThreshold | null
    violateTemperatureThreshold?: boolean
  }
  pairingEta: boolean
}

export interface PairingPayload {
  createPairing: {
    description?: string
    temperatureThreshold?: TemperatureThreshold | null
    violateTemperatureThreshold?: boolean
  }
  pairingEta: boolean
}

export interface CreateShipmentFormValues {
  company: CompanyProps
  user: UserProps | null
  shipment: {
    startDate: Date
    endDate: Date
    customerAddress: CustomerAdress
    pickUp: PickUpDelivery
    delivery: PickUpDelivery
    lastLocationGeoPoint?: GeoPoint | null
    statuses: StatusField[]
  }
  pairing: Pairing | null
  telematics: ShipmentTelematics | null
  document: Document | null
}

export interface CreateShipmentPayloadValues {
  company: CompanyProps
  user: UserProps | null
  shipment: {
    startDate: Date
    endDate: Date
    customerAddress: CustomerAdress
    pickUp: PickUpDelivery
    delivery: PickUpDelivery
    lastLocationGeoPoint?: GeoPoint | null
    statuses: StatusField[]
  }
  pairing: PairingPayload | null
  telematics: ShipmentTelematics | null
  document: Document | null
}

export enum StepStatus {
  OK = 'OK',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum StepType {
  COMPANY = 'COMPANY',
  USER = 'USER',
  CREATE_SHIPMENT = 'CREATE_SHIPMENT',
  CREATE_POD_DOCUMENT = 'CREATE_POD_DOCUMENT',
  GENERATE_TELEMATICS_DATA = 'GENERATE_TELEMATICS_DATA',
  GENERATE_CHORUS_DATA = 'GENERATE_CHORUS_DATA',
  CREATE_PAIRING = 'CREATE_PAIRING',
  CREATE_TRIP = 'CREATE_TRIP',
  SKIPPED = 'SKIPPED',
}

export interface Step {
  step: StepType
  stepStatus: StepStatus
  errorMsg?: string
}

export interface PlaygroundResponse {
  steps: Step[]
}

export interface FormCompanyProps {
  control: any
  setValue: any
  getValues: any
  companies: Company[]
  scenario: boolean
}

export interface FormUserProps {
  control: any
  setValue: any
  getValues: any
  users: UserData[]
  resetUser: () => void
  filteredUsersOptions: DropdownProps[]
  selectedCompany: Company
}

export interface DropdownProps {
  label: string
  value: string
  description?: string
}

export interface TripTelematics {
  tripCid: string
  licensePlate?: string
  withoutApproachAndDeparture?: boolean
  randomizedOrder?: boolean
}

export interface CreateTripTelematicsFormValues {
  company: CompanyProps
  user: null
  telematics: TripTelematics
}
