import { useState } from 'react'
import FadeIn from 'react-fade-in'

// @mui imports
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

// KN Components
import Icon from 'components/KN_Components/Base/Icons/Icon'
import { IconName } from 'components/KN_Components/Base/Icons/Icon.type'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Types
import FormFieldProps from '../FormField.type'

const TextInput: React.FC<FormFieldProps> = ({
  required,
  onChange,
  label,
  id,
  name,
  autoComplete,
  type,
  icon,
  placeholder,
  value,
  withFieldValidation = false,
  withMaxChars = false,
  errorMessage,
  autoFocus = true,
  helperText,
  disabled,
}) => {
  const [errored, setErrored] = useState(false)
  const [success, setSuccess] = useState(false)

  const validateField = (): void => {
    if (!value || value === '') {
      setSuccess(false)
      setErrored(true)
    }
    if (value) {
      setErrored(false)
      setSuccess(true)
    }
  }

  const checkMaxChars = (): void => {
    if (value && value.length > 255) {
      setSuccess(false)
      setErrored(true)
    }
    if (value && value.length < 255) {
      setErrored(false)
      setSuccess(true)
    }
  }

  const validate = (): void => {
    setSuccess(true)
  }

  return (
    <>
      <TextField
        id={id}
        required={required}
        onChange={onChange}
        autoFocus={autoFocus}
        onBlur={withFieldValidation ? validateField : withMaxChars ? checkMaxChars : validate}
        error={withFieldValidation || withMaxChars ? errored : false}
        label={label}
        name={name}
        autoComplete={autoComplete}
        type={type}
        variant="outlined"
        disabled={disabled}
        inputProps={{ style: { fontSize: '1rem' } }}
        InputProps={{
          startAdornment: icon && (
            <InputAdornment position="start">
              <Icon name={icon} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {success && withFieldValidation && (
                <FadeIn>
                  <Icon name={IconName.check_circle} color="success" />
                </FadeIn>
              )}
              {errored && withFieldValidation && (
                <FadeIn>
                  <Icon name={IconName.delete_circle} color="error" />
                </FadeIn>
              )}
            </InputAdornment>
          ),
        }}
        fullWidth
        placeholder={placeholder}
        value={value}
        helperText={helperText}
      />
      <Collapse in={errored}>
        <Box>
          <KNTypography color="error" variant="caption">
            {errorMessage}
          </KNTypography>
        </Box>
      </Collapse>
    </>
  )
}

export default TextInput
