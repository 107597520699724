import { ReactElement } from 'react'
import { Controller, FieldValues } from 'react-hook-form'

// @mui imports
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

// KN imports
import Chip from '@mui/material/Chip'

// Functional
import { getFieldErrorMessages } from 'global/helpers/form'

const KNFormAutocompleteMultiple = ({ name, control, rules, disabled }: FieldValues): ReactElement => {
  const defaultRenderOption = (props, option): ReactElement => (
    <Box component="li" {...props} key={option.value}>
      {option.label}
    </Box>
  )

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { invalid, error }, formState }): ReactElement => (
        <FormControl fullWidth sx={{ marginTop: -0.3 }} error={invalid}>
          <Autocomplete
            multiple
            freeSolo
            size="small"
            disabled={formState.isSubmitting || disabled}
            renderOption={defaultRenderOption}
            renderTags={(value, getTagProps): ReactElement[] =>
              value.map((option: string, index: number) => (
                // eslint-disable-next-line react/jsx-key
                <Chip label={option} size="small" {...getTagProps({ index })} />
              ))
            }
            renderInput={(params): ReactElement => <TextField {...params} error={invalid} variant="standard" />}
            onChange={(event, value): void => {
              onChange(value)
            }}
            value={value ?? []}
            options={[]}
            disableClearable
          />
          {error?.message && <FormHelperText>{getFieldErrorMessages(error)}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

export default KNFormAutocompleteMultiple
