import { ReactElement, useEffect, useState, useCallback, useContext } from 'react'

// @mui material imports //
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade/Fade'
import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar/Snackbar'

// KN components //
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Context //
import { useInsightDetailsContext } from 'context/detailsNext/InsightDetails'
import { UserContext } from 'context/authentication/UserContext'

// Functional //
import { downloadShipmentFile } from './ShipmentFiles.service'

// Types //
import { ShipmentFileProps } from './ShipmentFiles.types'

// Data //
import { shipmentFilesTranslations } from './ShipmentFiles.data'

// Template //
import ShipmentFilesTemplate from './ShipmentFilesItem'

const ShipmentFiles = (): ReactElement => {
  // Context //
  const { insightDetailsState } = useInsightDetailsContext()
  const { userTimezone } = useContext(UserContext)

  const shipmentId = insightDetailsState.insightDetailsContext.shipmentId
  const files = insightDetailsState.insightDetailsContext.data?.filesProps

  // Translated Data //
  const { translation } = shipmentFilesTranslations()

  // Module state //
  const [filesData] = useState<ShipmentFileProps[]>(files || [])

  // Snackbar //
  const [alertOpen, setAlertOpen] = useState(false)
  const [message, setMessage] = useState<{
    status: string
    name: string
  }>()

  // Download file //
  const getDownloadFile = async (attachment: ShipmentFileProps): Promise<void> => {
    setMessage({
      status: translation.downloadLoadingState,
      name: translation.downloadLoadingState,
    })
    if (shipmentId) {
      try {
        const blob = await downloadShipmentFile(shipmentId, attachment.id)
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = attachment.name
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)
        a.remove()
        setMessage({
          status: '200',
          name: translation.downloadSuccess,
        })
      } catch (error) {
        setMessage({
          status: '500',
          name: translation.downloadError,
        })
      }
    }
  }

  const handleDownloadFile = useCallback(
    async (attachment: ShipmentFileProps): Promise<void> => {
      await getDownloadFile(attachment)
    },
    [filesData]
  )

  useEffect(() => {
    if (message?.status === '500' || message?.status === '200') {
      setAlertOpen(true)
    }
  }, [message])

  return (
    <>
      <KNTypography variant="displayXS_SB">{translation.moduleName}</KNTypography>
      <Box data-test="attachments" mt={1.5} sx={{ width: '100%', position: 'relative' }}>
        <Snackbar
          data-test="attachmentsInfoAlert"
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={alertOpen}
          onClose={(): void => setAlertOpen(false)}
          TransitionComponent={Fade}
          autoHideDuration={2000}
          key="attachmentsInfoAlert"
        >
          <Alert
            variant="filled"
            severity={
              message?.status === '200'
                ? 'success'
                : message?.status === '400' || message?.status === '500'
                ? 'error'
                : 'info'
            }
          >
            {message?.name}
          </Alert>
        </Snackbar>
        <Stack spacing={1}>
          {shipmentId &&
            filesData
              .filter((file) => file.type === 'ProofOfDelivery' || file.type === 'ProofOfPickup')
              .map((file, i) => (
                <Box data-test="attachments-list" key={i} sx={{}}>
                  {ShipmentFilesTemplate(file, () => handleDownloadFile(file), userTimezone, file.type)}
                </Box>
              ))}
        </Stack>
        <Box data-test="attachments-info" mt={2}>
          {shipmentId &&
            filesData
              .filter((file) => file.type !== 'ProofOfDelivery' && file.type !== 'ProofOfPickup')
              .map((file, i) => (
                <Box key={i} my={1} sx={{ width: '50%', display: 'inline-block' }}>
                  {ShipmentFilesTemplate(file, () => handleDownloadFile(file), userTimezone)}
                </Box>
              ))}
        </Box>
      </Box>
    </>
  )
}

export default ShipmentFiles
