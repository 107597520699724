import addMinutes from 'date-fns/addMinutes'
import subMinutes from 'date-fns/subMinutes'

// Types //
import { TemperatureDataSetProps } from 'modules/TemperatureChart/TemperatureChart.types'

export const limitTemperatureSnippetData = (
  data: TemperatureDataSetProps[],
  breachValue?: number
): TemperatureDataSetProps[] => {
  let dateStart: Date
  let dateEnd: Date

  if (data.length === 0) return []

  const breachedPoint = data.find((el) => Number(el.value) === breachValue)
  if (breachedPoint) {
    dateStart = subMinutes(new Date(breachedPoint.timestamp).setSeconds(0), 15)
    dateEnd = addMinutes(new Date(breachedPoint.timestamp).setSeconds(0), 15)
  } else {
    dateEnd = new Date(data.reduce((a, b) => (a.timestamp > b.timestamp ? a : b)).timestamp)
    dateStart = subMinutes(dateEnd, 30)
  }
  return data.filter((element) => new Date(element.timestamp) >= dateStart && new Date(element.timestamp) <= dateEnd)
}
