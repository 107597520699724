import i18n from 'i18n'

export const generalInfoListTranslations = (): Record<any, any> => {
  const generalInfoList = 'modules.cv.general_info_cards'
  return {
    translation: {
      noTMSdata: i18n.t(`${generalInfoList}.noTMSdata`),
      goBack: i18n.t(`${generalInfoList}.goBack`),
    },
  }
}
