import { ReactElement } from 'react'
import { Controller, FieldValues } from 'react-hook-form'

// @mui imports
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

// KN imports
import { getFieldErrorMessages } from 'global/helpers/form'
import { KNFormAutocompleteProps, KNOptionProps } from './types'

const KNFormAutocomplete = <T extends FieldValues>({
  name,
  control,
  rules,
  label,
  options,
  size,
  sx,
  disabled,
  renderOption,
}: KNFormAutocompleteProps<T>): ReactElement => {
  const getValueObject = (value): KNOptionProps => options.find((option) => option.value === value) ?? value

  const defaultRenderOption = (props, option): ReactElement => (
    <Box component="li" {...props} key={option.value}>
      {option.label}
    </Box>
  )

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value, name, ref }, fieldState: { invalid, error }, formState }): ReactElement => (
        <FormControl fullWidth sx={sx} error={invalid}>
          <Autocomplete
            data-test="company-input"
            size={size}
            disabled={formState.isSubmitting || disabled}
            renderOption={renderOption ?? defaultRenderOption}
            renderInput={(params): ReactElement => <TextField {...params} label={label} error={invalid} />}
            value={getValueObject(value)}
            onChange={(event, value, reason): void => {
              onChange(Array.isArray(value) ? value.map((item) => item.value) : value.value)
            }}
            options={options}
            disableClearable
            isOptionEqualToValue={(option, value): boolean => option.value === value.value}
          />
          {error?.message && <FormHelperText>{getFieldErrorMessages(error)}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

export default KNFormAutocomplete
