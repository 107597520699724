// Functional
import { fetchGet } from 'global/helpers/fetchWrapper'

// Types
import { ShipmentDataResponse } from 'context/detailsNext/InsightDetails.types'

export const getTemperatureSnippetData = async (shipmentId: string): Promise<any> => {
  let temperatureData = {}
  try {
    const shipmentData: ShipmentDataResponse = await fetchGet(`shipments/${shipmentId}`, {}, 'si')
    if (!shipmentData.pairingCid) return
    temperatureData = await fetchGet(`pairings/${shipmentData.pairingCid}/datapoints/temperature?threshold=0`, {}, 'si')
  } catch (error) {
    return Promise.reject(error)
  }
  return temperatureData
}
