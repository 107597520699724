import { ReactElement, useEffect } from 'react'
import FadeIn from 'react-fade-in'
import { useForm } from 'react-hook-form'

// @mui imports //
import Box from '@mui/material/Box'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Stack from '@mui/material/Stack'

// KN components //
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNFormRadioGroup from 'components/KN_Molecules/KNForm/KNFormRadioGroup'

// Functional //
import { processDefaultValues } from 'global/helpers/form'
import { regexEmail } from 'global/helpers/validators'
import { getUserRoles } from './PlaygroundForms.helpers'

// Data //
import { playgroundTranslations } from 'screens/Playground/Playground.data'

// Types //
import { CreateUserDialogProps, UserProps } from 'screens/Playground/Playground.types'

const CreateUserDialog = ({ userData, handleClose, dialogOpen, handleSave }: CreateUserDialogProps): ReactElement => {
  const { handleSubmit, reset, control, formState, setValue, getValues, setError, clearErrors } = useForm<UserProps>()
  const { translation } = playgroundTranslations()

  useEffect(() => {
    if (userData) reset(processDefaultValues(userData))
    else resetForm()
  }, [userData])

  const resetForm = (): void => {
    reset(
      processDefaultValues({
        email: null,
        displayName: null,
        role: null,
        createUser: false,
      })
    )
  }

  const onSave = (data: UserProps): void => {
    data.createUser = true
    handleClose()
    resetForm()
    handleSave(data)
  }

  const onClose = (): void => {
    clearErrors()
    handleClose()
  }

  const getDialogContent = (): ReactElement => (
    <Box my={2}>
      <Box mb={3}>
        <FadeIn>
          <Stack spacing={3}>
            <Box mt={1}>
              <KNFormText
                name="email"
                label={translation.userEmailLabel}
                sx={{
                  maxWidth: 'sm',
                }}
                rules={{
                  pattern: {
                    value: regexEmail,
                    message: translation.invalidEmail,
                  },
                  required: translation.required,
                }}
                control={control}
              />
            </Box>
            <Box mt={1}>
              <KNFormText
                name="displayName"
                label={translation.userDisplayNameLabel}
                sx={{
                  maxWidth: 'sm',
                }}
                control={control}
              />
            </Box>
            <Box mt={1}>
              <KNFormRadioGroup
                label={translation.userRoleLabel}
                name="role"
                control={control}
                options={getUserRoles()}
                rules={{
                  required: translation.required,
                }}
              />
            </Box>
          </Stack>
        </FadeIn>
      </Box>
    </Box>
  )
  return (
    <Dialog
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          maxHeight: 435,
          zIndex: 9999,
        },
      }}
      maxWidth="xs"
      open={dialogOpen}
    >
      <DialogContent>{getDialogContent()}</DialogContent>
      <DialogActions>
        <KNButton
          size="medium"
          color="primary"
          variant="contained"
          type="submit"
          startIcon={<CheckIcon fontSize="inherit" />}
          disabled={Object.keys(formState.errors).length > 0}
          onClick={handleSubmit(onSave)}
        >
          {translation.userCreateButton}
        </KNButton>
        <KNButton
          size="medium"
          color="primary"
          type="button"
          variant="contained"
          startIcon={<CloseIcon fontSize="inherit" />}
          onClick={onClose}
        >
          {translation.shipmentCancelButton}
        </KNButton>
      </DialogActions>
    </Dialog>
  )
}

export default CreateUserDialog
