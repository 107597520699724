// @mui imports //
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import DownloadIcon from '@mui/icons-material/Download'
import UploadIcon from '@mui/icons-material/Upload'

// KN imports //
import {
  KNCheckMarkerIcon,
  KNStarsMarkerIcon,
  KNTemperatureIcon,
  KNStatusChangeIcon,
} from 'components/KN_Molecules/KNIcon/KNIcon'
import KNCountryFlag from 'components/KN_Molecules/KNCountryFlag/KNCountryFlag'

// Data //
import { sharingRuleCreateTranslations } from './SharingRuleCreateOrEdit.data'

// Types //
import { MappedEntity, MegaFormStep } from 'components/KN_Molecules/MegaForm/MegaForm.types'
import { AccessGroupConditionTypes, ConditionGroup } from 'modules/AccessGroupTable/AccessGroupTable.types'

export const accessGroupOptions = () => {
  const { translation } = sharingRuleCreateTranslations()

  const styles = { fontSize: 'inherit', mr: 1 }

  return {
    GuestUserPickUpStatusCondition: {
      optionId: 1,
      icon: <UploadIcon sx={styles} />,
      displayName: translation.optionOneTitle,
      description: translation.optionOneDescription,
      goToStep: 4,
    },
    GuestUserDeliveryStatusCondition: {
      optionId: 2,
      icon: <DownloadIcon sx={styles} />,
      displayName: translation.optionTwoTitle,
      description: translation.optionTwoDescription,
      goToStep: 4,
    },
    GuestUserAggregatedStatusCondition: {
      optionId: 3,
      icon: <KNStatusChangeIcon sx={styles} />,
      displayName: translation.optionThreeTitle,
      description: translation.optionThreeDescription,
      goToStep: 3,
    },
    GuestUserTemperatureThresholdViolationCondition: {
      optionId: 4,
      icon: <KNTemperatureIcon sx={styles} />,
      displayName: translation.optionFourTitle,
      description: translation.optionFourDescription,
      goToStep: 4,
    },
    GuestUserPickUpAddressCondition: {
      optionId: 5,
      icon: <KNStarsMarkerIcon sx={styles} />,
      displayName: translation.optionFiveTitle,
      description: translation.optionFiveDescription,
      goToStep: 1,
    },
    GuestUserDeliveryAddressCondition: {
      optionId: 6,
      icon: <KNCheckMarkerIcon sx={styles} />,
      displayName: translation.optionSixTitle,
      description: translation.optionSixDescription,
      goToStep: 2,
    },
  }
}

export const getMappedOption = (option: AccessGroupConditionTypes): MappedEntity => {
  return accessGroupOptions()[option]
}

export const getFormSteps = (
  conditionGroup,
  setConditionType,
  setAddressGroupConditions,
  setAggregatedStatusGroupConditions,
  guestUsers,
  setUserEmails,
  setGroupName
): MegaFormStep[] => {
  const { translation } = sharingRuleCreateTranslations()

  const options = accessGroupOptions()

  return [
    {
      id: 0,
      stepLevel: 1,
      title: translation.stepOneTitle,
      type: 'option',
      value: [],
      goToStep: 4,
      goBackToStep: 0,
      options: [
        {
          id: options.GuestUserPickUpStatusCondition.optionId,
          displayName: options.GuestUserPickUpStatusCondition.displayName,
          description: options.GuestUserPickUpStatusCondition.description,
          icon: options.GuestUserPickUpStatusCondition.icon,
          name: 'GuestUserPickUpStatusCondition',
          checked: !!conditionGroup.conditions.find((condition) => condition.type === 'GuestUserPickUpStatusCondition'),
          onClick: (): void => {
            setConditionType('GuestUserPickUpStatusCondition')
          },
          disabledWith: [2, 3],
          hasMoreSteps: false,
          goToStep: options.GuestUserPickUpStatusCondition.goToStep,
        },
        {
          id: options.GuestUserDeliveryStatusCondition.optionId,
          displayName: options.GuestUserDeliveryStatusCondition.displayName,
          description: options.GuestUserDeliveryStatusCondition.description,
          icon: options.GuestUserDeliveryStatusCondition.icon,
          name: 'GuestUserDeliveryStatusCondition',
          checked: !!conditionGroup.conditions.find(
            (condition) => condition.type === 'GuestUserDeliveryStatusCondition'
          ),
          onClick: (): void => {
            setConditionType('GuestUserDeliveryStatusCondition')
          },
          disabledWith: [1, 3],
          hasMoreSteps: false,
          goToStep: options.GuestUserDeliveryStatusCondition.goToStep,
        },
        {
          id: options.GuestUserAggregatedStatusCondition.optionId,
          displayName: options.GuestUserAggregatedStatusCondition.displayName,
          description: options.GuestUserAggregatedStatusCondition.description,
          icon: options.GuestUserAggregatedStatusCondition.icon,
          checked: !!conditionGroup.conditions.find(
            (condition) => condition.type === 'GuestUserAggregatedStatusCondition'
          ),
          name: 'GuestUserAggregatedStatusCondition',
          disabledWith: [1, 2],
          hasMoreSteps: true,
          goToStep: options.GuestUserAggregatedStatusCondition.goToStep,
        },
        {
          id: options.GuestUserTemperatureThresholdViolationCondition.optionId,
          displayName: options.GuestUserTemperatureThresholdViolationCondition.displayName,
          description: options.GuestUserTemperatureThresholdViolationCondition.description,
          icon: options.GuestUserTemperatureThresholdViolationCondition.icon,
          name: 'GuestUserTemperatureThresholdViolationCondition',
          checked: !!conditionGroup.conditions.find(
            (condition) => condition.type === 'GuestUserTemperatureThresholdViolationCondition'
          ),
          onClick: (): void => {
            setConditionType('GuestUserTemperatureThresholdViolationCondition')
          },
          hasMoreSteps: false,
          goToStep: options.GuestUserTemperatureThresholdViolationCondition.goToStep,
        },
        {
          id: options.GuestUserPickUpAddressCondition.optionId,
          displayName: options.GuestUserPickUpAddressCondition.displayName,
          description: options.GuestUserPickUpAddressCondition.description,
          icon: options.GuestUserPickUpAddressCondition.icon,
          checked: !!conditionGroup.conditions.find(
            (condition) => condition.type === 'GuestUserPickUpAddressCondition'
          ),
          name: 'GuestUserPickUpAddressCondition',
          hasMoreSteps: true,
          goToStep: options.GuestUserPickUpAddressCondition.goToStep,
        },
        {
          id: options.GuestUserDeliveryAddressCondition.optionId,
          displayName: options.GuestUserDeliveryAddressCondition.displayName,
          description: options.GuestUserDeliveryAddressCondition.description,
          icon: options.GuestUserDeliveryAddressCondition.icon,
          checked: !!conditionGroup.conditions.find(
            (condition) => condition.type === 'GuestUserDeliveryAddressCondition'
          ),
          name: 'GuestUserDeliveryAddressCondition',
          hasMoreSteps: true,
          goToStep: options.GuestUserDeliveryAddressCondition.goToStep,
        },
      ],
    },
    {
      id: 1,
      stepLevel: 2,
      title: translation.stepTwoTitle,
      type: 'address',
      value: conditionGroup.conditions.find((condition) => condition.type === 'GuestUserPickUpAddressCondition')
        ?.address,
      onSet: (value): void => {
        setAddressGroupConditions('GuestUserPickUpAddressCondition', value)
      },
      goToStep: 4,
      goBackToStep: 0,
    },
    {
      id: 2,
      stepLevel: 2,
      title: translation.stepThreeTitle,
      type: 'address',
      value: conditionGroup.conditions.find((condition) => condition.type === 'GuestUserDeliveryAddressCondition')
        ?.address,
      onSet: (value): void => {
        setAddressGroupConditions('GuestUserDeliveryAddressCondition', value)
      },
      goToStep: 4,
      goBackToStep: 0,
    },
    {
      id: 3,
      stepLevel: 2,
      title: translation.stepFourTitle,
      type: 'aggregated_status',
      value: conditionGroup.conditions.find((condition) => condition.type === 'GuestUserAggregatedStatusCondition')
        ?.status,
      onSet: (value): void => {
        setAggregatedStatusGroupConditions('GuestUserAggregatedStatusCondition', value)
      },
      goToStep: 4,
      goBackToStep: 0,
    },
    {
      id: 4,
      stepLevel: 3,
      title: translation.stepFiveTitle,
      addMoreText: translation.addMoreText,
      titleIcon: <SupervisedUserCircleIcon color="success" sx={{ width: '40px', height: '40px', mr: 1 }} />,
      type: 'last_step',
      customFields: [
        {
          type: 'text',
          value: conditionGroup.name,
          placeholder: translation.customFieldGroupNamePlaceholder,
          onSet: (e): void => {
            setGroupName(e.target.value)
          },
          label: translation.customFieldGroupName,
        },
        {
          type: 'multiselect',
          onSet: (newValue): void => {
            setUserEmails(newValue.map((user) => user.value))
          },
          label: translation.customFieldUsers,
          placeholder: translation.customFieldUsersPlaceholder,
          value: guestUsers
            .filter((user) => conditionGroup.userEmails.includes(user.email))
            .map((user) => {
              return {
                value: user.email,
                label: user.displayName ?? user.email,
              }
            }),
          options: guestUsers.map((user) => {
            return {
              value: user.email,
              label: user.displayName ?? user.email,
            }
          }),
        },
      ],
      goToStep: 0,
      goBackToStep: 0,
    },
  ]
}

export const getMappedConditions = (
  conditionGroup: ConditionGroup,
  removeCondition?: (number) => void
): MappedEntity[] => {
  const { statusTranslations } = sharingRuleCreateTranslations()

  const conditionsMapped: MappedEntity[] = []
  conditionGroup.conditions.forEach((condition, i) => {
    const newConditionMapped = getMappedOption(condition.type)
    if (!newConditionMapped) return
    if (removeCondition) newConditionMapped.remove = (): void => removeCondition(i)
    if (
      (condition.type === 'GuestUserPickUpAddressCondition' ||
        condition.type === 'GuestUserDeliveryAddressCondition') &&
      condition.address
    ) {
      const addressString = Object.values(condition.address)
        .filter(Boolean)
        .map((data) => data.trim())
        .join(', ')
      condition.address.country &&
        (newConditionMapped.detailIcon = <KNCountryFlag countryCode={condition.address.country} />)
      newConditionMapped.details = addressString
    }
    if (condition.type === 'GuestUserAggregatedStatusCondition' && condition.status) {
      newConditionMapped.details = statusTranslations[condition.status]
    }
    conditionsMapped.push(newConditionMapped)
  })

  return conditionsMapped
}
