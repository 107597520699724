import i18n from 'i18n'

export const shipmentPairerTranslations = (): Record<any, any> => {
  const dropdown = 'modules.cv.pair.dropdown'
  const button = 'modules.cv.pair.button'
  const card = 'modules.cv.pair.card'
  return {
    translation: {
      moduleName: i18n.t('modules.cv.pair.moduleName'),
      dropdown: {
        name: i18n.t(`${dropdown}.name`),
        coldChain: i18n.t(`${dropdown}.coldChain`),
        controlled: i18n.t(`${dropdown}.controlled`),
        standard: i18n.t(`${dropdown}.standard`),
      },
      button: {
        back: i18n.t(`${button}.back`),
        add_shipment: i18n.t(`${button}.add_shipment`),
        edit_shipment: i18n.t(`${button}.edit_shipment`),
        loading: i18n.t(`${button}.loading`),
        success: i18n.t(`${button}.success`),
        error: i18n.t(`${button}.error`),
      },
      card: {
        button: {
          remove_shipment: i18n.t(`${card}.button.remove_shipment`),
          add_beacon: i18n.t(`${card}.button.add_beacon`),
          scan_qr_code: i18n.t(`${card}.button.scan_qr_code`),
          temperature_threshold_toggle: i18n.t(
            `${card}.button.temperature_threshold_toggle`
          ),
          temperature_threshold_type: i18n.t(
            `${card}.button.temperature_threshold_type`
          ),
          temperature_threshold_min: i18n.t(
            `${card}.button.temperature_threshold_min`
          ),
          temperature_threshold_max: i18n.t(
            `${card}.button.temperature_threshold_max`
          ),
          finish_pairing: i18n.t(`${card}.button.finish_pairing`),
          tooltip: i18n.t(`${card}.button.tooltip`),
        },
        error: {
          shipment_requered: i18n.t(`${card}.error.shipment_requered`),
          beacon_requered: i18n.t(`${card}.error.beacon_requered`),
          duplicate_beacon: i18n.t(`${card}.error.duplicate_beacon`),
          special_chars: i18n.t(`${card}.error.special_chars`),
        },
        label: {
          or: i18n.t(`${card}.label.or`),
          beacon_id: i18n.t(`${card}.label.beacon_id`),
          shipment_id: i18n.t(`${card}.label.shipment_id`),
          shipment_description: i18n.t(`${card}.label.shipment_description`),
          empty_field: i18n.t(`${card}.label.empty_field`),
          optional_field: i18n.t(`${card}.label.optional_field`),
          automatic_unpairing: i18n.t(`${card}.label.automatic_unpairing`),
        },
      },
    },
  }
}
