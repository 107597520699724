// Types
import { TrackingDeviceInfoProps } from './TrackingInfo.types'

// Functional
import { fetchGet } from 'global/helpers/fetchWrapper'

export const getTrackingDevicesData = async (
  pairingcid: string,
  token?: string,
  pinCode?: string
): Promise<TrackingDeviceInfoProps[]> => {
  try {
    const data = await fetchGet(
      `pairings/${pairingcid}/devices`,
      token && pinCode
        ? {
            Authorization: `Bearer ${token}:${pinCode}`,
          }
        : {},
      'si'
    )
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}
