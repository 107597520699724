import { useState, ReactElement } from 'react'

// @mui imports
import { IconButton } from '@mui/material'
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

// KN Components
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Data
import { helpMenuTranslations } from './HelpMenu.data'
import { getWelcomeGuide } from 'screens/VisibilityDashboard/VisibilityDashboard.guide'

const HelpMenu = (): ReactElement => {
  // Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }

  // Translated Data //
  const { translation } = helpMenuTranslations()

  // Menu items list //
  const helpData: HelpMenuItem[] = [
    {
      title: translation.paulWalkerHelpMenuItem,
      onClick: (): void => {
        const paulWalker = getWelcomeGuide(false)
        paulWalker.drive()
        handleClose()
      },
    },
  ]

  interface HelpMenuItem {
    onClick: () => void
    title: string
  }

  // Notification UI
  const getNotificationUI = (menuItem: HelpMenuItem, index: number): ReactElement => {
    return (
      <Box key={index} my={1} sx={{ position: 'relative', width: '150px' }}>
        <KNTypography
          variant="body2"
          color="secondary.main"
          mb={0.5}
          onClick={menuItem.onClick}
          sx={{ cursor: 'pointer' }}
        >
          {menuItem.title}
        </KNTypography>
      </Box>
    )
  }

  return (
    <>
      <IconButton onClick={handleClick} sx={{ marginLeft: 1 }} color="primary">
        <HelpOutlineIcon />
      </IconButton>

      <Menu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 50,
        }}
        sx={{
          '& .MuiPaper-root': {
            mt: 1,
            p: 2,
          },
        }}
      >
        <KNTypography variant="displayXXS_SB">{translation.moduleName}</KNTypography>
        {helpData.map((notification, index) => getNotificationUI(notification, index))}
      </Menu>
    </>
  )
}

export default HelpMenu
