// Functional
import { fetchDelete, fetchGet, fetchPost } from 'global/helpers/fetchWrapper'

export const getAllShipmentsData = async (pageNumber: number, filterString?: string): Promise<any> =>
  await fetchGet(`shipmentList?pageSize=10&pageNumber=${pageNumber}${filterString ?? ''}`, {}, 'si')

export const getLevelIData = async (): Promise<any[]> =>
  await fetchGet(`shipments?pageSize=5&pageNumber=${0}`, {}, 'si')

export const getLevelMaxData = async (): Promise<any[]> =>
  await fetchGet(`pairings?pageSize=5&pageNumber=${0}`, {}, 'si')

export const followShipment = async (shipmentId: string): Promise<void> =>
  await fetchPost(`shipments/${shipmentId}/follow`, null, {}, 'si')

export const unfollowShipment = async (shipmentId: string): Promise<void> =>
  await fetchPost(`shipments/${shipmentId}/unfollow`, null, {}, 'si')

export const unpairShipment = async (pairingCid: string): Promise<void> =>
  await fetchDelete(`pairings/${pairingCid}`, null, {}, 'si')

export const deletePairing = async (pairingCid: string): Promise<void> =>
  await fetchDelete(`pairings/${pairingCid}/remove`, null, {}, 'si')
