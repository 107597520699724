import i18n from 'i18n'

export const shipmentDetailsTranslations = (): Record<any, any> => {
  const shipmentDetails = 'screens.cv.shipment_details'
  return {
    translation: {
      screenName: i18n.t(`${shipmentDetails}.screenName`),
      cargo_info: i18n.t(`${shipmentDetails}.cargo_info`),
      attachments: i18n.t(`${shipmentDetails}.attachments`),
      tracking_details: i18n.t(`${shipmentDetails}.tracking_details`),
      combined_view: i18n.t(`${shipmentDetails}.combined_view`),
      map_view: i18n.t(`${shipmentDetails}.map_view`),
      temperature_view: i18n.t(`${shipmentDetails}.temperature_view`),
      mobileViews: i18n.t(`${shipmentDetails}.mobile_views`),
    },
  }
}
