import { ReactElement } from 'react'

// @mui imports
import { Box } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'

// KN imports
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'

// Types
import { MonitoringRuleFormTextFieldProps } from './MonitoringRuleForm.types'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

const MonitoringRuleFormTextField = ({
  label,
  placeholder,
  name,
  control,
}: MonitoringRuleFormTextFieldProps): ReactElement => {
  return (
    <Box mt={1}>
      <InputLabel>
        <KNTypography variant="textLG" color="black.main">
          {label}
        </KNTypography>
      </InputLabel>
      <KNFormText
        sx={{
          input: {
            background: 'white',
            borderRadius: 'inherit',
          },
        }}
        name={name}
        placeholder={placeholder}
        control={control}
      />
    </Box>
  )
}

export default MonitoringRuleFormTextField
