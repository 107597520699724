import i18n from 'i18n'

export const addressInfoTranslations = (): Record<any, any> => {
  const addressInfo = 'modules.cv.address_details'
  return {
    translation: {
      moduleName: i18n.t(`${addressInfo}.moduleName`),
      origin: i18n.t(`${addressInfo}.origin`),
      destination: i18n.t(`${addressInfo}.destination`),
      address: i18n.t(`${addressInfo}.address`),
      expected: i18n.t(`${addressInfo}.expected`),
    },
  }
}
