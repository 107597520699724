import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'

// @mui material imports //
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import ThermostatIcon from '@mui/icons-material/Thermostat'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LocationOnIcon from '@mui/icons-material/LocationOn'

// KN imports //
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Data //
import { snippetTranslations } from './Snippets.data'

// Types //
import { TemplateDataContextType } from 'modules/NotificationsMenu/NotificationsMenu.type'
import { SnippetWrapperProps } from './Snippets.types'

const SnippetWrapper: React.FC<SnippetWrapperProps> = ({
  shipmentId,
  variant,
  typeName,
  eventType,
  date,
  geofenceDirection,
  children,
}) => {
  // Data
  const { translation } = snippetTranslations()

  // History
  const history = useHistory()

  const getEventTypeUI = (type: TemplateDataContextType): ReactElement => {
    let icon
    let title

    switch (type) {
      case TemplateDataContextType.temperature:
        icon = <ThermostatIcon sx={{ width: '15px', height: '15px' }} color="primary" />
        title = translation.tempBreached
        break
      case TemplateDataContextType.geofence:
        icon = <LocationOnIcon sx={{ width: '15px', height: '15px' }} color="primary" />
        geofenceDirection === 'ENTER' ? (title = translation.geofenceEnter) : (title = translation.geofenceExit)
        break
      case TemplateDataContextType.shipment_status:
        icon = <InfoOutlinedIcon sx={{ width: '15px', height: '15px' }} color="primary" />
        title = translation.statusChanged
        break
      case TemplateDataContextType.delivery:
        icon = <InfoOutlinedIcon sx={{ width: '15px', height: '15px' }} color="primary" />
        title = translation.delivered
        break
      case TemplateDataContextType.pick_up:
        icon = <InfoOutlinedIcon sx={{ width: '15px', height: '15px' }} color="primary" />
        title = translation.pickedUp
        break
    }

    return (
      <Box>
        <Box display="flex">
          <Box display="flex" alignItems="center" mr={0.5}>
            {icon}
          </Box>
          <Box>
            <KNTypography variant="textLG_SB" color="primary">
              {title}
            </KNTypography>
          </Box>
        </Box>

        {date && (
          <Box ml={2.5}>
            <KNTypography variant="textSM" color="primary.light" mb={0.5}>
              {date}
            </KNTypography>
          </Box>
        )}
      </Box>
    )
  }

  const getNotificationUI = (): ReactElement => (
    <Box mb={2}>
      <Box>{getEventTypeUI(eventType)}</Box>
      <Box display="flex" flexDirection="column" sx={{ overflowWrap: 'break-word' }} my={2}>
        <KNTypography variant="textSM" color="primary.light">
          {typeName}
        </KNTypography>
        <KNTypography
          variant="textLG_SB"
          color="black.main"
          onClick={(): void => history.push(`/shipment-details/S/${shipmentId}/${shipmentId}`)}
          sx={{ cursor: 'pointer' }}
        >
          {shipmentId}
        </KNTypography>
      </Box>
      <Box>
        <Box>{children}</Box>
      </Box>
    </Box>
  )

  const getGeneralUI = (): ReactElement => (
    <Box>
      <Box display="flex" flexDirection="column" sx={{ overflowWrap: 'break-word' }}>
        <KNTypography variant="textSM" color="primary.light">
          {typeName}
        </KNTypography>
        <KNTypography variant="textLG_SB" color="black.main">
          {shipmentId}
        </KNTypography>
      </Box>
      <Box>{children}</Box>
    </Box>
  )

  return variant === 'notification' ? getNotificationUI() : getGeneralUI()
}

export default SnippetWrapper
