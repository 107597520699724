import { useEffect, useState, ReactElement } from 'react'
import _ from 'lodash'
import { Link as RouterLink } from 'react-router-dom'

// @mui imports
import { Theme } from '@mui/material/styles/createTheme'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Drawer from '@mui/material/Drawer'
import Fade from '@mui/material/Fade'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import MenuItem from '@mui/material/MenuItem'
import Pagination from '@mui/material/Pagination'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import useMediaQuery from '@mui/material/useMediaQuery'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { SystemStyleObject } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'

// KN imports
import borders from 'assets/theme/base/borders'
import theme from 'assets/theme'
import KNChip from 'components/KN_Components/Base/KNChip/KNChip'
import KNCountryFlag from 'components/KN_Molecules/KNCountryFlag/KNCountryFlag'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import Modal from 'components/Molecules/Modal/Modal'

// Functional
import { getErrorMessage } from 'global/helpers/errorHandler'

// Types
import {
  MegaTableConfirmMenuOption,
  MegaTableMenuOption,
  MegaTableProps,
  MegaTableRow,
  MegaTableRowLabel,
} from './MegaTable.types'
import { MessageProps } from 'modules/GeneralInfoList/submodules/ShipmentDetailsMenu/ShipmentDetailsMenu.types'

// Data
import { MegaTableTranslations } from './MegaTable.data'
import ShipmentDetailsMenuAlert from 'modules/GeneralInfoList/submodules/ShipmentDetailsMenu/ShipmentDetailsMenuAlert'

const MegaTable: React.FC<MegaTableProps> = ({ tableRows, paging, dataTest }) => {
  const [rightEnhancerVisible, setRightEnhancerVisible] = useState<number | null>(null)
  const [menuVisible, setMenuVisible] = useState<number | null>(null)
  const [cellMenuVisible, setCellMenuVisible] = useState<string | null>(null)
  const [menuLoading, setMenuLoading] = useState<number>()
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<MessageProps>()
  const [activeModal, setActiveModal] = useState<{
    id?: string
    option?: (row) => ReactElement
  }>({})
  const [activeSlideIn, setActiveSlideIn] = useState<{
    id?: string
    option?: (row) => ReactElement
  }>({})
  const [openDialog, setOpenDialog] = useState<{
    id?: string
    option?: MegaTableConfirmMenuOption
  }>({})

  // Paging
  const [page, setPage] = useState(paging?.activePage)
  const handleChangePage = (event: any, newPage: number): void => {
    setPage(newPage)
    paging?.onChange(newPage)
  }
  useEffect(() => {
    setPage(paging?.activePage)
  }, [paging?.activePage])

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  // Translated Data
  const { translation } = MegaTableTranslations()

  const getCellLabels = (labels: MegaTableRowLabel[]): JSX.Element => (
    <Box>
      {labels.map((label, index) => (
        <KNChip
          key={index}
          data-test={`${String(dataTest)}-megatable-label-${String(label.name)}`}
          label={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {label.icon && <Icon sx={{ mr: 1 }}>{label.icon}</Icon>}
              <KNTypography
                variant="textMD"
                color={label.color}
                sx={({ typography: { lineHeight } }: Theme): SystemStyleObject<Theme> => ({
                  lineHeight: lineHeight.sm,
                })}
              >
                {label.name}
              </KNTypography>
            </Box>
          }
          size="small"
          color={label.backgroundColor}
          variant="contained"
          sx={({ typography }: Theme): SystemStyleObject<Theme> => ({
            height: 'auto',
            mt: 0.5,
            mr: 1,
            fontSize: typography.size.xs,
          })}
        />
      ))}
    </Box>
  )

  const handleClickMenuItem = async (
    withAlert: (row: MegaTableRow) => Promise<void>,
    type: string,
    row: MegaTableRow,
    messageText: string
  ): Promise<void> => {
    try {
      const response = await withAlert(row)
      setMessage({
        status: String(response),
        name: type,
        messageText: `${messageText}${typeof response === 'string' ? `: ${String(response)}` : ''}`,
        persistent: true,
      })
    } catch (error) {
      setMessage({
        status: getErrorMessage(error),
        name: type,
        messageText: messageText,
        persistent: true,
      })
    }
    setMenuLoading(undefined)
    setAlertOpen(true)
  }

  const getMenuOptions = (row: MegaTableRow, options: MegaTableMenuOption[], variant: string): JSX.Element => (
    <Paper
      sx={({ boxShadows, borders: { borderRadius } }: Theme): SystemStyleObject<Theme> => ({
        position: 'absolute',
        right: variant === 'right' ? '0' : 'auto',
        left: variant === 'left' ? '0' : 'auto',
        top: 10,
        width: 'auto',
        zIndex: 3,
        boxShadow: boxShadows.standardBoxShadow,
        borderRadius: borderRadius.lg,
        py: 1,
      })}
      elevation={5}
      onMouseLeave={(): void => {
        setCellMenuVisible(null)
        setMenuVisible(null)
      }}
    >
      {options.map((option, index) =>
        menuLoading === index ? (
          <Box key={index}>
            <MenuItem sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size={20} />
            </MenuItem>
          </Box>
        ) : (
          <MenuItem
            key={index}
            onClick={async (): Promise<void> => {
              if (option.withModal) {
                setActiveModal({
                  id: row.cid ?? '',
                  option: option.withModal,
                })
              } else if (option.withConfirmation) {
                setOpenDialog({
                  id: row.cid ?? '',
                  option: option.withConfirmation,
                })
              } else if (option.withAlert) {
                setMenuLoading(index)
                await handleClickMenuItem(option.withAlert, option.displayName, row, option.optionMessage ?? '')
              } else if (option.onClick) {
                option.onClick(row)
              } else if (option.withSlideIn) {
                setActiveSlideIn({
                  id: row.cid ?? '',
                  option: option.withSlideIn,
                })
              }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                verticalAlign: 'middle',
              }}
            >
              {option.icon && <Icon>{option.icon}</Icon>}
              <KNTypography
                variant="body2"
                sx={({ palette: { dark } }: Theme): SystemStyleObject<Theme> => ({
                  ml: 2,
                  color: dark.main,
                })}
              >
                {option.displayName}
              </KNTypography>
            </Box>
          </MenuItem>
        )
      )}
    </Paper>
  )

  const getIndicators = (icon, index): ReactElement => (
    <Tooltip
      key={index}
      title={
        <Stack spacing={1}>
          {icon.tooltip.map((entry, i) => (
            <Box
              key={i}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
              data-test={`${String(dataTest)}-megatable-indicator-${String(entry.title)}`}
            >
              {entry.title && (
                <KNTypography
                  variant="textMD"
                  sx={({ palette: { primary } }: Theme): SystemStyleObject<Theme> => ({
                    color: primary.contrastText,
                  })}
                >
                  {entry.title}
                </KNTypography>
              )}
              <KNTypography
                variant="textSM"
                sx={({ palette: { white } }: Theme): SystemStyleObject<Theme> => ({
                  color: white.main,
                })}
              >
                {entry.text}
              </KNTypography>
            </Box>
          ))}
        </Stack>
      }
    >
      <Box
        sx={({
          borders: { borderRadius },
          functions: { pxToRem },
          typography: { size },
        }: Theme): SystemStyleObject<Theme> => ({
          borderRadius: borderRadius.round,
          backgroundColor: icon.backgroundColor,
          color: icon.color,
          width: pxToRem(22),
          height: pxToRem(22),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ml: 0.5,
          fontSize: size.xxs,
          zIndex: 2,
        })}
      >
        {icon.name}
      </Box>
    </Tooltip>
  )

  const getTableRows = (index: number, tableRow: MegaTableRow): ReactElement => {
    return (
      <Box
        key={index}
        sx={{
          position: 'relative',
          width: '100%',
          '&::after': {
            content: '""',
            position: 'absolute',
            marginLeft: 0,
            marginRight: 'auto',
            textAlign: 'center',
            left: 0,
            right: 0,
            height: '1px',
            backgroundColor: ({ palette: { text } }) => text.disabled,
            transition: 'all 500ms',
          },
          '&:hover::after': {
            backgroundColor: ({ palette }) => palette.dark.main,
          },
        }}
        onMouseEnter={(): void => setRightEnhancerVisible(tableRow.rowId)}
        onMouseLeave={(): void => {
          setRightEnhancerVisible(null)
        }}
        data-test={`${String(dataTest)}-megatable-row-${String(tableRow.rowId)}`}
      >
        <Grid
          container
          mb={0.5}
          spacing={1}
          alignItems={'flex-start'}
          sx={({ functions: { pxToRem } }: Theme): SystemStyleObject<Theme> => ({
            minHeight: pxToRem(70),
          })}
        >
          {tableRow.ribbon && (
            <Box
              sx={{
                position: 'absolute',
                top: '20px',
                backgroundColor: ({ palette: { warning } }: Theme): string => warning.light,
                color: ({ palette: { dark } }: Theme): string => dark.light,
                borderTopLeftRadius: ({ borders: { borderRadius } }: Theme): string | number => borderRadius.lg,
                borderBottomLeftRadius: ({ borders: { borderRadius } }: Theme): string | number => borderRadius.lg,
                right: '-40px',
                pr: 1,
                pl: 2,
                zIndex: 3,
                '&:after': {
                  position: 'absolute',
                  left: 'auto',
                  right: 0,
                  borderStyle: 'solid',
                  borderWidth: '1.2em 1.2em 0 0',
                  borderRightColor: 'transparent !important',
                  borderTopColor: ({ palette: { warning } }: Theme): string => warning.main,
                  content: "''",
                  top: '100%',
                  width: 0,
                  height: 0,
                },
              }}
            >
              <Box>{tableRow.ribbon}</Box>
            </Box>
          )}
          <Grid item container spacing={1} md>
            {tableRow.rowCells.map((row, cellIndex) => (
              <Grid item xs={12} md={row.cellSize ?? 2.4} key={cellIndex}>
                <Box
                  data-test={`${String(dataTest)}-megatable-column-${String(cellIndex)}-row-${String(tableRow.rowId)}`}
                >
                  <KNTypography
                    variant="textMD"
                    sx={{
                      display: 'flex',
                      mt: 1.5,
                      cursor: row.menuOptions || row.withModal ? 'pointer' : 'auto',
                    }}
                    onClick={
                      row.withModal
                        ? (): void =>
                            setActiveModal({
                              id: tableRow.pairingCid ?? tableRow.cid ?? '',
                              option: row.withModal,
                            })
                        : undefined
                    }
                  >
                    {!row.cellHyperlink && !row.cellColor && (
                      <KNTypography
                        data-test={`${String(dataTest)}-megatable-cellContent-${String(cellIndex)}-${String(
                          tableRow.rowId
                        )}`}
                        component={'span'}
                        color="text.main"
                        sx={({ palette: { dark } }: Theme): SystemStyleObject<Theme> => ({
                          position: 'relative',
                          '&:hover': {
                            textDecoration:
                              row.menuOptions || row.withModal ? `underline ${dark.main as string}` : 'none',
                          },
                        })}
                        onClick={(): void => setCellMenuVisible(`${index}-${cellIndex}`)}
                      >
                        {row.cellContent}
                        {row.cellFlag && (
                          <KNCountryFlag countryCode={row.cellFlag} sx={{ ml: 1, display: 'inline-flex' }} />
                        )}
                        {row.menuOptions && row.menuOptions.length > 0 && (
                          <Fade in={cellMenuVisible === `${index}-${cellIndex}`}>
                            {getMenuOptions(tableRow, row.menuOptions, 'left')}
                          </Fade>
                        )}
                      </KNTypography>
                    )}
                    {row.cellHyperlink && (
                      <KNTypography variant="body2">
                        <Link
                          component={RouterLink}
                          to={row.cellHyperlink}
                          variant={rightEnhancerVisible === tableRow.rowId ? 'underlined' : undefined}
                          sx={({ palette: { secondary, dark } }: Theme): SystemStyleObject<Theme> => ({
                            color: dark.main,
                            transition: 'all 500ms',
                            textDecoration: 'none',
                            '&:hover': {
                              color: secondary.main,
                            },
                          })}
                          data-test={`${String(dataTest)}-megatable-hyperlink-${String(tableRow.rowId)}`}
                        >
                          {row.cellContent}
                        </Link>
                      </KNTypography>
                    )}
                    {row.cellIcons?.map((icon, index) => getIndicators(icon, index))}
                    {row.cellColor && <KNChip color={row.cellColor} label={row.cellContent} size="small" />}
                  </KNTypography>
                  <KNTypography
                    variant="textMD"
                    color="text.light"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {row.additionalContent && row.additionalContent}
                  </KNTypography>
                  {row.cellLabels && getCellLabels(row.cellLabels)}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {tableRow.indicators && (
          <Box
            sx={({ functions: { pxToRem } }: Theme): SystemStyleObject<Theme> => ({
              position: 'absolute',
              top: '45%',
              right: pxToRem(25),
            })}
          >
            {tableRow.indicators.map((entry, index) => getIndicators(entry, index))}
          </Box>
        )}
        {tableRow.menuOptions && tableRow.menuOptions.length > 0 && (
          <Fade in={rightEnhancerVisible === tableRow.rowId}>
            <Box
              sx={{
                position: 'absolute',
                top: '40%',
                right: -15,
              }}
            >
              {!tableRow.menudisabled && (
                <IconButton
                  color="primary"
                  data-test="shipments-list-option-button"
                  id={String(tableRow.rowId)}
                  size="small"
                  onClick={(): void => setMenuVisible(tableRow.rowId)}
                  sx={{
                    zIndex: menuVisible === tableRow.rowId ? 0 : 9,
                  }}
                >
                  <Icon>more_horiz</Icon>
                </IconButton>
              )}
              <Fade in={menuVisible === tableRow.rowId}>{getMenuOptions(tableRow, tableRow.menuOptions, 'right')}</Fade>
            </Box>
          </Fade>
        )}
        {activeModal?.id === tableRow.cid && activeModal?.option && (
          <Dialog open={activeModal.id === tableRow.cid} onClose={(): void => setActiveModal({})}>
            <DialogContent>
              <Box display="flex" justifyContent="flex-end">
                <IconButton size="small" onClick={(): void => setActiveModal({})}>
                  <CloseIcon data-test="close-icon" fontSize="small" />
                </IconButton>
              </Box>

              <Box my={2}>{activeModal.option(tableRow)}</Box>
            </DialogContent>
          </Dialog>
        )}
        {openDialog.id === tableRow.cid && openDialog.option && (
          <Dialog
            sx={{
              '& .MuiDialog-paper': {
                width: '80%',
                maxHeight: 435,
                zIndex: 9999,
              },
            }}
            maxWidth="xs"
            open={openDialog.id === tableRow.cid}
          >
            <DialogTitle>{openDialog.option?.title}</DialogTitle>
            <DialogContent>{openDialog.option?.content}</DialogContent>
            <DialogActions>
              <Button autoFocus onClick={(): void => setOpenDialog({ id: undefined, option: undefined })}>
                {openDialog.option?.cancelButton}
              </Button>
              <Button onClick={(): void => openDialog.option?.confirm(tableRow)}>
                {openDialog.option?.confirmButton}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {activeSlideIn?.id === tableRow.cid && activeSlideIn?.option && (
          <Drawer
            open={activeSlideIn.id === tableRow.cid}
            anchor="right"
            onClose={(): void => setActiveSlideIn({})}
            PaperProps={{
              sx: {
                borderRadius: borders.borderRadius.md,
                width: isMobile ? '100%' : isMedium ? '50%' : '30%',
              },
            }}
          >
            <Box padding={3}>{activeSlideIn.option(tableRow)}</Box>
          </Drawer>
        )}
      </Box>
    )
  }

  return (
    <>
      <ShipmentDetailsMenuAlert message={message} alertOpen={alertOpen} setAlertOpen={setAlertOpen} />
      <Box>{tableRows.map((tableRow, index) => getTableRows(index, tableRow))}</Box>
      {paging && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
            <KNTypography variant="textLG" color="text.light">
              {translation.total_items}:
            </KNTypography>
            <KNTypography data-test="number-items" variant="textLG" color="dark.main" sx={{ ml: 0.5 }}>
              {paging.totalItems}
            </KNTypography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            {!paging.loading ? (
              <Pagination
                data-test="pagination"
                page={page}
                count={paging.totalPages}
                hideNextButton={!paging.nextPage}
                shape="rounded"
                onChange={handleChangePage}
                sx={{
                  display: 'flex',
                }}
              />
            ) : (
              <CircularProgress />
            )}
          </Box>
        </>
      )}
    </>
  )
}

export default MegaTable
