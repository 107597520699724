import { useState, useEffect, useContext, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'

// @mui imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

// KN Components
import { analyticsEvent } from 'global/helpers/analytics'
import { DriverListContext } from 'context/drivers/DriverListContext'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'

const DriverFilters = (): ReactElement => {
  const { t } = useTranslation()
  const [driverListState, driverListDispatch] = useContext(DriverListContext)
  const [payload, setPayload] = useState<object | null>(null)
  const [debouncedPayload] = useDebounce(payload, 100)

  useEffect(() => {
    if (debouncedPayload) {
      driverListDispatch({
        type: 'setFilters',
        payload: debouncedPayload,
      })
    }
    setPayload(null)
  }, [debouncedPayload])

  const handleResetClick = useCallback((): void => {
    driverListDispatch({ type: 'resetFilters' })
    analyticsEvent('polestar_cs_driver_filters_reset')
  }, [])

  return (
    <Box sx={{ position: 'relative' }}>
      <Grid container spacing={2} mb={2} data-test="drivers-filter-container">
        <Grid item xs={12} sm={4}>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={driverListState.filters.keywords ?? []}
            onChange={(event, newValue): void => {
              setPayload({
                keywords: [...newValue] as string[],
              })
              analyticsEvent('polestar_cs_driver_filters', ['keywords', newValue.join(', ')])
            }}
            renderTags={(tagValue, getTagProps): ReactElement[] =>
              tagValue.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip {...getTagProps({ index })} size="small" label={option} />
              ))
            }
            renderInput={(params): ReactElement => (
              <TextField {...params} label={t('screens.cs.driver_manager.filters.search')} />
            )}
          />
        </Grid>
      </Grid>
      {driverListState.filtersDirty && (
        <KNButton
          size="small"
          variant="text"
          color="secondary"
          data-test="reset-driver-filters-button"
          onClick={handleResetClick}
          sx={{
            position: 'absolute',
            left: 0,
            bottom: '-28px',
            paddingX: 0,
            paddingY: '4px',
            minHeight: 'auto',
          }}
        >
          {t('general.reset_filters')}
        </KNButton>
      )}
    </Box>
  )
}

export default DriverFilters
