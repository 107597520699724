import i18n from 'i18n'

export const shareShipmentTranslations = (): Record<any, any> => {
  const shareShipment = 'screens.cv.share_shipment'
  return {
    translation: {
      title: i18n.t(`${shareShipment}.title`),
      errorTitle: i18n.t(`${shareShipment}.error_title`),
      message: i18n.t(`${shareShipment}.message`),
      errorMessage: i18n.t(`${shareShipment}.error_message`),
      submit: i18n.t(`${shareShipment}.submit`),
      goBack: i18n.t(`${shareShipment}.go_back`),
    },
  }
}
