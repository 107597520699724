import { FC, memo, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

// @mui imports
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import { Theme } from '@mui/material/styles/createTheme'
import Tooltip from '@mui/material/Tooltip'
import useMediaQuery from '@mui/material/useMediaQuery'
import { SystemStyleObject } from '@mui/system'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import ShipmentDetailsMenuAlert from 'modules/GeneralInfoList/submodules/ShipmentDetailsMenu/ShipmentDetailsMenuAlert'

// Functional
import { getErrorMessage } from 'global/helpers/errorHandler'

// Types
import KNCardProps, { KNCardIndicator } from './KNCard.types'

const KNCard: FC<KNCardProps> = ({
  title,
  link,
  highlighted,
  label,
  mobileOnly = useMediaQuery('(max-width:401px)') && true,
  leftIndicators,
  rightIndicators,
  gridItems,
  menuOptions,
}) => {
  const [cardHovered, cardHoveredSet] = useState(false)
  const handleCardHover = useCallback(() => cardHoveredSet(!cardHovered), [cardHovered])

  // CARD MENU
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const cardMenuOpen = Boolean(anchorEl)
  const handleCardMenuClose = useCallback(() => setAnchorEl(null), [])
  const handleCardMenuClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
    []
  )

  // MODAL
  const [cardModal, openCardModal] = useState<number>()
  const handleModalOpen = useCallback((id: number) => openCardModal(id), [])

  // ALERT
  const [alertOpen, setAlertOpen] = useState(false)
  const [message, setMessage] = useState<{
    status: string
    name: string
    messageText?: string
    persistent?: boolean
  }>()

  // CONFIRMATION DIALOG
  const [openDialog, setOpenDialog] = useState(false)

  const IndicatorTemplate: FC<{ indicator: KNCardIndicator }> = memo(({ indicator }) => {
    return (
      <Tooltip title={indicator.description}>
        <Box
          sx={({
            borders: { borderRadius },
            functions: { pxToRem },
            typography: { size },
          }: Theme): SystemStyleObject<Theme> => ({
            borderRadius: borderRadius.round,
            backgroundColor: indicator.backgroundColor,
            color: indicator.color,
            width: pxToRem(30),
            height: pxToRem(30),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: size.sm,
            mr: indicator.noMargin ? 0 : 1,
          })}
        >
          {indicator.icon}
        </Box>
      </Tooltip>
    )
  })

  const handleClickMenuItem = async (
    onClick: () => Promise<void>,
    type: string,
    messageText?: string
  ): Promise<void> => {
    try {
      const response = await onClick()
      setMessage({
        status: String(response),
        name: type,
        messageText: `${messageText as string}${typeof response === 'string' ? `: ${String(response)}` : ''}`,
        persistent: true,
      })
    } catch (error) {
      setMessage({
        status: getErrorMessage(error),
        name: type,
        messageText: messageText,
        persistent: true,
      })
    }
    setAlertOpen(true)
  }

  return (
    <>
      <ShipmentDetailsMenuAlert message={message} alertOpen={alertOpen} setAlertOpen={setAlertOpen} />

      <Box
        sx={({
          borders: { borderRadius, borderWidth },
          boxShadows,
          palette: { white, primary, secondary },
        }: Theme): SystemStyleObject<Theme> => ({
          backgroundColor: white.main,
          borderRadius: borderRadius.lg,
          border: `${borderWidth[1]} ${highlighted ? secondary.dark : (white.main as string)} solid`,
          boxShadow: boxShadows.standardBoxShadow,
          position: 'relative',
          py: 2,
          px: 3,
          transition: 'all 200ms ease-in-out',
          '&:hover': {
            border: `${borderWidth[1]} ${primary.light} solid`,
          },
        })}
      >
        <Stack
          data-test="shipments-mobile-card"
          direction={mobileOnly ? 'column' : 'row'}
          justifyContent="space-between"
        >
          <Stack
            direction={mobileOnly ? 'row' : 'column'}
            sx={{ minWidth: mobileOnly ? '100%' : '30%' }}
            justifyContent={mobileOnly ? 'space-between' : 'unset'}
          >
            <Box sx={{ maxWidth: '60%' }}>
              <Link to={link}>
                <KNTypography
                  variant="displayXS_SB"
                  color={'secondary.main'}
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    transition: 'all 0.5s ease-out',
                  }}
                >
                  {title}
                </KNTypography>
              </Link>
            </Box>
            {label && (
              <Box mb={1}>
                <KNTypography
                  data-test="trip-status-mobile"
                  variant="textLG"
                  sx={({
                    borders: { borderRadius },
                    functions: { pxToRem },
                    palette: { white },
                  }: Theme): SystemStyleObject<Theme> => ({
                    borderRadius: borderRadius.xl,
                    backgroundColor: label.color,
                    color: white.main,
                    height: pxToRem(16),
                    py: pxToRem(1),
                    px: 1,
                    width: 'fit-content',
                    lineHeight: pxToRem(14),
                  })}
                >
                  {label.text}
                </KNTypography>
              </Box>
            )}
          </Stack>
          <Grid
            container
            spacing={2}
            wrap={mobileOnly ? undefined : 'nowrap'}
            mt={mobileOnly ? 0 : undefined}
            mb={mobileOnly ? 2.5 : undefined}
          >
            {gridItems?.map((gridItem, index) =>
              !gridItem.valueMultiline ? (
                <Grid item xs={12} key={index} zeroMinWidth>
                  <Stack direction={mobileOnly ? 'row' : 'column'}>
                    <KNTypography
                      variant="textLG"
                      sx={({ palette: { primary } }: Theme): SystemStyleObject<Theme> => ({
                        color: primary.light,
                      })}
                      mr={0.5}
                    >
                      {gridItem.property}
                    </KNTypography>
                    <KNTypography
                      data-test="shipment-id"
                      variant="textLG"
                      sx={({ palette: { dark } }: Theme): SystemStyleObject<Theme> => ({
                        color: dark.main,
                      })}
                      noWrap
                    >
                      {gridItem.value}
                    </KNTypography>
                  </Stack>
                </Grid>
              ) : (
                <Grid item xs={12} key={index} zeroMinWidth>
                  <Stack direction={'column'}>
                    <KNTypography
                      data-test="test"
                      variant="textLG"
                      sx={({ palette: { primary } }: Theme): SystemStyleObject<Theme> => ({
                        color: primary.light,
                      })}
                      mr={0.5}
                    >
                      {gridItem.property}
                    </KNTypography>
                    <KNTypography
                      data-test="shipment-pickup-delivery"
                      variant="textLG"
                      sx={({ palette: { dark } }: Theme): SystemStyleObject<Theme> => ({
                        color: dark.main,
                      })}
                    >
                      {gridItem.value}
                    </KNTypography>
                  </Stack>
                </Grid>
              )
            )}
          </Grid>
        </Stack>
        {menuOptions && menuOptions.length > 0 && (
          <Box sx={{ position: 'absolute', top: 2, right: 0, mt: 1, mr: 0 }}>
            <IconButton
              data-test="shipments-list-option-button"
              size="small"
              color="primary"
              id="cardMenu-iconButton"
              aria-controls={cardMenuOpen ? 'cardMenu' : undefined}
              aria-haspopup="true"
              aria-expanded={cardMenuOpen ? 'true' : undefined}
              onClick={handleCardMenuClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="cardMenu"
              anchorEl={anchorEl}
              open={cardMenuOpen}
              onClose={handleCardMenuClose}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              transformOrigin={{
                horizontal: 'left',
                vertical: 'bottom',
              }}
              PaperProps={{ sx: { mt: 5 } }}
            >
              {menuOptions.map((menuOption, index) => (
                <div key={index}>
                  <MenuItem
                    onClick={async (): Promise<void> => {
                      if (menuOption.withModal) {
                        handleModalOpen(index)
                      } else if (menuOption.withConfirmation) {
                        setOpenDialog(true)
                      } else if (menuOption.onClick) {
                        await handleClickMenuItem(menuOption.onClick, menuOption.text, menuOption.optionMessage)
                      }
                    }}
                  >
                    {menuOption.icon && (
                      <ListItemIcon>
                        <Icon>{menuOption.icon}</Icon>
                      </ListItemIcon>
                    )}
                    <ListItemText disableTypography>{menuOption.text}</ListItemText>
                  </MenuItem>
                  {cardModal === index && menuOption.withModal && (
                    <Dialog open={cardModal === index}>
                      <IconButton
                        aria-label="close"
                        style={{ position: 'absolute', top: 0, right: 0 }}
                        onClick={(): void => openCardModal(undefined)}
                      >
                        <Icon>close</Icon>
                      </IconButton>
                      <Box my={1} p={3}>
                        {menuOption.withModal?.()}
                      </Box>
                    </Dialog>
                  )}
                  {openDialog && menuOption.withConfirmation && (
                    <Dialog
                      sx={{
                        '& .MuiDialog-paper': {
                          width: '80%',
                          maxHeight: 435,
                          zIndex: 9999,
                        },
                      }}
                      maxWidth="xs"
                      open={openDialog}
                    >
                      <DialogTitle>{menuOption.withConfirmation.title}</DialogTitle>
                      <DialogContent>{menuOption.withConfirmation.content}</DialogContent>
                      <DialogActions>
                        <Button autoFocus onClick={(): void => setOpenDialog(false)}>
                          {menuOption.withConfirmation.cancelButton}
                        </Button>
                        <Button onClick={(): void => menuOption.withConfirmation?.confirm()}>
                          {menuOption.withConfirmation.confirmButton}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}
                </div>
              ))}
            </Menu>
          </Box>
        )}
        {leftIndicators && (
          <Stack direction="row" sx={{ position: 'absolute' }}>
            {leftIndicators.map((indicator, index) => {
              return <IndicatorTemplate key={index} indicator={indicator} />
            })}
          </Stack>
        )}
        {rightIndicators && (
          <Stack
            direction="row"
            sx={({ functions: { pxToRem } }: Theme): SystemStyleObject<Theme> => ({
              position: 'absolute',
              right: pxToRem(24),
            })}
          >
            {rightIndicators.map((indicator, index) => {
              return <IndicatorTemplate key={index} indicator={{ ...indicator, noMargin: true }} />
            })}
          </Stack>
        )}
      </Box>
    </>
  )
}

export default memo(KNCard)
