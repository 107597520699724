import i18n from 'i18n'

export const MegaFormTranslations = (): Record<any, any> => {
  const megaForm = 'shared.mega_form'
  return {
    translation: {
      details: i18n.t(`${megaForm}.details`),
      finalize: i18n.t(`${megaForm}.finalize`),
      buttonBack: i18n.t(`${megaForm}.buttons.back`),
      buttonContinue: i18n.t(`${megaForm}.buttons.continue`),
      buttonCancel: i18n.t(`${megaForm}.buttons.cancel`),
      buttonSave: i18n.t(`${megaForm}.buttons.save`),
      typeAggregatedStatus: i18n.t(`${megaForm}.types.aggregated_status`),
    },
  }
}
