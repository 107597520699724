import { ReactElement } from 'react'

export enum CounterType {
  active = 'ACTIVE',
  awaitingForPickup = 'AWAITING_FOR_PICKUP',
  delayedDelivery = 'DELAYED_DELIVERY',
  trackingActive = 'TRACKING_ACTIVE',
  trackingDeviation = 'TRACKING_DEVIATION',
}

export enum CounterTheme {
  primary = 'primary.main',
  light = 'primary.light',
  contrast = 'primary.contrastText',
  white = 'white.main',
  black = 'black.main',
}

export interface CounterColors {
  backgroundColor: CounterTheme
  titleColor: CounterTheme
  amountColor: CounterTheme
  iconBackground: CounterTheme
  iconColor: CounterTheme
}

export interface CounterProps {
  key: string
  name: string
  amount: number
  active: boolean
  description: string
  icon: ReactElement
  onClick: () => void
}

export interface LiveCountersProps {
  basicCounters: CounterProps[]
  advancedCounters: CounterProps[]
  maxCounters: CounterProps[]
}

export interface LiveCounterRequestProps {
  count: number
  type: CounterType
}
