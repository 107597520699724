import { fetchGet, fetchPost, fetchPut } from 'global/helpers/fetchWrapper'

// Types
import {
  CreateOrEditMonitoringRuleFormValues,
  MonitoringRuleFormData,
} from 'modules/MonitoringRuleForm/MonitoringRuleForm.types'
import { MonitoringRule } from 'modules/MonitoringRulesTable/MonitoringRulesTable.types'

export const getMonitoringRule = async (cid: string): Promise<MonitoringRule> => {
  return await fetchGet(`monitoringRules/${cid}`)
}

export const getSingleShipmentMonitoringRule = async (cid: string): Promise<MonitoringRule[]> => {
  return await fetchGet(`rules/shipment/${cid}`, {}, 'si', 'v1')
}

export const createMonitoringRule = async (
  data: MonitoringRuleFormData,
  type: 'rule' | 'singleShipment'
): Promise<void> => {
  const url = type === 'rule' ? 'rules' : 'rules/shipment'
  await fetchPost(url, data, {}, 'si', 'v1')
}

export const editMonitoringRule = async (cid: string, data: CreateOrEditMonitoringRuleFormValues): Promise<void> => {
  const processedData = {
    ...data,
    cid: cid,
  }
  return await fetchPut(`monitoringRules/${cid}`, processedData)
}
