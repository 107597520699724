import i18n from 'i18n'

export const etaTranslations = (): Record<any, any> => {
  const eta = 'modules.cv.ETA'

  return {
    translation: {
      label: i18n.t(`${eta}.label`),
      timezone: i18n.t(`${eta}.timezone`),
    },
  }
}
