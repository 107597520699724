import { SubmitHandler, useForm, useFieldArray } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { format } from 'date-fns-tz'

// @mui imports //
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import InputLabel from '@mui/material/InputLabel'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import FormHelperText from '@mui/material/FormHelperText'
import { Theme } from '@mui/material'

// KN imports //
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNFormDropdown from 'components/KN_Molecules/KNForm/KNFormDropdown'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNFormCheckbox from 'components/KN_Molecules/KNForm/KNFormCheckbox'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import KNFormDate from 'components/KN_Molecules/KNForm/KNFormDate'
import StatusFieldDialog from './StatusFieldDialog'
import GeocodeAddressDialog from './GeocodeAddressDialog'

// Modules //
import FormCompany from './FormCompany'
import FormUser from './FormUser'

// Functional //
import { getErrorMessage } from 'global/helpers/errorHandler'
import { processDefaultValues } from 'global/helpers/form'
import { getDocumentTypes, getFilteredUserOptions, getPlaygroundLogs } from './PlaygroundForms.helpers'
import { getStatuses } from 'global/helpers/statuses'
import { createShipment } from 'screens/Playground/Playground.service'
import { analyticsEvent } from 'global/helpers/analytics'
import { getDateFormat } from 'global/helpers/dateFormatters'

// Data //
import { playgroundTranslations } from 'screens/Playground/Playground.data'

// Types //
import {
  CreateShipmentFormValues,
  DropdownProps,
  PickUpDelivery,
  PlaygroundResponse,
  StatusField,
} from 'screens/Playground/Playground.types'

const CreatePlaygroundShipmentForm = ({ fetchLoading, fetchError, users, companies }): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false)
  const [documentSectionVisible, setDocumentSectionVisible] = useState<boolean>(false)
  const [pairingSectionVisible, setPairingSectionVisible] = useState<boolean>(false)
  const [telematicsSectionVisible, setTelematicsSectionVisible] = useState<boolean>(false)
  const [temperatureThresholdSectionVisible, setTemperatureThresholdSectionVisible] = useState<boolean>(false)
  const [requestMessage, setRequestMessage] = useState<PlaygroundResponse | null>(null)
  const [statusDialogOpen, setStatusDialogOpen] = useState<StatusField | null>(null)
  const [addStatusDialogOpen, setAddStatusDialogOpen] = useState<boolean>(false)
  const [geocodeAddressDialogOpen, setGeocodeAddressDialogOpen] = useState<'pickUp' | 'delivery' | null>(null)
  const [displayPickUpAddressDetails, setDisplayPickUpAddressDetails] = useState<boolean>(false)
  const [displayDeliveryAddressDetails, setDisplayDeliveryAddressDetails] = useState<boolean>(false)
  const [editPickUpAddress, setEditPickUpAddress] = useState<boolean>(true)
  const [editDeliveryAddress, setEditDeliveryAddress] = useState<boolean>(true)
  const [filteredUsersOptions, setFilteredUsersOptions] = useState<DropdownProps[]>([])
  const { translation } = playgroundTranslations()
  const { handleSubmit, reset, setValue, register, control, formState, getValues, setError, clearErrors, watch } =
    useForm<CreateShipmentFormValues>()

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'shipment.statuses',
  })

  useEffect(() => {
    reset(
      processDefaultValues({
        user: null,
        shipment: {
          customerAddress: {
            name: null,
            country: null,
            postalCode: null,
            city: null,
            address: null,
          },
          pickUp: {
            early: null,
            late: null,
            address: {
              name: null,
              country: null,
              postalCode: null,
              city: null,
              address: null,
              location: {
                latitude: null,
                longitude: null,
              },
            },
          },
          delivery: {
            early: null,
            late: null,
            address: {
              name: null,
              country: null,
              postalCode: null,
              city: null,
              address: null,
              location: {
                latitude: null,
                longitude: null,
              },
            },
          },
          lastLocationGeoPoint: null,
          statuses: [
            {
              code: '0100',
              dateTime: null,
              cityLocation: null,
            },
          ],
        },
        document: null,
        telematics: null,
        pairing: null,
      })
    )
    register('shipment.pickUp.address.address', { required: true })
    register('shipment.delivery.address.address', { required: true })
  }, [])

  useEffect(() => {
    if (fetchError)
      setError('root.server', {
        message: fetchError,
      })
  }, [fetchError])

  const watchTrigger = watch('shipment.startDate')
  const watchCompany = watch('company.name')

  useEffect(() => {
    resetUser()
    if (watchCompany) {
      setFilteredUsersOptions(
        getFilteredUserOptions(
          users,
          companies.find((company) => company.displayName === watchCompany)
        )
      )
    }
  }, [watchCompany])

  useEffect(() => {
    if (!fields[0]) return
    if (fields[0].code === '0100') update(0, { ...fields[0], dateTime: getValues('shipment.startDate') })
  }, [watchTrigger])

  const resetUser = (): void => {
    setValue('user', null)
  }

  const onSubmit: SubmitHandler<CreateShipmentFormValues> = async (data: CreateShipmentFormValues) => {
    setLoading(true)
    setRequestMessage(null)
    try {
      const user = data.user?.email
        ? {
            ...data.user,
          }
        : null
      const pairing = data.pairing
        ? {
            ...data.pairing,
            createPairing: {
              ...data.pairing?.createPairing,
            },
          }
        : null
      const payload = {
        ...data,
        user: user,
        shipment: {
          ...data.shipment,
          lastLocationGeoPoint:
            data.shipment.lastLocationGeoPoint?.latitude && data.shipment.lastLocationGeoPoint?.latitude
              ? data.shipment.lastLocationGeoPoint
              : null,
          statuses: data.shipment.statuses,
        },
        pairing: pairing,
      }
      await createShipment(payload).then((response: PlaygroundResponse) => {
        setRequestMessage(response)
        analyticsEvent('polestar_cv_playground_shipment_created')
      })
    } catch (error) {
      setError('root.server', {
        message: getErrorMessage(error),
      })
    }
    setLoading(false)
  }

  const setPairingSettings = (): void => {
    if (pairingSectionVisible) {
      setPairingSectionVisible(false)
      setValue('pairing', null)
      clearErrors('pairing')
      return
    }
    setPairingSectionVisible(true)
  }

  const setTemperatureThresholdSettings = (): void => {
    if (temperatureThresholdSectionVisible) {
      setTemperatureThresholdSectionVisible(false)
      setValue('pairing.createPairing.temperatureThreshold', null)
      setValue('pairing.createPairing.violateTemperatureThreshold', false)
      clearErrors('pairing')
      return
    }
    setTemperatureThresholdSectionVisible(true)
  }

  const setTelematicsSettings = (): void => {
    if (telematicsSectionVisible) {
      setTelematicsSectionVisible(false)
      setValue('telematics.licensePlate', '')
      clearErrors('telematics.licensePlate')
      return
    }
    setTelematicsSectionVisible(true)
  }

  const setDocumentSettings = (): void => {
    if (documentSectionVisible) {
      setDocumentSectionVisible(false)
      setValue('document', null)
      clearErrors('document')
      return
    }
    setDocumentSectionVisible(true)
  }

  const addStatusField = (data: StatusField): void => {
    append(data)
  }

  return loading || fetchLoading ? (
    <KNLoader />
  ) : (
    <KNForm onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {formState.errors?.root?.server && (
          <DialogContentText component="div" mb={3}>
            <Alert severity="error">{formState.errors.root.server.message}</Alert>
          </DialogContentText>
        )}
        {requestMessage && (
          <DialogContentText component="div" mb={3}>
            <Alert severity="success">{getPlaygroundLogs(requestMessage)}</Alert>
          </DialogContentText>
        )}
        <Box mt={1}>
          <KNTypography variant="displayMD_SB" color="primary.focus">
            {translation.createShipmentFormName}
          </KNTypography>
        </Box>
        <Box mt={1}>
          <FormCompany
            control={control}
            setValue={setValue}
            getValues={getValues}
            companies={companies}
            scenario={false}
          />
          <FormUser
            control={control}
            setValue={setValue}
            getValues={getValues}
            users={users}
            resetUser={resetUser}
            filteredUsersOptions={filteredUsersOptions}
            selectedCompany={companies.find((company) => company.displayName === watchCompany)}
          />
        </Box>
        <KNTypography variant="textLG_SB">{translation.shipmentData}</KNTypography>
        <Box mt={1}>
          <KNFormDate
            name="shipment.startDate"
            control={control}
            format={getDateFormat('full_timezoned')}
            showTimeSelect={true}
            allowFuture={true}
            label={translation.shipmentStartDateLabel}
            rules={{
              validate: {
                pastDateRequired: (value) => (value && value < new Date() ? true : translation.pastDateRequired),
              },
              required: translation.required,
            }}
            sx={{
              maxWidth: 'sm',
            }}
          />
        </Box>
        <Box mt={1}>
          <Box mt={1}>
            <KNFormDate
              name="shipment.endDate"
              control={control}
              label={translation.shipmentEndDateLabel}
              format={getDateFormat('full_timezoned')}
              showTimeSelect={true}
              allowFuture={true}
              rules={{
                validate: {
                  dateAfterRequired: (value) =>
                    value && value > getValues('shipment.startDate') ? true : translation.dateAfterRequired,
                },
                required: translation.required,
              }}
              sx={{
                maxWidth: 'sm',
              }}
            />
          </Box>
        </Box>
        {/* <KNTypography variant="textLG_SB">{translation.customerAddress}</KNTypography>
        <Box mt={1}>
          <KNFormText
            name="shipment.customerAddress.name"
            label={translation.addressNameLabel}
            sx={{
              maxWidth: 'sm',
            }}
            control={control}
          />
        </Box>
        <Box mt={1}>
          <KNFormText
            name="shipment.customerAddress.city"
            label={translation.addressCityLabel}
            sx={{
              maxWidth: 'sm',
            }}
            control={control}
          />
        </Box>
        <Box mt={1}>
          <KNFormText
            name="shipment.customerAddress.country"
            label={translation.addressCountryLabel}
            sx={{
              maxWidth: 'sm',
            }}
            control={control}
          />
        </Box>
        <Box mt={1}>
          <KNFormText
            name="shipment.customerAddress.postalCode"
            label={translation.addressPostalCodeLabel}
            sx={{
              maxWidth: 'sm',
            }}
            control={control}
          />
        </Box>
        <Box mt={1}>
          <KNFormText
            name="shipment.customerAddress.address"
            label={translation.addressAddressLabel}
            sx={{
              maxWidth: 'sm',
            }}
            control={control}
          />
        </Box> */}
        <KNTypography variant="textLG_SB">{translation.pickUpAddress}</KNTypography>
        <Box mt={1}>
          <Stack
            spacing={1}
            sx={{
              maxWidth: 'sm',
            }}
          >
            {editPickUpAddress && (
              <KNTypography variant="textMD" color="primary.light">
                {translation.shipmentGeocodeAddressText}
              </KNTypography>
            )}
            <Box>
              <KNButton
                color="primary"
                variant="contained"
                size="small"
                onClick={(): void => {
                  setGeocodeAddressDialogOpen('pickUp')
                }}
                sx={{
                  border: ({ palette: { error, primary } }: Theme): string =>
                    formState.errors.shipment?.pickUp?.address?.address
                      ? `1px solid ${error.main}`
                      : `1px solid ${primary.main}`,
                }}
              >
                {editPickUpAddress ? translation.shipmentAddAddressButton : translation.shipmentEditAddressButton}
              </KNButton>
              {formState.errors.shipment?.pickUp?.address?.address && (
                <FormHelperText error={true}>{translation.required}</FormHelperText>
              )}
            </Box>
            <Box>
              <KNFormText
                name="shipment.pickUp.address.name"
                label={translation.addressNameLabel}
                sx={{
                  maxWidth: 'sm',
                }}
                control={control}
              />
            </Box>
            {displayPickUpAddressDetails && (
              <>
                <Box>
                  <KNFormText
                    name="shipment.pickUp.address.address"
                    disabled={!editPickUpAddress}
                    label={translation.addressAddressLabel}
                    control={control}
                  />
                </Box>
                <Box>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <KNFormText
                        name="shipment.pickUp.address.postalCode"
                        disabled={!editPickUpAddress}
                        label={translation.addressPostalCodeLabel}
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <KNFormText
                        name="shipment.pickUp.address.city"
                        disabled={!editPickUpAddress}
                        label={translation.addressCityLabel}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <KNFormText
                    name="shipment.pickUp.address.country"
                    disabled={!editPickUpAddress}
                    label={translation.addressCountryLabel}
                    control={control}
                  />
                </Box>
              </>
            )}
            <Box
              mt={1}
              sx={{
                maxWidth: 'sm',
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Box>
                    <KNFormText
                      type="number"
                      name="shipment.pickUp.address.location.latitude"
                      label={translation.locationLatitudeLabel}
                      sx={{
                        maxWidth: 'sm',
                      }}
                      rules={{
                        required: translation.required,
                        min: -90,
                        max: 90,
                      }}
                      control={control}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <KNFormText
                      type="number"
                      name="shipment.pickUp.address.location.longitude"
                      label={translation.locationLongitudeLabel}
                      sx={{
                        maxWidth: 'sm',
                      }}
                      rules={{
                        required: translation.required,
                        min: -180,
                        max: 180,
                      }}
                      control={control}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Box>

        <Box>
          {geocodeAddressDialogOpen === 'pickUp' && (
            <GeocodeAddressDialog
              addressData={getValues('shipment.pickUp')}
              dialogOpen={geocodeAddressDialogOpen === 'pickUp'}
              handleClose={(): void => {
                setGeocodeAddressDialogOpen(null)
                clearErrors('shipment.pickUp.address')
              }}
              handleSave={(data: PickUpDelivery): void => {
                setValue('shipment.pickUp.address', data.address)
                setDisplayPickUpAddressDetails(true)
                setEditPickUpAddress(false)
              }}
            />
          )}
        </Box>

        <KNTypography variant="textLG_SB">{translation.deliveryAddress}</KNTypography>
        <Box mt={1}>
          <Stack
            spacing={1}
            sx={{
              maxWidth: 'sm',
            }}
          >
            {editDeliveryAddress && (
              <KNTypography variant="textMD" color="primary.light">
                {translation.shipmentGeocodeAddressText}
              </KNTypography>
            )}
            <Box>
              <KNButton
                color="primary"
                variant="contained"
                size="small"
                onClick={(): void => {
                  setGeocodeAddressDialogOpen('delivery')
                }}
                sx={{
                  border: ({ palette: { error, primary } }: Theme): string =>
                    formState.errors.shipment?.pickUp?.address?.address
                      ? `1px solid ${error.main}`
                      : `1px solid ${primary.main}`,
                }}
              >
                {editDeliveryAddress ? translation.shipmentAddAddressButton : translation.shipmentEditAddressButton}
              </KNButton>
              {formState.errors.shipment?.delivery?.address?.address && (
                <FormHelperText error={true}>{translation.required}</FormHelperText>
              )}
            </Box>
            <Box>
              <KNFormText
                name="shipment.delivery.address.name"
                label={translation.addressNameLabel}
                sx={{
                  maxWidth: 'sm',
                }}
                control={control}
              />
            </Box>
            {displayDeliveryAddressDetails && (
              <>
                <Box mt={1}>
                  <KNFormText
                    name="shipment.delivery.address.address"
                    disabled={!editDeliveryAddress}
                    label={translation.addressAddressLabel}
                    control={control}
                  />
                </Box>
                <Box mt={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <KNFormText
                        name="shipment.delivery.address.postalCode"
                        disabled={!editDeliveryAddress}
                        label={translation.addressPostalCodeLabel}
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <KNFormText
                        name="shipment.delivery.address.city"
                        disabled={!editDeliveryAddress}
                        label={translation.addressCityLabel}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={1}>
                  <KNFormText
                    name="shipment.delivery.address.country"
                    disabled={!editDeliveryAddress}
                    label={translation.addressCountryLabel}
                    control={control}
                  />
                </Box>
              </>
            )}

            <Box
              sx={{
                maxWidth: 'sm',
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Box>
                    <KNFormText
                      type="number"
                      name="shipment.delivery.address.location.latitude"
                      label={translation.locationLatitudeLabel}
                      sx={{
                        maxWidth: 'sm',
                      }}
                      rules={{
                        required: translation.required,
                        min: -90,
                        max: 90,
                      }}
                      control={control}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    <KNFormText
                      type="number"
                      name="shipment.delivery.address.location.longitude"
                      label={translation.locationLongitudeLabel}
                      sx={{
                        maxWidth: 'sm',
                      }}
                      rules={{
                        required: translation.required,
                        min: -180,
                        max: 180,
                      }}
                      control={control}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Box>

        <Box>
          {geocodeAddressDialogOpen === 'delivery' && (
            <GeocodeAddressDialog
              addressData={getValues('shipment.delivery')}
              dialogOpen={geocodeAddressDialogOpen === 'delivery'}
              handleClose={(): void => {
                setGeocodeAddressDialogOpen(null)
                clearErrors('shipment.delivery.address')
              }}
              handleSave={(data: PickUpDelivery): void => {
                setValue('shipment.delivery.address', data.address)
                setDisplayDeliveryAddressDetails(true)
                setEditDeliveryAddress(false)
              }}
            />
          )}
        </Box>

        <KNTypography variant="textLG_SB">{translation.shipmentLastLocation}</KNTypography>
        <Box mt={1}>
          <KNFormText
            type="number"
            name="shipment.lastLocationGeoPoint.latitude"
            label={translation.locationLatitudeLabel}
            sx={{
              maxWidth: 'sm',
            }}
            rules={{
              min: -90,
              max: 90,
            }}
            control={control}
          />
        </Box>
        <Box mt={1}>
          <KNFormText
            type="number"
            name="shipment.lastLocationGeoPoint.longitude"
            label={translation.locationLongitudeLabel}
            sx={{
              maxWidth: 'sm',
            }}
            rules={{
              min: -180,
              max: 180,
            }}
            control={control}
          />
        </Box>
        <KNTypography variant="textLG_SB">{translation.shipmentStatuses}</KNTypography>
        <Stack spacing={1}>
          {fields.map((item, index) => {
            const statuses = getStatuses()
            return (
              <Box key={item.id}>
                <Stack spacing={1}>
                  <Grid container>
                    <Grid item xs={12} md={3}>
                      <Stack>
                        <KNTypography variant="textMD">{translation.shipmentStatusName}</KNTypography>
                        {statuses.find((status) => status.STATUS_CODE === item.code)?.STATUS_NAME}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Stack>
                        <KNTypography variant="textMD">{translation.shipmentStatusCode}</KNTypography>
                        {item.code}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Stack>
                        <KNTypography variant="textMD">{translation.shipmentStatusDateTime}</KNTypography>
                        {item.dateTime && format(item.dateTime, getDateFormat('full_timezoned'))}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Stack>
                        <KNTypography variant="textMD">{translation.shipmentStatusCityLocation}</KNTypography>
                        {item.cityLocation}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Stack>
                        <KNTypography variant="textMD">{translation.shipmentStatusActions}</KNTypography>
                        <Box>
                          <IconButton
                            size="small"
                            onClick={(): void => setStatusDialogOpen(item)}
                            sx={{
                              width: 20,
                              height: 20,
                            }}
                          >
                            <EditIcon fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={(): void => remove(index)}
                            sx={{
                              width: 20,
                              height: 20,
                            }}
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>

                <Stack direction={'row'} spacing={1} useFlexGap flexWrap="wrap" mt={1}>
                  {index === fields.length - 1 && (
                    <KNButton color="primary" variant="contained" onClick={(): void => setAddStatusDialogOpen(true)}>
                      {translation.addNewStatus}
                    </KNButton>
                  )}
                </Stack>
                <StatusFieldDialog
                  statusData={item}
                  dialogOpen={statusDialogOpen === item}
                  handleClose={(): void => setStatusDialogOpen(null)}
                  handleSave={(data: StatusField): void => update(index, data)}
                />
              </Box>
            )
          })}
          <StatusFieldDialog
            dialogOpen={addStatusDialogOpen}
            handleClose={(): void => setAddStatusDialogOpen(false)}
            handleSave={addStatusField}
          />
        </Stack>
        <Box display="flex" alignItems="center">
          <Checkbox
            sx={{ pl: 0 }}
            value={pairingSectionVisible}
            checked={pairingSectionVisible}
            onChange={(): void => setPairingSettings()}
          />
          {translation.checkboxAddPairing}
        </Box>
        {pairingSectionVisible && (
          <>
            <KNTypography variant="textLG_SB">{translation.pairing}</KNTypography>
            <Box mt={1}>
              <KNFormText
                name="pairing.createPairing.description"
                label={translation.pairingDescriptionLabel}
                sx={{
                  maxWidth: 'sm',
                }}
                control={control}
              />
            </Box>
            <Box mt={1}>
              <Box mt={1}>
                <KNFormCheckbox
                  name="pairing.pairingEta"
                  checkboxProps={{
                    value: getValues('pairing.pairingEta'),
                    checked: getValues('pairing.pairingEta'),
                  }}
                  control={control}
                  label={translation.checkboxAddPairingEta}
                />
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Checkbox
                sx={{ pl: 0 }}
                value={temperatureThresholdSectionVisible}
                checked={temperatureThresholdSectionVisible}
                onChange={(): void => setTemperatureThresholdSettings()}
              />
              {translation.checkboxAddTemperatureThreshold}
            </Box>
            {temperatureThresholdSectionVisible && (
              <>
                <Box mt={1}>
                  <InputLabel>
                    <KNTypography variant="textSM_SB" color="primary.light">
                      {translation.pairingTemperatureThresholdLabel}
                    </KNTypography>
                  </InputLabel>
                  <Stack
                    direction={'row'}
                    spacing={1}
                    sx={{
                      maxWidth: 'sm',
                    }}
                  >
                    <KNFormText
                      type="number"
                      name={'pairing.createPairing.temperatureThreshold.min'}
                      label={translation.minLabel}
                      control={control}
                      rules={{
                        required: translation.required,
                      }}
                    />
                    <KNFormText
                      type="number"
                      name={'pairing.createPairing.temperatureThreshold.max'}
                      label={translation.maxLabel}
                      control={control}
                      rules={{
                        required: translation.required,
                      }}
                    />
                  </Stack>
                </Box>
                <KNFormCheckbox
                  name="pairing.createPairing.violateTemperatureThreshold"
                  checkboxProps={{
                    value: getValues('pairing.createPairing.violateTemperatureThreshold'),
                    checked: getValues('pairing.createPairing.violateTemperatureThreshold'),
                  }}
                  control={control}
                  label={translation.checkboxViolateTemperatureThreshold}
                />
              </>
            )}
          </>
        )}

        <Box display="flex" alignItems="center">
          <Checkbox
            sx={{ pl: 0 }}
            value={telematicsSectionVisible}
            checked={telematicsSectionVisible}
            onChange={(): void => setTelematicsSettings()}
          />
          {translation.checkboxAddTelematics}
        </Box>
        {telematicsSectionVisible && (
          <>
            <KNTypography variant="textLG_SB">{translation.telematics}</KNTypography>
            <Box mt={1}>
              <KNFormText
                name="telematics.licensePlate"
                label={translation.telematicsLicensePlate}
                sx={{
                  maxWidth: 'sm',
                }}
                control={control}
              />
            </Box>
          </>
        )}

        <Box display="flex" alignItems="center">
          <Checkbox
            sx={{ pl: 0 }}
            value={documentSectionVisible}
            checked={documentSectionVisible}
            onChange={(): void => setDocumentSettings()}
          />
          {translation.checkboxAddDocument}
        </Box>
        {documentSectionVisible && (
          <>
            <KNTypography variant="textLG_SB">{translation.document}</KNTypography>
            <Box mt={1} mb={2}>
              <KNFormDropdown
                sx={{
                  maxWidth: 'sm',
                }}
                name="document.type"
                label={translation.documentType}
                control={control}
                options={getDocumentTypes()}
                rules={{
                  required: translation.required,
                }}
              />
            </Box>
            <Box mt={1}>
              <KNFormText
                name="document.countryCode"
                label={translation.documentCountryCode}
                sx={{
                  maxWidth: 'sm',
                }}
                control={control}
                rules={{
                  required: translation.required,
                }}
              />
            </Box>
          </>
        )}
        <Box>
          <KNButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={formState.isSubmitted && Object.keys(formState.errors).length > 0 && !formState.errors.root}
            onClick={handleSubmit(onSubmit)}
          >
            {translation.saveButtonText}
          </KNButton>
        </Box>
      </Stack>
    </KNForm>
  )
}

export default CreatePlaygroundShipmentForm
