import { ReactElement } from 'react'
import FadeIn from 'react-fade-in'

// @mui imports //
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { FormControl } from '@mui/base/FormControl'

// Functional //
import { getAggregatedStatuses } from 'global/helpers/statuses'

// Data //
import { MegaFormTranslations } from './MegaForm.data'

// Types
import { AggregatedStatusStepProps } from './MegaForm.types'

const AggregatedStatusStep = ({ currentStatus, setCurrentStatus }: AggregatedStatusStepProps): ReactElement => {
  const { translation } = MegaFormTranslations()

  return (
    <FadeIn>
      <FormControl>
        <FormLabel>{translation.typeAggregatedStatus}</FormLabel>
        <RadioGroup value={currentStatus ?? ''} onChange={(e): void => setCurrentStatus(e.target.value)}>
          {getAggregatedStatuses().map((option, index) => (
            <FormControlLabel key={index} value={option.STATUS} control={<Radio />} label={option.STATUS_NAME} />
          ))}
        </RadioGroup>
      </FormControl>
    </FadeIn>
  )
}

export default AggregatedStatusStep
