import { ReactElement, useState } from 'react'
import { t } from 'i18next'

// @mui imports
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import Tooltip from '@mui/material/Tooltip'
import useMediaQuery from '@mui/material/useMediaQuery'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import { Theme } from '@mui/material/styles/createTheme'

// KN imports
import theme from 'assets/theme'
import TemperatureThresholdDialog from './TemperatureThresholdDialog'
import KNFormSwitch from 'components/KN_Molecules/KNForm/KNFormSwitch'
import KNSwitch from 'components/KN_Components/Base/KNSwitch/KNSwitch'
import KNFormText from 'components/KN_Molecules/KNForm/KNFormText'
import KNFormAutocompleteMultiple from 'components/KN_Molecules/KNForm/KNFormAutocompleteMultiple'

// Functional
import { getHeader } from './PairDevices.helpers'

// Data
import { pairDevicesTranslations } from '../../screens/PairDevices/PairDevices.data'

// Types
import { PairingSessionFormProps } from 'screens/PairDevices/PairDevices.types'

const PairingSessionForm = ({
  editMode,
  control,
  getValues,
  setValue,
  pairingIndex,
}: PairingSessionFormProps): ReactElement => {
  const [temperatureThresholdPopupOpen, setTemperatureThresholdPopupOpen] = useState<number>()
  const desktopView = useMediaQuery(theme.breakpoints.up('md'))
  const temperatureThresholdActive: boolean =
    !!getValues(`pairings.${pairingIndex}.temperatureThreshold.min`) ||
    !!getValues(`pairings.${pairingIndex}.temperatureThreshold.max`)
  const [autocompleteTooltip, setAutocompleteTooltipActive] = useState(false)
  const disableReferenceEdit = getValues(`pairings.${pairingIndex}.cid`) ? true : false

  const { translation } = pairDevicesTranslations()

  return (
    <Box mt={1} sx={{ width: '100%' }}>
      <Box>
        <Grid container spacing={2}>
          {editMode && desktopView && (
            <Grid item md={1}>
              <HourglassTopIcon sx={{ color: 'primary.light' }} />
            </Grid>
          )}
          <Grid item xs={6} md={editMode ? 2 : 3}>
            {!desktopView && getHeader(translation.shipmentId, translation.required)}
            <KNFormText
              name={`pairings.${pairingIndex}.reference`}
              control={control}
              rules={{
                required: t('form.validation.required'),
              }}
              variant="standard"
              placeholder={translation.required}
              disabled={disableReferenceEdit}
            />
          </Grid>

          <Grid item xs={8} md={3}>
            {!desktopView && getHeader(translation.description, translation.optional)}
            <KNFormText
              name={`pairings.${pairingIndex}.description`}
              control={control}
              variant="standard"
              placeholder={translation.optional}
            />
          </Grid>

          <Grid item xs={8} md={3}>
            {!desktopView && getHeader(translation.deviceIds, translation.required)}
            <Tooltip
              title={`${translation.required as string}. ${translation.autocompleteTooltip as string}`}
              arrow
              open={autocompleteTooltip}
              placement={'bottom-end'}
            >
              <Box
                display="flex"
                onFocus={(): void => setAutocompleteTooltipActive(true)}
                onBlur={(): void => setAutocompleteTooltipActive(false)}
              >
                <KNFormAutocompleteMultiple
                  name={`pairings.${pairingIndex}.deviceIds`}
                  value={getValues(`pairings.${pairingIndex}.deviceIds`)}
                  control={control}
                  rules={{
                    required: t('form.validation.required'),
                  }}
                />
              </Box>
            </Tooltip>
          </Grid>

          <Grid item xs={12} md={3} container>
            <Grid item xs={6} md={9}>
              {!desktopView && getHeader(translation.temperatureThreshold)}
              <Box display="flex" alignItems="center" sx={{ mt: '-5px' }}>
                <KNSwitch
                  onChange={(): void => {
                    setTemperatureThresholdPopupOpen(pairingIndex)
                  }}
                  checked={temperatureThresholdActive}
                />
                {temperatureThresholdActive && (
                  <Tooltip
                    title={t('modules.cv.pair_devices.pairing_sessions.tooltip_text', {
                      min: getValues(`pairings.${pairingIndex}.temperatureThreshold.min`) ?? '-',
                      max: getValues(`pairings.${pairingIndex}.temperatureThreshold.max`) ?? '-',
                    })}
                    color="primary"
                  >
                    <Icon fontSize="small">info_circle</Icon>
                  </Tooltip>
                )}
              </Box>
            </Grid>

            <Grid item xs={6} md={3}>
              {!desktopView && getHeader(translation.autoUnpair)}
              <Box sx={{ mt: '-5px' }}>
                <KNFormSwitch name={`pairings.${pairingIndex}.unpairingRule`} control={control} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Divider
          sx={{
            backgroundColor: ({ palette: { grey } }: Theme): string => `${grey[300]} !important`,
            my: 2,
          }}
        />
      </Box>

      {temperatureThresholdPopupOpen === pairingIndex && (
        <TemperatureThresholdDialog
          currentMin={getValues(`pairings.${pairingIndex}.temperatureThreshold.min`) ?? null}
          currentMax={getValues(`pairings.${pairingIndex}.temperatureThreshold.max`) ?? null}
          dialogOpen={temperatureThresholdPopupOpen === pairingIndex}
          handleClose={(): void => setTemperatureThresholdPopupOpen(undefined)}
          handleSave={(min: number | null, max: number | null): void => {
            if (!min && !max) setValue(`pairings.${pairingIndex}.temperatureThreshold`, null)
            min && setValue(`pairings.${pairingIndex}.temperatureThreshold.min`, min)
            max && setValue(`pairings.${pairingIndex}.temperatureThreshold.max`, max)
          }}
        />
      )}
    </Box>
  )
}

export default PairingSessionForm
