import i18n from 'i18n'

export const geofenceTranslations = (): Record<any, any> => {
  const geofence = 'screens.cv.shipment_details.geofence'
  return {
    translation: {
      moduleName: i18n.t(`${geofence}.moduleName`),
      edit: i18n.t(`${geofence}.edit`),
      drawEnterZone: i18n.t(`${geofence}.draw_enter_zone`),
      drawExitZone: i18n.t(`${geofence}.draw_exit_zone`),
      drawEnterAndExitZone: i18n.t(`${geofence}.draw_enter_and_exit_zone`),
      deleteZone: i18n.t(`${geofence}.delete_zone`),
      saveZone: i18n.t(`${geofence}.save_zone`),
      successMessageDeleted: i18n.t(`${geofence}.success_message_deleted`),
      successMessageEdited: i18n.t(`${geofence}.success_message_edited`),
      successMessageCreated: i18n.t(`${geofence}.success_message_created`),
      pickup: i18n.t(`${geofence}.pickup`),
      delivery: i18n.t(`${geofence}.delivery`),
    },
  }
}
