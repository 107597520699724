import { VehicleData } from './VehicleManager.types'
import { CreateOrEditVehicleFormValues } from './CreateOrEditVehicleDialog'

export const getDataForCreateVehicle = (data: CreateOrEditVehicleFormValues): VehicleData => {
  return {
    id: 'pending',
    carrierCid: 'pending',
    telematicsReady: false,
    type: 'pending',
    licensePlate: data.licensePlate,
    displayLicensePlate: data.licensePlate,
    vehicleType: data.vehicleType as VehicleData['vehicleType'],
    weightCapacity: data.weightCapacity,
    fuelType: data.fuelType as VehicleData['fuelType'],
    makeModel: data.makeModel,
    assetId: data.assetId,
  }
}

export const getDataForEditVehicle = (vehicle: VehicleData, data: CreateOrEditVehicleFormValues): VehicleData => {
  return {
    ...vehicle,
    licensePlate: data.licensePlate,
    displayLicensePlate: data.licensePlate,
    vehicleType: data.vehicleType as VehicleData['vehicleType'],
    weightCapacity: data.weightCapacity,
    fuelType: data.fuelType as VehicleData['fuelType'],
    makeModel: data.makeModel,
    assetId: data.assetId,
  }
}

export const getDataForDeleteVehicle = (vehicle: VehicleData): VehicleData => {
  return vehicle
}
