import { fetchDelete, fetchGet } from 'global/helpers/fetchWrapper'
import { MonitoringRule } from './MonitoringRulesTable.types'

export const getMonitoringRules = async (): Promise<MonitoringRule[]> => {
  return await fetchGet(`rules`, {}, 'si', 'v1')
}

export const deleteMonitoringRule = async (cid: string): Promise<void> => {
  return await fetchDelete(`rules/${cid}`, null, {}, 'si', 'v1')
}
