// Types //
import { ShipmentFileProps } from 'modules/ShipmentFiles/ShipmentFiles.types'
import { DevicesLocationProps } from 'modules/VisibilityMap/VisibilityMap.types'
import { TemperatureDataSetProps } from 'modules/TemperatureChart/TemperatureChart.types'
import { Color } from '@mui/material'

// Expected response from API //
export interface TrackingInfoProps {
  cid?: string
  creationDate?: string
  completionDate?: string
  latestEta?: string
  level?: string
  deviceIds?: string[]
  health?: string
  description?: string
  reference?: string
  temperatureThreshold?: {
    min?: number | null
    max?: number | null
  }
  sendingApplication?: string
  trackingNumber?: string
}

export interface CargoSummaryProps {
  dangerousGoods: boolean
  grossWeightInKg: number
  numberOfPackages: number
  volumeInMtq: number
}

export interface CargoDetailsProps {
  cargoContent: string
  grossWeightInKg: number
  marksAndNumbers: string
  numberOfPackages: number
  volumeInMtq: number
}

export interface ShipmentAddressProps {
  address: {
    name: string
    country: string
    postalCode: string
    city: string
    address: string
    additionalAddress: any
    location: {
      latitude: number
      longitude: number
    }
    customerIdentifier: string
  }
  early: string
  late: string
}

export interface ShipmentDataResponse {
  comRefId: string
  pairingCid?: string
  description?: string
  followed?: boolean
  level: string
  status: string
  creationDate: string
  reference?: string
  pairing?: TrackingInfoProps
  pickUp: ShipmentAddressProps
  delivery: ShipmentAddressProps
  cargoSummary: CargoSummaryProps
  cargoDetails: CargoDetailsProps[]
  references?: string[]
  temperatureThreshold?: {
    min?: number | null
    max?: number | null
  }
  sendingApplication?: string
  trackingNumber?: string
  hasMonitoringRules?: boolean
  airInfo?: {
    departure: ShipmentAddressProps
    arrival: ShipmentAddressProps
    flightNumber: string
  }
}

// Interfaces for the UI //
export interface GeneralInfoListItemProps {
  icon: JSX.Element
  propertyName: string
  propertyValue: string
  additionalInfo?: { text: string; info: string[] }
  color?: Color
}

export interface InsightDetailsDataProps {
  pairingId?: string
  comRefId?: string
  status?: string
  creationDate?: string
  generalInfoListProps?: {
    followed?: boolean
    completionDate?: string
    level?: string
    listItems?: GeneralInfoListItemProps[]
  }
  etaProps?: { latestEta?: string; delivered?: boolean }
  addressInfoProps?: {
    pickup: ShipmentAddressProps
    delivery: ShipmentAddressProps
    airInfo?: {
      departure: ShipmentAddressProps
      arrival: ShipmentAddressProps
      flightNumber: string
    }
  }
  filesProps?: ShipmentFileProps[]
  cargoProps?: {
    cargoSummary: CargoSummaryProps
    cargoDetails: CargoDetailsProps[]
  }
  trackingInfoProps?: TrackingInfoProps
  visibilityMapInfoProps?: DevicesLocationProps[]
  temperatureChartProps?: TemperatureDataSetProps[]
  temperaturePredictionProps?: TemperatureDataSetProps[]
}

export enum EntityType {
  shipment = 'S',
  pairing = 'P',
}

export default interface InsightDetailsProps {
  shipmentId: string | undefined
  comRefId?: string
  entityType?: EntityType
  data?: InsightDetailsDataProps
  token?: string
  pinCode?: string
  shareError?: boolean
}
