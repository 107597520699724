// @mui imports
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'

// Types
import KNIconMessageProps from './types'

const KNIconMessage: React.FC<KNIconMessageProps> = ({ children, messageType, noMargin }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Icon color={messageType === 'error' ? 'error' : 'success'} sx={{ fontSize: '80px' }}>
        {messageType === 'error' ? 'cross' : 'check_circle'}
      </Icon>
      <Box sx={{ mt: noMargin ? 0.5 : 2 }}>{children}</Box>
    </Box>
  )
}

export default KNIconMessage
