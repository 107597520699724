import { Reducer } from 'react'
import createStore from 'global/helpers/createStore'
import { ErrorMessage } from 'global/helpers/errorHandler'

export interface StopsViewState {
  activeStopsGroupSequence: number
  stopsErrors: ErrorMessage[]
}

export type StopsViewAction =
  | { type: 'setActiveStopsGroupSequence'; payload: number }
  | { type: 'setStopsErrors'; payload: ErrorMessage[] }
  | { type: 'clearStopsErrors' }

const initialState: StopsViewState = {
  activeStopsGroupSequence: -1,
  stopsErrors: [],
}

const reducer = (state: StopsViewState, action: StopsViewAction): StopsViewState => {
  switch (action.type) {
    case 'setActiveStopsGroupSequence':
      return {
        ...state,
        activeStopsGroupSequence: action.payload,
      }
    case 'setStopsErrors':
      return {
        ...state,
        stopsErrors: action.payload,
      }
    case 'clearStopsErrors':
      return {
        ...state,
        stopsErrors: [],
      }
    default:
      throw new Error()
  }
}

const { StoreContext, StoreProvider } = createStore(reducer, initialState)

export { StoreContext as StopsViewContext, StoreProvider as StopsViewProvider }
