import { useEffect, useState, useContext, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'

// KN imports
import { CompanyListContext, CompanyFiltersValues } from 'context/companies/CompanyListContext'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import CompanyFilters from './CompanyFilters'
import CompanyTable from './CompanyTable'
import CreateOrEditCompanyDialog from './CreateOrEditCompanyDialog'
import BulkCreateCompaniesDialog from './BulkCreateCompaniesDialog'

// Functional
import { getCompanies } from './CompanyManager.service'
import { analyticsEvent } from 'global/helpers/analytics'
import { hasRole } from 'global/helpers/authorization'

// Types
import { Company } from 'context/authentication/Company.types'
import { Role } from 'context/authentication/Role.types'

const CompanyManager = (): ReactElement => {
  const { t } = useTranslation()
  const [companiesData, setCompaniesData] = useState<Company[]>([])
  const [filteredCompaniesData, setFilteredCompaniesData] = useState<Company[]>([])
  const [loading, setLoading] = useState(true)
  const [companyListState, companyListDispatch] = useContext(CompanyListContext)
  const [createOrEditCompanyDialogOpen, setCreateOrEditCompanyDialogOpen] = useState(false)
  const [bulkCreateCompaniesDialogOpen, setBulkCreateCompaniesDialogOpen] = useState(false)

  const fetchData = async (): Promise<void> => {
    setLoading(true)
    setCompaniesData(await getCompanies())
    setLoading(false)
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData()
  }, [])

  useEffect(() => {
    filterData(companyListState.filters)
  }, [companiesData, companyListState.filters])

  const handleOnChange = useCallback(async (): Promise<void> => {
    await fetchData()
  }, [])

  const filterData = (filters: CompanyFiltersValues): void => {
    setFilteredCompaniesData(
      companiesData.filter((company) => {
        const lowercaseKeywords = filters.keywords?.map((keyword: string) => keyword.toLowerCase())
        let keywordsCondition = true
        if (lowercaseKeywords?.length) {
          const lowercaseValues = [company.displayName, ...company.customerIdentifiers].map((value: string) =>
            value.toLowerCase()
          )
          keywordsCondition = lowercaseValues
            .map((value) =>
              lowercaseKeywords.map((keyword) => value.includes(keyword)).some((condition: boolean) => condition)
            )
            .some((condition: boolean) => condition)
        }

        let typesCondition = true
        const typesFilters = filters.types
        if (typesFilters?.length) {
          typesCondition = typesFilters
            .map((type) => {
              return company.type === type
            })
            .some((condition: boolean) => condition)
        }

        return [keywordsCondition, typesCondition].every((condition: boolean) => condition)
      })
    )
  }

  const handleAddClick = useCallback((): void => {
    setCreateOrEditCompanyDialogOpen(true)
    analyticsEvent('polestar_cv_add_company_button_clicked')
  }, [])

  const handleCreateOrEditDialogAction = useCallback(async (): Promise<void> => {
    setCreateOrEditCompanyDialogOpen(false)
    await fetchData()
  }, [])

  const handleCreateOrEditDialogClose = useCallback((): void => {
    setCreateOrEditCompanyDialogOpen(false)
  }, [])

  const handleBulkCreateClick = useCallback((): void => {
    setBulkCreateCompaniesDialogOpen(true)
  }, [])

  const handleBulkCreateCompaniesDialogAction = useCallback(async (): Promise<void> => {
    setBulkCreateCompaniesDialogOpen(false)
    await fetchData()
  }, [])

  const handleBulkCreateCompaniesDialogClose = useCallback((): void => {
    setBulkCreateCompaniesDialogOpen(false)
  }, [])

  return (
    <Container maxWidth="xl" data-test="companies-container">
      {loading ? (
        <KNLoader>
          <KNTypography>{t('screens.shared.company_manager.loading')}</KNTypography>
        </KNLoader>
      ) : (
        <>
          <Paper elevation={8} sx={{ padding: 2 }}>
            <Stack
              spacing={1}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              alignItems={{ xs: 'start', md: 'center' }}
              mb={2}
            >
              <KNTypography data-test="companies-count" variant="h4">
                {t('screens.shared.company_manager.companies_count', { count: filteredCompaniesData.length })}
              </KNTypography>
              {hasRole(Role.Admin) && (
                <Stack direction="row" spacing={1}>
                  <KNButton
                    onClick={handleAddClick}
                    data-test="add-new-company-button"
                    variant="contained"
                    color="secondary"
                    size="small"
                  >
                    {t('screens.shared.company_manager.card.actions.new')}
                  </KNButton>
                  <KNButton
                    onClick={handleBulkCreateClick}
                    data-test="bulk-create-companies-button"
                    variant="contained"
                    color="secondary"
                    size="small"
                  >
                    {t('screens.shared.company_manager.card.actions.bulk')}
                  </KNButton>
                </Stack>
              )}
            </Stack>
            <CompanyFilters data-test="companies-filters" />
            <CompanyTable companies={filteredCompaniesData} onChange={handleOnChange} />
          </Paper>
          {hasRole(Role.Admin) && (
            <>
              <CreateOrEditCompanyDialog
                open={createOrEditCompanyDialogOpen}
                onAction={handleCreateOrEditDialogAction}
                onClose={handleCreateOrEditDialogClose}
              />
              <BulkCreateCompaniesDialog
                open={bulkCreateCompaniesDialogOpen}
                onAction={handleBulkCreateCompaniesDialogAction}
                onClose={handleBulkCreateCompaniesDialogClose}
              />
            </>
          )}
        </>
      )}
    </Container>
  )
}

export default CompanyManager
