import { ReactElement, useState } from 'react'
import FadeIn from 'react-fade-in'

// @mui imports
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import CheckIcon from '@mui/icons-material/Check'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

// KN components
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNTextSlider from 'components/KN_Molecules/KNTextSlider/KNTextSlider'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'

// Types
import { IconName } from 'components/KN_Components/Base/Icons/Icon.type'
import { TemperatureThresholdDialogProps } from 'screens/PairDevices/PairDevices.types'

// Data
import { pairDevicesTranslations } from 'screens/PairDevices/PairDevices.data'

const TemperatureThresholdDialog = ({
  currentMin,
  currentMax,
  dialogOpen,
  handleClose,
  handleSave,
}: TemperatureThresholdDialogProps): ReactElement => {
  const { translation } = pairDevicesTranslations()

  const [minTemperature, setMinTemperature] = useState<{
    active: boolean
    value: number | null
  }>({
    active: !!currentMin,
    value: currentMin ?? null,
  })
  const [maxTemperature, setMaxTemperature] = useState<{
    active: boolean
    value: number | null
  }>({
    active: !!currentMax,
    value: currentMax ?? null,
  })
  const [temperaturePreset, setTemperaturePreset] = useState<string>()

  const presetValues = [
    {
      label: '< -20°C',
      value: '1',
      onClick: (): void => {
        setTemperaturePreset('1')
        setMinTemperature({ active: false, value: null })
        setMaxTemperature({ active: true, value: -20 })
      },
    },
    {
      label: '+2°C <-> +8°C',
      value: '2',
      onClick: (): void => {
        setTemperaturePreset('2')
        setMinTemperature({ active: true, value: 2 })
        setMaxTemperature({ active: true, value: 8 })
      },
    },
    {
      label: '+15°C <-> +25°C',
      value: '3',
      onClick: (): void => {
        setTemperaturePreset('3')
        setMinTemperature({ active: true, value: 15 })
        setMaxTemperature({ active: true, value: 25 })
      },
    },
  ]

  const maxChange = (val: boolean): void =>
    setMaxTemperature({
      active: val,
      value: maxTemperature.value ?? 0,
    })

  const minChange = (val: boolean): void =>
    setMinTemperature({
      active: val,
      value: minTemperature.value ?? 0,
    })

  const getDialogContent = (): ReactElement => (
    <Box my={2}>
      <Box mb={3}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box>
            <FadeIn>
              <KNTypography variant="textSM" color="primary.light">
                {translation.presetValues}
              </KNTypography>
              <Select
                sx={{ minWidth: 150 }}
                value={temperaturePreset}
                size="small"
                onChange={(event: SelectChangeEvent): void => {
                  const {
                    target: { value },
                  } = event
                  setTemperaturePreset(value)
                }}
              >
                {presetValues.map((option, index) => (
                  <MenuItem key={index} value={option.value} onClick={option.onClick}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FadeIn>
          </Box>
        </Box>
      </Box>
      <FadeIn>
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <Checkbox
            sx={{ ml: -1 }}
            value={minTemperature.active}
            checked={minTemperature.active}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => minChange(event.target.checked)}
          />
          <KNTypography>{translation.notLowerThan}</KNTypography>
        </Box>
        <Box>
          <KNTextSlider
            getValue={(val: number): void => {
              setMinTemperature({ ...minTemperature, value: val })
            }}
            icon={IconName.min_temp}
            value={minTemperature.value ?? 0}
            disabled={!minTemperature.active}
          />
        </Box>
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
          }}
        >
          <Checkbox
            sx={{ ml: -1 }}
            value={maxTemperature.active}
            checked={maxTemperature.active}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => maxChange(event.target.checked)}
          />
          <KNTypography>{translation.notHigherThan}</KNTypography>
        </Box>
        <Box>
          <KNTextSlider
            getValue={(val: number): void => {
              setMaxTemperature({ ...maxTemperature, value: val })
            }}
            icon={IconName.max_temp}
            value={maxTemperature.value ?? 0}
            disabled={!maxTemperature.active}
          />
        </Box>
      </FadeIn>
    </Box>
  )
  return (
    <Dialog
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          maxHeight: 435,
          zIndex: 9999,
        },
      }}
      maxWidth="xs"
      open={dialogOpen}
    >
      <DialogContent>{getDialogContent()}</DialogContent>
      <DialogActions>
        <KNButton
          size="medium"
          color="primary"
          variant="contained"
          type="submit"
          startIcon={<CheckIcon fontSize="inherit" />}
          onClick={(): void => {
            handleClose()
            handleSave(
              minTemperature.active ? minTemperature.value : null,
              maxTemperature.active ? maxTemperature.value : null
            )
          }}
        >
          {translation.saveSession}
        </KNButton>
      </DialogActions>
    </Dialog>
  )
}

export default TemperatureThresholdDialog
