import { SubmitHandler, useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'

// @mui imports //
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'

// KN imports //
import KNForm from 'components/KN_Molecules/KNForm/KNForm'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNFormDropdown from 'components/KN_Molecules/KNForm/KNFormDropdown'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'

// Modules //
import FormCompany from './FormCompany'
import FormUser from './FormUser'

// Functional //
import { getErrorMessage } from 'global/helpers/errorHandler'
import { createPredefinedScenario } from 'screens/Playground/Playground.service'
import { getFilteredUserOptions, getScenarios, getPlaygroundLogs } from './PlaygroundForms.helpers'
import { analyticsEvent } from 'global/helpers/analytics'

// Data //
import { playgroundTranslations } from 'screens/Playground/Playground.data'

// Types //
import {
  CreatePredefinedScenarioFormValues,
  DropdownProps,
  PlaygroundResponse,
} from 'screens/Playground/Playground.types'

const CreatePlaygroundScenarioForm = ({ fetchLoading, fetchError, users, companies }): React.ReactElement => {
  const [loading, setLoading] = useState(false)
  const [requestMessage, setRequestMessage] = useState<PlaygroundResponse | null>(null)
  const [filteredUsersOptions, setFilteredUsersOptions] = useState<DropdownProps[]>([])
  const { translation } = playgroundTranslations()
  const { handleSubmit, unregister, control, formState, setError, getValues, setValue, watch } =
    useForm<CreatePredefinedScenarioFormValues>()

  useEffect(() => {
    unregister('company.visibilityLevel')
  }, [])

  useEffect(() => {
    if (fetchError)
      setError('root.server', {
        message: fetchError,
      })
  }, [fetchError])

  const watchCompany = watch('company.name')

  const resetUser = (): void => setValue('user', null)

  useEffect(() => {
    resetUser()
    if (watchCompany) {
      setFilteredUsersOptions(
        getFilteredUserOptions(
          users,
          companies.find((company) => company.displayName === watchCompany)
        )
      )
    }
  }, [watchCompany])

  const onSubmit: SubmitHandler<CreatePredefinedScenarioFormValues> = async (
    data: CreatePredefinedScenarioFormValues
  ) => {
    setLoading(true)
    setRequestMessage(null)
    try {
      await createPredefinedScenario(data).then((response) => {
        setRequestMessage(response)
        analyticsEvent('polestar_cv_playground_predefined_scenario_created', [data.scenarioId])
      })
    } catch (error) {
      setError('root.server', {
        message: getErrorMessage(error),
      })
    }
    setLoading(false)
  }

  return loading || fetchLoading ? (
    <KNLoader />
  ) : (
    <KNForm onSubmit={handleSubmit(onSubmit)}>
      <Stack data-test="predefined-scenario-container" spacing={3}>
        {formState.errors?.root?.server && (
          <DialogContentText component="div" mb={3}>
            <Alert severity="error">{formState.errors.root.server.message}</Alert>
          </DialogContentText>
        )}
        {requestMessage && (
          <DialogContentText component="div" mb={3}>
            <Alert severity="success">{getPlaygroundLogs(requestMessage)}</Alert>
          </DialogContentText>
        )}
        <Box mt={1}>
          <KNTypography variant="displayMD_SB" color="primary.focus">
            {translation.createPredefinedScenarioFormName}
          </KNTypography>
          <Box data-test="scenario-id-dropdown" mt={1} mb={2} display="flex" flexDirection={'column'}>
            <KNFormDropdown
              sx={{
                maxWidth: 'sm',
              }}
              name="scenarioId"
              label={translation.scenarioId}
              control={control}
              rules={{
                required: translation.required,
              }}
              options={getScenarios()}
              description={true}
            />
          </Box>
          <FormCompany
            control={control}
            setValue={setValue}
            getValues={getValues}
            companies={companies}
            scenario={true}
          />
          <FormUser
            control={control}
            setValue={setValue}
            getValues={getValues}
            users={users}
            resetUser={resetUser}
            filteredUsersOptions={filteredUsersOptions}
            selectedCompany={companies.find((company) => company.displayName === watchCompany)}
          />
        </Box>
        <Box>
          <KNButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={formState.isSubmitted && Object.keys(formState.errors).length > 0 && !formState.errors.root}
            onClick={handleSubmit(onSubmit)}
            dataAttribute="save"
          >
            {translation.saveButtonText}
          </KNButton>
        </Box>
      </Stack>
    </KNForm>
  )
}

export default CreatePlaygroundScenarioForm
