import { useContext, useEffect, useState } from 'react'
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Filler, Tooltip } from 'chart.js'
import { Scatter } from 'react-chartjs-2'
import annotationPlugin from 'chartjs-plugin-annotation'

// @mui material imports
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'

// KN Components
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'

// Context
import { useInsightDetailsContext } from 'context/detailsNext/InsightDetails'
import { UserContext } from 'context/authentication/UserContext'

// Functional
import {
  getChartOptions,
  getHighestTemperatureValue,
  getHighestTimestampValue,
  getLowestTemperatureValue,
  getLowestTimestampValue,
  mapToChartData,
} from './TemperatureChart.helpers'

// Types //
import TemperatureChartProps from './TemperatureChart.types'

ChartJS.register(annotationPlugin, LineElement, PointElement, CategoryScale, LinearScale, Filler, Tooltip)

const TemperatureChart: React.FC<TemperatureChartProps> = ({
  filteredIds,
  filteredDateFrom,
  filteredDateTo,
  temperaturePredictionToggle,
  visible,
}) => {
  // Context
  const { insightDetailsState } = useInsightDetailsContext()
  const { data } = insightDetailsState.insightDetailsContext
  const temperatureThreshold = data?.trackingInfoProps?.temperatureThreshold
  const temperatureProps = data?.temperatureChartProps
  const temperaturePrediction = data?.temperaturePredictionProps
  const trackingInfoProps = data?.trackingInfoProps
  const devices = trackingInfoProps?.deviceIds

  const combinedTemperatureData =
    temperaturePredictionToggle &&
    data?.temperatureChartProps &&
    data?.temperaturePredictionProps &&
    data?.temperaturePredictionProps.length > 0
      ? [...(temperatureProps ?? []), ...(temperaturePrediction ?? [])]
      : temperatureProps ?? []
  const { userTimezone } = useContext(UserContext)

  // Module state//
  const [dataLoading, setDataLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [temperatureData, setTemperatureData] = useState<any>(combinedTemperatureData || [])
  const [temperatureLabelVisible, setTemperatureLabelVisible] = useState<boolean>(false)

  const [deviceIds] = useState<string[]>(
    devices
      ? devices.sort(function (a, b) {
          return a.localeCompare(b)
        })
      : []
  )

  useEffect(() => {
    setTemperatureData(combinedTemperatureData)
  }, [temperaturePredictionToggle])

  const changeSetTemperatureLabelVisible = (state: boolean): void => {
    setTemperatureLabelVisible(state)
  }
  // Temperature Chart defaults
  // defaults.font.family = 'SuisseIntl'

  if (!temperatureData || !visible) return null
  return temperatureData > [] ? (
    <Box data-test="temperature-chart" py={2}>
      {error && <Alert severity="error">{error}</Alert>}
      {dataLoading ? (
        <KNLoader />
      ) : (
        temperatureData && (
          <Scatter
            data={mapToChartData(deviceIds, temperatureData, filteredIds)}
            options={getChartOptions(
              getLowestTimestampValue(temperatureData, filteredDateFrom),
              getHighestTimestampValue(temperatureData, filteredDateTo),
              getLowestTemperatureValue(temperatureData),
              getHighestTemperatureValue(temperatureData),
              temperatureLabelVisible,
              changeSetTemperatureLabelVisible,
              userTimezone,
              temperatureThreshold,
              data?.temperaturePredictionProps && data?.temperaturePredictionProps.length > 0
                ? getLowestTimestampValue(data?.temperaturePredictionProps)
                : undefined,
              data?.temperaturePredictionProps && data?.temperaturePredictionProps.length > 0
                ? getHighestTimestampValue(data?.temperaturePredictionProps)
                : undefined
            )}
          />
        )
      )}
    </Box>
  ) : null
}

export default TemperatureChart
