const norrisQuotes = [
  "404: Chuck Norris Can't Find This Page – It's That Hidden!",
  "Whoa, You've Discovered Chuck Norris's Hideout: 404 Error",
  "404: Even Chuck Norris Couldn't Deliver This Page!",
  'Chuck Norris is on Break – 404 Page Missing in Action',
  '404: Chuck Norris Tried, But the Page Took Off on a Joyride',
  "Chuck Norris Says, '404 Error: Where's My Payload?'",
  '404: Chuck Norris Has Gone Rogue, Page MIA',
  'Chuck Norris Took a Detour – 404 Page Lost in Transit',
  "404: Chuck Norris Wonders If You're on the Wrong Route",
  'Chuck Norris Is Impressed – You Found the 404 Page!',
  "404: Chuck Norris Couldn't Locate This Page – It's That Elusive!",
  "Whoa, You've Stumbled into Chuck Norris's Mystery Zone: 404 Error",
]

export const getTruckNorrisQuote = (): string => {
  const randomIndex = Math.floor(Math.random() * norrisQuotes.length)
  const randomQuote = norrisQuotes[randomIndex]
  return randomQuote
}
