import { Observable } from 'rxjs'

// Functional
import { fetchGet, fetchStream } from 'global/helpers/fetchWrapper'
import { fetchPost } from 'global/helpers/fetchWrapper'

// Types
import NotificationProps from './NotificationsMenu.type'

export const streamNotifications = async (
  onNext: (any) => void,
  onError: (any) => void,
  onComplete: () => void
): Promise</* Observable<MessageEvent> */ NotificationProps[]> => await fetchGet('notifications', {}, 'si')

export const markNotificationAsRead = async (entityId: string): Promise<'success' | 'error'> => {
  try {
    return await fetchPost(`notifications/${entityId}/read`).then((_) => 'success')
  } catch (error) {
    return Promise.reject(error)
  }
}
