import { ReactElement } from 'react'
import { renderToString } from 'react-dom/server'

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

import theme from 'assets/theme'

const outlineColor = '#e9ecef'
const strokeWidth = 4

interface MapIconProps {
  color?: string
  rotation?: number
  label?: string
}

export const encodeIconToDataUrl = (icon: ReactElement): string =>
  `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(renderToString(icon))))}`

export const KNGenericMapIcon = ({ color, label }: MapIconProps): ReactElement => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={outlineColor}
      stroke={outlineColor}
      strokeWidth={strokeWidth}
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Z"
    />
    <path
      fill={color ?? theme.palette.primary.main}
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Z"
    />
    {label && (
      <text fill={theme.palette.white.main} textAnchor="middle" fontFamily="Arial" fontSize="10" x="12" y="14">
        {label}
      </text>
    )}
  </svg>
)

export const KNPickupMapIcon = ({ color }: MapIconProps): ReactElement => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={outlineColor}
      stroke={outlineColor}
      strokeWidth={strokeWidth}
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Z"
    />
    <path
      fill={color ?? theme.palette.secondary.main}
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Zm-5 8 5-5 5 5h-3v4.043h-4V10H7Z"
    />
  </svg>
)

export const KNDeliveryMapIcon = ({ color }: MapIconProps): ReactElement => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={outlineColor}
      stroke={outlineColor}
      strokeWidth={strokeWidth}
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Z"
    />
    <path
      fill={color ?? theme.palette.primary.main}
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Zm5 7.043-5 5-5-5h3V5h4v4.043h3Z"
    />
  </svg>
)

export const KNCustomsMapIcon = ({ color }: MapIconProps): ReactElement => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={outlineColor}
      stroke={outlineColor}
      strokeWidth={strokeWidth}
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Z"
    />
    <path
      fill={color ?? theme.palette.secondary.main}
      d="M12 2C8.16 2 5 5.16 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.84-3.16-7-7-7Zm.909 10.182h-2.727V14L7 10.818l3.182-3.182v1.819h2.727v2.727ZM13.818 4 17 7.182l-3.182 3.182V8.545h-2.727V5.818h2.727V4Z"
    />
  </svg>
)

export const KNTruckMapIcon = ({ color }: MapIconProps): ReactElement => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={outlineColor}
      stroke={outlineColor}
      strokeWidth={strokeWidth}
      d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z"
    />
    <path
      fill={color ?? theme.palette.success.main}
      d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z"
    />
  </svg>
)

export const KNTruckDelayedMapIcon = ({ color }: MapIconProps): ReactElement => <KNTruckMapIcon color="#b2455d" />

export const KNHeadingIcon = ({ color, rotation = 0 }: MapIconProps): ReactElement => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={outlineColor}
      stroke={outlineColor}
      strokeWidth={strokeWidth}
      transform={`rotate(${rotation} 12 12)`}
      d="M12 2 4.5 20.29l.71.71L12 18l6.79 3 .71-.71z"
    />
    <path
      fill={color ?? theme.palette.primary.main}
      transform={`rotate(${rotation} 12 12)`}
      d="M12 2 4.5 20.29l.71.71L12 18l6.79 3 .71-.71z"
    />
  </svg>
)

export const KNGeoPointMapIcon = ({ color }: MapIconProps): ReactElement => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={outlineColor}
      stroke={outlineColor}
      strokeWidth={strokeWidth}
      d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"
    />
    <path
      fill={color ?? theme.palette.primary.main}
      d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z"
    />
  </svg>
)
