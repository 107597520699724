import i18n from 'i18n'

export const eventTimelineTranslations = (): Record<any, any> => {
  const eventTimeline = 'modules.cv.event_tracking'
  return {
    translation: {
      moduleName: i18n.t(`${eventTimeline}.moduleName`),
      shipment_status: i18n.t(`${eventTimeline}.shipment_status`),
      aggregated_status: i18n.t(`${eventTimeline}.aggregated_status`),
      moreEventsButton: i18n.t(`${eventTimeline}.moreEventsButton`),
      viewAllEventsButton: i18n.t(`${eventTimeline}.viewAllEventsButton`),
      collapseEventsButton: i18n.t(`${eventTimeline}.collapseEventsButton`),
      no_events: i18n.t(`${eventTimeline}.no_events`),
      shipment_created: i18n.t(`${eventTimeline}.shipment_created`),
      timeline: i18n.t(`${eventTimeline}.timeline`),
      date: i18n.t(`${eventTimeline}.date`),
      view: i18n.t(`${eventTimeline}.view`),
      status: i18n.t(`shared.aggregatedStatus`),
    },
  }
}
