import { ReactElement, MouseEventHandler } from 'react'
import { format, utcToZonedTime } from 'date-fns-tz'

// @mui material imports //
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'
import { Theme } from '@mui/material/styles/createTheme'

// KN Components //
import boxShadow from 'assets/theme/functions/boxShadow'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Functional //
import { getIconForFileType } from './ShipmentFiles.helpers'

// Types //
import { ShipmentFileProps } from './ShipmentFiles.types'

// Data //
import { shipmentFilesTranslations } from './ShipmentFiles.data'
import { getDateFormat } from 'global/helpers/dateFormatters'

const ShipmentFilesTemplate = (
  shipmentsFilesItem: ShipmentFileProps,
  downloadFile: MouseEventHandler<HTMLDivElement> | undefined,
  userTimezone: string,
  highlightedFile?: string
): ReactElement => {
  // Translated Data //
  const { translation } = shipmentFilesTranslations()

  return (
    <Box
      data-test={`shipmentFilesItem-${shipmentsFilesItem.name}`}
      sx={{
        display: highlightedFile ? 'inline-flex' : 'block',
        width: '100%',
      }}
    >
      {/* Icon box */}
      <Box
        sx={{
          color: ({ palette: { dark } }: Theme): string => dark.main,
          display: 'inline-flex',
          alignItems: 'center',
          cursor: 'pointer',
          textDecoration: 'underline 0.1em transparent',
          textUnderlineOffset: '0em',
          transition: 'all 200ms',
          '&:hover': {
            textDecorationColor: ({ palette: { primary } }: Theme): string => primary.main,
            textUnderlineOffset: '0.2em',
            color: ({ palette: { primary } }: Theme): string => primary.main,
          },
        }}
        onClick={downloadFile}
      >
        <Box
          sx={{
            backgroundColor: ({ palette: { primary } }: Theme): string =>
              highlightedFile ? primary.main : 'transparent',
            color: ({ palette: { white, primary } }: Theme): string => (highlightedFile ? white.main : primary.main),
            borderRadius: '4px',
            display: 'flex',
            width: 25,
            height: 25,
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '17px',
            mr: highlightedFile ? 1 : 0.5,
            transition: 'all 200ms',
            '&:hover': {
              boxShadow: ({ palette: { primary } }: Theme): string =>
                `${boxShadow([0, 0], [0, 3], primary.contrastText, 1)}, ${boxShadow(
                  [0, 1],
                  [2, 0],
                  primary.contrastText,
                  1
                )}`,
            },
          }}
        >
          <Icon color="inherit" fontSize="inherit">
            {getIconForFileType(highlightedFile)}
          </Icon>
        </Box>
        <KNTypography variant="textLG" color="inherit" sx={{ boxShadow: 'none !important' }}>
          {highlightedFile && translation[highlightedFile]} {shipmentsFilesItem.name}
        </KNTypography>
      </Box>
      <Box
        sx={{
          display: highlightedFile ? 'block' : 'flex',
          mt: highlightedFile ? 0 : -1,
          ml: highlightedFile ? 'auto' : 3.75,
          alignItems: 'center',
          textDecoration: 'underline 0.15em transparent !important',
        }}
      >
        <KNTypography variant="textMD" color="primary.light">
          {translation.podDateTime}{' '}
          {format(utcToZonedTime(new Date(shipmentsFilesItem.dateTime), userTimezone), getDateFormat('full_timezoned'))}
        </KNTypography>
      </Box>
    </Box>
  )
}

export default ShipmentFilesTemplate
