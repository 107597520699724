import Box from '@mui/material/Box'
import { IconButton, Modal as MuiModal } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ModalProps } from './Modal.type'
import theme from 'assets/theme'

const Modal: React.FC<ModalProps> = ({ open, onClose, children, size = 'medium', overflow = 'scroll' }) => {
  return (
    <MuiModal open={open} onClose={onClose}>
      <>
        <Box
          sx={{
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            position: 'absolute',
            p: 4,
            top: '50%',
            left: '50%',
            width: '100%',
            transform: 'translate(-50%, -50%)',
            maxHeight: '850px',
            overflow: overflow,
            [theme.breakpoints.up('md')]: {
              width: size === 'small' ? '250px' : size === 'large' ? '850px' : '400px',
            },
          }}
        >
          {onClose && (
            <IconButton onClick={onClose} sx={{ position: 'absolute', top: 10, right: 10 }}>
              <CloseIcon />
            </IconButton>
          )}
          {children}
        </Box>
      </>
    </MuiModal>
  )
}

export default Modal
