import colors from 'assets/theme/base/colors'
import typography from 'assets/theme/base/typography'

const tableCell = {
  styleOverrides: {
    head: {
      fontSize: typography.textMD_SB.fontSize,
      fontWeight: typography.textMD_SB.fontWeight,
      color: colors.primary.light,
      paddingTop: '.5rem',
      paddingBottom: '.5rem',
    },
  },
}

export default tableCell
