import { ReactElement, ChangeEvent, Fragment } from 'react'
import _ from 'lodash'

// @mui imports
import Autocomplete from '@mui/material/Autocomplete'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Select, { SelectChangeEvent } from '@mui/material/Select/Select'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import useMediaQuery from '@mui/material/useMediaQuery'
import { SystemStyleObject, Theme } from '@mui/system'
import CloseIcon from '@mui/icons-material/Close'

// KN imports
import theme from 'assets/theme'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNDatePicker from '../KNDatePicker/KNDatePicker'

// Types
import { MegaTableFilterProps } from './MegaTable.types'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'

const MegaTableFilters: React.FC<MegaTableFilterProps> = ({ megaTableFilters }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Stack
      data-test="filters-container"
      id="shipment-insight-filters"
      direction={isMobile ? 'column' : 'row'}
      spacing={2}
    >
      {megaTableFilters?.map((megaTableFilter, index) => {
        if (megaTableFilter.type === 'Dropdown') {
          const handleChange = (event: SelectChangeEvent): void => {
            const {
              target: { value },
            } = event
            megaTableFilter.setValue(value)
            analyticsEvent('polestar_cv_table_filter_set', [megaTableFilter.label])
          }
          const handleClear = (): void => {
            megaTableFilter.setValue(undefined)
          }
          return (
            <FormControl fullWidth key={index} size="small">
              {megaTableFilter.placeholder && !megaTableFilter.value && (
                <KNTypography
                  color="grey.300"
                  sx={({ functions: { pxToRem } }): SystemStyleObject<Theme> => ({
                    position: 'absolute',
                    top: pxToRem(25.5),
                    left: pxToRem(14),
                  })}
                >
                  {megaTableFilter.placeholder}
                </KNTypography>
              )}
              <InputLabel id={`MegaTableFilterLabel${String(megaTableFilter.type)}${String(index)}`}>
                {megaTableFilter.label}
              </InputLabel>
              <Select
                id={`MegaTableFilterSelect${String(megaTableFilter.type)}${String(index)}`}
                value={megaTableFilter.value}
                size="small"
                onClick={(e: any): void => {
                  e.target.getAttribute('data-value') === megaTableFilter.value ? handleClear() : null
                }}
                onChange={handleChange}
              >
                {megaTableFilter.dropdownOptions?.map((dropdownOption, index) => (
                  <MenuItem key={index} value={dropdownOption.value}>
                    {dropdownOption.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        }
        if (megaTableFilter.type === 'DropdownMulti') {
          const handleChange = (event: SelectChangeEvent): void => {
            const {
              target: { value },
            } = event
            megaTableFilter.setValue(
              // On autofill we get a stringified value.
              typeof value === 'string' ? value.split(',') : value
            )
            analyticsEvent('polestar_cv_table_filter_set', [megaTableFilter.label])
          }
          return (
            <FormControl fullWidth key={index} size="small">
              <InputLabel id={`MegaTableFilterLabel${String(megaTableFilter.type)}${String(index)}`}>
                {megaTableFilter.label}
              </InputLabel>
              <Select
                id={`MegaTableFilterSelect${String(megaTableFilter.type)}${String(index)}`}
                value={megaTableFilter.value}
                multiple
                size="small"
                onChange={handleChange}
              >
                {megaTableFilter.dropdownOptions?.map((dropdownOption, index) => (
                  <MenuItem key={index} value={dropdownOption.value}>
                    <Checkbox checked={dropdownOption.value.search(megaTableFilter.value) > -1} />
                    <ListItemText primary={dropdownOption.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        }
        if (megaTableFilter.type === 'Text') {
          const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
            megaTableFilter.setValue(event.target.value)
            analyticsEvent('polestar_cv_table_filter_set', [megaTableFilter.label])
          }
          return (
            <FormControl size="small" fullWidth key={index}>
              {megaTableFilter.placeholder && !megaTableFilter.value && (
                <KNTypography
                  color="grey.300"
                  sx={({ functions: { pxToRem } }): SystemStyleObject<Theme> => ({
                    position: 'absolute',
                    top: pxToRem(25.5),
                    left: pxToRem(14),
                  })}
                >
                  {megaTableFilter.placeholder}
                </KNTypography>
              )}
              <TextField
                label={megaTableFilter.label}
                id={`MegaTableFilterTextField${String(megaTableFilter.type)}${String(index)}`}
                value={megaTableFilter.value}
                onChange={handleChange}
                size="small"
                InputProps={{
                  endAdornment: megaTableFilter.value && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={(): void => {
                          megaTableFilter.setValue('')
                        }}
                      >
                        <CloseIcon data-test="close-icon" fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          )
        }
        if (megaTableFilter.type === 'DateRangePicker') {
          return (
            <Fragment key={index}>
              <Box data-test="date-from" sx={{ width: '100%', position: 'relative' }}>
                <KNDatePicker
                  selected={megaTableFilter.value?.startDate ?? null}
                  startDate={megaTableFilter.value?.startDate ?? null}
                  endDate={megaTableFilter.value?.endDate ?? null}
                  onChange={(value): void => {
                    megaTableFilter.setValue([value, megaTableFilter.value?.endDate ?? null])
                    analyticsEvent('polestar_cv_table_filter_set', [megaTableFilter.label])
                  }}
                  disabled={megaTableFilter.disabled}
                  label={megaTableFilter.label}
                  inputProps={{ size: 'small' }}
                  id={`MegaTableFilterTextField${String(megaTableFilter.type)}${String(index)}Start`}
                  placeholderText={
                    megaTableFilter.placeholder && megaTableFilter.disabled && megaTableFilter.placeholder
                  }
                  value={megaTableFilter.value?.startDate}
                  selectsStart={true}
                />
              </Box>
              <Box data-test="date-to" key={index} sx={{ width: '100%', position: 'relative' }}>
                <KNDatePicker
                  selected={megaTableFilter.value?.endDate ?? null}
                  startDate={megaTableFilter.value?.startDate ?? null}
                  endDate={megaTableFilter.value?.endDate ?? null}
                  onChange={(value): void => megaTableFilter.setValue([megaTableFilter.value?.startDate, value])}
                  disabled={megaTableFilter.disabled}
                  label={megaTableFilter.labelTo ?? megaTableFilter.label}
                  inputProps={{ size: 'small' }}
                  id={`MegaTableFilterTextField${String(megaTableFilter.type)}${String(index)}End`}
                  placeholderText={
                    megaTableFilter.placeholder && megaTableFilter.disabled && megaTableFilter.placeholder
                  }
                  value={megaTableFilter.value?.endDate}
                  selectsEnd={true}
                  minDate={megaTableFilter.value?.startDate ?? null}
                />
              </Box>
            </Fragment>
          )
        }
        if (megaTableFilter.type === 'DatePicker' || megaTableFilter.type === 'DateTimePicker') {
          const isTimePicker = megaTableFilter.type === 'DateTimePicker'
          return (
            <Box key={index} sx={{ width: '100%', position: 'relative' }}>
              <KNDatePicker
                selected={megaTableFilter.value ?? null}
                onChange={(value): void => megaTableFilter.setValue(value)}
                disabled={megaTableFilter.disabled}
                label={megaTableFilter.label}
                inputProps={{ size: 'small' }}
                id={`MegaTableFilterTextField${String(megaTableFilter.type)}${String(index)}`}
                placeholderText={megaTableFilter.placeholder && megaTableFilter.disabled && megaTableFilter.placeholder}
                value={megaTableFilter.value}
                showTimeSelect={isTimePicker}
                minDate={megaTableFilter.value.startDate ?? null}
              />
            </Box>
          )
        }

        if (megaTableFilter.type === 'Toggle') {
          const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            megaTableFilter.setValue(event.target.checked)
            analyticsEvent('polestar_cv_table_filter_set', [megaTableFilter.label])
          }

          return (
            <FormControlLabel
              key={index}
              control={<Switch checked={megaTableFilter.value} name={megaTableFilter.label} onChange={handleChange} />}
              label={megaTableFilter.label}
              sx={{ pt: 3 }}
            />
          )
        }
        if (megaTableFilter.type === 'Autocomplete') {
          const handleChange = (event: ChangeEvent<HTMLInputElement> | null): void => {
            megaTableFilter.setValue(event)
          }
          return (
            <FormControl variant="standard" fullWidth key={index}>
              <Autocomplete
                multiple
                freeSolo
                size="small"
                options={[]}
                value={megaTableFilter.value}
                renderTags={(tagValue, getTagProps): ReactElement[] =>
                  tagValue.map((option, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Chip {...getTagProps({ index })} size="small" label={option} />
                  ))
                }
                renderInput={(params): ReactElement => (
                  <TextField {...params} data-test="search-input" label={megaTableFilter.label} />
                )}
              />
            </FormControl>
          )
        }
      })}
    </Stack>
  )
}

export default MegaTableFilters
