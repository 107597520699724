import { useContext, useEffect, useState } from 'react'
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Filler, Tooltip } from 'chart.js'
import { Scatter } from 'react-chartjs-2'
import annotationPlugin from 'chartjs-plugin-annotation'

// @mui imports //
import Box from '@mui/material/Box'

// Context //
import { UserContext } from 'context/authentication/UserContext'

// Functional //
import { getTemperatureSnippetData } from './Snippets.service'
import {
  getChartOptions,
  getHighestTemperatureValue,
  getHighestTimestampValue,
  getLowestTemperatureValue,
  getLowestTimestampValue,
  getThresholdBreachedXMaxValue,
  getThresholdBreachedXMinValue,
  getUniqueDevices,
  mapToChartData,
} from 'modules/TemperatureChart/TemperatureChart.helpers'
import { limitTemperatureSnippetData } from './TemperatureSnippet.helpers'

// Types //
import { TemperatureDataSetProps } from 'modules/TemperatureChart/TemperatureChart.types'
import { TemperatureSnippetProps } from './Snippets.types'

ChartJS.register(annotationPlugin, LineElement, PointElement, CategoryScale, LinearScale, Filler, Tooltip)

const TemperatureSnippet: React.FC<TemperatureSnippetProps> = ({ shipmentId, breachValue }) => {
  // Module state //
  const [temperatureData, setTemperatureData] = useState<TemperatureDataSetProps[]>([])
  const [temperatureLabelVisible, setTemperatureLabelVisible] = useState<boolean>(false)
  const [devicesIds, setDevicesIds] = useState<string[]>([])

  // Context //
  const { userTimezone } = useContext(UserContext)

  useEffect(() => {
    if (!shipmentId) return
    const getData = async (): Promise<void> => {
      const data: TemperatureDataSetProps[] = await getTemperatureSnippetData(shipmentId)
      const limitedData: TemperatureDataSetProps[] = limitTemperatureSnippetData(data, breachValue)
      setTemperatureData(limitedData)
      const uniqueDevices = getUniqueDevices(limitedData)
      setDevicesIds(uniqueDevices)
    }
    void getData()
  }, [shipmentId])

  const changeSetTemperatureLabelVisible = (state: boolean): void => {
    setTemperatureLabelVisible(state)
  }

  return temperatureData > [] ? (
    <Box py={2}>
      {temperatureData && (
        <Scatter
          data={mapToChartData(devicesIds, temperatureData)}
          options={getChartOptions(
            getLowestTimestampValue(temperatureData),
            getHighestTimestampValue(temperatureData),
            getLowestTemperatureValue(temperatureData),
            getHighestTemperatureValue(temperatureData),
            temperatureLabelVisible,
            changeSetTemperatureLabelVisible,
            userTimezone,
            undefined,
            getThresholdBreachedXMinValue(temperatureData, breachValue),
            getThresholdBreachedXMaxValue(temperatureData, breachValue)
          )}
        />
      )}
    </Box>
  ) : null
}

export default TemperatureSnippet
