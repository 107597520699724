import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import FadeIn from 'react-fade-in'

// @mui imports
import Alert from '@mui/material/Alert'
import DialogContentText from '@mui/material/DialogContentText'
import Box from '@mui/material/Box'

// KN modules
import GeofenceMap from 'modules/GeofenceMap/GeofenceMap'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Functional
import {
  createShipmentGeofence,
  deleteShipmentGeofence,
  editShipmentGeofence,
  getShipmentGeofences,
} from './Geofence.service'
import { getErrorMessage } from 'global/helpers/errorHandler'

// Types
import { GeofenceCircle, GeofenceData, GeofencePolygon } from './Geofence.types'

// Data
import { geofenceTranslations } from './Geofence.data'

const Geofence = (): React.ReactElement => {
  const { shipmentid } = useParams<{
    shipmentid: string
  }>()
  const [geofenceData, setGeofenceData] = useState<GeofenceData[]>([])
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)

  const { translation } = geofenceTranslations()

  const fetchData = async (): Promise<void> => {
    setDataLoading(true)
    try {
      const data = await getShipmentGeofences(shipmentid)
      setGeofenceData(data)
    } catch (error) {
      setError(getErrorMessage(error))
    }
    setDataLoading(false)
  }

  useEffect(() => {
    void fetchData()
  }, [])

  const deleteGeofence = async (cid: string | null): Promise<void> => {
    if (!cid) return
    try {
      await deleteShipmentGeofence(shipmentid, cid).then(() => setSuccessMessage(translation.successMessageDeleted))
    } catch (error) {
      setError(getErrorMessage(error))
    }
  }

  const saveGeofence = async (geofence: GeofencePolygon | GeofenceCircle | null): Promise<void> => {
    if (!geofence) return

    let points
    let shape
    let center
    let radius

    if ('polygon' in geofence) {
      shape = 'polygon'
      // Type guard for GeofencePolygon
      points = geofence.polygon
        .getPath()
        .getArray()
        .map((point) => ({ latitude: point.lat(), longitude: point.lng() }))
    } else if ('circle' in geofence) {
      shape = 'radius'
      // Type guard for GeofenceCircle
      const latitude = geofence.circle.getCenter()?.lat()
      const longitude = geofence.circle.getCenter()?.lng()
      center = { latitude: latitude, longitude: longitude }
      radius = geofence.circle.getRadius()
      if (!center) {
        return
      }
    }

    try {
      if (geofence.cid) {
        // Edit existing geofence
        await editShipmentGeofence(shipmentid, geofence.cid, {
          points: points,
          shape: shape,
          center: center,
          radius: Number(radius?.toFixed(5)),
        }).then(() => setSuccessMessage(translation.successMessageEdited))
      } else {
        // Create new geofence
        await createShipmentGeofence(shipmentid, {
          points: points,
          type: geofence.type,
          shape: shape,
          center: center,
          radius: Number(radius?.toFixed(5)),
        }).then(() => {
          setSuccessMessage(translation.successMessageCreated)
        })
      }
    } catch (error) {
      setError(getErrorMessage(error))
    }

    void fetchData()
  }

  return (
    <Box>
      <KNTypography variant="displayXXS_SB" color="primary.focus">
        {translation.moduleName}
      </KNTypography>
      {error && (
        <DialogContentText component="div">
          <Alert onClose={(): void => setError(null)} severity="error">
            {error}
          </Alert>
        </DialogContentText>
      )}
      {successMessage && (
        <DialogContentText component="div">
          <Alert onClose={(): void => setSuccessMessage(null)} severity="success">
            {successMessage}
          </Alert>
        </DialogContentText>
      )}
      {dataLoading ? (
        <FadeIn visible={dataLoading}>
          <KNLoader />
        </FadeIn>
      ) : (
        <FadeIn visible={!dataLoading}>
          <Box mt={2}>
            <GeofenceMap geofenceData={geofenceData} saveGeofence={saveGeofence} deleteGeofence={deleteGeofence} />
          </Box>
        </FadeIn>
      )}
    </Box>
  )
}

export default Geofence
