import { ReactElement, useContext, useEffect, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { format, utcToZonedTime } from 'date-fns-tz'
import FadeIn from 'react-fade-in'

// @mui imports
import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import { Theme } from '@mui/material/styles/createTheme'
import Stack from '@mui/material/Stack'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import CheckIcon from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/Delete'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import LeakRemoveIcon from '@mui/icons-material/LeakRemove'
import VideoChatIcon from '@mui/icons-material/VideoChat'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import Tooltip from '@mui/material/Tooltip/Tooltip'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import theme from 'assets/theme'

// Modules
import CodeScanner from 'components/Molecules/CodeScanner/CodeScanner'
import DeviceScanner from 'components/Molecules/CodeScanner/DeviceScanner'
import PairingSessionForm from './PairingSessionForm'
import PairingSessionRow from './PairingSessionRow'

// Functional
import { processDefaultValues } from 'global/helpers/form'
import pxToRem from 'assets/theme/functions/pxToRem'
import { getHeader } from './PairDevices.helpers'
import { stylingActive, stylingInactive } from 'global/helpers/tabs'
import { createPairingSession, editPairing, unpairBulk, unpairShipment } from 'screens/PairDevices/PairDevices.service'

// Context
import { UserContext } from 'context/authentication/UserContext'

// Data
import { pairDevicesTranslations } from 'screens/PairDevices/PairDevices.data'

// Types
import { PairingSession, PairingSessionType, PairingSessionsProps } from 'screens/PairDevices/PairDevices.types'

const PairingSessions = ({ pairingSessionsData, enforcedEdit, inputResult }: PairingSessionsProps): ReactElement => {
  // Context //
  const { userTimezone } = useContext(UserContext)

  // Module specific //
  const [storagePairingSessions, setStoragePairingSessions] = useState<PairingSession[]>([])
  const [localPairingSessions, setLocalPairingSessions] = useState<PairingSession[]>([])
  const [sessionTab, setSessionTab] = useState<number>(0)
  const [activeRow, setActiveRow] = useState<number | null>(null)
  const [editModeRow, setEditModeRow] = useState<number | null>(null)
  const [errorActive, setErrorActive] = useState(false)

  // Scanners //
  const [mobileScannerOpen, setMobileScannerOpen] = useState(false)
  const [deviceScannerOpen, setDeviceScannerOpen] = useState(false)

  useEffect(() => {
    setTimeout(() => setErrorActive(false), 5000)
  }, [errorActive])

  const { handleSubmit, reset, control, setValue, getValues } = useForm<PairingSession>()
  const desktopView = useMediaQuery(theme.breakpoints.up('md'))

  const { translation } = pairDevicesTranslations()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'pairings',
  })

  const updatePairingsList = (sessions: PairingSession[]): void => {
    if (pairingSessionsData) sessions = sessions.concat(pairingSessionsData)
    setLocalPairingSessions(sessions)
    setSessionTab(0)
  }

  useEffect(() => {
    const savedSessions = localStorage.getItem('pairingSessions')
    if (!savedSessions && !pairingSessionsData) return
    let sessions: PairingSession[] = []
    if (savedSessions) {
      sessions = JSON.parse(savedSessions)
      setStoragePairingSessions(sessions)
    }
    updatePairingsList(sessions)
  }, [])

  useEffect(() => {
    if (!localPairingSessions[sessionTab]) return
    if (localPairingSessions[sessionTab].type === PairingSessionType.pending)
      reset(processDefaultValues(localPairingSessions[sessionTab]))
    if (editModeRow !== null) setEditModeRow(null)
  }, [sessionTab])

  useEffect(() => {
    if (editModeRow === null) reset(processDefaultValues({}))
    else reset(processDefaultValues(localPairingSessions[sessionTab]))
  }, [editModeRow])

  const handleAddNewSession = (inputResult?: PairingSession): void => {
    if (!inputResult) {
      const newPairing = {
        reference: '',
        deviceIds: [],
        temperatureThreshold: null,
        unpairingRule: true,
      }
      const newDate = Date.now()
      const newSession = {
        sessionTimestamp: newDate,
        pairings: [newPairing],
        type: PairingSessionType.pending,
      }
      if (localPairingSessions[sessionTab]?.type === PairingSessionType.pending) {
        const newSessions = localPairingSessions.map((session, index) =>
          index === sessionTab ? { ...session, pairings: getValues('pairings') } : session
        )
        setLocalPairingSessions([...newSessions, newSession])
      } else {
        setLocalPairingSessions([newSession, ...localPairingSessions])
        reset(processDefaultValues(newSession))
        setSessionTab(0)
      }
    }
    if (inputResult) {
      const sessionNumber = 0
      const newDate = Date.now()
      const newSession = {
        sessionTimestamp: newDate,
        pairings: inputResult.pairings,
        type: PairingSessionType.pending,
      }
      setLocalPairingSessions([newSession, ...localPairingSessions])
      reset(processDefaultValues(newSession))
      setSessionTab(sessionNumber)
    }
  }

  // Enforce edit useeffect //
  useEffect(() => {
    if (enforcedEdit) {
      handleAddNewSession(inputResult)
    }
  }, [enforcedEdit])

  const handleRemoveSession = (indexToRemove: number): void => {
    const updatedSessions = localPairingSessions.filter((_, index) => index !== indexToRemove)
    const savedSessions = updatedSessions.filter((session) => session.type === PairingSessionType.saved)
    setLocalPairingSessions(updatedSessions)
    localStorage.setItem('pairingSessions', JSON.stringify(savedSessions))

    // If the removed session was the currently selected tab, update the tab to the last session
    if (sessionTab === indexToRemove) {
      const lastSessionIndex = updatedSessions.length - 1
      setSessionTab(Math.max(0, lastSessionIndex)) // Ensure the new tab index is valid
    }
    window.location.reload()
  }

  // SESSION TABS
  const getTabs = (): JSX.Element => {
    const getTab = (label: string, value: number, type?: string): JSX.Element => {
      return (
        <Box
          key={value}
          sx={{
            ...(sessionTab === value ? stylingActive : stylingInactive),
            flexBasis: 'auto',
            flexShrink: 0,
          }}
          onClick={(): void => {
            if (localPairingSessions[sessionTab].type === PairingSessionType.pending) {
              setLocalPairingSessions(
                localPairingSessions.map((session, index) =>
                  index === sessionTab ? { ...session, pairings: getValues('pairings') } : session
                )
              )
            }
            setSessionTab(value)
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <KNTypography color="inherit" variant="textLG">
              {label}
            </KNTypography>
            {type === 'saved' && sessionTab === value && (
              <DeleteIcon fontSize="small" sx={{ ml: 1.5 }} onClick={(): void => handleRemoveSession(sessionTab)} />
            )}
          </Box>
        </Box>
      )
    }

    return (
      <Stack direction="row" spacing={1} sx={{ backgroundColor: 'transparent', height: 40, zIndex: 2 }}>
        <Box
          sx={{
            color: ({ palette: { primary } }: Theme): string => primary.light,
            cursor: 'pointer',
            display: 'inline-flex',
            padding: ({ functions: { pxToRem } }: Theme): string => `${pxToRem(8) as number} ${pxToRem(12) as number}`,
            alignItems: 'center',
            flexShrink: 0,
          }}
          onClick={(): void => handleAddNewSession()}
        >
          <AddIcon color="inherit" sx={{ mr: 0.5 }} />
          <KNTypography color="inherit" variant="textLG">
            {translation.createSession}
          </KNTypography>
        </Box>
        {localPairingSessions
          .sort((a, b) => Number(b?.sessionTimestamp) - Number(a?.sessionTimestamp))
          .map((session, index) => {
            return getTab(
              format(utcToZonedTime(new Date(session.sessionTimestamp), userTimezone), 'dd-MM-yyyy HH:mm'),
              index,
              session.type
            )
          })}
      </Stack>
    )
  }

  // MANAGE PAIRINGS IN CURRENT SESSION
  const addCopyRow = (): void => {
    const currentSession = getValues('pairings')
    if (currentSession) {
      const currentSession = getValues('pairings')
      const sessionTimestamp = getValues('sessionTimestamp')
      const lastItemId = currentSession.length - 1
      const lastItem = {
        reference: '',
        description: '',
        deviceIds: [],
        temperatureThreshold: currentSession[lastItemId].temperatureThreshold ?? null,
        unpairingRule: currentSession[lastItemId].unpairingRule ?? true,
      }
      const updatedPairings = [...currentSession, lastItem]

      const otherSessions = localPairingSessions.filter((session) => session.sessionTimestamp !== sessionTimestamp)

      const newSession = {
        ...currentSession,
        type: PairingSessionType.reopened,
        pairings: updatedPairings,
        sessionTimestamp: sessionTimestamp,
      }

      setLocalPairingSessions([newSession, ...otherSessions])
      setEditModeRow(currentSession.length)
    } else {
      const currentSession = localPairingSessions[sessionTab]
      const lastItemId = currentSession.pairings.length - 1
      const lastItem = {
        reference: '',
        description: '',
        deviceIds: [],
        temperatureThreshold: currentSession.pairings[lastItemId].temperatureThreshold ?? null,
        unpairingRule: currentSession.pairings[lastItemId].unpairingRule ?? true,
      }
      const updatedPairings = [...currentSession.pairings, lastItem]

      const otherSessions = localPairingSessions.filter(
        (session) => session.sessionTimestamp !== currentSession.sessionTimestamp
      )

      const newSession = {
        ...currentSession,
        type: PairingSessionType.reopened,
        pairings: updatedPairings,
      }

      setLocalPairingSessions([newSession, ...otherSessions])
      setEditModeRow(currentSession.pairings.length)
    }
  }

  const addEmptyRow = (): void => {
    const currentSession = getValues('pairings')
    if (currentSession) {
      const currentSession = getValues('pairings')
      const sessionTimestamp = getValues('sessionTimestamp')
      const lastItem = {
        reference: '',
        description: '',
        deviceIds: [],
        temperatureThreshold: null,
        unpairingRule: true,
      }
      const updatedPairings = [...currentSession, lastItem]

      const otherSessions = localPairingSessions.filter((session) => session.sessionTimestamp !== sessionTimestamp)

      const newSession = {
        ...currentSession,
        type: PairingSessionType.reopened,
        pairings: updatedPairings,
        sessionTimestamp: sessionTimestamp,
      }

      setLocalPairingSessions([newSession, ...otherSessions])
      setEditModeRow(currentSession.length)
    } else {
      const currentSession = localPairingSessions[sessionTab]
      const lastItem = {
        reference: '',
        description: '',
        deviceIds: [],
        temperatureThreshold: null,
        unpairingRule: true,
      }
      const updatedPairings = [...currentSession.pairings, lastItem]

      const otherSessions = localPairingSessions.filter(
        (session) => session.sessionTimestamp !== currentSession.sessionTimestamp
      )

      const newSession = {
        ...currentSession,
        type: PairingSessionType.reopened,
        pairings: updatedPairings,
      }

      setLocalPairingSessions([newSession, ...otherSessions])
      setEditModeRow(currentSession.pairings.length)
    }
  }

  // SAVE SESSIONS IN LOCAL STORAGE
  const onSaveSessions = (data: PairingSession): void => {
    const sessionExists = storagePairingSessions.find((session) => session.sessionTimestamp === data.sessionTimestamp)

    const newSessions = sessionExists
      ? storagePairingSessions.map((session) => (session.sessionTimestamp === data.sessionTimestamp ? data : session))
      : [...storagePairingSessions, data]
    newSessions.forEach((session) => (session.type = PairingSessionType.saved))
    setLocalPairingSessions(
      localPairingSessions.map((session) =>
        session.sessionTimestamp === data.sessionTimestamp ? { ...data, type: PairingSessionType.saved } : session
      )
    )
    setStoragePairingSessions(newSessions)
    localStorage.setItem('pairingSessions', JSON.stringify(newSessions))
    setEditModeRow(null)
  }

  // ACTIONS
  const pairEverything = async (): Promise<void> => {
    const currentSession = localPairingSessions[sessionTab]
    await createPairingSession(currentSession.pairings)
      .then(() => {
        handleRemoveSession(sessionTab)
        window.location.reload()
      })
      .catch(() => setErrorActive(true))
  }

  const unpairEverything = async (): Promise<void> => {
    const currentSession = localPairingSessions[sessionTab]
    const cidsForUnpairing = currentSession.pairings.map((pairing) => pairing.cid)
    await unpairBulk(cidsForUnpairing)
    window.location.reload()
  }

  const saveRow = async (index: number): Promise<void> => {
    const currentSession = { ...localPairingSessions[sessionTab], pairings: getValues('pairings') }
    if (localPairingSessions[sessionTab].type === PairingSessionType.saved) {
      const newStorage = storagePairingSessions.map((session) =>
        session.sessionTimestamp === currentSession.sessionTimestamp ? currentSession : session
      )
      localStorage.setItem('pairingSessions', JSON.stringify(newStorage))
      updatePairingsList(newStorage)
      setSessionTab(sessionTab)
    } else {
      setLocalPairingSessions(
        localPairingSessions.map((session) =>
          session.sessionTimestamp === currentSession.sessionTimestamp ? currentSession : session
        )
      )
      const currentPairing = currentSession?.pairings[index]
      if (currentPairing.cid) {
        const params = {
          reference: currentPairing.reference,
          deviceIds: currentPairing.deviceIds,
          unpairingRule: currentPairing.unpairingRule ? true : false,
          description: currentPairing.description,
          temperatureThreshold: currentPairing.temperatureThreshold ?? null,
        }
        await editPairing(currentPairing.cid, params)
      }
    }
  }

  // const pairRow = async (index: number): Promise<void> => {
  //   const pairing = localPairingSessions[sessionTab].pairings[index]
  //   if (pairing.cid) {
  //     await pairShipment(pairing)
  //   }
  // }

  const unpairRow = async (index: number): Promise<void> => {
    const pairing = localPairingSessions[sessionTab].pairings[index]
    if (pairing.cid) {
      await unpairShipment(pairing.cid)
    }
  }

  const deleteRow = (index: number): void => {
    if (localPairingSessions[sessionTab].type === PairingSessionType.pending) {
      remove(index)
      if (getValues('pairings').length === 0) {
        const newSessions = storagePairingSessions.filter(
          (session) => getValues('sessionTimestamp') !== session.sessionTimestamp
        )
        localStorage.setItem('pairingSessions', JSON.stringify(newSessions))
        setStoragePairingSessions(newSessions)
        setLocalPairingSessions(
          localPairingSessions.filter((session) => getValues('sessionTimestamp') !== session.sessionTimestamp)
        )
        window.location.reload()
      }
    } else {
      const currentSession = localPairingSessions[sessionTab]
      const newPairings = currentSession.pairings.filter((pairing, i) => index !== i)

      const newStorage =
        newPairings.length > 0
          ? storagePairingSessions.map((session) =>
              session.sessionTimestamp === currentSession.sessionTimestamp
                ? { ...session, pairings: newPairings }
                : session
            )
          : storagePairingSessions.filter((session) => session.sessionTimestamp !== currentSession.sessionTimestamp)

      updatePairingsList(newStorage)
      setStoragePairingSessions(newStorage)
      localStorage.setItem('pairingSessions', JSON.stringify(newStorage))
      newPairings.length === 0 && window.location.reload()
    }
  }

  // TABLE HEADERS
  const tableHeaders = (): {
    name: string
    size: number
  }[] => {
    const { translation } = pairDevicesTranslations()
    const notPendingSession = localPairingSessions[sessionTab]?.type != PairingSessionType.pending
    const columns = desktopView && notPendingSession ? [{ name: 'Paired', size: 1 }] : []
    return [
      ...columns,
      { name: translation.shipmentId, size: notPendingSession ? 2 : 3 },
      { name: translation.description, size: 3 },
      { name: translation.deviceIds, size: 3 },
      { name: translation.temperatureThreshold, size: 2 },
      { name: translation.autoUnpair, size: 1 },
    ]
  }

  const getHeaders = (): ReactElement => (
    <Box sx={{ borderBottom: 1, borderColor: 'grey.300' }} mt={1}>
      <Grid container spacing={2}>
        {tableHeaders().map((header, index) => (
          <Grid item md={header.size} key={index}>
            {getHeader(header.name)}
          </Grid>
        ))}
      </Grid>
    </Box>
  )

  // ACTION BUTTONS
  const getSubmitButton = (): ReactElement | null => {
    if (
      localPairingSessions[sessionTab]?.type === PairingSessionType.pending ||
      localPairingSessions[sessionTab]?.type === PairingSessionType.reopened
    )
      return (
        <KNButton
          size="medium"
          color="primary"
          variant="contained"
          type="submit"
          startIcon={<CheckIcon fontSize="inherit" />}
          onClick={handleSubmit(onSaveSessions)}
        >
          {translation.saveSession}
        </KNButton>
      )
    if (localPairingSessions[sessionTab]?.type === PairingSessionType.saved)
      return (
        <KNButton
          size="medium"
          color="primary"
          variant="contained"
          type="submit"
          startIcon={<CheckIcon fontSize="inherit" />}
          onClick={(): Promise<void> => pairEverything()}
        >
          {'Pair everything'}
        </KNButton>
      )
    if (localPairingSessions[sessionTab]?.pairings?.filter((pairing) => pairing?.completionDate).length === 0)
      return (
        <KNButton
          size="medium"
          color="primary"
          variant="contained"
          type="submit"
          startIcon={<CheckIcon fontSize="inherit" />}
          onClick={(): Promise<void> => unpairEverything()}
        >
          {'Unpair everything'}
        </KNButton>
      )
    return null
  }

  const getActionButtons = (index: number): ReactElement | null => {
    const editButton = (
      <Tooltip title={translation.edit} arrow placement={'bottom-end'}>
        <IconButton
          size="small"
          onClick={(): void => setEditModeRow(index)}
          sx={{
            marginRight: 0.5,
            width: 20,
            height: 20,
          }}
        >
          <EditIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    )
    const saveButton = (
      <IconButton
        size="small"
        onClick={(): void => {
          setEditModeRow(null)
          void saveRow(index)
        }}
        sx={{
          marginRight: 0.5,
          width: 20,
          height: 20,
        }}
      >
        <CheckIcon fontSize="inherit" />
      </IconButton>
    )

    const removeButton = (
      <Tooltip title={translation.removeRow} arrow placement={'bottom-end'}>
        <IconButton
          size="small"
          onClick={(): void => deleteRow(index)}
          sx={{
            width: 20,
            height: 20,
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    )

    if (localPairingSessions[sessionTab].pairings[activeRow ?? 0]?.completionDate) return null
    if (localPairingSessions[sessionTab]?.type === PairingSessionType.pending) return removeButton
    if (localPairingSessions[sessionTab]?.type === PairingSessionType.saved)
      return (
        <>
          {editModeRow === index ? saveButton : editButton}
          {removeButton}
        </>
      )
    return (
      <>
        {editModeRow === index ? saveButton : editButton}
        {localPairingSessions[sessionTab].pairings[index].cid && (
          <Tooltip title={translation.complete} arrow placement={'bottom-end'}>
            <IconButton
              size="small"
              onClick={(): void => void unpairRow(index)}
              sx={{
                width: 20,
                height: 20,
              }}
            >
              <LeakRemoveIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
        {(localPairingSessions[sessionTab]?.type === PairingSessionType.pending ||
          localPairingSessions[sessionTab]?.type === PairingSessionType.reopened) &&
          removeButton}
      </>
    )
  }

  return (
    <Box mt={3} display="flex" flexDirection="column" sx={{ position: 'relative' }}>
      <FadeIn visible={errorActive}>
        <Alert variant="filled" severity="error" sx={{ mb: 2, position: 'absolute', top: '-60px', width: '100%' }}>
          {translation.error}
        </Alert>
      </FadeIn>
      <FadeIn>
        <KNTypography variant="textLG_SB" color="primary.focus">
          {translation.sessionsTitle}
        </KNTypography>
        <Box mt={2}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              height: '45px',
              width: '100%',
              overflowX: 'scroll',
              overflowY: 'hidden',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}
          >
            {getTabs()}
          </Box>

          {localPairingSessions[sessionTab]?.type !== PairingSessionType.pending ? (
            <FadeIn>
              <Box sx={{ width: '95%', position: 'relative' }}>
                {desktopView && getHeaders()}
                {localPairingSessions[sessionTab]?.pairings.map((pairing, index) => (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="center"
                    position="relative"
                    sx={{ width: '100%' }}
                    onMouseEnter={(): void => setActiveRow(index)}
                    onMouseLeave={(): void => {
                      setActiveRow(null)
                    }}
                  >
                    {editModeRow === index ? (
                      <PairingSessionForm
                        editMode={true}
                        control={control}
                        getValues={getValues}
                        setValue={setValue}
                        pairingIndex={index}
                      />
                    ) : (
                      <PairingSessionRow pairing={pairing} type={localPairingSessions[sessionTab].type} />
                    )}
                    {desktopView && (
                      <Box position="absolute" sx={{ top: '15px', right: '-45px', pl: '50px', height: '100%' }}>
                        <FadeIn visible={activeRow === index}>{getActionButtons(index)}</FadeIn>
                      </Box>
                    )}
                    {!desktopView && (
                      <Box position="absolute" top={10} right={0}>
                        {getActionButtons(index)}
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            </FadeIn>
          ) : (
            <FadeIn>
              <Box sx={{ width: '95%', position: 'relative' }}>
                {desktopView && getHeaders()}
                {fields.map((pairing, index) => (
                  <Box
                    key={pairing.id}
                    display="flex"
                    alignItems="center"
                    position="relative"
                    onMouseEnter={(): void => setActiveRow(index)}
                    onMouseLeave={(): void => {
                      setActiveRow(null)
                    }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <PairingSessionForm
                        editMode={false}
                        control={control}
                        getValues={getValues}
                        setValue={setValue}
                        pairingIndex={index}
                      />
                    </Box>
                    {desktopView && (
                      <Box position="absolute" sx={{ top: '15px', right: '-45px', pl: '50px', height: '100%' }}>
                        <FadeIn visible={activeRow === index}>{getActionButtons(index)}</FadeIn>
                      </Box>
                    )}
                    {!desktopView && (
                      <Box position="absolute" top={10} right={0}>
                        {getActionButtons(index)}
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            </FadeIn>
          )}

          <FadeIn
            visible={
              localPairingSessions[sessionTab]?.type === PairingSessionType.pending ||
              localPairingSessions[sessionTab]?.type === PairingSessionType.reopened ||
              localPairingSessions[sessionTab]?.type === PairingSessionType.saved
            }
          >
            <Box mt={1} display="flex" justifyContent="flex-end" mr={pxToRem(20)}>
              <Tooltip title={translation.copyRow}>
                <IconButton
                  size="small"
                  onClick={(): void => addCopyRow()}
                  sx={{ color: 'white.main', backgroundColor: 'primary.light', mr: 1 }}
                >
                  <AddToPhotosIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title={translation.addRow}>
                <IconButton
                  size="small"
                  onClick={(): void => addEmptyRow()}
                  sx={{ color: 'white.main', backgroundColor: 'primary.light' }}
                >
                  <AddIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Box>
          </FadeIn>

          {!desktopView && (
            <FadeIn visible={localPairingSessions[sessionTab]?.type === PairingSessionType.saved}>
              <Box my={1} sx={{ ml: '-16px' }}>
                <Stack display="flex" justifyContent="flex-start" alignItems={'baseline'}>
                  <KNButton onClick={(): void => setMobileScannerOpen(true)} sx={{ color: 'secondary.main' }}>
                    <CameraAltIcon fontSize="inherit" sx={{ mr: 1 }} />
                    {translation.continueCamera}
                  </KNButton>
                  <KNButton onClick={(): void => setDeviceScannerOpen(true)} sx={{ color: 'secondary.main' }}>
                    <VideoChatIcon fontSize="inherit" sx={{ mr: 1 }} />
                    {translation.continueDevice}
                  </KNButton>
                </Stack>
              </Box>
            </FadeIn>
          )}

          <Box display="flex" justifyContent="flex-end" mt={2} mr={pxToRem(20)}>
            {getSubmitButton()}
          </Box>

          {mobileScannerOpen && (
            <Modal open={mobileScannerOpen} sx={{ backgroundColor: 'white !important' }}>
              <>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={(): void => setMobileScannerOpen(false)}
                  sx={{ position: 'absolute', top: 0, right: 5, zIndex: 99999 }}
                >
                  <CloseIcon color="info" />
                </IconButton>
                <CodeScanner
                  setInputResult={(input: PairingSession): void => setValue(`pairings`, input.pairings)}
                  onClose={(): void => {
                    const currentSession = localPairingSessions[sessionTab]
                    const otherSessions = localPairingSessions.filter(
                      (session) => session.sessionTimestamp !== currentSession.sessionTimestamp
                    )
                    const newSession = { ...currentSession, type: PairingSessionType.reopened }
                    setLocalPairingSessions([newSession, ...otherSessions])
                    setEditModeRow(currentSession.pairings.length - 1)
                    setMobileScannerOpen(false)
                  }}
                  existingPairings={
                    editModeRow === null ? localPairingSessions[sessionTab].pairings : getValues('pairings')
                  }
                  existingSessionId={
                    editModeRow === null
                      ? localPairingSessions[sessionTab].sessionTimestamp
                      : getValues('sessionTimestamp')
                  }
                />
              </>
            </Modal>
          )}

          {deviceScannerOpen && (
            <Modal open={deviceScannerOpen} sx={{ backgroundColor: 'white !important' }}>
              <>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={(): void => setDeviceScannerOpen(false)}
                  sx={{ position: 'absolute', top: 0, right: 5, zIndex: 99999 }}
                >
                  <CloseIcon color="info" />
                </IconButton>
                <DeviceScanner
                  setInputResult={(input: PairingSession): void => setValue(`pairings`, input.pairings)}
                  onClose={(): void => {
                    const currentSession = localPairingSessions[sessionTab]
                    const otherSessions = localPairingSessions.filter(
                      (session) => session.sessionTimestamp !== currentSession.sessionTimestamp
                    )
                    const newSession = { ...currentSession, type: PairingSessionType.reopened }
                    setLocalPairingSessions([newSession, ...otherSessions])
                    setEditModeRow(currentSession.pairings.length - 1)
                    setDeviceScannerOpen(false)
                  }}
                  existingPairings={
                    editModeRow === null ? localPairingSessions[sessionTab].pairings : getValues('pairings')
                  }
                  existingSessionId={
                    editModeRow === null
                      ? localPairingSessions[sessionTab].sessionTimestamp
                      : getValues('sessionTimestamp')
                  }
                />
              </>
            </Modal>
          )}
        </Box>
      </FadeIn>
    </Box>
  )
}

export default PairingSessions
