import { ReactElement } from 'react'

// KN components
import GuardedRoute from './GuardedRoute'

// Screens Shared
import Support from 'modules/Support/Support'

// Screens CS
import TripDashboard from '../TripDashboard/TripDashboard'
import TripDetails from '../TripDetails/TripDetails'

// Context
import { TripListProvider } from 'context/trips/TripListContext'
import { StopsViewProvider } from 'context/trips/StopsViewContext'

const CSRoot = (): ReactElement => {
  return (
    <TripListProvider>
      <GuardedRoute exact path="/trips" companyTypeGuard="Carrier">
        <Support moduleName="cs" />
        <TripDashboard />
      </GuardedRoute>
      <GuardedRoute exact path="/trips/:tripCid" companyTypeGuard="Carrier">
        <Support moduleName="cs" />
        <StopsViewProvider>
          <TripDetails />
        </StopsViewProvider>
      </GuardedRoute>
    </TripListProvider>
  )
}

export default CSRoot
