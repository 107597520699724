import { differenceInSeconds } from 'date-fns'

import { fetchPost } from 'global/helpers/fetchWrapper'

// Types
import { Auth, IdToken, Jwt, User } from './Auth.types'

export class AuthImpl implements Auth {
  async sendPasswordResetEmail(email: string): Promise<void> {
    return fetchPost('users/resetpassword', {
      email,
    })
  }

  async verifyPasswordResetCode(oobCode: string): Promise<void> {
    return fetchPost('users/resetpassword/verify', {
      oobCode,
    })
  }

  async confirmPasswordReset(oobCode: string, newPassword: string): Promise<void> {
    return fetchPost('users/resetpassword/confirm', {
      oobCode,
      newPassword,
    })
  }

  async signInWithEmailAndPassword(email: string, password: string): Promise<IdToken> {
    clearTokens()
    const jwt = await fetchToken('idToken', `Basic ${btoa(`${email}:${password}`)}`)
    return decodeJwt(jwt)
  }

  signOut(): void {
    clearTokens()
  }

  async getCurrentUser(): Promise<User | null> {
    const token = await retrieveToken('idToken')
    const idToken: IdToken = decodeJwt(token)
    if (token && idToken) {
      return {
        idToken: token,
        uid: idToken.sub,
        email: idToken.email,
        displayName: idToken.displayName,
        language: idToken.language,
        timezone: idToken.timezone,
      }
    } else {
      return null
    }
  }
}

export const getToken = async (groupId?: string): Promise<string> => {
  if (window.location.hash) {
    return window.location.hash.slice(1)
  } else {
    return (groupId && (await retrieveToken(`group-${groupId}`))) || ''
  }
}

const fetchToken = async (key: string, authorization: string): Promise<string> => {
  console.log(`Fetching new token for '${key}'`)
  const path =
    key === 'idToken'
      ? `/api/${process.env.REACT_APP_API_VERSION}/token`
      : `/api/${process.env.REACT_APP_API_VERSION}/groups/${key.replace('group-', '')}/token`
  const response = await fetch(`${process.env.REACT_APP_API_URL}${path}`, {
    method: 'POST',
    headers: {
      Authorization: authorization,
    },
  })
  if (!response.ok) {
    if (response.status == 404) {
      window.location.replace('/notfound')
    }
    return Promise.reject(response.status)
  }
  const jwt = await response.text()
  storeToken(key, jwt)
  return jwt
}

const retrieveToken = async (key: string): Promise<string | null> => {
  const jwt = getTokenIfValid(key)
  if (jwt) {
    const totalSeconds = tokenTotalValidityInSeconds(jwt)
    const remainingSeconds = tokenRemainingValidityInSeconds(jwt)
    if (remainingSeconds < totalSeconds / 2) {
      // refresh the token if it expires within half of its total validity duration
      return fetchToken(key, `Bearer ${jwt}`)
    } else {
      return jwt
    }
  }
  if (key !== 'idToken') {
    const idToken = await retrieveToken('idToken')
    if (idToken) {
      // attempt to fetch a token for the requested key using the `idToken`
      return fetchToken(key, `Bearer ${idToken}`)
    }
  }
  // all attempts above failed
  return null
}

const getTokenIfValid = (key: string): string | null => {
  const jwt = localStorage.getItem(key)
  if (!jwt) return null
  const remainingSeconds = tokenRemainingValidityInSeconds(jwt)
  if (remainingSeconds > 0) {
    return jwt
  } else {
    console.log(`Token '${key}' expired ${Math.abs(remainingSeconds)} seconds ago`)
    clearToken(key)
    return null
  }
}

const storeToken = (key: string, jwt: string): void => {
  console.log(`Storing token '${key}'`)
  localStorage.setItem(key, jwt)
}

const clearTokens = (): void => {
  console.log('Clearing all tokens')
  clearToken('idToken')
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i)
    if (key?.startsWith('group-')) {
      clearToken(key)
    }
  }
}

const clearToken = (key: string): void => {
  console.log(`Clearing token '${key}'`)
  localStorage.removeItem(key)
}

const decodeJwt = (jwt: string | null): any | null => {
  if (!jwt || jwt.length === 0) {
    return null
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [header, body, signature] = jwt.split('.', 3)
  // body is encoded using Base64 URL safe encoding
  const base64Body = body.replace(/-/g, '+').replace(/_/g, '/')
  const json = atob(base64Body)
  return JSON.parse(json)
}

const tokenTotalValidityInSeconds = (jwt: string): number => {
  const parsed: Jwt = decodeJwt(jwt)
  return differenceInSeconds((parsed.iat || 0) * 1000, (parsed.exp || 0) * 1000)
}

const tokenRemainingValidityInSeconds = (jwt: string): number => {
  const parsed: Jwt = decodeJwt(jwt)
  return differenceInSeconds((parsed.exp || 0) * 1000, new Date())
}
