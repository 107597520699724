import { useCallback, ReactElement, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

// @mui imports
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

// KN imports
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNDetailsPopover from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopover'
import KNDetailsPopoverSection from 'components/KN_Molecules/KNDetailsPopover/KNDetailsPopoverSection'
import usePopoverState from 'components/KN_Molecules/KNDetailsPopover/usePopoverState'
import { KNDataTableOverflowProps } from './types'

const itemWrapper = (item) => (
  <KNTypography
    component="div"
    color="inherit"
    fontSize="0.75rem"
    sx={{
      paddingX: 2,
      paddingY: 0.25,
      lineHeight: '20px',
      '&:hover': {
        backgroundColor: 'primary.dark',
      },
    }}
  >
    {item}
  </KNTypography>
)

const KNDataTableOverflow = <T extends object>({
  row,
  label,
  header,
  items,
  limit = 1,
  rightAligned,
  withoutWrapper,
}: KNDataTableOverflowProps<T>): ReactElement | null => {
  const {
    anchorEl: moreItemsAnchorEl,
    open: moreItemsOpen,
    handleClick: handleMoreItemsClick,
    handleClose: handleMoreItemsClose,
  } = usePopoverState()

  const handlePropagation = useCallback((event) => event.stopPropagation(), [])
  const { t } = useTranslation()

  if (items.length === 0) {
    return <KNTypography color="primary.light">{t('general.none')}</KNTypography>
  }

  return (
    <Box onClick={handlePropagation}>
      <Stack spacing={1} direction="row" alignItems="center">
        <Stack data-test="shipmentId" direction="column">
          {items.slice(0, limit).map((item, index) => (
            <Fragment key={index}>{item}</Fragment>
          ))}
        </Stack>
        {items.length > limit && (
          <Tooltip title={label} arrow enterDelay={500}>
            <Chip
              data-test="shipmentMoreIcon"
              label={`+${items.length - limit}`}
              size="small"
              onClick={handleMoreItemsClick}
            />
          </Tooltip>
        )}
      </Stack>
      {items.length > limit && (
        <KNDetailsPopover
          open={moreItemsOpen}
          onClose={handleMoreItemsClose}
          anchorEl={moreItemsAnchorEl}
          rightAligned={rightAligned}
        >
          <KNDetailsPopoverSection header={header} items={withoutWrapper ? items : items.map(itemWrapper)} />
        </KNDetailsPopover>
      )}
    </Box>
  )
}

export default KNDataTableOverflow
