import { forwardRef } from 'react'

// Types
import KNSliderProps from './types'

// Custom KN styles
import KNInputRoot from './KNInputRoot'

const KNInput = forwardRef<HTMLElement, KNSliderProps>(
  ({ children, ...rest }, ref) => (
    <KNInputRoot {...rest} ref={ref}>
      {children}
    </KNInputRoot>
  )
)

export default KNInput
