// Functional //
import { fetchGet } from 'global/helpers/fetchWrapper'

// Types //
import { EventProps } from './EventTimeline.types'

export const getEventTimelineData = async (
  shipmentId: string,
  token?: string,
  pinCode?: string
): Promise<EventProps[]> =>
  await fetchGet(
    `shipments/${shipmentId}/status/details`,
    token && pinCode
      ? {
          Authorization: `Bearer ${token}:${pinCode}`,
        }
      : {},
    'si'
  )
