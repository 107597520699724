// Functional
import { fetchDelete, fetchGet } from 'global/helpers/fetchWrapper'

// Types
import { ShareShipmentResponse } from './ShipmentDetailsMenu.types'

export const unpairShipment = async (pairingCid: string): Promise<void> => {
  try {
    return await fetchDelete(`pairings/${pairingCid}`, null, {}, 'si').then((_) => window.location.reload())
  } catch (error) {
    return Promise.reject(error)
  }
}

export const shareShipment = async (shipmentId: string): Promise<ShareShipmentResponse> => {
  try {
    return await fetchGet(`shipments/${shipmentId}/token`, {}, 'si')
  } catch (error) {
    return Promise.reject(error)
  }
}

export const sharePairing = async (pairingCid: string): Promise<ShareShipmentResponse> => {
  try {
    return await fetchGet(`pairings/${pairingCid}/token`, {}, 'si')
  } catch (error) {
    return Promise.reject(error)
  }
}
