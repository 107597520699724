// Icons
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined'
import RunningWithErrorsOutlinedIcon from '@mui/icons-material/RunningWithErrorsOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined'

// Functional
import { analyticsEvent } from 'global/helpers/analytics'

// Data
import { liveCountersTranslations } from './LiveCounters.data'

// Types
import { LiveCountersProps, LiveCounterRequestProps, CounterType } from './LiveCounters.types'

export const getCountersData = (
  action,
  allCounters: LiveCounterRequestProps[],
  activeCounter?: string
): LiveCountersProps => {
  const { translation } = liveCountersTranslations()
  const basicCounters = [
    {
      key: 'active',
      name: translation.counterActive,
      amount: allCounters.filter((counter) => counter.type === CounterType.active)[0].count,
      icon: <LocalShippingOutlinedIcon />,
      active: activeCounter === 'ACTIVE',
      description: translation.descriptionActive,
      onClick: (): void => {
        if (activeCounter === 'ACTIVE') {
          action('')
        } else {
          action('ACTIVE')
          analyticsEvent('polestar_cv_live_counter_active_clicked')
        }
      },
    },
    {
      key: 'awaitingForPickup',
      name: translation.counterAwaitingForPickup,
      amount: allCounters.filter((counter) => counter.type === CounterType.awaitingForPickup)[0].count,
      icon: <HourglassEmptyOutlinedIcon />,
      active: activeCounter === 'AWAITING_FOR_PICKUP',
      description: translation.descriptionAwaitingForPickup,
      onClick: (): void => {
        if (activeCounter === 'AWAITING_FOR_PICKUP') {
          action('')
        } else {
          action('AWAITING_FOR_PICKUP')
          analyticsEvent('polestar_cv_live_counter_awaiting_for_pickup_clicked')
        }
      },
    },
    {
      key: 'delayedDeliveries',
      name: translation.counterDelayedDeliveries,
      amount: allCounters.filter((counter) => counter.type === CounterType.delayedDelivery)[0].count,
      icon: <RunningWithErrorsOutlinedIcon />,
      active: activeCounter === 'DELAYED_DELIVERY',
      description: translation.descriptionDelayedDeliveries,
      onClick: (): void => {
        if (activeCounter === 'DELAYED_DELIVERY') {
          action('')
        } else {
          action('DELAYED_DELIVERY')
          analyticsEvent('polestar_cv_live_counter_delayed_delivery_clicked')
        }
      },
    },
  ]
  const advancedCounters = [
    {
      key: 'trackingActive ',
      name: translation.counterTrackingActive,
      amount: allCounters.filter((counter) => counter.type === CounterType.trackingActive)[0].count,
      icon: <FmdGoodOutlinedIcon />,
      active: activeCounter === 'TRACKING_ACTIVE',
      description: translation.descriptionTrackingActive,
      onClick: (): void => {
        if (activeCounter === 'TRACKING_ACTIVE') {
          action('')
        } else {
          action('TRACKING_ACTIVE')
          analyticsEvent('polestar_cv_live_counter_tracking_active_clicked')
        }
      },
    },
  ]
  const maxCounters = [
    {
      key: 'deviations ',
      name: translation.counterDeviations,
      amount: allCounters.filter((counter) => counter.type === CounterType.trackingDeviation)[0].count,
      icon: <ReportProblemOutlinedIcon />,
      active: activeCounter === 'TRACKING_DEVIATION',
      description: translation.descriptionDeviations,
      onClick: (): void => {
        if (activeCounter === 'TRACKING_DEVIATION') {
          action('')
        } else {
          action('TRACKING_DEVIATION')
          analyticsEvent('polestar_cv_live_counter_tracking_deviation_clicked')
        }
      },
    },
  ]

  return { basicCounters, advancedCounters, maxCounters }
}
