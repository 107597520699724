export const getEnv = (): string => process.env.REACT_APP_API_ENV

export const isDev = (): boolean => getEnv() === 'dev'
export const isUat = (): boolean => getEnv() === 'uat'
export const isDemo = (): boolean => getEnv() === 'demo'
export const isProd = (): boolean => getEnv() === 'prod'

export const isSentryEnabled = (): boolean => {
  if (!process.env.REACT_APP_SENTRY_DSN) {
    return false
  }
  if (!process.env.REACT_APP_API_ENV) {
    return false
  }
  return true
}
