import { ReactElement } from 'react'

// @mui imports
import Box from '@mui/material/Box'
import { Theme } from '@mui/material/styles/createTheme'

const ShipmentBackground = (): ReactElement => (
  <>
    <Box
      sx={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: ({ palette: { white } }: Theme): string => white.main,
        backgroundImage: `url(${process.env.PUBLIC_URL}/img/shipment-placeholder.png)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        pointerEvents: 'none',
        zIndex: -2,
      }}
    />
    <Box
      sx={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: ({ palette: { white } }: Theme): string => white.focus,
        opacity: 0.8,
        zIndex: -1,
      }}
    />
  </>
)

export default ShipmentBackground
