import { useState, useEffect, useContext, useCallback, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'use-debounce'

// @mui imports
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

// KN Components
import { analyticsEvent } from 'global/helpers/analytics'
import { TripListContext } from 'context/trips/TripListContext'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNAdvancedDropdown from 'components/KN_Molecules/KNAdvancedDropdown/KNAdvancedDropdown'
import KNDatePicker from 'components/KN_Molecules/KNDatePicker/KNDatePicker'
import { BranchesData } from './TripDashboard.types'

interface TripFiltersProps {
  branches?: BranchesData
}

const TripFilters = ({ branches }: TripFiltersProps): ReactElement => {
  const { t } = useTranslation()
  const [tripListState, tripListDispatch] = useContext(TripListContext)
  const [payload, setPayload] = useState<object | null>(null)
  const [debouncedPayload] = useDebounce(payload, 100)

  useEffect(() => {
    if (debouncedPayload) {
      tripListDispatch({
        type: 'setFilters',
        payload: debouncedPayload,
      })
    }
    setPayload(null)
  }, [debouncedPayload])

  const handleResetClick = useCallback((): void => {
    tripListDispatch({ type: 'resetFilters' })
    analyticsEvent('polestar_cs_reset_trip_filters')
  }, [])

  return (
    <Box sx={{ position: 'relative' }}>
      <Grid data-test="trips-filter" data-guide="trip-filters" container spacing={2} mb={4}>
        <Grid item xs={12} sm={3}>
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={tripListState.filters.keywords ?? []}
            onChange={(event, newValue): void => {
              setPayload({
                keywords: [...newValue] as string[],
              })
              if (newValue.length > 0) {
                analyticsEvent('polestar_cs_trip_filters', ['keywords', newValue.join(', ')])
              }
            }}
            renderTags={(tagValue, getTagProps): ReactElement[] =>
              tagValue.map((option, index) => (
                // eslint-disable-next-line react/jsx-key
                <Chip {...getTagProps({ index })} size="small" label={option} />
              ))
            }
            renderInput={(params): ReactElement => (
              <TextField {...params} label={t('screens.cs.trip_dashboard.filters.search')} data-test="search-input" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3} data-test="status-dropdown">
          <KNAdvancedDropdown
            label={t('screens.cs.trip_dashboard.filters.status')}
            options={[
              { value: 'NEW', label: t('screens.cs.trip_dashboard.statuses.new') },
              { value: 'TRIP_ASSIGNED', label: t('screens.cs.trip_dashboard.statuses.assigned') },
              { value: 'IN_PROGRESS', label: t('screens.cs.trip_dashboard.statuses.in_progress') },
              { value: 'DELIVERED', label: t('screens.cs.trip_dashboard.statuses.delivered') },
              { value: 'COMPLETED', label: t('screens.cs.trip_dashboard.statuses.completed') },
            ]}
            value={tripListState.filters.status ?? []}
            onChange={(event): void => {
              const newValue = event.target.value as string[]
              setPayload({
                status: newValue,
              })
            }}
            multiple
          />
        </Grid>
        <Grid data-test="date-from" item xs={12} sm={2}>
          <KNDatePicker
            selected={tripListState.filters.fromDate ?? null}
            startDate={tripListState.filters.fromDate ?? null}
            endDate={tripListState.filters.toDate ?? null}
            onChange={(date): void => {
              setPayload({
                fromDate: date,
              })
            }}
            inputProps={{ size: 'normal' }}
            label={t('screens.cs.trip_dashboard.filters.date_from')}
            id={'dateRangePickerStart'}
            value={tripListState.filters.fromDate}
            selectsStart={true}
          />
        </Grid>
        <Grid data-test="date-to" item xs={12} sm={2}>
          <KNDatePicker
            selected={tripListState.filters.toDate ?? null}
            startDate={tripListState.filters.fromDate ?? null}
            endDate={tripListState.filters.toDate ?? null}
            onChange={(date): void => {
              setPayload({
                toDate: date,
              })
            }}
            inputProps={{ size: 'normal' }}
            label={t('screens.cs.trip_dashboard.filters.date_to')}
            id={'dateRangePickerEnd'}
            value={tripListState.filters.fromDate}
            selectsEnd={true}
            minDate={tripListState.filters.fromDate ?? null}
          />
        </Grid>
        <Grid item xs={12} sm={2} data-test="branch-dropdown">
          <KNAdvancedDropdown
            label={t('screens.cs.trip_dashboard.filters.branches')}
            options={
              branches
                ? Object.entries(branches).map((branch) => {
                    return { value: branch[0], label: branch[1] }
                  })
                : []
            }
            value={tripListState.filters.branches ?? []}
            onChange={(event): void => {
              const newValue = event.target.value as string[]
              setPayload({
                branches: newValue,
              })
            }}
            multiple
          />
        </Grid>
      </Grid>
      {tripListState.filtersDirty && (
        <KNButton
          size="small"
          variant="text"
          color="secondary"
          data-test="reset-trip-filters-button"
          onClick={handleResetClick}
          sx={{
            position: 'absolute',
            left: 0,
            bottom: '-28px',
            paddingX: 0,
            paddingY: '4px',
            minHeight: 'auto',
          }}
        >
          {t('general.reset_filters')}
        </KNButton>
      )}
    </Box>
  )
}

export default TripFilters
