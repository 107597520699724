import i18n from 'i18n'

export const advancedVisibilityFilterTranslation = (): Record<any, any> => {
  const advancedVisibilityFilters = 'modules.cv.advanced_visibility_filters'
  return {
    translation: {
      pairedDevices: i18n.t(`${advancedVisibilityFilters}.pairedDevices`),
      filterDateFrom: i18n.t(`${advancedVisibilityFilters}.filterDateFrom`),
      filterDateTo: i18n.t(`${advancedVisibilityFilters}.filterDateTo`),
      predefinedFilters: i18n.t(`${advancedVisibilityFilters}.predefinedFilters`),
      filter6hours: i18n.t(`${advancedVisibilityFilters}.filter6hours`),
      filter12hours: i18n.t(`${advancedVisibilityFilters}.filter12hours`),
      temperaturePredictionToggle: i18n.t(`${advancedVisibilityFilters}.temperaturePredictionToggle`),
    },
  }
}
