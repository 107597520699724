import { useState, useEffect, useContext, useCallback, ReactElement } from 'react'

// @mui imports
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Collapse from '@mui/material/Collapse'

// KN imports
import { sleep } from 'global/helpers/sleep'
import { StopsViewContext } from 'context/trips/StopsViewContext'
import KNMap from 'components/KN_Molecules/KNMap/KNMap'
import { MapMarker } from 'components/KN_Molecules/KNMap/types'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'
import GroupedStopsCard from './GroupedStopsCard'
import { getTripVehiclePositions } from './TripDetails.service'
import {
  getGroupedStops,
  getStopsGroupColor,
  positionDataTransformer,
  groupGeoPointsBySpeed,
} from './TripDetails.helpers'
import { LegData, StopData, StopsGroup, GeoPoint, GeoPointsGroup } from './TripDetails.types'

export interface MapViewProps {
  trip: TripData
  legs: LegData[]
  weblinkToken?: string
  onChange: (updatedStops: StopData[]) => void
}

interface MapMarkerSequencePayload {
  sequence: number
}

const getBaseMarkers = (groups: StopsGroup[]): MapMarker<MapMarkerSequencePayload>[] =>
  groups.reduce((markers: MapMarker<MapMarkerSequencePayload>[], group) => {
    if (group.stopLegPairs[0].stop.geoPoint) {
      markers.push({
        coords: {
          lat: group.stopLegPairs[0].stop.geoPoint.latitude,
          lng: group.stopLegPairs[0].stop.geoPoint.longitude,
        },
        type: 'GENERIC',
        label: group.sequence.toString(),
        color: getStopsGroupColor(group.state),
        payload: {
          sequence: group.sequence,
        },
      })
    }
    return markers
  }, [])

const MapView = ({ trip, legs, weblinkToken, onChange }: MapViewProps): ReactElement => {
  const [stopsViewState, stopsViewDispatch] = useContext(StopsViewContext)
  const [groupedStopsData, setGroupedStopsData] = useState<StopsGroup[]>([])
  const [markers, setMarkers] = useState<MapMarker<MapMarkerSequencePayload>[]>([])
  const [loading, setLoading] = useState(true)
  const [geoPoints, setGeoPoints] = useState<GeoPoint[]>([])
  const [groupedGeoPoints, setGroupedGeoPoints] = useState<GeoPointsGroup[]>([])

  const fetchData = async (): Promise<void> => {
    setLoading(true)
    const groupedStops = getGroupedStops(legs)
    setGroupedStopsData(groupedStops)

    try {
      const vehiclePositions = positionDataTransformer(await getTripVehiclePositions(trip.entityId, weblinkToken))
      const groupedVehiclePositions = groupGeoPointsBySpeed(vehiclePositions)
      if (vehiclePositions.length > 0) {
        const lastVehiclePosition = vehiclePositions[vehiclePositions.length - 1]
        setMarkers([
          {
            coords: {
              lat: lastVehiclePosition.latitude,
              lng: lastVehiclePosition.longitude,
            },
            type: 'TRUCK',
          },
          ...getBaseMarkers(groupedStops),
        ])
        setGeoPoints(vehiclePositions)
        setGroupedGeoPoints(groupedVehiclePositions)
      } else {
        setMarkers(getBaseMarkers(groupedStops))
      }
    } catch (error) {
      //
    }

    // NOTE: without this sleep() there are issues with the map
    // where markers don't show up for some reason
    // TODO: something to investigate further
    await sleep(100)
    setLoading(false)
  }

  const highlightActiveMarker = async (): Promise<void> => {
    // NOTE: wait till initial drop animation ends
    await sleep(700)
    markers.forEach((marker) => {
      marker.instance?.setAnimation(
        stopsViewState.activeStopsGroupSequence === marker.payload?.sequence ? google.maps.Animation.BOUNCE : null
      )
    })
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData()
  }, [legs])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    highlightActiveMarker()
  }, [markers, stopsViewState.activeStopsGroupSequence])

  const handleMarkerClick = useCallback(
    (marker, map) => {
      markers.forEach((marker) => {
        marker.instance?.setAnimation(null)
      })
      if (marker.payload) {
        if (stopsViewState.activeStopsGroupSequence === marker.payload.sequence) {
          stopsViewDispatch({
            type: 'setActiveStopsGroupSequence',
            payload: -1,
          })
          const bounds = new google.maps.LatLngBounds()
          for (const marker of markers) {
            bounds.extend(marker.coords)
          }
          map.fitBounds(bounds, 16)
        } else {
          stopsViewDispatch({
            type: 'setActiveStopsGroupSequence',
            payload: marker.payload.sequence,
          })
          marker.instance?.setAnimation(google.maps.Animation.BOUNCE)
          map.panTo(marker.coords)
        }
      }
    },
    [markers, stopsViewState.activeStopsGroupSequence]
  )

  return (
    <Stack spacing={0} sx={{ flexGrow: 1 }}>
      <Paper
        elevation={8}
        sx={{
          flexGrow: 1,
          overflow: 'hidden',
          backgroundColor: '#e9ecef',
          '& > div > div': {
            backgroundColor: 'transparent !important',
          },
        }}
      >
        {loading ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <CircularProgress size={64} color="primary" />
          </Stack>
        ) : (
          <KNMap
            markers={markers}
            geoPoints={geoPoints}
            groupedGeoPoints={groupedGeoPoints}
            withTimestamps
            onMarkerClick={handleMarkerClick}
          />
        )}
      </Paper>
      {groupedStopsData
        .filter((group) => stopsViewState.activeStopsGroupSequence === group.sequence)
        .map((group) => (
          <GroupedStopsCard
            key={group.sequence}
            trip={trip}
            group={group}
            weblinkToken={weblinkToken}
            onChange={onChange}
            minimal
          />
        ))}
    </Stack>
  )
}

export default MapView
