import { ReactElement } from 'react'

export interface MonitoringRule {
  type: string
  cid: string
  name: string
  userId: string
  groupCid: string
  monitoringRuleTrigger: monitoringRuleTrigger
  shipmentConditions: shipmentConditions
  channels: string[]
  createdAt: string
  settings: Map<SettingType, string>
}

export enum SettingType {
  STATUS = 'STATUS',
  PICK_UP = 'PICK_UP',
  DELIVERY = 'DELIVERY',
}

export interface monitoringRuleTrigger {
  type: string
  status: string
}

export interface MonitoringRuleEntityAddress {
  name?: string
  country?: string
  postalCode?: string
  city?: string
  address?: string
}

export interface shipmentConditions {
  pickUp?: MonitoringRuleEntityAddress
  delivery?: MonitoringRuleEntityAddress
  shipper?: MonitoringRuleEntityAddress
  consignee?: MonitoringRuleEntityAddress
}

export interface MonitoringRulesTableProps {
  userTimezone: string
  singleShipmentMode?: {
    sendingApplication: string
    trackingNumber: string
    cid: string
  }
}

export interface MonitoringRulesTableColumn {
  name: string
  label: string
  getValue?: (row: MonitoringRuleRow) => JSX.Element
}

export interface MonitoringRulesTableAction {
  name: string
  label: string
  icon: ReactElement
  onClick: () => void
}

export interface MonitoringRuleRow {
  cid: string
  name: string
  type: string
  settings: string[]
  created: string
}

export interface DeleteMonitoringRuleDialogPayload {
  monitoringRule: MonitoringRuleRow
}

export interface DeleteUserDialogProps {
  payload: DeleteMonitoringRuleDialogPayload
  open: boolean
  onAction: () => void
  onClose: () => void
}
