import { ReactElement } from 'react'

// @mui imports //
import Box from '@mui/material/Box'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import { Theme } from '@mui/material'

// KN components //
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import KNChip from 'components/KN_Components/Base/KNChip/KNChip'

// Functional //
import { getStatuses } from 'global/helpers/statuses'

// Data //
import { snippetTranslations } from './Snippets.data'

// Types //
import { TextSnippetProps } from './Snippets.types'

const TextSnippet: React.FC<TextSnippetProps> = ({ variant, status, date }) => {
  // Data
  const { translation } = snippetTranslations()

  const statusList = getStatuses()

  const getNotificationUI = (): ReactElement => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        borderRadius: ({ borders: { borderRadius } }: Theme): string | number => borderRadius.lg,
        backgroundColor: ({ palette: { secondary } }: Theme): string => secondary.main,
      }}
    >
      <KNTypography variant="textMD_SB" color="white.main">
        {statusList.find((statusElement) => statusElement.STATUS_CODE === status)?.STATUS_NAME}
      </KNTypography>
      <Box display="flex" alignItems="center">
        <AccessTimeOutlinedIcon sx={{ width: '12px', height: '12px', color: 'white.main', mr: 0.5 }} />
        <KNTypography variant="textSM" color="white.main">
          {date}
        </KNTypography>
      </Box>
    </Box>
  )

  const getGeneralUI = (): ReactElement => (
    <Box>
      <KNTypography variant="textLG">{translation.lastStatusUpdate}</KNTypography>{' '}
      <KNTypography variant="textLG" color="primary.light">
        {date}
      </KNTypography>
      <Box>
        <KNChip
          label={statusList.find((stat) => stat.STATUS_CODE === status)?.STATUS_NAME}
          variant="gradient"
          color="secondary"
          size="small"
        />
      </Box>
      <KNTypography variant="textSM_SB" color="black">
        {date}
      </KNTypography>
    </Box>
  )

  return status && date ? <Box>{variant === 'notification' ? getNotificationUI() : getGeneralUI()}</Box> : null
}

export default TextSnippet
