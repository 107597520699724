import { fetchGet, fetchPost, fetchDelete } from 'global/helpers/fetchWrapper'
import { getSelectedCompany } from 'context/authentication/User.helpers'
import { VehicleData } from './VehicleManager.types'
import { CreateOrEditVehicleFormValues } from './CreateOrEditVehicleDialog'

export const getVehicles = async (): Promise<VehicleData[]> => {
  const company = getSelectedCompany()
  if (!company) {
    return Promise.reject(new Error('Invalid company'))
  }
  const vehicles = await fetchGet(`carriers/${company.customerIdentifiers[0]}/vehicles`)
  return vehicles.map(processVehicleFields).sort(sortVehicles)
}

export const createVehicle = async (data: CreateOrEditVehicleFormValues): Promise<void> => {
  const company = getSelectedCompany()
  if (!company) {
    return Promise.reject(new Error('Invalid company'))
  }
  return await fetchPost(`carriers/${company.customerIdentifiers[0]}/vehicles`, data)
}

export const editVehicle = async (vehicleCid: string, data: CreateOrEditVehicleFormValues): Promise<void> => {
  const company = getSelectedCompany()
  if (!company) {
    return Promise.reject(new Error('Invalid company'))
  }
  return await fetchPost(`carriers/${company.customerIdentifiers[0]}/vehicles/${vehicleCid}`, data)
}

export const deleteVehicle = async (vehicleCid: string): Promise<void> => {
  const company = getSelectedCompany()
  if (!company) {
    return Promise.reject(new Error('Invalid company'))
  }
  return await fetchDelete(`carriers/${company.customerIdentifiers[0]}/vehicles/${vehicleCid}`)
}

const processVehicleFields = (vehicle: VehicleData): VehicleData => {
  if (!vehicle.displayLicensePlate) {
    vehicle.displayLicensePlate = vehicle.licensePlate
  }
  return vehicle
}

export const sortVehicles = (a, b): number => {
  return a.licensePlate === b.licensePlate ? 0 : a.licensePlate < b.licensePlate ? -1 : 1
}
