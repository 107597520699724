import { ReactElement, useEffect, useState } from 'react'
import FadeIn from 'react-fade-in'

// @mui imports //
import Box from '@mui/material/Box'
import { Theme } from '@mui/material/styles'

// KN imports //
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Types //
import { OptionProps } from './MegaForm.types'

const OptionStep = ({ option, setStepNumber, optionsChosen, setCurrentOption }: OptionProps): ReactElement => {
  const [disabled, setDisabled] = useState<boolean>(false)

  useEffect(() => {
    if (optionsChosen.includes(option.id)) setDisabled(true)
    optionsChosen.forEach((opt) => {
      if (option.disabledWith?.includes(opt)) setDisabled(true)
    })
  }, [optionsChosen])

  return (
    <FadeIn>
      <Box
        sx={{
          mt: 1,
          p: 2,
          borderRadius: ({ borders: { borderRadius } }: Theme): string | number => borderRadius.lg,
          backgroundColor: ({ palette: { white } }: Theme): string => white.main,
          boxShadow: ({ boxShadows }: Theme): string => boxShadows.md,
          display: 'flex',
          alignItems: 'center',
          cursor: disabled ? 'auto' : 'pointer',
          color: ({ palette: { primary, grey } }: Theme): string => (disabled ? grey[300] : primary.main),
        }}
        onClick={(): void => {
          if (disabled) return
          option.onClick?.()
          setCurrentOption(option)
          if (option.goToStep) {
            setStepNumber(option.goToStep)
          }
        }}
      >
        <Box sx={{ fontSize: '40px', display: 'flex', alignItems: 'center' }}>{option.icon}</Box>
        <KNTypography variant="text_LG" color={disabled ? 'grey.300' : 'black.main'} ml={1}>
          {option.displayName}
        </KNTypography>
      </Box>
    </FadeIn>
  )
}

export default OptionStep
