import { Driver, PopoverDOM, driver } from 'driver.js'
import 'driver.js/dist/driver.css'

// Functional
import { postUserPreferences } from 'screens/UserManager/UserManager.service'

// Data
import { VisibilityDashboardTranslations } from './VisibilityDashboard.data'

// Types
import { WelcomeGuideStatus } from 'screens/UserManager/UserManager.types'

export const getWelcomeGuide = (updateUserPreferences: boolean): Driver => {
  // Welcome guide translations
  const { guideTranslations } = VisibilityDashboardTranslations()

  const welcomeGuide = driver({
    showProgress: true,
    showButtons: ['next'],
    nextBtnText: guideTranslations.buttonNext,
    doneBtnText: guideTranslations.buttonDone,
    onPopoverRender: (popover: PopoverDOM) => {
      if (welcomeGuide.isLastStep()) return
      const skipButton = document.createElement('button')
      skipButton.innerText = guideTranslations.buttonSkip
      popover.footerButtons.appendChild(skipButton)

      skipButton.addEventListener('click', async () => {
        welcomeGuide.destroy()
        updateUserPreferences && (await postUserPreferences({ welcomeGuide: WelcomeGuideStatus.SKIPPED }))
      })
    },
    steps: [
      {
        popover: {
          title: guideTranslations.welcomeTitle,
          description: guideTranslations.welcomeMessage,
        },
      },
      {
        element: '#shipment-insight-container',
        popover: {
          title: guideTranslations.listTitle,
          description: guideTranslations.listMessage,
          side: 'top',
          align: 'center',
        },
      },
      {
        element: '#live-counters-container',
        popover: {
          title: guideTranslations.countersTitle,
          description: guideTranslations.countersMessage,
          side: 'bottom',
          align: 'start',
        },
      },
      {
        element: '#shipment-insight-filters',
        popover: {
          title: guideTranslations.filtersTitle,
          description: guideTranslations.filtersMessage,
          side: 'top',
          align: 'start',
        },
      },
    ],
    onDestroyStarted: async () => {
      if (welcomeGuide.isLastStep() && updateUserPreferences) {
        try {
          await postUserPreferences({ welcomeGuide: WelcomeGuideStatus.DONE })
        } catch {
          //
        }
      }
      welcomeGuide.destroy()
    },
  })

  return welcomeGuide
}
