import i18n from 'i18n'

export const snippetTranslations = (): Record<any, any> => {
  const snippets = 'modules.cv.snippets'
  return {
    translation: {
      tempBreached: i18n.t(`${snippets}.temp_breached`),
      geofenceEnter: i18n.t(`${snippets}.geofence_enter`),
      geofenceExit: i18n.t(`${snippets}.geofence_exit`),
      statusChanged: i18n.t(`${snippets}.status_changed`),
      pickedUp: i18n.t(`${snippets}.picked_up`),
      delivered: i18n.t(`${snippets}.delivered`),
      lastStatusUpdate: i18n.t(`${snippets}.last_status_update`),
    },
  }
}
