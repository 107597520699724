import { useState, useEffect, ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// @mui imports
import Button from '@mui/material/Button'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN imports
import SupportRequestDialog from './SupportRequestDialog'
import { getWeblinkToken } from 'screens/Weblink/Weblink.service'

export interface SupportProps {
  moduleName: 'cv' | 'cs'
}

const Support = (props: SupportProps): ReactElement => {
  const { moduleName } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { jwt } = useParams<{ jwt: string }>()
  const [weblinkToken, setWeblinkToken] = useState<string>()
  const [supportRequestDialogOpen, setSupportRequestDialogOpen] = useState(false)

  const fetchData = async (): Promise<void> => {
    if (jwt) {
      try {
        const { token } = await getWeblinkToken(jwt)
        setWeblinkToken(token)
      } catch (error) {
        //
      }
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData()
  }, [])

  return (
    <>
      <Button
        data-test="feedback-support-button"
        data-guide="feedback"
        variant="contained"
        size="small"
        color="primary"
        startIcon={<QuestionAnswerIcon />}
        onClick={(): void => setSupportRequestDialogOpen(true)}
        sx={{
          position: 'fixed',
          zIndex: 1200,
          ...(isMobile
            ? {
                width: '40px',
                minWidth: 'auto',
                paddingX: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                bottom: '2rem',
                right: 0,
                '& .MuiButton-startIcon': {
                  margin: 0,
                },
              }
            : {
                width: '16rem',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                bottom: '18rem',
                right: 0,
                transform: 'rotate(-90deg)',
                transformOrigin: '100% 100%',
              }),
        }}
      >
        {!isMobile && t('shared.support.feedback_support')}
      </Button>

      <SupportRequestDialog
        moduleName={moduleName}
        payload={{
          weblinkToken,
        }}
        open={supportRequestDialogOpen}
        onAction={(): void => {
          setSupportRequestDialogOpen(false)
        }}
        onClose={(): void => {
          setSupportRequestDialogOpen(false)
        }}
      />
    </>
  )
}

export default Support
