import theme from 'assets/theme'
import React from 'react'
import IconProps, { IconSize } from './Icon.type'
import Icons from './Icons.svg'

const Icon: React.FC<IconProps> = ({ name, color, colorType, size }) => (
  <svg
    className={`icon icon-${name}`}
    fill="none"
    stroke={color || theme.palette.primary.main}
    width={size ?? IconSize.small}
    height={size ?? IconSize.small}
    strokeWidth="3px"
  >
    <use xlinkHref={`${Icons}#icon-${name}`} />
  </svg>
)

export default Icon
