// @mui imports
import { Theme } from '@mui/material/styles/createTheme'

export const stylingInactive = {
  cursor: 'pointer',
  backgroundColor: ({ palette: { primary } }: Theme): string => primary.light,
  color: ({ palette: { white } }: Theme): string => white.main,
  padding: ({ functions: { pxToRem } }: Theme): string => `${pxToRem(8) as number} ${pxToRem(12) as number}`,
  borderRadius: ({ borders: { borderRadius } }: Theme): string => `${borderRadius.md} ${borderRadius.md} 0 0`,
  border: ({ palette: { primary } }: Theme): string => `1px solid ${primary.light}`,
  opacity: 0.5,
  transition: 'opacity 0.75s ease',
  '&:hover': {
    opacity: 0.8,
  },
}
export const stylingActive = {
  ...stylingInactive,
  backgroundColor: ({ palette: { white } }: Theme): string => white.main,
  color: ({ palette: { primary } }: Theme): string => primary.light,
  border: ({ palette: { primary } }: Theme): string => `0.5px solid ${primary.light}`,
  borderBottom: ({ palette: { white } }: Theme): string => `1px solid ${white.main as string}`,
  opacity: 1,
}
