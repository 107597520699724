import i18n from 'i18n'

export const sharingRulesTranslations = (): Record<any, any> => {
  const sharingRules = 'modules.cv.sharing_rules'

  return {
    translation: {
      screenName: i18n.t(`${sharingRules}.screen_name`),
      addNew: i18n.t(`${sharingRules}.add_new`),
      name: i18n.t(`${sharingRules}.name`),
      users: i18n.t(`${sharingRules}.users`),
      conditions: i18n.t(`${sharingRules}.conditions`),
      noGroupsMessage: i18n.t(`${sharingRules}.no_rules_message`),
      cancelDelete: i18n.t(`${sharingRules}.delete_cancel`),
      delete: i18n.t(`${sharingRules}.delete`),
      deleteMessage: i18n.t(`${sharingRules}.delete_message`),
    },
  }
}
