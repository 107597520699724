import { ReactElement } from 'react'
import { t } from 'i18next'

// @mui imports
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import Tooltip from '@mui/material/Tooltip'
import useMediaQuery from '@mui/material/useMediaQuery'
import HourglassTopIcon from '@mui/icons-material/HourglassTop'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import LeakRemoveIcon from '@mui/icons-material/LeakRemove'
import { Theme } from '@mui/material/styles/createTheme'

// KN imports
import theme from 'assets/theme'
import KNSwitch from 'components/KN_Components/Base/KNSwitch/KNSwitch'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Functional
import { getHeader } from './PairDevices.helpers'

// Types
import { PairingSessionRowProps } from 'screens/PairDevices/PairDevices.types'

// Data
import { pairDevicesTranslations } from 'screens/PairDevices/PairDevices.data'

const PairingSessionRow = ({ pairing, type }: PairingSessionRowProps): ReactElement => {
  const desktopView = useMediaQuery(theme.breakpoints.up('md'))
  const temperatureThresholdActive = !!pairing.temperatureThreshold?.min || !!pairing.temperatureThreshold?.max
  const { translation } = pairDevicesTranslations()

  return (
    <Box mt={1} sx={{ width: '100%' }}>
      <Box>
        <Grid container spacing={2}>
          {desktopView && (
            <Grid item md={1}>
              {!desktopView && getHeader(translation.status)}
              <Box sx={{ display: 'flex' }}>
                {!type && pairing.cid && !pairing.completionDate ? (
                  <>
                    <CheckCircleIcon color="success" sx={{ mr: 0.5 }} />
                    <KNTypography variant="textMD">{translation.paired}</KNTypography>
                  </>
                ) : !type && pairing.completionDate ? (
                  <>
                    <LeakRemoveIcon color="success" sx={{ mr: 0.5 }} />
                    <KNTypography variant="textMD">{translation.completed}</KNTypography>
                  </>
                ) : (
                  <>
                    <HourglassTopIcon sx={{ color: 'primary.light', mr: 0.5 }} />
                    <KNTypography variant="textMD">{translation.saved}</KNTypography>
                  </>
                )}
              </Box>
            </Grid>
          )}

          <Grid item xs={6} md={2}>
            {!desktopView && getHeader(translation.shipmentId)}
            <KNTypography variant="textLG_SB">{pairing.reference}</KNTypography>
          </Grid>

          {!desktopView && (
            <Grid item md={1}>
              {!desktopView && getHeader(translation.status)}
              {!type && pairing.cid ? (
                <CheckCircleIcon color="success" />
              ) : (
                <HourglassTopIcon sx={{ color: 'primary.light' }} />
              )}
            </Grid>
          )}

          <Grid item xs={8} md={3}>
            {!desktopView && getHeader(translation.description)}
            <KNTypography variant="textLG">{pairing.description}</KNTypography>
          </Grid>

          <Grid item xs={8} md={3}>
            {!desktopView && getHeader(translation.deviceIds)}
            <Box display="flex" flexWrap="wrap">
              {pairing.deviceIds.map((deviceId, i) => (
                <Chip key={i} size="small" color="primary" label={deviceId} sx={{ margin: 0.5 }} />
              ))}
            </Box>
          </Grid>

          <Grid item xs={6} md={2}>
            {!desktopView && getHeader(translation.temperatureThreshold)}
            <Box display="flex" alignItems="center" sx={{ mt: '-5px' }}>
              <KNSwitch disabled checked={temperatureThresholdActive} />
              {temperatureThresholdActive && (
                <Tooltip
                  title={t('modules.cv.pair_devices.pairing_sessions.tooltip_text', {
                    min: pairing.temperatureThreshold?.min ?? '-',
                    max: pairing.temperatureThreshold?.max ?? '-',
                  })}
                  color="primary"
                >
                  <Icon fontSize="small">info_circle</Icon>
                </Tooltip>
              )}
            </Box>
          </Grid>
          <Grid item xs={6} md={1}>
            {!desktopView && getHeader(translation.autoUnpair)}
            <Box sx={{ mt: '-5px' }}>
              <KNSwitch disabled checked={pairing.unpairingRule} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider
        sx={{
          backgroundColor: ({ palette: { grey } }: Theme): string => `${grey[200]} !important`,
          my: 2,
        }}
      />
    </Box>
  )
}

export default PairingSessionRow
