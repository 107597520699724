import { DriverData } from './DriverManager.types'
import { CreateOrEditDriverFormValues } from './CreateOrEditDriverDialog'

export const getDataForCreateDriver = (data: CreateOrEditDriverFormValues): DriverData => {
  return {
    cid: 'pending',
    carrierCid: 'pending',
    name: data.name,
    contactNumber: data.contactNumber,
    email: data.email,
  }
}

export const getDataForEditDriver = (driver: DriverData, data: CreateOrEditDriverFormValues): DriverData => {
  return {
    ...driver,
    name: data.name,
    contactNumber: data.contactNumber,
    email: data.email,
  }
}

export const getDataForDeleteDriver = (driver: DriverData): DriverData => {
  return driver
}
