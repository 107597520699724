import { ReactElement } from 'react'

// @mui material imports //
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'
import { Theme } from '@mui/material/styles/createTheme'

// KN Components //
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Functional //
import { getColorByIndex } from 'global/helpers/styling'

const FilterItemTemplate = (
  device: string,
  index: number,
  changeActive: any,
  active: boolean,
  trackingLevel?: string
): ReactElement => {
  return (
    <Box data-test={`trackingDetailsInfoItem-${index}`} key={index}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          color: ({ palette: { grey } }: Theme): string => (active ? getColorByIndex(index) : grey[200]),
          transition: 'all 100ms ease-in-out',
          '&:hover': {
            color: !active ? getColorByIndex(index) : getColorByIndex(index),
          },
        }}
        onClick={(): void => changeActive(device)}
      >
        <Icon
          sx={{
            color: 'inherit',
            mr: 0.5,
            transition: 'all 100ms ease-in-out',
          }}
        >
          {trackingLevel === 'Advanced' ? 'local_shipping' : 'contactless'}
        </Icon>
        <KNTypography
          data-test="serie-name"
          variant="textLG"
          color={active ? 'dark.main' : 'grey.200'}
          sx={{
            textDecoration: active ? 'none' : 'line-through',
            textDecorationThickness: ({ functions: { pxToRem } }: Theme): number => pxToRem(2),
          }}
        >
          {device}
        </KNTypography>
      </Box>
    </Box>
  )
}

export default FilterItemTemplate
