import { useEffect, ReactElement } from 'react'
import { useHistory } from 'react-router-dom'

// @mui material imports
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN Modules
import BruceBanner from 'modules/BruceBanner/BruceBanner'
import ShipmentsInsight from 'screens/ShipmentsInsight/ShipmentsInsight'

// Context
import { useNavigationContext } from 'context/navigation/NavigationContext'
import { useSearchContext } from 'context/search/SearchContext'

// Driver guide
import { getWelcomeGuide } from './VisibilityDashboard.guide'

// Functional
import { getUserPreferences } from 'screens/UserManager/UserManager.service'

// Types
import { WelcomeGuideStatus } from 'screens/UserManager/UserManager.types'

const VisibilityDashboard = (): ReactElement => {
  const matches = useMediaQuery('(min-width:600px)')
  const { dispatchSearchState } = useSearchContext()
  const { navigationState, dispatch } = useNavigationContext()
  const history = useHistory()

  const setScrollPosition = (): void => {
    if (history.location.pathname !== '/visibility-dashboard')
      dispatch({
        type: 'setNavigationProps',
        payload: {
          ...navigationState.navigationContext,
          scrollPosition: window.scrollY,
        },
      })
  }

  useEffect(() => {
    dispatch({
      type: 'setNavigationProps',
      payload: {
        ...navigationState.navigationContext,
        breadcrumbHome: {
          name: 'Visibility dashboard',
          link: '/visibility-dashboard',
        },
        breadcrumbActive: '',
        activeLink: ``,
      },
    })
    dispatchSearchState({
      type: 'setSearchProps',
      payload: {
        activeContext: 'ShipmentSearch',
      },
    })
    history.listen(setScrollPosition)
  }, [])

  const welcomeGuide = async (): Promise<void> => {
    await getUserPreferences()
      .then((response) => {
        if (response.welcomeGuide === WelcomeGuideStatus.PENDING) {
          const guide = getWelcomeGuide(true)
          guide.drive()
        }
      })
      .catch((error) => console.log(error))
  }

  useEffect(() => {
    void welcomeGuide()
  }, [])

  return (
    <>
      <BruceBanner moduleName="cv" />
      <Box sx={{ display: matches ? 'inline-flex' : 'block', width: '100%' }} mt={2}>
        <ShipmentsInsight />
      </Box>
    </>
  )
}

export default VisibilityDashboard
