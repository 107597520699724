import { useState, lazy, Suspense } from 'react'

// @mui imports
import Box from '@mui/material/Box'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import useMediaQuery from '@mui/material/useMediaQuery'

// KN imports
import theme from 'assets/theme'
import { IconName } from 'components/KN_Components/Base/Icons/Icon.type'
import ScannerProps from './Scanner.type'
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import Icon from 'components/KN_Components/Base/Icons/Icon'
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'

const BarcodeScannerComponent = lazy(() => import('react-qr-barcode-scanner'))

const Scanner: React.FC<ScannerProps> = ({ type = 'barcode', onChange, scannerOpen = false, dataAttribute }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [isStopped, toggleStream] = useState(false)
  const [isOpen, openScanner] = useState(scannerOpen)

  const onScanCode = (err, result): void => {
    if (result) {
      onChange(result.text)
      openScanner(false)
      toggleStream(false)
    }
  }

  return (
    <>
      <KNButton
        onClick={(): void => openScanner(true)}
        color="primary"
        variant="contained"
        fullWidth
        startIcon={
          <Icon name={type === 'qrcode' ? IconName.qrcode : IconName.barcode} color="white" colorType={null} />
        }
        data-test={`${String(dataAttribute)}-button`}
      >
        {isMobile ? '' : 'Scan'}
      </KNButton>
      <Dialog open={isOpen} onClose={(): void => openScanner(false)}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={(): void => openScanner(false)}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Box p={2}>
          <Suspense fallback={<KNLoader />}>
            <BarcodeScannerComponent onUpdate={onScanCode} stopStream={isStopped} />
          </Suspense>
        </Box>
      </Dialog>
    </>
  )
}

export default Scanner
