import i18n from 'i18n'

export const liveCountersTranslations = (): Record<any, any> => {
  const liveCounters = 'modules.cv.live_counters'

  return {
    translation: {
      counterActive: i18n.t(`${liveCounters}.active`),
      counterAwaitingForPickup: i18n.t(`${liveCounters}.awaiting_for_pickup`),
      counterDelayedDeliveries: i18n.t(`${liveCounters}.delayed_deliveries`),
      counterTrackingActive: i18n.t(`${liveCounters}.tracking_active`),
      counterDeviations: i18n.t(`${liveCounters}.deviations`),
      descriptionActive: i18n.t(`${liveCounters}.description.active`),
      descriptionAwaitingForPickup: i18n.t(`${liveCounters}.description.awaiting_for_pickup`),
      descriptionDelayedDeliveries: i18n.t(`${liveCounters}.description.delayed_deliveries`),
      descriptionTrackingActive: i18n.t(`${liveCounters}.description.tracking_active`),
      descriptionDeviations: i18n.t(`${liveCounters}.description.deviations`),
    },
  }
}
