import { forwardRef } from 'react'

// Types
import KNSliderProps from './types'

// Custom KN styles
import KNSliderRoot from './KNSliderRoot'

const KNSlider = forwardRef<HTMLElement, KNSliderProps>(
  ({ children, ...rest }, ref) => (
    <KNSliderRoot {...rest} ref={ref}>
      {children}
    </KNSliderRoot>
  )
)

export default KNSlider
