import { useCallback, useEffect, useState } from 'react'
import { CircleF, GoogleMap, Marker, PolygonF, useJsApiLoader } from '@react-google-maps/api'

// @mui imports //
import Box from '@mui/material/Box'

// KN imports //
import borders from 'assets/theme/base/borders'
import theme from 'assets/theme'

// Functional //
import { gradientMarkerIcon } from 'modules/VisibilityMap/VisibilityMapMarker/VisibilityMapMarker.helpers'
import { getShipmentGeofences } from 'screens/Geofence/Geofence.service'

// Types //
import { GeofenceData } from 'screens/Geofence/Geofence.types'
import { GeofenceSnippetProps } from './Snippets.types'

const GeofenceSnippet: React.FC<GeofenceSnippetProps> = ({ location, direction, shipmentId }) => {
  // Module state //
  const [geofenceData, setGeofenceData] = useState<GeofenceData[]>([])

  const { borderRadius } = borders
  const { warning } = theme.palette

  // Google Map config
  const mapKey = process.env.REACT_APP_MAPS_API_KEY ? process.env.REACT_APP_MAPS_API_KEY : ''

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: mapKey,
    libraries: ['drawing'],
  })

  const [map, setMap] = useState<{
    center?: google.maps.LatLng | google.maps.LatLngLiteral | undefined
  }>({})

  const onLoad = useCallback(function callback(map: any) {
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback() {
    setMap({})
  }, [])

  useEffect(() => {
    if (!shipmentId || !direction) return
    const getData = async (): Promise<void> => {
      const data: GeofenceData[] = await getShipmentGeofences(shipmentId)
      setGeofenceData(data)
    }
    void getData()
  }, [shipmentId])

  return isLoaded && location ? (
    <Box py={2}>
      <GoogleMap
        mapContainerStyle={{
          height: '100px',
          borderRadius: borderRadius.lg,
        }}
        options={{
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          keyboardShortcuts: false,
        }}
        center={{ lat: location.latitude, lng: location.longitude }}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {geofenceData.length > 0 &&
          geofenceData.map((geofence, index) => {
            if (geofence.shape === 'radius') {
              return (
                <CircleF
                  key={index}
                  center={{ lat: geofence.center?.latitude ?? 0, lng: geofence.center?.longitude ?? 0 }}
                  radius={geofence.radius ?? 0}
                  options={{
                    fillColor: warning.main,
                    strokeWeight: 0,
                  }}
                />
              )
            }
            if (geofence.shape === 'polygon') {
              return (
                <PolygonF
                  key={index}
                  path={geofence.points?.map((point) => ({ lat: point.latitude, lng: point.longitude }))}
                  options={{
                    fillColor: warning.main,
                    strokeWeight: 0,
                  }}
                />
              )
            }
          })}
        <Marker position={new google.maps.LatLng(location.latitude, location.longitude)} icon={gradientMarkerIcon} />
      </GoogleMap>
    </Box>
  ) : null
}

export default GeofenceSnippet
