import { useState, useEffect, useContext, ReactElement } from 'react'

// @mui imports
import Stack from '@mui/material/Stack'

// KN imports
import { StopsViewContext } from 'context/trips/StopsViewContext'
import GroupedStopsCard from './GroupedStopsCard'
import { TripData } from 'screens/TripDashboard/TripDashboard.types'
import { getGroupedStops } from './TripDetails.helpers'
import { LegData, StopData, StopsGroup } from './TripDetails.types'

export interface StopsViewProps {
  trip: TripData
  legs: LegData[]
  weblinkToken?: string
  onChange: (updatedStops: StopData[]) => void
}

const StopsView = ({ trip, legs, weblinkToken, onChange }: StopsViewProps): ReactElement => {
  const [groupedStopsData, setGroupedStopsData] = useState<StopsGroup[]>([])
  const [stopsViewState, stopsViewDispatch] = useContext(StopsViewContext)

  useEffect(() => {
    let activeStopsGroupSequenceSet = false
    const groupedStops = getGroupedStops(legs).map((group) => {
      if (
        group.state !== 'completed' &&
        stopsViewState.activeStopsGroupSequence === -1 &&
        !activeStopsGroupSequenceSet
      ) {
        stopsViewDispatch({
          type: 'setActiveStopsGroupSequence',
          payload: group.sequence,
        })
        activeStopsGroupSequenceSet = true
      }
      return group
    })
    setGroupedStopsData(groupedStops)
  }, [legs])

  return (
    <Stack spacing={2} sx={{ flexGrow: 1 }}>
      {groupedStopsData.map((group) => (
        <GroupedStopsCard
          key={group.sequence}
          trip={trip}
          group={group}
          weblinkToken={weblinkToken}
          onChange={onChange}
        />
      ))}
    </Stack>
  )
}

export default StopsView
