import { useEffect, useState } from 'react'

// @mui imports
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Grid from '@mui/material/Grid'

// Functional
import LiveCounterTemplate from './LiveCounterTemplate'
import { getCounters } from './LiveCounters.service'
import { getCountersData } from './LiveCounters.helpers'
import { hasVisibilityLevel } from 'global/helpers/authorization'
import { getErrorMessage } from 'global/helpers/errorHandler'

//Context
import { useDashboardContext } from 'context/dashboard/DashboardContext'

// Types
import { CounterProps } from './LiveCounters.types'
import { VisibilityLevel } from 'context/authentication/Company.types'
import { usePostHog } from 'posthog-js/react'

interface LiveCountersWrapperProps {
  setLoading?: (loadingState: boolean) => void
}

const LiveCountersWrapper: React.FC<LiveCountersWrapperProps> = (props) => {
  // Props
  const { setLoading } = props
  // Context
  const { dashboardState, dispatchDashboardState } = useDashboardContext()
  const { dashboardContext } = dashboardState
  const { counterFilterValue } = dashboardContext
  // Module state
  const [dataLoading, setDataLoading] = useState(true)
  useEffect(() => {
    setLoading && setLoading(dataLoading)
  }, [dataLoading])

  const [error, setError] = useState<string>()
  const [action, setAction] = useState<string>()
  // Data
  // TODO: Fetch and watch real data once new DB is implemented
  const [basicLiveCountersData, setBasicLiveCountersData] = useState<CounterProps[]>()
  const [advancedLiveCountersData, setAdvancedLiveCountersData] = useState<CounterProps[]>()
  const [maxLiveCountersData, setMaxLiveCountersData] = useState<CounterProps[]>()
  // Event tracking
  const posthog = usePostHog()

  const filterActive = (): void => {
    dispatchDashboardState({
      type: 'setDashboardProps',
      payload: {
        counterFilterValue: 'ACTIVE',
        pageNumber: 1,
      },
    })
  }

  const filterAwaitingForPickup = (): void => {
    dispatchDashboardState({
      type: 'setDashboardProps',
      payload: {
        counterFilterValue: 'AWAITING_FOR_PICKUP',
        pageNumber: 1,
      },
    })
  }

  const filterDelayedDelivery = (): void => {
    dispatchDashboardState({
      type: 'setDashboardProps',
      payload: {
        counterFilterValue: 'DELAYED_DELIVERY',
        pageNumber: 1,
      },
    })
  }

  const filterTrackingActive = (): void => {
    dispatchDashboardState({
      type: 'setDashboardProps',
      payload: {
        counterFilterValue: 'TRACKING_ACTIVE',
        pageNumber: 1,
      },
    })
  }

  const filterDeviations = (): void => {
    dispatchDashboardState({
      type: 'setDashboardProps',
      payload: {
        counterFilterValue: 'TRACKING_DEVIATION',
        pageNumber: 1,
      },
    })
  }

  const filterReset = (): void => {
    dispatchDashboardState({
      type: 'setDashboardProps',
      payload: {
        counterFilterValue: undefined,
        pageNumber: 1,
      },
    })
  }

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setDataLoading(true)
      try {
        const allCounters = await getCounters()
        const { basicCounters, advancedCounters, maxCounters } = getCountersData(
          setAction,
          allCounters,
          counterFilterValue
        )
        setBasicLiveCountersData(basicCounters)
        setAdvancedLiveCountersData(advancedCounters)
        setMaxLiveCountersData(maxCounters)
        setAction(undefined)
      } catch (error) {
        setError(getErrorMessage(error))
      }
      setDataLoading(false)
    }
    void fetchData()
  }, [counterFilterValue])

  useEffect(() => {
    switch (action) {
      case '':
        filterReset()
        break
      case 'AWAITING_FOR_PICKUP':
        filterAwaitingForPickup()
        break
      case 'ACTIVE':
        filterActive()
        break
      case 'DELAYED_DELIVERY':
        filterDelayedDelivery()
        break
      case 'TRACKING_ACTIVE':
        filterTrackingActive()
        break
      case 'TRACKING_DEVIATION':
        filterDeviations()
        break
    }
  }, [action])

  useEffect(() => {
    posthog?.capture('Clicked Live Counter', { counter: action })
  }, [posthog, action])

  const getCounterElement = (liveCounter: CounterProps, key): React.ReactElement => (
    <Grid sx={{ cursor: 'pointer' }} key={key} item xs={12} md={4} onClick={liveCounter.onClick}>
      <LiveCounterTemplate liveCounter={liveCounter} />
    </Grid>
  )

  return (
    <Box py={2}>
      {error && <Alert severity="error">{error}</Alert>}
      <Grid container spacing={3} id="live-counters-container">
        {basicLiveCountersData?.map((liveCounter, index) => getCounterElement(liveCounter, index))}
        {!hasVisibilityLevel(VisibilityLevel.Basic) &&
          advancedLiveCountersData?.map((liveCounter, index) => getCounterElement(liveCounter, index))}
        {hasVisibilityLevel(VisibilityLevel.Max) &&
          maxLiveCountersData?.map((liveCounter, index) => getCounterElement(liveCounter, index))}
      </Grid>
    </Box>
  )
}

export default LiveCountersWrapper
