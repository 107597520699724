import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'

// @mui imports
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'

// KN imports
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'

// Functional
import { deleteMonitoringRule } from './MonitoringRulesTable.service'
import { getErrorMessage } from 'global/helpers/errorHandler'
import { sleep } from 'global/helpers/sleep'
import { analyticsEvent } from 'global/helpers/analytics'

// Data
import { notificationCenterTranslations } from 'screens/NotificationCenter/NotificationCenter.data'

// Types
import { DeleteUserDialogProps } from './MonitoringRulesTable.types'

const DeleteMonitoringRuleDialog = ({ payload, open, onAction, onClose }: DeleteUserDialogProps): ReactElement => {
  const { translation } = notificationCenterTranslations()
  const { handleSubmit, formState, setError } = useForm()

  const onSubmit: SubmitHandler<object> = async (): Promise<void> => {
    try {
      await deleteMonitoringRule(payload.monitoringRule.cid)
      await sleep(300)
      analyticsEvent('polestar_cv_monitoring_rule_deleted', [payload.monitoringRule.cid])
      window.location.reload()
      onAction()
    } catch (error) {
      setError('root.server', {
        message: getErrorMessage(error),
      })
    }
  }

  return (
    <KNDialog
      open={open}
      onClose={onClose}
      closeLabel={translation.cancelDelete}
      preventClosing={formState.isSubmitting}
      title={`${String(payload.monitoringRule.type)} — ${String(translation.delete)}`}
      actions={
        <>
          <KNLoadingButton
            type="submit"
            color="error"
            variant="contained"
            loading={formState.isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {translation.delete}
          </KNLoadingButton>
        </>
      }
      onSubmit={handleSubmit(onSubmit)}
      maxWidth="xs"
    >
      {formState.errors?.root?.server && (
        <DialogContentText component="div" mb={3}>
          <Alert severity="error">{formState.errors.root.server.message}</Alert>
        </DialogContentText>
      )}
      <DialogContentText>{translation.deleteMessage}</DialogContentText>
    </KNDialog>
  )
}

export default DeleteMonitoringRuleDialog
