import { useState, useEffect, ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import FadeIn from 'react-fade-in/lib/FadeIn'

// @mui material imports
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'

// KN Components
import KNLoader from 'components/KN_Molecules/KNLoader/KNLoader'
import MonitoringRuleForm from 'modules/MonitoringRuleForm/MonitoringRuleForm'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Functional
import { getMonitoringRule } from './MonitoringRuleCreateOrEdit.service'

// Data
import { monitoringRuleTranslations } from './MonitoringRuleCreateOrEdit.data'

// Types
import { MonitoringRule } from 'modules/MonitoringRulesTable/MonitoringRulesTable.types'

const MonitoringRuleCreateOrEdit = (): ReactElement => {
  const { action, ruleCid } = useParams<{
    action: string
    ruleCid?: string
  }>()
  const [ruleData, setRuleData] = useState<MonitoringRule>()
  const [loading, setLoading] = useState(false)

  const { translation } = monitoringRuleTranslations()

  const fetchData = async (): Promise<void> => {
    if (!ruleCid) return
    setLoading(true)
    setRuleData(await getMonitoringRule(ruleCid))
    setLoading(false)
  }

  useEffect(() => {
    if (action === 'edit') {
      void fetchData()
    }
  }, [])

  return loading ? (
    <Box>
      <FadeIn visible={loading}>
        <KNLoader />
      </FadeIn>
    </Box>
  ) : (
    <FadeIn visible={!loading}>
      <Paper elevation={8} sx={{ padding: 2 }}>
        <Stack
          spacing={1}
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems={{ xs: 'start', md: 'center' }}
          mb={2}
        >
          <KNTypography variant="displayMD_SB" color="primary.focus">
            {action === 'edit' ? translation.screenNameEdit : translation.screenNameNew}
          </KNTypography>
        </Stack>
        <MonitoringRuleForm payload={ruleData} />
      </Paper>
    </FadeIn>
  )
}

export default MonitoringRuleCreateOrEdit
