import i18n from 'i18n'

export const scannerTranslations = (code?: string): Record<any, any> => {
  const scanner = 'modules.cv.pair_devices.scanners'
  return {
    translation: {
      log: i18n.t(`${scanner}.log`),
      result: i18n.t(`${scanner}.result`),
      devicesTip: i18n.t(`${scanner}.devices_tip`),
      pairing: i18n.t(`${scanner}.pairing`),
      connectedDevices: i18n.t(`${scanner}.connected_devices`),
      noResults: i18n.t(`${scanner}.no_results`),
      next: i18n.t(`${scanner}.next`),
      finish: i18n.t(`${scanner}.finish`),
      landscape: i18n.t(`${scanner}.landscape`),
      codes: {
        active: i18n.t(`${scanner}.codes.active`, {
          code: code,
        }),
        active_devices: i18n.t(`${scanner}.codes.active_devices`, {
          code: code,
        }),
        started: i18n.t(`${scanner}.codes.started`, {
          code: code,
        }),
        barcode_scanned: i18n.t(`${scanner}.codes.barcode_scanned`, {
          code: code,
        }),
        first_barcode: i18n.t(`${scanner}.codes.first_barcode`, {
          code: code,
        }),
        qr_error: i18n.t(`${scanner}.codes.qr_error`, {
          code: code,
        }),
        qr_added: i18n.t(`${scanner}.codes.qr_added`, {
          code: code,
        }),
        qr_added_more: i18n.t(`${scanner}.codes.qr_added_more`, {
          code: code,
        }),
        qr_inuse: i18n.t(`${scanner}.codes.qr_inuse`, {
          code: code,
        }),
        barcode_needs_qr: i18n.t(`${scanner}.codes.barcode_needs_qr`, {
          code: code,
        }),
        barcode_inuse: i18n.t(`${scanner}.codes.barcode_inuse`, {
          code: code,
        }),
      },
    },
  }
}
