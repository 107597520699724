import { ReactElement, useContext } from 'react'
import format from 'date-fns/format'
import { utcToZonedTime } from 'date-fns-tz'

// @mui material imports //
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'
import Tooltip from '@mui/material/Tooltip'

// KN Components //
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'

// Functional //
import { getColorByIndex } from 'global/helpers/styling'

// Types //
import { TrackingDeviceInfoProps } from './TrackingInfo.types'

// Data //
import { trackingInfoTranslation } from './TrackingInfo.data'

const TrackingInfoItemTemplate = (
  trackingInfoItem: TrackingDeviceInfoProps,
  index: number,
  userTimezone: string,
  level?: string
): ReactElement => {
  // Translated Data
  const { translation } = trackingInfoTranslation()

  const timeZonedTimestamp = trackingInfoItem.timestamp
    ? utcToZonedTime(trackingInfoItem.timestamp, userTimezone)
    : undefined

  return (
    <Box data-test={`trackingDetailsInfoItem-${index}`} key={index}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Tooltip
          title={
            level === 'Advanced' ? `${translation.license_plate as string}` : `${translation.wireless_device as string}`
          }
        >
          <Icon sx={{ color: getColorByIndex(index), mr: 0.5 }}>
            {level === 'Advanced' ? 'local_shipping' : 'contactless'}
          </Icon>
        </Tooltip>
        <KNTypography variant="textLG" color="dark.main">
          {trackingInfoItem.deviceId}
        </KNTypography>
      </Box>
      <KNTypography variant="textSM" color="grey.400">
        {translation.lastUpdate} {timeZonedTimestamp && format(new Date(timeZonedTimestamp), 'dd/MM/yyyy HH:mm')}
      </KNTypography>
    </Box>
  )
}

export default TrackingInfoItemTemplate
