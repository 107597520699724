export interface GeoPoint {
  latitude: number
  longitude: number
}

export enum GeofenceType {
  ENTER_ZONE = 'ENTER_ZONE',
  EXIT_ZONE = 'EXIT_ZONE',
  ENTER_OR_EXIT_ZONE = 'ENTER_OR_EXIT_ZONE',
}

export interface GeofenceData {
  cid: string
  shipmentCid: string
  userId: string
  groupId: string
  points: GeoPoint[]
  createdAt: string
  updatedAt: string
  type: GeofenceType
  shape: 'polygon' | 'radius'
  center?: { latitude: number; longitude: number }
  radius?: number
}

export interface GeofencePolygon {
  cid: string | null
  polygon: google.maps.Polygon
  type: GeofenceType
}

export interface GeofenceCircle {
  cid: string | null
  circle: google.maps.Circle
  type: GeofenceType
}

export interface GeofenceMapProps {
  geofenceData?: GeofenceData[]
  saveGeofence: (geofence: GeofencePolygon | GeofenceCircle | null) => void
  deleteGeofence: (cid: string | null) => void
}

export interface GeofenceRequestBody {
  points?: GeoPoint[]
  center?: { latitude: number; longitude: number }
  radius?: number
  type?: GeofenceType
  shape: 'polygon' | 'radius'
}
