import { useState, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'

// @mui imports
import DialogContentText from '@mui/material/DialogContentText'
import Alert from '@mui/material/Alert'

// KN imports
import { analyticsEvent } from 'global/helpers/analytics'
import KNDialog from 'components/KN_Molecules/KNDialog/KNDialog'
import KNDialogFormErrors from 'components/KN_Molecules/KNDialog/KNDialogFormErrors'
import { processServerErrorMessages } from 'global/helpers/form'
import KNLoadingButton from 'components/KN_Components/Base/KNLoadingButton/KNLoadingButton'
import { DriverData } from './DriverManager.types'
import { deleteDriver } from './DriverManager.service'
import { getDataForDeleteDriver } from './DriverManager.helpers'

interface DeleteDriverDialogPayload {
  driver: DriverData
}

interface DeleteDriverDialogProps {
  payload: DeleteDriverDialogPayload
  open: boolean
  onAction: (updatedDriver: DriverData, action: string) => void
  onClose: () => void
}

const DeleteDriverDialog = ({ payload, open, onAction, onClose }: DeleteDriverDialogProps): ReactElement => {
  const { t } = useTranslation()
  const { handleSubmit, formState, setError } = useForm()

  const onSubmit: SubmitHandler<object> = async (): Promise<void> => {
    try {
      await deleteDriver(payload.driver.cid)
      onAction(getDataForDeleteDriver(payload.driver), 'delete')
      analyticsEvent('polestar_cs_driver_deleted')
    } catch (error) {
      setError('root', processServerErrorMessages(error))
    }
  }

  return (
    <KNDialog
      open={open}
      onClose={onClose}
      closeLabel={t('general.cancel')}
      preventClosing={formState.isSubmitting}
      title={`${payload.driver.name} — ${t('screens.cs.driver_manager.card.actions.delete')}`}
      actions={
        <>
          <KNLoadingButton
            type="submit"
            color="error"
            variant="contained"
            loading={formState.isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {t('screens.cs.driver_manager.delete_driver.delete')}
          </KNLoadingButton>
        </>
      }
      onSubmit={handleSubmit(onSubmit)}
      maxWidth="xs"
    >
      <KNDialogFormErrors errors={formState.errors?.root} />
      <DialogContentText>{t('screens.cs.driver_manager.delete_driver.confirmation')}</DialogContentText>
    </KNDialog>
  )
}

export default DeleteDriverDialog
