import React, { useState, useEffect, useRef, useCallback, useContext, ReactElement, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

// @mui imports
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Alert from '@mui/material/Alert'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import EditNoteIcon from '@mui/icons-material/EditNote'
import Collapse from '@mui/material/Collapse'

// KN imports
import { StopsViewContext } from 'context/trips/StopsViewContext'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import { KNDataCardAction } from 'components/KN_Molecules/KNDataCard/types'
import StatusUpdateWithAttachmentsDialog from 'screens/StatusManager/StatusUpdateWithAttachmentsDialog'
import DocumentsUploadDialog from 'screens/StatusManager/DocumentsUploadDialog'
import DocumentLink from 'screens/StatusManager/DocumentLink'
import LocationLink from 'screens/TripDashboard/LocationLink'
import LegHeader from './LegHeader'
import LegCargo from './LegCargo'
import StopHeader from './StopHeader'
import SecondaryActions from './SecondaryActions'
import { GroupedStopsCardPrimaryStopProps } from './GroupedStopsCard.types'
import { StopData } from './TripDetails.types'

const GroupedStopsCardPrimaryStop = ({
  trip,
  leg,
  stop,
  weblinkToken,
  onChange,
  collapsed = false,
}: GroupedStopsCardPrimaryStopProps): ReactElement => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const location = useLocation<{ wayPointCids: string[] }>()
  const [stopsViewState, stopsViewDispatch] = useContext(StopsViewContext)
  const [statusUpdateWithAttachmentsDialogOpen, setStatusUpdateWithAttachmentsDialogOpen] = useState(false)
  const [documentsUploadDialogOpen, setDocumentsUploadDialogOpen] = useState(false)
  const deliveryStop: StopData | null =
    stop.type === 'PUP' ? leg.wayPoints.filter((stop) => stop.type === 'DEL')?.[0] ?? null : null
  const stopErrors = stopsViewState.stopsErrors?.filter((error) => error.waypointCid === stop.wayPointCid)

  const handleStatusUpdateClick = useCallback((): void => setStatusUpdateWithAttachmentsDialogOpen(true), [])
  const handleStatusUpdateAction = useCallback((updatedStops: StopData[]): void => {
    setStatusUpdateWithAttachmentsDialogOpen(false)
    onChange?.(updatedStops)
  }, [])
  const handleStatusUpdateClose = useCallback((): void => {
    setStatusUpdateWithAttachmentsDialogOpen(false)
  }, [])

  const handleDocumentsUploadClick = useCallback((): void => setDocumentsUploadDialogOpen(true), [])
  const handleDocumentsUploadAction = useCallback((updatedStops: StopData[]): void => {
    setDocumentsUploadDialogOpen(false)
    onChange?.(updatedStops)
  }, [])
  const handleDocumentsUploadClose = useCallback((): void => {
    setDocumentsUploadDialogOpen(false)
  }, [])

  const actions: KNDataCardAction[] = []
  if (stop.availableStatuses.length > 0) {
    actions.push({
      label: t('screens.cs.trip_details.card.actions.update_status_and_documents'),
      icon: <EditNoteIcon />,
      handler: handleStatusUpdateClick,
    })
  } else {
    actions.push({
      label: t('screens.cs.trip_details.card.actions.upload_documents'),
      icon: <UploadFileIcon />,
      handler: handleDocumentsUploadClick,
    })
  }

  return (
    <>
      {stopErrors.length > 0 && (
        <Alert severity="error" sx={{ marginBottom: 1 }}>
          {stopErrors.map((error, index) => (
            <Fragment key={index}>
              {index > 0 && <br />}
              {error.message}
            </Fragment>
          ))}
        </Alert>
      )}
      <Stack
        spacing={2}
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'stretch', md: 'start' }}
      >
        <Box sx={{ width: '100%' }}>
          <Grid container direction="row" alignItems="center">
            <Grid item md={6} sx={{ marginBottom: 1 }}>
              <StopHeader stop={stop} />
            </Grid>
            <Grid item md={6} sx={{ marginBottom: 1 }}>
              <LegHeader trip={trip} leg={leg} />
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item md={6}>
              <Box>
                <KNTypography variant="textMD_SB" color="primary.light">
                  {t('screens.cs.trip_dashboard.card.columns.cargo')}
                </KNTypography>
                <LegCargo leg={leg} />
              </Box>
              {stop.documents && (
                <Box>
                  <KNTypography variant="textMD_SB" color="primary.light">
                    {t('screens.cs.trip_details.card.attachments')}
                  </KNTypography>
                  <KNTypography color="text.main" fontSize="0.875rem">
                    {stop.documents
                      .filter((file) => ['POP', 'POD'].includes(file.fileType))
                      .map((file, index) => (
                        <DocumentLink
                          key={file.id}
                          file={file}
                          index={index > 1 ? index : undefined}
                          weblinkToken={weblinkToken}
                        />
                      ))}
                    {stop.documents
                      .filter((file) => !['POP', 'POD'].includes(file.fileType))
                      .map((file, index) => (
                        <DocumentLink key={file.id} file={file} weblinkToken={weblinkToken} />
                      ))}
                  </KNTypography>
                </Box>
              )}
            </Grid>
            {deliveryStop && (
              <Grid item md={6}>
                <KNTypography variant="textMD_SB" color="primary.light">
                  {t('screens.cs.trip_details.card.delivery_address')}
                </KNTypography>
                <LocationLink
                  countryCode={deliveryStop.address.countryCode}
                  city={deliveryStop.address.city.join(', ')}
                  zipCode={deliveryStop.address.zipCode}
                  street={deliveryStop.address.street.join(', ')}
                  marker={
                    deliveryStop.geoPoint
                      ? {
                          coords: {
                            lat: deliveryStop.geoPoint.latitude,
                            lng: deliveryStop.geoPoint.longitude,
                          },
                          type: deliveryStop.type,
                        }
                      : undefined
                  }
                  lineBreaks={isMobile ? 'city' : undefined}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        {actions.length > 0 && (
          <SecondaryActions
            actions={actions}
            sx={{ marginTop: { xs: '0.5rem !important', md: '0.125rem !important' }, minWidth: '76px' }}
          />
        )}
      </Stack>

      <StatusUpdateWithAttachmentsDialog
        payload={{
          trip,
          leg,
          stop,
          weblinkToken,
          useDeviceTime: !!weblinkToken && isMobile, // NOTE: use device time on weblink mobile
        }}
        open={statusUpdateWithAttachmentsDialogOpen}
        onAction={handleStatusUpdateAction}
        onClose={handleStatusUpdateClose}
      />

      <DocumentsUploadDialog
        payload={{
          trip,
          leg,
          stop,
          weblinkToken,
        }}
        open={documentsUploadDialogOpen}
        onAction={handleDocumentsUploadAction}
        onClose={handleDocumentsUploadClose}
      />
    </>
  )
}

export default GroupedStopsCardPrimaryStop
