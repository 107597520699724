import i18n from 'i18n'

export const pairDevicesTranslations = (): Record<any, any> => {
  const pairDevices = 'modules.cv.pair_devices'

  return {
    translation: {
      screenName: i18n.t(`${pairDevices}.screen_name`),
      manualInput: i18n.t(`${pairDevices}.manual_input`),
      mobileScan: i18n.t(`${pairDevices}.mobile_scan`),
      scanningDevices: i18n.t(`${pairDevices}.scanning_devices`),
      welcomeTitle: i18n.t(`${pairDevices}.welcome_screen.title`),
      welcomeText: i18n.t(`${pairDevices}.welcome_screen.text`),
      sessionsTitle: i18n.t(`${pairDevices}.pairing_sessions.title`),
      createSession: i18n.t(`${pairDevices}.pairing_sessions.create_session`),
      saveSession: i18n.t(`${pairDevices}.pairing_sessions.save_session`),
      paired: i18n.t(`${pairDevices}.pairing_sessions.paired`),
      status: i18n.t(`${pairDevices}.pairing_sessions.status`),
      completed: i18n.t(`${pairDevices}.pairing_sessions.completed`),
      saved: i18n.t(`${pairDevices}.pairing_sessions.saved`),
      required: i18n.t(`${pairDevices}.pairing_sessions.required`),
      optional: i18n.t(`${pairDevices}.pairing_sessions.optional`),
      shipmentId: i18n.t(`${pairDevices}.pairing_sessions.shipment_id`),
      description: i18n.t(`${pairDevices}.pairing_sessions.description`),
      deviceIds: i18n.t(`${pairDevices}.pairing_sessions.devices`),
      temperatureThreshold: i18n.t(`${pairDevices}.pairing_sessions.temperature_threshold`),
      autoUnpair: i18n.t(`${pairDevices}.pairing_sessions.auto_unpair`),
      presetValues: i18n.t(`${pairDevices}.pairing_sessions.preset_values`),
      notLowerThan: i18n.t(`${pairDevices}.pairing_sessions.not_lower_than`),
      notHigherThan: i18n.t(`${pairDevices}.pairing_sessions.not_higher_than`),
      autocompleteTooltip: i18n.t(`${pairDevices}.pairing_sessions.autocomplete_tooltip`),
      addRow: i18n.t(`${pairDevices}.pairing_sessions.add_row`),
      copyRow: i18n.t(`${pairDevices}.pairing_sessions.copy_row`),
      removeRow: i18n.t(`${pairDevices}.pairing_sessions.remove_row`),
      error: i18n.t(`${pairDevices}.pairing_sessions.error`),
      edit: i18n.t(`${pairDevices}.pairing_sessions.edit`),
      complete: i18n.t(`${pairDevices}.pairing_sessions.complete`),
      continueCamera: i18n.t(`${pairDevices}.pairing_sessions.continue_camera`),
      continueDevice: i18n.t(`${pairDevices}.pairing_sessions.continue_device`),
    },
  }
}
