import { ReactElement, memo, useEffect } from 'react'
import { Link } from 'react-router-dom'

// @mui imports
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'

// KN components
import KNButton from 'components/KN_Components/Base/KNButton/KNButton'
import KNTypography from 'components/KN_Components/Base/KNTypography/KNTypography'
import TruckNorris from './TruckNorris/TruckNorris'

// Functional
import { analyticsPageView } from 'global/helpers/analytics'
import { getTruckNorrisQuote } from './ErrorPage.helpers'

// Data
import { truckNorrisTranslations } from './ErrorPage.data'

const ErrorPage = (): ReactElement => {
  // Data
  const { translation } = truckNorrisTranslations()
  useEffect(() => {
    analyticsPageView('polestar/not_found')
  }, [])

  return (
    <Container maxWidth="sm">
      <Stack data-test="notfound-container" spacing={2} justifyContent="center" alignItems="center">
        <TruckNorris />
        <KNTypography variant="displaySM_SB">{getTruckNorrisQuote()}</KNTypography>
        <KNTypography variant="displayXS" color="dark.main">
          {translation.msg}
        </KNTypography>
        <Link to={'/'} style={{ textDecoration: 'none' }}>
          <KNButton variant="contained" color="primary" startIcon={<ArrowBackIcon />}>
            {translation.back}
          </KNButton>
        </Link>
      </Stack>
    </Container>
  )
}

export default memo(ErrorPage)
