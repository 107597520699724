import toDate from 'date-fns-tz/toDate'
import formatISO from 'date-fns/formatISO'

// Functional //
import { valueToLocation } from 'global/helpers/dataTransformation'

// Types //
import { CoordsProps, DevicesDataLocationProps, LocationProps } from './VisibilityMap.types'

export const getUniqueDevices = (devices: DevicesDataLocationProps[]): string[] => {
  return devices
    .map((device: DevicesDataLocationProps) => device.deviceId)
    .filter((value, index, device) => device.indexOf(value) === index)
}

export const getCenterLocation = (devices: DevicesDataLocationProps[]): CoordsProps | undefined => {
  const locationData = devices
  const latestTimestamp = Math.max(...locationData.map((o) => toDate(o.timestamp).getTime()))
  const lastPingedDevice = devices.filter((device) => toDate(device.timestamp).getTime() === latestTimestamp)[0]?.value
  const valuesToLocation = lastPingedDevice && valueToLocation(lastPingedDevice)
  const centerLocation = valuesToLocation ? { lat: valuesToLocation[0], lng: valuesToLocation[1] } : undefined
  return centerLocation
}

export const getDefaultCenterLocation = (location: { latitude: number; longitude: number }): CoordsProps => {
  return { lat: location.latitude, lng: location.longitude }
}

export const getCenterLocationBetween = (
  location1: { latitude: number; longitude: number },
  location2: { latitude: number; longitude: number }
): CoordsProps => {
  const centerLat = (location1.latitude + location2.latitude) / 2
  const centerLng = (location1.longitude + location2.longitude) / 2

  return { lat: centerLat, lng: centerLng }
}

export const setDevicesForMap = (
  devices: DevicesDataLocationProps[],
  filteredIds?: string[],
  filteredDateFrom?: Date,
  filteredDateTo?: Date
): {
  details: LocationProps[]
}[] => {
  const startDate = filteredDateFrom && formatISO(new Date(filteredDateFrom))
  const endDate = filteredDateTo ? formatISO(new Date(filteredDateTo)) : formatISO(new Date())

  const uniqueDevices = getUniqueDevices(devices)
  const filteredDevices = devices.filter(
    (b) =>
      startDate &&
      new Date(formatISO(new Date(b.timestamp))).getTime() > new Date(startDate).getTime() &&
      new Date(formatISO(new Date(b.timestamp))).getTime() < new Date(endDate).getTime()
  )
  const mappedDevices = startDate && endDate ? filteredDevices : devices
  const devicesForMap = uniqueDevices.map((uniqueDevice) => {
    return {
      details: mappedDevices
        ?.sort(function (a, b) {
          return a.deviceId.localeCompare(b.deviceId)
        })
        .filter((device) => device.deviceId === uniqueDevice)
        .map((device) => {
          return {
            timestamp: device.timestamp,
            deviceId: device.deviceId,
            lat: Number(device.value.split(',')[0].trim()),
            lng: Number(device.value.split(',')[1].trim()),
            visible: filteredIds?.includes(device.deviceId) ? false : true,
          }
        }),
    }
  })
  return mappedDevices.length > 0 ? devicesForMap : []
}

// Markers //
export const getFirstLocation = (locations: LocationProps[]): LocationProps => locations[0]

export const getLastLocation = (locations: LocationProps[]): LocationProps => locations[locations.length - 1]

export const getOtherLocations = (locations: LocationProps[]): LocationProps[] =>
  locations.length > 2 ? locations.slice(1, -1) : []
