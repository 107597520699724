import i18n from 'i18n'

export const cargoInfoTranslations = (): Record<any, any> => {
  const cargoInfo = 'modules.cv.cargo_info'
  return {
    translation: {
      moduleName: i18n.t(`${cargoInfo}.moduleName`),
      summary: i18n.t(`${cargoInfo}.summary`),
      packages: i18n.t(`${cargoInfo}.packages`),
      items: i18n.t(`${cargoInfo}.items`),
      cargo_content: i18n.t(`${cargoInfo}.cargo_content`),
      number_of_items: i18n.t(`${cargoInfo}.number_of_items`),
      weight: i18n.t(`${cargoInfo}.weight`),
      volume: i18n.t(`${cargoInfo}.volume`),
      notAvailable: i18n.t(`${cargoInfo}.notAvailable`),
    },
  }
}
